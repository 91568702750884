(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('TransactionDetailLoanController', TransactionDetailLoanController);

    TransactionDetailLoanController.$inject = ['Principal', '$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$translate', 'detailMode', 'transaction'];

    function TransactionDetailLoanController (Principal, $scope, $state, $stateParams, Loan, defaultPageSize, $translate, detailMode, transaction) {
        var vm = this;
        vm.kendo = kendo;
        vm.editMode = detailMode.edit;
      	vm.createMode = detailMode.create;
      	vm.currentTransaction = transaction ? transaction : {} ;
      	vm.tranId = $stateParams.tranId;
      	vm.balDate = new Date(vm.currentTransaction.date).toLocaleDateString('lt-LT');
      	
      	Principal.identity().then(function (account) {
      		vm.right = account.login === 'valsin' || account.authorities.indexOf("ROLE_DEVELOPER") >= 0 || account.authorities.indexOf("ROLE_ACCOUNTANT") >= 0;
      		if(!vm.right) $state.go('modules.loans.current.condition',{reload:true});
      	});
      	
      	vm.dateFormat = kendo.culture().calendar.patterns.d;
      	vm.pickerOptions = {
      		change: function(e){
      			$state.go("modules.loans.current.accounting.balances", { balDate: vm.balDate, tranId: vm.tranId }, {reload: true});
      		}
      	};
        console.log("TransactionDetailLoanController started");
        console.log(vm.currentTransaction.lines);
        vm.loanTransactionDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "lineNo",  
			    	fields: {
			    	  "lineNo": { type: "number" },
			          "cGl": { type: "string" },
			          "cGlTr": { type: "string" },
			          "component": { type: "string" },
			          "componentTr": { type: "string" },
			          "cre": { type: "number" },
			          "subAcc": { type: "string" },
			          "crtdBy": { type: "string" },
			          "crtdOn": { type: "date" },
			          "deb": { type: "number" },
			          "gl": { type: "string" },
			          "glTr": { type: "string" },
			          "updtBy": { type: "string" },
			          "updtOn": { type: "date" },
			          "ver": { type: "number" }
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.glTr = elem.gl ? $translate.instant('modules.accounting.gl.' + elem.gl) : "";
	                        elem.cGlTr = elem.cGl ? $translate.instant('modules.accounting.gl.' + elem.cGl) : "";
	                        elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                    });
	                	return d;
	              }
    		},
    		data: vm.currentTransaction.lines,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
	        aggregate: [ 
			            { field: "deb", aggregate: "sum"},
			            { field: "cre", aggregate: "sum"},
			],
            sort: [
                   { field: "lineNo", dir: "asc" }
            ]
        });
        
        vm.loanTransactionOptions = {
        		scrollable: true,
                sortable: false,                
                pageable: false,
                filterable: false,
                columns: [{ 
								field: "component", 
								template: "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#= componentTr #'\">#= component #</span> ",
							    title: "{{'modules.transaction.table.component'|translate}}",
							    width: "16ch"
							},{ 
								field: "gl", 
							    title: "{{'modules.transaction.table.gl'|translate}}",
							    template: "<span kendo-tooltip k-position=\"'top'\" k-content=\"'{{'#= glTr #' | translate }}'\">#= gl #</span> ",
							    width: "12ch",
							    footerTemplate: "{{'modules.transaction.table.total'|translate}}:",
						        footerAttributes: {class: "text-right"}
							},{ 
								field: "deb",
								title: "{{'modules.transaction.table.deb'|translate}}",
							    attributes: { class: "text-right" },
							    aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    format: "{0:n2}",
							    width: "10ch"
							},{ 
								field: "cre",
								title: "{{'modules.transaction.table.cre'|translate}}",
							    attributes: { class: "text-right" },
							    aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    format: "{0:n2}",
							    width: "10ch"
							},{ 
								field: "subAcc",
								title: "{{'modules.transaction.table.subAcc'|translate}}",
								width: "16ch"
							},{ 
								field: "glTr",
								title: "{{'modules.transaction.table.glTr'|translate}}",
								template: "{{'#= glTr #' | translate }}"
							},{
		                    	title: "{{'modules.transaction.table.actions'|translate}}",
		                    	template: "<button ng-hide='vm.createMode || vm.editMode' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.accounting.transaction.lineView({currentLine: this.dataItem })'>"
		                            + "<span class='glyphicon glyphicon-eye-open'></span>"
		                            + "</button>"
		                            + "<button ng-show='vm.createMode || vm.editMode' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.edit' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.accounting.transaction.lineEdit({currentLine: this.dataItem })'>"
		                            + "<span class='glyphicon glyphicon-pencil'></span>"
		                            + "</button>"
		                            + "<button ng-show='vm.createMode || vm.editMode' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.delete' | translate }}'\" class='btn btn-xs btn-danger k-button-icontext' ui-sref='modules.loans.current.accounting.transaction.lineDelete({currentLine: this.dataItem })'>"
		                            + "<span class='glyphicon glyphicon-remove-circle'></span>"
		                            + "</button>"
		                        ,
		                        width: "10ch"
		                    }
						]
                            	
        };
        
    }
    
})();
