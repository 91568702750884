(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('SalesChannelController', SalesChannelController);

    SalesChannelController.$inject = ['$scope', '$state', '$stateParams', '$location', "AlertService", "$translate", "baseUrl", "SalesChannel"];

    function SalesChannelController ($scope, $state, $stateParams, $location, AlertService, $translate, baseUrl, SalesChannel) {
        var vm = this;
        console.log("SalesChannelController starting");
        
        vm.partner = null;
        vm.office = null;
        vm.invalidPartner = false;
        vm.loadedReference = "";
        
        vm.errorMessage = "";
        vm.errorTimestamp = null;
    	vm.error = false;
        
        var request = {
    			module: null,
    			reference: null,
    			value: null,
    			officeId: null,
    			partnerId: null
    	};
        
        vm.isSalesChannelSelection = false;
        vm.isPartnerSelected = true;
        
        vm.salesChannelData = new kendo.data.DataSource({
    		data: [
    	    		{
    	    			id: "OTHER",
    					order: 6,
    					hidden: false,
    	    		},{
    	    			id: "WEB",
    					order: 1,
    					hidden: false,
    	    		},{
    	    			id: "LANDING_PAGE",
    					order: 2,
    					hidden: false,    	    			
    	    		},{
    	    			id: "PARTNER",
    					order: 4,
    					hidden: false
    	    		},{
    	    			id: "CC_CALL",
    					order: 3,
    					hidden: false,    	    			
    	    		},{
    	    			id: "BRANCHES",
    					order: 5,
    					hidden: false,    	    			
    	    		}    
    		],
			sort: {
				field: "order",
				dir: "asc"
			}
    	});
        
        vm.salesChannelModules = new kendo.data.DataSource({
    		data: [
			{
    			key: "application",
    			value: $translate.instant("modules.salesChannel.application")
    		},{
    			key: "loan",
    			value: $translate.instant("modules.salesChannel.loan")
    		}]
    	});
        
        vm.officeDataSource = new kendo.data.DataSource({
    		schema: {
			    model: {
			    	id: "id",
			        fields: {
			        	  "id": { type: "number" },
				          "offName": { type: "string"}
			        }
			    },
    		},
    		transport: {
				read: function(e){
					SalesChannel.getOffices(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('loan list error');
								e.error(error);
							}
					);
				}
			},
            sort: {
                field: "id"
            }
        });
        
        vm.partnerDataSource = new kendo.data.DataSource({
    		schema: {
			    model: {
			    	id: "id",
			        fields: {
			        	  "id": { type: "number" },
				          "prtName": { type: "string"}
			        }
			    },
    		},
    		transport: {
				read: function(e){
					SalesChannel.getPartners(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('loan list error');
								e.error(error);
							}
					);
				}
			},
            sort: {
                field: "id"
            }
        });
        
        vm.save = function () {
        	console.log("Save");
        	if (vm.salesChannel == "PARTNER" && vm.partner == 1){
        		alertError("modules.salesChannel.message.invalidPartner");
        		vm.invalidPartner = true;
        		return;
        	}
        	if (vm.salesChannel != "PARTNER"){
        		vm.partner = 1;
        	}
        	
        	request = {
        			module: vm.module,
        			reference: vm.reference,
        			channel: vm.salesChannel,
        			officeId: vm.office,
        			partnerId: vm.partner
        	};
        	SalesChannel.assignSalesChannel(request,
					function(response){
        				if(response) {
	        				AlertService.success('modules.salesChannel.message.successSave');
	        				$state.reload();
        				} else {
        					AlertService.error('modules.salesChannel.message.errorSave');
        					return;
        				}
					},
					function(error){
						console.log('interest rates list error');
					}
			);
        };	
        
        
        vm.cancel = function(){
            console.log("Cancel sales channel form");
            $state.reload();
        };
        
        vm.search = function(){
            console.log("Search");
            request = {
        			module: vm.module,
        			reference: vm.reference,
        	};

		    SalesChannel.getSalesChannel(request,
		    		function(response){
		    			vm.salesChannel= response.salesChannel;
		    			console.log(vm.salesChannel);
		    			vm.isSalesChannelSelection = true;
		    			vm.office = response.officeId;
		    			vm.partner = response.partnerId;
		    			vm.loadedReference = vm.reference;
		    			console.log(vm.office);
		    			console.log(vm.partner);
		    		},
		    		function(error){
		    			console.log('sales channel error');
		    		}
		    );
		    
        };
        
        function alertError(msg, params){
        	vm.errorMessage = $translate.instant(msg, params);
			vm.errorTimestamp = (new Date).getTime();
			vm.error = true;
        }
        
        $scope.$watch(
        	    function(){
        	    	return vm.error;
        	    },
        	    function(isError){
        	    	if(vm.errorMessage != "" && vm.errorTimestamp > (new Date).getTime() - 10000) {
	        	    	if(vm.errorMessage != vm.prevErrorMessage) {
	        	            AlertService.error(vm.errorMessage);
	        	    	} else {
	        	    		AlertService.clear();
	        	    		AlertService.error(vm.errorMessage);
	        	    	}
	        	    	vm.prevErrorMessage = vm.errorMessage;
	        	    	vm.error = false;
        	    	}
        	    }
            );

    }
    
})();
