(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterFirstCardController', WallesterFirstCardController);

    WallesterFirstCardController.$inject = ['$scope', '$state', 'Wallester', 'formHelper', 'defaultValue', '$translate', '$rootScope', 'AlertService', 'shortDateFormats', '$element', 'detailMode', 'cardTemplates', 'cardHolderAddresses', '$filter', 'menuWithHeader', 'defaultNonamePrefix', 'Principal'];

    function WallesterFirstCardController ($scope, $state, Wallester, formHelper, defaultValue, $translate, $rootScope, AlertService, shortDateFormats, $element, detailMode, cardTemplates, cardHolderAddresses, $filter, menuWithHeader, defaultNonamePrefix, Principal) {
        var vm = this;
        vm.kendo = kendo;
    	vm.shortDateFormats = shortDateFormats;
    	vm.phoneNoError = true;
    	
        var declaredAddress = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "DECLARED"}, false)[0] : null;
        var residentialAddress = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "RESIDENTIAL"}, false)[0] : null;
        var branchAddresses = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "BRANCH"}, false) : null;
        vm.selectedBranch = cardHolderAddresses != null ? $filter('filter')(branchAddresses, {isDefault: true}, false)[0] : null;

        vm.holder = defaultValue != null ? defaultValue : {};
        
        vm.isPepValue = "1";
        if (vm.holder.isPep != null)
        	vm.isPepValue = vm.holder.isPep ? "2" : "1";
        vm.isBeneficialOwnerValue = "2";
        if (vm.holder.isBeneficialOwner != null)
        	vm.isBeneficialOwnerValue = vm.holder.isBeneficialOwner ? "2" : "1";
        vm.isSanctionsRelatedValue = "1";
        if (vm.holder && vm.holder.isSanctionsRelated != null)
        	vm.isSanctionsRelatedValue = vm.holder.isSanctionsRelated ? "2" : "1";
        vm.isAdverseMediaInvolvedValue = "1";
        if (vm.holder && vm.holder.isAdverseMediaInvolved != null)
        	vm.isAdverseMediaInvolvedValue = vm.holder.isAdverseMediaInvolved ? "2" : "1";
        
        
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
        
        
        vm.setDelivery = function() {
        	changeAddress();
        };
        
//        vm.cancel = function(){
//        	console.log('cancel');
//        	if(detailMode.create || detailMode.noname) $state.go('modules.loans.current.condition');
//        	if(detailMode.edit) {
//        		vm.holder = cardHolder != null ? angular.copy(cardHolder) : {};
//        		$state.go('modules.loans.current.wallester-person');
//        	}
//        };

        
	    vm.createCard = function(){
	    	if (vm.isPepValue ==="2")
	    		vm.holder.isPep = true;
	    	else if (vm.isPepValue === "1")
	    		vm.holder.isPep = false;
	    	if (vm.isBeneficialOwnerValue === "2")
	    		vm.holder.isBeneficialOwner = true;
	    	else if (vm.isBeneficialOwnerValue === "1")
	    		vm.holder.isBeneficialOwner = false;

	    	if(!validateForCreate()) return;
        	Wallester.createCard(
            		{loan_no : $state.params.loan_no},
            		vm.holder,
            		function(result){
    		    		if(result) {
    		    			AlertService.success("modules.loan.message.createCardSuccess");
    		    		//	vm.holder = result;
    		    			$state.go('modules.loans.current.wallester-cards',{},{reload:true});
    		    		}
    	    		},
    	    		function(error){
    	    			console.log(error);
    	    		}
            ).$promise;
        };
        
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });

        vm.titleDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "Mr.",
    			value: "Mr."
    		},{
    			key: "Miss",
    			value: "Miss"
    		},{
    			key: "Mrs.",
    			value: "Mrs."
    		},{
    			key: "Ms.",
    			value: "Ms."
    		}]
    	});

        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "FEMALE",
    			value: $translate.instant("modules.loan.female")
    		},{
    			key: "MALE",
    			value: $translate.instant("modules.loan.male")
    		}]
    	});

        vm.expectedTurnoverDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "From0to1500",
    			value: "From 0 to 1500"
    		},{
    			key: "From1501to3000",
    			value: "From 1501 to 3000"
    		},{
    			key: "From3001to5000",
    			value: "From 3001 to 5000"
    		},{
    			key: "From5001to15000",
    			value: "From 5001 to 15000"
    		},{
    			key: "From15001AndMore",
    			value: "From 15001 And More"
    		}]
    	});
        
        vm.riskDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "Low",
    			value: $translate.instant("modules.loan.riskLow")
    		},{
    			key: "Medium",
    			value: $translate.instant("modules.loan.riskMedium")
    		},{
    			key: "High",
    			value: $translate.instant("modules.loan.riskHigh")
    		}]
    	});

        vm.deliveryMethodDataSource = new kendo.data.DataSource({
        	data: [
       			{
           			key: "StandardLatvianPostMail",
           			value: $translate.instant("modules.loan.deliveryByStandardLatvianPostMail")
           		},{
           			key: "DPDExpress",
           			value: $translate.instant("modules.loan.deliveryByDPDExpress")
           		}]
        });
        
        vm.branchChange = function(){
//        	console.log('TEST');
        };
        var widthCombo = 0;
        
        console.log("templates:");
        console.log(cardTemplates);
        
        vm.cardTemplateDataSource = new kendo.data.DataSource({
        	schema: {
				model: {
			        id: "code",
			        fields: {
			          "code": { type: "string"},
			          "name": { type: "string" },
			          "desc": { type: "string" },
			          "priority": { type: "number"}
			    	}
				}				
//                parse: function(d){
//	              	$.each(d, function (idx, elem) {
//	              		  var itemWidth =  elem.labelName.length + elem.fullAddress.length + 2;
//	              		  widthCombo = widthCombo > itemWidth ? widthCombo : itemWidth;   
//	                  });
//	              	return d;
//	            }
			},
			sort: {
				field: "priority",
				dir: "desc"
			},
			data: cardTemplates
    	});
        
        if (cardTemplates && cardTemplates.length == 1 && vm.holder.cardTemplateCode == null){
        	vm.holder.cardTemplateCode = cardTemplates[0].code;
        }

        vm.branchDataSource = new kendo.data.DataSource({
        	schema: {
				model: {
			        id: "id",
			        fields: {
			          "id": { type: "number"},
			          "house": { type: "string" },
			          "labelName": { type: "string" },
			          "place": { type: "string" },
			          "postCode": { type: "string"},
			          "stateCode": { type: "string"},
			          "street": { type: "string"},
			          "addressLine2": { type: "string"},
			          "fullAddress": { type: "string" },
			    	}
				},
                parse: function(d){
	              	$.each(d, function (idx, elem) {
	              		  var itemWidth =  elem.labelName.length + elem.fullAddress.length + 2;
	              		  widthCombo = widthCombo > itemWidth ? widthCombo : itemWidth;   
	                  });
	              	return d;
	            }
			},
        	data: branchAddresses
    	});
        
        vm.loadAddress = function(selcted){
        	vm.addressOfDelivery = selcted;
        	switch(vm.addressOfDelivery) {
        	
        		case 0:
        			loadAddressInForm(residentialAddress);
        			break;
        		case 1:
        			loadAddressInForm(declaredAddress);
        			break;
        		case 2:
        			if(typeof vm.selectedBranch == 'undefined') {
        	        	AlertService.error("modules.loan.message.addressOfDeliveryIsNotSelected");
        	        	return;
        	        }
        			loadAddressInForm(vm.selectedBranch);
        			break;
        	}
        };
        
        vm.branchOptions = {
        		template: "<strong>#:labelName#</strong>.&nbsp;#:fullAddress#",
        		open: function(e){
        			e.sender.list[0].style.minWidth = widthCombo + "ch";
        		},
        		select: function(e) {
        			vm.addressOfDelivery = 2;
        			loadAddressInForm(e.dataItem);
        			return;
        		}
        };
        
        function loadAddressInForm(selectedAddress) {
        	vm.holder.comments = typeof declaredAddress != 'undefined' ? declaredAddress.fullAddress : "";
        	if(typeof selectedAddress != 'undefined' && selectedAddress != null) {
	        	vm.holder.deliveryAddress1 = selectedAddress.street + " " +  selectedAddress.house;
	        	vm.holder.deliveryCity = selectedAddress.place;
	        	vm.holder.deliveryPostalCode = selectedAddress.postCode;
	        	vm.holder.deliveryCountryCode = selectedAddress.stateCode;
	        	
	        	if (selectedAddress.companyName === selectedAddress.addressLine2){
	        		vm.holder.deliveryAddress2 = "";
	        		vm.holder.deliveryCompanyName = selectedAddress.companyName;
	        	} else {
		        	vm.holder.deliveryAddress2 = selectedAddress.addressLine2;
	        		vm.holder.deliveryCompanyName = selectedAddress.companyName;
	        	}
        	} else {
        		vm.holder.deliveryAddress1 = "";
        		vm.holder.deliveryAddress2 = "";
	        	vm.holder.deliveryCity = "";
	        	vm.holder.deliveryPostalCode = "";
	        	vm.holder.deliveryCountryCode = "";
	        	vm.holder.deliveryCompanyName = "";
        	}
        }

        
        $scope.$watch(
        		function() { 
        			return vm.holder.gender;
	        	}, 
	        	function(newVal, oldVal) {
		        	if(vm.holder.title != null) {
		        		if(newVal == "FEMALE") vm.holder.title = "Ms.";
		        		if(newVal == "MALE") vm.holder.title = "Mr.";
		        	}
        		
	        	}
        );
        
        function validateForCreate() {
        	
        	// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	var isPersonValid = true;
        	
     //   	console.log("vm.wallesterFirstCard:");
      //  	console.log(vm.wallesterFirstCard);
        	
        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  
	                  if ($input.is("input[data-role=datepicker]")) {
    	                  if($input.context.required && $input.data("kendoDatePicker").value() === null) {
    	                	  isPersonValid = false;
    	                	  $formGroup.toggleClass('has-error', true);
    	    //            	  console.log("error in input: " + $input[0].attributes['name'].value);
    	                  } 
    	              }
	                  
	                  if($input[0].tagName == "BC-PHONE-NUMBER") {
	                	  vm.phoneNoError = true;
	                	//  vm.phoneNoError = $input.hasClass('ng-invalid');
	                	  if($input.hasClass('ng-invalid')) 
	                		  vm.phoneNoError = false;
	                	  if($input.hasClass('ng-valid')) {
	                		  vm.phoneNoError = true;
	                		  eval('vm.wallesterFirstCard.mobile.$setValidity("mobile", true);');
	                	  }
	                	  
	                	  $formGroup.toggleClass('has-error', !vm.phoneNoError);
	                	  
	                	 /* console.log("BC-PHONE-NUMBER ERROR: has-invalid=" 
	                			  + $input.hasClass('ng-invalid') 
	                			  + ", has-valid=" + $input.hasClass('ng-valid') 
	                			  + ", vm.phoneNoError=" + vm.phoneNoError);
*/
	                  }
	                  
	                  if($input.hasClass('ng-invalid') || $input.hasClass('k-invalid')) {
	                	  $formGroup.toggleClass('has-error', true);
	         //       	  console.log("error in input: " + $input[0].attributes['name'].value);
	                  }
	           //       console.log("ID: " + $input[0].id 
	           //     		  + ", name=" + ($input[0].attributes['name'] ? $input[0].attributes['name'].value : 'undefined') 
	           //     		  + ", is-valid=" + ($input[0].attributes['is-valid'] ? $input[0].attributes['is-valid'].value : 'undefined'));
	           //       if ($input[0].attributes['name'] && $input[0].attributes['name'].value && !$input[0].attributes['is-valid']){
	            //    	  console.log($input);
	           //       }
	              });
	            }
        			
        	});
        	
        	console.log("isPersonValid=" + isPersonValid + ", vm.carholderForm.invalid=" +  vm.wallesterFirstCard.$invalid + ", vm.validator.validate=" + vm.validator.validate());
        	if(!isPersonValid || vm.wallesterFirstCard.$invalid || !vm.validator.validate()){
        		var errorFields = "";
//        		if (!vm.wallesterFirstCard.$invalid){
//        			console.log(vm.wallesterFirstCard.$error)
//        		}
        		AlertService.error("modules.loan.message.errorInForm");
        //		vm.validator.showValidationSummary();
        		return false;
        	}
        	
        	return true;
        }
        
    }
    
})();
