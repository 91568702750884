(function() {
    'use strict';
    angular
        .module('sigmaApp')
        .factory('CardStatus', CardStatus)
        ;

    CardStatus.$inject = [];

    function CardStatus () {
        console.log("CardStatus started");
        var getStyle = function(item, isActive){
        	var resp;
        	
        	console.log(isActive + ', ' + item);
        	if(isActive){
	        	switch(item) {
	        		case 'Active':
	        			resp = 'success';
	        			break;
	        		case '00':
	        			resp = 'success';
	        			break;
	        		default:
	        			resp = 'danger';
	        	}
        	} else {
        		resp = 'warning';
        	}
        	return resp;
        };
        return {
        	getStyle: getStyle
        };
    }
})();
