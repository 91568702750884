(function() {
	'use strict';
	angular.module('sigmaApp').factory('CurrentOffice', CurrentOffice);

	CurrentOffice.$inject = [ '$resource', 'baseUrl'];

	function CurrentOffice($resource, baseUrl) {
		var resourceUrl = baseUrl + 'api';
		
		return $resource(resourceUrl, {}, {
			'getList' : {
				method : 'GET',
				url: resourceUrl + "/account/offices", 
				isArray : true,
				transformResponse : function(data) {
					if (data) {
						try {
							data = angular.fromJson(data);
						} catch(e){
							data = "";
						}
					}
					return data;
				}
			}
        });	
	}
})();