(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DemoWsController', DemoWsController);

   DemoWsController.$inject = ['$scope', '$state', '$stateParams', '$location', "WsService", "AlertService", 'baseUrl'];

    function DemoWsController ($scope, $state, $stateParams, $location, WsService, AlertService, baseUrl) {
        var vm = this;
        var targetAction = false;
        console.log("DemoWsController ...");
        vm.receivedText = "... not yet...";
       
        vm.activities = [];

        WsService.receive().then(null, null, function(activity) {
            showActivity(activity);
        });
       
        function showActivity(activity) {
            var existingActivity = false;
            for (var index = 0; index < vm.activities.length; index++) {
                if(vm.activities[index].sessionId === activity.sessionId) {
                    existingActivity = true;
                    if (activity.page === 'logout') {
                        vm.activities.splice(index, 1);
                    } else {
                        vm.activities[index] = activity;
                    }
                }
            }
            if (!existingActivity && (activity.page !== 'logout')) {
                vm.activities.push(activity);
            }
        }
    }
    
})();
