(function() {
	'use strict';
	angular.module('sigmaApp').factory('Client', Client);

	Client.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Client($resource, baseUrl, DateUtils) {
		
		var resourceUrl = baseUrl + 'api/crm/clients/:clnId';

		return $resource(resourceUrl,{},
				{
					'get' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'update' : {
						method : 'PUT',
						transformRequest : function(data) {
								console.log("Siunciama data");
								console.log(data.birthDate);
								data.birthDate = DateUtils.convertLocalDateToServer(data.birthDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'createAddress' : {
						method : 'POST',
						url: resourceUrl + "/addresses",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updateAddress' : {
						method : 'PUT',
						url: resourceUrl + "/addresses/:adrId",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deleteAddress' : {
						method: 'DELETE',
						url: resourceUrl + "/addresses/:adrId"
					},
					'getAgreements' : {
						method : 'GET',
						url: resourceUrl + "/agreements",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getAgreement' : {
						method : 'GET',
						url: resourceUrl + "/agreements/:agrId",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'cancelAgreement' : {
						method : 'POST',
						url: resourceUrl + "/agreements/:agrId/cancel",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deleteAgreementCancellation' : {
						method : 'DELETE',
						url: resourceUrl + "/agreements/:agrId/delete-cancellation"
					},
					'changeAgreementValue' : {
						method : 'PUT',
						url: resourceUrl + "/agreements/:agrId/change-value",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'createPhone' : {
						method : 'POST',
						url: resourceUrl + "/phones",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updatePhone' : {
						method : 'PUT',
						url: resourceUrl + "/phones/:phnId",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deletePhone' : {
						method: 'DELETE',
						url: resourceUrl + "/phones/:phnId"
					},
					'createEmail' : {
						method : 'POST',
						url: resourceUrl + "/emails",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updateEmail' : {
						method : 'PUT',
						url: resourceUrl + "/emails/:emlId",
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deleteEmail' : {
						method: 'DELETE',
						url: resourceUrl + "/emails/:emlId"
					}
				});
	}
})();