(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('PaymentListLoanController', PaymentListLoanController);

    PaymentListLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$filter', '$window', '$translate'];

    function PaymentListLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, $filter, $window, $translate) {
        var vm = this;
        vm.kendo = kendo;
		var bankCodes = {
				data: []
			};
        console.log("PaymentListLoanController started");
        vm.loanPaymentsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "dat": { type: "date" },
			          "bankCode": { type: "string" },
			          "bankName": { type: "string" },
			          "des": { type: "string" },
			          "num": { type: "number" },
			          "idCdh": { type: "number" },
			          "amount": { type: "number" },
			          "paid4avn": { type: "number" },
			          "paid4prn": { type: "number" },
			          "paid4int": { type: "number" },
			          "paid4moksa": { type: "number" },
			          "paid4pen": { type: "number" },
			          "paid4oth": { type: "number" },
			          "paid4moksut": { type: "number" }
			        }
			      },
   				  parse: function(d){
 		              	$.each(d, function (idx, elem) {
 		              		var bankCode = {
 		              				bankCode: elem.bankCode
 		              		};
 		              		var foundBankCode = $filter('filter')(bankCodes.data, {bankCode: elem.bankCode}, true);
 		                    if(foundBankCode.length < 1) (bankCodes.data).push(bankCode);
 		                });
 		              	return d;
 				  }
    		},
			transport : {
				read : function(e) {
					Loan.getPayments(
							{loan_no : $state.params.loan_no}, 
							function(response) {
								e.success(response);
							}, 
							function(error) {
								console.log('payments list error');
								e.error(error);
							})
							.$promise;;
				}
			},
			aggregate: [
                        { 
                        	field: "dat", 
                        	aggregate: "count"
                        },{ 
                        	field: "amount", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4avn", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4prn", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4int", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4moksa", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4pen", 
                        	aggregate: "sum"
                        },{ 
                        	field: "paid4oth", 
                        	aggregate: "sum"
                        }                            
            ],
//            pageSize: defaultPageSize,
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
	        sort: {
	        	field: "num",
	        	dir: "desc"
	        }
        });
        
        vm.loanPaymentsOptions = {
        		scrollable: {
    	            virtual: true
    	        },
    	        height: function(){
    	        	return $window.innerHeight/2;
    	        },
    	        sortable: true,                
    	        pageable: {
    	        	refresh: true,
    	        	previousNext: false,
    	        	numeric: false,
    	        	info:false,
    	        	messages: {
    	        	      empty: "No data"
    	        	    }
    	        },
    			filterable : {
    				extra : false,
    				operators : {
    					string : {
    						contains : "contains",
    					}
    				}
    			},
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.payments'></span>"
  		                    		 + "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-payments.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },                
                columns: [
                    { 
                    	title: "{{'modules.loan.table.paymentInfo'|translate}}",
                    	columns: [ {
	                         field:"num",
	 	                     title: "{{'modules.loan.table.no'|translate}}",
	                         filterable: true,
	                         sortable: true,
	                         attributes: { "class": "text-center" },
	                         width: "7ch"
	                    },{
	                         field:"dat",
	                         title: "{{'modules.loan.table.date'|translate}}",
	                         filterable: true,
	                         sortable: true,
	                         format: "{0:d}",
	                         aggregates: ["count"], 
	                         footerTemplate: "Kiekis: #=count#",
	                         footerAttributes: { "class": "text-center" },
	                         attributes: { "class": "text-center" },
	                         width: "12ch"
	                    },{
	                         field: "amount",
	                         title: "{{'modules.loan.table.sum'|translate}}",
	                         sortable: false,
	                         filterable: false,
							 template: "#= (amount == 0 || amount == null) ? '' : kendo.toString(amount, 'n2') #",
	                         attributes: {class:"text-right"},
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                    },{
	                         field: "bankCode",
	                         title: "{{'modules.loan.table.bank'|translate}}",
	                         sortable: true,
	                         filterable : {
		                     	multi : true,
		                        dataSource : new kendo.data.DataSource(bankCodes),
		                        dataTextField : "bankCode"
						     },
	                         template: "<span title='#=bankName#'>#=bankCode#</span>",
	                         attributes: { "class": "text-center" },
	                         width: "10ch"
	                    }] 
                    },{ 
                    	headerTemplate: "<span translate='modules.loan.table.paymentDistribution'>",
                        title: "{{'modules.loan.table.bank'|translate}}",
                        columns: [{
	                         field: "paid4avn",
	                         title: "{{'modules.loan.table.avans'|translate}}",
	                         template: "#= (paid4avn == 0 || paid4avn == null) ? '' : kendo.toString(paid4avn, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     },{
	                         field: "paid4prn",
	                         //title: "Kreditas",
	                         title: "{{'modules.loan.table.credit'|translate}}",
	                         template: "#= (paid4prn == 0 || paid4prn == null) ? '' : kendo.toString(paid4prn, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     },{
	                         field: "paid4int",
	                         title: "{{'modules.loan.table.interest'|translate}}",
	                         template: "#= (paid4int == 0 || paid4int == null) ? '' : kendo.toString(paid4int, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     },{
	                         field: "paid4moksa",
	                         title: "{{'modules.loan.table.masik'|translate}}",
	                         template: "#= (paid4moksa == 0 || paid4moksa == null) ? '' : kendo.toString(paid4moksa, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     },{
	                         field: "paid4pen",
	                         title: "{{'modules.loan.table.fine'|translate}}",
	                         template: "#= (paid4pen == 0 || paid4pen == null) ? '' : kendo.toString(paid4pen, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     },{
	                         field: "paid4oth",
	                         title: "{{'modules.loan.table.other'|translate}}",
	                         template: "#= (paid4oth == 0 || paid4oth == null) ? '' : kendo.toString(paid4oth, 'n2') #",
	                         attributes: {class:"text-right"},
	                         sortable: false,
	                         filterable: false,
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
	                     }]
                    },{
                         field: "des",
                         title: "{{'modules.loan.table.note'|translate}}",
                         sortable: false,
                     }                            
                 ]
                          
        };
        
       
    }

})();
