(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['Principal', 'detailMode', '$stateParams', '$rootScope','$scope', '$state', 'entity', '$translate', 'Application', 'Calculator', 'AlertService', '$element', 'formHelper', '$location', '$interval', 'shortRefresh', 'longRefresh', 'Rights', '$window', 'baseUrl', 'shortDateFormats', '$filter', 'Cookies'];

    function ApplicationController (Principal, detailMode, $stateParams, $rootScope, $scope, $state, entity, $translate, Application, Calculator, AlertService, $element, formHelper, $location, $interval, shortRefresh, longRefresh, Rights, $window, baseUrl, shortDateFormats, $filter, Cookies) {
    	var setTestData = false; /* setting test data */
    	var rights = "";
    	formHelper.setFocus('first-field');
    	var vm = this;
    	vm.isViewOnly = ($state.current.name).search("new") >= 0 ? false : true ;
    	vm.isSalesChannelViewOnly = ($state.current.name).search("new") >= 0 ? false : true ;
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.dateMask = vm.dateFormat.replace("dd","00").replace("yyyy","0000").replace("MM","00");
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";
    	vm.shortDateFormats = shortDateFormats;
    	vm.loanNo = $stateParams.loan_no ? $stateParams.loan_no : ""; 
    	vm.isDefaultAmountValue = false;
    	vm.errorMessage = "";
    	vm.prevErrorMessage = "";
    	vm.errorTimestamp = null;
    	vm.error = false;
    	vm.detailMode = detailMode;
    	vm.disablePrint = false;
    	vm.confirmSubmitClicked = false;
    	vm.pleaseWaitOperationID = null;
    	vm.office = {};
    	vm.isLatekoPartner = false;
    	vm.salesPartnerName = "";
    	
    	vm.emptyClntWorkType = false;
    	vm.emptyGrntWorkType = false;
    	
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
    	
    	Principal.identity().then(function(account) {
            vm.account = account;
            vm.office.prtId = parseInt(Cookies.partnerID(vm.account.login));
            vm.office.offId = parseInt(Cookies.officeID(vm.account.login));
            if (vm.office.prtId <= 0 || vm.office.prtId == null || vm.office.offId <= 0 || vm.office.offId == null){
            	AlertService.error("global.messages.error.wrongPartnerOffice");
            };
        });
    	
    	Principal.hasAuthority("ROLE_LATEKO").then(function (result) {
    		if (result) {
    			vm.hasRoleLateko = true;
    		}else{
    			vm.hasRoleLateko = false;
    		};
    	});
    	vm.workTypeData = new kendo.data.DataSource({
    		data: [
    	    		{
    	    			id: "EMPLOYEE",
    					order: 1,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "SEP",
    					order: 2,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "OTHER",
    					order: 3,
    					hidden: false, 
    					disabled: false,   	    			
    	    		},{
    	    			id: "RETIRED",
    					order: 4,
    					hidden: false,
    					disabled: false,
    	    		}    
    		],
			sort: {
				field: "order",
				dir: "asc"
			}
    	});
    	
    	vm.salesChannelData = new kendo.data.DataSource({
    		data: [
    	    		{
    	    			id: "OTHER",
    					order: 6,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "WEB",
    					order: 1,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "LANDING_PAGE",
    					order: 2,
    					hidden: false, 
    					disabled: false,   	    			
    	    		},{
    	    			id: "PARTNER",
    					order: 4,
    					hidden: false,
    					disabled: false,
    					option: [
    					         {
    					        	 id: "A",
    					        	 title: "titleA"
    					         },{
    					        	 id: "B",
    					        	 title: "titleA"
    					         },
    					]
    	    		},{
    	    			id: "CC_CALL",
    					order: 3,
    					hidden: false,
    					disabled: false,    	    			
    	    		},{
    	    			id: "BRANCHES",
    					order: 5,
    					hidden: false,
    					disabled: false,    	    			
    	    		}    
    		],
			sort: {
				field: "order",
				dir: "asc"
			}
    	});
    	
        vm.noTariffs = false;
        vm.tariff = {};
        vm.calculate = calculate;
        vm.calculator = {};
        
    	initCustomValidation();
        
    	var mode = "view";
    	mode = ($state.current.name).search("new") >= 0 ? "new" : mode;
    	
    	vm.showSurety = false;
    	
    	vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.application.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.application.male")
    		}]
    	});

    	var testData = {
    		    "productName": "HERCUS_CARD",
    		    "applSum": 300,
    		    "currency": "EUR",
    		    "trm": 5,
    		    "goodsName": "Mobils",
    		    "notes": "New mobils!!!!",
    		    "attDocs": "Fax",
    		    "supplicant": [{
    		        "supplicantType": "MAIN",
    		        "otherPhone": "",
    		        "mobPhone": "",
    		        "contactPhone": "",
    		        "firstName": "Vardenis",
    		        "lastName": "Pavardenis",
    		        "personCode": "051561-12261",
    		        "birthDate": "1991-12-31",
    		        "sex": "F",
    		        "voicePass": "test pass",
    		        "passNumber": "XX123456789",
    		        "passExpire": "2021-12-31",
    		        "citizenship": "LVA",
    		        "email": "mail@domen.lv",
    		        "regPlace": "Riga",
    		        "regStreet": "Ozols",
    		        "regHouse": "1",
    		        "regPostcode": "0123",
    		        "livPlace": "Ventspils",
    		        "livStreet": "Zalgirs",
    		        "livHouse": "2",
    		        "livPostcode": "4321",
    		        "workName": "Seims",
    		        "workTitle": "Presedatels",
    		        "workExperience": "1Y3",
    		        "workPlace": "Jurmals",
    		        "workStreet": "Marijus",
    		        "workHouse": "7",
    		        "workPostcode": "9874",
    		        "workIncome": 1000,
    		        "otherIncome": 10,
    		        "loanPay": 10,
    		        "dependantsCount": 2,
    		        "familyIncome": 1010,
    		        "familyLoanPay": 100,
    		        "contactFirstName": "Names",
    		        "contactLastName": "Surnames",
    		        "vsaaAllowed": 2
    		    }]
    		};
    	
        vm.application = setTestData && mode == "new" ? testData : entity ;
        vm.applDate = vm.application.applDate != null ? kendo.toString( new Date(vm.application.applDate), 'd') : "";
        vm.application.currency = mode == "new" ? "EUR" : vm.application.currency ;
        if (vm.application.partnerId > 1){
        	vm.salesPartnerName = "(" + vm.application.partnerName + ")";
        }
        
        var supplicants = vm.application.supplicant;
        angular.forEach(supplicants, function(supplicant){
            switch(supplicant.supplicantType) {
	    		case "MAIN":
	    			vm.clnt = supplicant;
	    			break;
	    		case "SURETY":
	    			vm.grnt = supplicant;
	    			vm.showSurety = mode == "new" ? false : true;
	    			initCustomGuarantorValidation();
	    			break;
	    		case "SPOUSE":
	    			vm.sps = supplicant;
	    			break;
            }	        	
        });
        
    	if(vm.clnt.agreement != null && vm.clnt.agreement.length > 0) {
        	vm.clntAgreement = {};
        	for(var i = 0; i < vm.clnt.agreement.length; i++) {
        		switch (vm.clnt.agreement[i].agr_type) {
        		case "MARKETING":
        			vm.clntAgreement.marketingAllowed = convertResponceAgreement(vm.clnt.agreement[i].agr_value); 
        			vm.clntAgreement.marketingAllowedDate = vm.clnt.agreement[i].agr_date; 
        			break;
        		}
        	}
//        	if(!vm.clntAgreement.marketingAllowed) vm.vsaaOptionsDisable = true; 
        } else {
        	vm.clntAgreement = {};
        	vm.clntAgreement.marketingAllowed = null; 
        	vm.clntAgreement.marketingAllowedDate = null;
			vm.clntAgreement.socialInsurance = null;
			vm.clntAgreement.socialInsuranceDate = null;
			vm.clntAgreement.paidOut = null;
			vm.clntAgreement.paidOutDate = null;
			vm.clntAgreement.paidAmount = null;
			vm.clntAgreement.paidAmountDate = null;
        }
    	
		function getClntAgreements(){
			console.log("Get client agreements");
        	/* agreements validation */
        	var agrClntMarketing = convertAgreement("MARKETING",vm.clntAgreement.marketingAllowed, vm.clnt.personCode,vm.clntAgreement.marketingAllowedDate);
        	
        	/*------------------------------*/
        	
        	vm.clnt.agreement = [];
        	var clntAgreement = [];
        	
        	clntAgreement.push(agrClntMarketing);
       	
        	vm.clnt.agreement = clntAgreement;
		};
		
		function getGrntAgreements(){
			console.log("Get grnt agreements");
        	/* agreements validation */
        	var agrGrntMarketing = convertAgreement("MARKETING",vm.grntAgreement.marketingAllowed, vm.grnt.personCode,vm.grntAgreement.marketingAllowedDate);
        	
        	if(agrGrntMarketing.agr_value.toUpperCase() === "NULL") {
        		alertError("modules.application.short.validate.emptyMarketingAgreement");
        		vm.grntMarketingNoError = false;
        		return;
        	}
        	
        	/*------------------------------*/
        	
        	vm.grnt.agreement = [];
        	var grntAgreement = [];
        	
        	grntAgreement.push(agrGrntMarketing);
       	
        	vm.grnt.agreement = grntAgreement;
		};
		
        function convertAgreement(key,bValue,code,date) {
        	var value = "NULL";
        	if(bValue != null && typeof(bValue) === "boolean"){
        		if(bValue)
        			value = "AGREE";
        		else
        	        value = "DISAG";
        	}
        	return {
        		agr_type: key,
        	    agr_value: value,
        	    person_code: code,
        	    agr_date: date
        	};
        }
        
        function convertResponceAgreement(tValue){
        	if(tValue != null && typeof(tValue) === "string") {
        		if(tValue.toUpperCase() === "AGREE")
        			return true;
        		else if(tValue.toUpperCase() === "DISAG")
        			return false;
        		else 
        			return null;
        	} else 
        		return null;
        }
        
        if(mode == "new") {
        	if($stateParams.applSum != null) vm.application.applSum = $stateParams.applSum;
        	if($stateParams.personCode != null) { 
        		vm.clnt.personCode = $stateParams.personCode;
        	}
        	if($stateParams.tgrId != null) vm.application.tgrId = $stateParams.tgrId;
        	if($stateParams.prtId != null && $stateParams.prtId > 1){
	    		vm.application.partnerId = $stateParams.prtId;
	    		vm.application.salesChannel = "PARTNER";
	    		vm.salesPartnerName = "(" + vm.application.partnerName + ")";
	    	}
        	if($stateParams.shortAppl != null) {
        		var shortAppl = $stateParams.shortAppl;
        		vm.clnt.personCode = shortAppl.personCode != null ? shortAppl.personCode : "" ;
        		vm.clnt.firstName = shortAppl.firstName != null ? shortAppl.firstName : "" ;
        		vm.clnt.lastName = shortAppl.lastName != null ? shortAppl.lastName : "" ;
        		vm.clnt.mobPhone = shortAppl.mobPhone != null ? shortAppl.mobPhone : "" ;
        		vm.clnt.email = shortAppl.email != null ? shortAppl.email : "" ;
        		vm.clnt.loanPay = shortAppl.monthOblig != null ? shortAppl.monthOblig : null ;
        		vm.clnt.workIncome = shortAppl.wrkInc != null ? shortAppl.wrkInc : null ;
        		vm.clnt.politicalPerson = shortAppl.politicalPerson != null ? shortAppl.politicalPerson : 0 ;
        		vm.clnt.beneficiary = shortAppl.beneficiary != null ? shortAppl.beneficiary : 0 ;
        		
        		vm.application.partnerId = shortAppl.partnerId != null ? shortAppl.partnerId : null ;
        		vm.application.officeId = shortAppl.officeId != null ? shortAppl.officeId : null ;
        		vm.application.salesChannel = shortAppl.salesChannel != null ? shortAppl.salesChannel : "" ;
        		vm.application.clnFoundBy = shortAppl.crtd_by != null ? shortAppl.crtd_by : "" ;
        		vm.application.clnFoundOffice = shortAppl.officeId != null ? shortAppl.officeId : null;
        		vm.application.sapplId = shortAppl.id != null ? shortAppl.id : null ;
        		vm.application.applSum = shortAppl.sapplSum != null ? shortAppl.sapplSum : null ;
        		vm.application.partnerName = shortAppl.partnerName != null ? shortAppl.partnerName : null ;
        		if(shortAppl.tgrId != null)
        			vm.application.tgrId = shortAppl.tgrId;
        		
        		if(shortAppl.hasOwnProperty('result') && shortAppl.result.length > 0) {
        			var result = shortAppl.result[0];
        			
        			var requirements = result.request_list ? result.request_list.split('#') : [];
        			if(requirements.length > 0) {
	        			for(var j=0; j < requirements.length; j++) {
	        				if(requirements[j].startsWith('HERCUS kartes limits')){
	        					var limit = requirements[j].match(/\d/g);
	        					var limitSum = parseFloat(limit.join(""));
	        					vm.application.applSum = vm.application.applSum > limitSum ? limitSum : vm.application.applSum ;
	        					vm.application.notes = requirements[j];
	        					break;
	        				} 
	        			}
        			}
        		}
        		// agreement transfer
        		if(shortAppl.hasOwnProperty('agreement') && shortAppl.agreement.length > 0) {
        			for(var i=0; i < shortAppl.agreement.length; i++) {
        				var key = shortAppl.agreement[i].agr_type.toUpperCase();
        				var value = shortAppl.agreement[i].agr_value.toUpperCase();
        				
        				if(key === 'MARKETING') {
        					vm.clntAgreement.marketingAllowed = convertResponceAgreement(value);
        				}
        			}
        		}
        	}
        }
        
        function convertAgreementValue(inputValue) {
        	switch(inputValue) {
				case 'AGREE':
					return 2;
					break;
				case 'DISAG':
					return 1;
					break;
				default:
					return null;
        	}
        }
        
        function convertAgreementValueReverse(inputValue) {
        	switch(inputValue) {
				case 2:
					return true;
					break;
				case 1:
					return false;
					break;
				default:
					return null;
        	}
        }
        
        vm.result = vm.application.result ? vm.application.result[0] : "";
        if(vm.result.isApplEditable && mode != "new"){
        	mode =  "edit";
        	vm.isViewOnly = false;
        }
        
        if(vm.application.rights) rights = vm.application.rights;
        Rights.init(rights);
        
        console.log("application controller in '" + mode + "' mode");
        
        vm.copyClntAddress = function(place,street,apartment,postCode){
        	if(vm.clnt.livPlace || vm.clnt.livStreet || vm.clnt.livHouse || vm.clnt.livPostcode) return;
        	if(place) vm.clnt.livPlace = place;
        	if(street) vm.clnt.livStreet = street; 
        	if(apartment) vm.clnt.livHouse = apartment;
        	if(postCode) vm.clnt.livPostcode = postCode;
        };
        
        vm.copyGrntAddress = function(place,street,apartment,postCode){
        	if(vm.grnt.livPlace || vm.grnt.livStreet || vm.grnt.livHouse || vm.grnt.livPostcode) return;
        	if(place) vm.grnt.livPlace = place;
        	if(street) vm.grnt.livStreet = street; 
        	if(apartment) vm.grnt.livHouse = apartment;
        	if(postCode) vm.grnt.livPostcode = postCode;
        };
        
        vm.tariffData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"id": { type: "number" },
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	}
				}
			},
			transport: {
				read: function(e){
					vm.tariff.personCode = vm.clnt.personCode;
					Calculator.query(
							{product : vm.application.productName, person_code: vm.tariff.personCode, prt_id: vm.application.partnerId },
							function(response){
				        		vm.noTariffs = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });
        
        vm.applOptions = {
        	    selectable: true,
        	    dataBound: function(event) {
        	    	var selected;
        	  		if(vm.application.applSum == 0 || typeof vm.application.applSum == 'undefined' || isNaN(vm.application.applSum)) { 
        	  			vm.isDefaultAmountValue = true;
        	  		}
        	    	if(vm.application.tgrId > 0 && !vm.isDefaultAmountValue) {
	        	    		selected = vm.application.tgrId; 
	        	    		var availableTarrifes = [];
	        	    		for(var j = 0; j < event.sender.element.children().length; j++) {
	        	    			availableTarrifes.push(event.sender.element.children().eq(j)[0].value);
	        	    		}
	        	    		
	        	    		var selectedTatiff = $filter('filter')(event.sender.element.children(), {value: selected }, false)[0];
	        	    		if( typeof selectedTatiff === 'undefined') {
	        	    			var notVisited = true;
	        	    			while(availableTarrifes.length > 0) {
	        	    				var i = availableTarrifes.shift();
	        	    				selectedTatiff = $filter('filter')(event.sender.element.children(), {value: i }, false)[0];
	        	    				if( typeof selectedTatiff != 'undefined') {
	        	    					selected = i;
	        	    					notVisited = false;
	        	    					break;
	        	    				} 
	        	    			}
	        	    			
	        	    			if(notVisited || mode != "new") {
	        	    				alertError('modules.application.validate.applWrongTariff');
		                            var formGrp = angular.element(event.sender.element.closest('.form-group.form-group-sm'));
		                            formGrp.toggleClass('has-error', true);
		        	    			return;
		        	    		}
	        	    		}
        	    	} else {
        	    		var first = event.sender.element.children().first();
        	    		selected = first.val();
        	    	}
        	    	if(typeof selected == "number") {
        	    		selected = selected.toString();
        	    	}
        	    	
        	    	event.sender.value(selected);
        	    	event.sender.trigger("change");
//        	    	event.sender.trigger("cascade");
        	    },
        	    cascade: function(event) {
        	    	if(event.sender.select() < 0) {
        	    		return;
        	    	}
        	    	
        	    	var formGrp = angular.element(event.sender.element.closest('.form-group.form-group-sm'));
                    formGrp.toggleClass('has-error', false);
                    
        	  		vm.onApplErrorHide = false;
        	        var selected = event.sender.dataItem(event.sender.select());
        	        
                	vm.minSum = selected.minAmount;
                	vm.maxSum = selected.maxAmount;
                	vm.defaultAmmount = selected.minAmount;
                	vm.tariffId = selected.id;
                	
                	vm.application.applSum = vm.application.applSum > 0 ? vm.application.applSum : vm.defaultAmmount;

//                    if(vm.minSum > vm.application.applSum) {
//                    	vm.application.applSum =  vm.minSum;
//                	}
//                	if(vm.maxSum < vm.application.applSum) {
//                		vm.application.applSum = vm.maxSum;
//                	}
                	
    	    		var calc = newApplAmountIsValid(vm.application.applSum);
    	    		if(calc) calculate('trigger');
    	    		else return;
                	
        	    }
        };
        
        $element.find('input[name="applAmount"]')
    	.bind("keyup", function (event) {
    		vm.isDefaultAmountValue = false;
    	}).bind("blur", function (event) {
    	});
        
        function newApplAmountIsValid(newValue){
        	var response = false;
        	if(vm.isDefaultAmountValue) {
        		if( newValue > vm.maxSum) vm.application.applSum = vm.maxSum;
        		else if( newValue < vm.minSum || isNaN(newValue) || newValue == null) vm.application.applSum = vm.minSum;
        		response = true;
        	} else {
        		if(	newValue > vm.maxSum || 
                	newValue < vm.minSum || 
                	newValue == null || 
                	isNaN(newValue)) alertOnAppAmmountError();
            	else {
            		vm.onApplErrorHide = false;
            		response = true;
            	}
        	}
        	
//	        	if(	newValue > vm.maxSum || 
//		           newValue < vm.minSum || 
//		           newValue == null || 
//		           isNaN(newValue)) alertOnAppAmmountError();
//		        else {
//		        	AlertService.clear();
//		        	vm.onApplErrorHide = false;
//		        	response = true;
//		        }
	        	
	        	return response;
	        }
        
        function alertOnAppAmmountError(){
        	vm.onApplErrorHide = true;
        	vm.calculator.calcPayForYear = null;
        	vm.application.loanPayTot = null;
        	alertError('modules.application.validate.applWrongAmountDetailed', { min: vm.minSum, max: vm.maxSum });
        }
        
        function alertError(msg, params){
        	vm.errorMessage = $translate.instant(msg, params);
			vm.errorTimestamp = (new Date).getTime();
			vm.error = true;
        }
        
        loadTariffs();
        
        function loadTariffs(){
        	vm.tariffData.read();
        }	        	
        
        function calculate(x){
        	if(vm.tariffId == null || isNaN(vm.application.applSum) || vm.application.applSum == null || vm.application.applSum <= 0) return;
        	var calculator = {};
        	calculator.applAmount = vm.application.applSum;
        	calculator.prtId = vm.application.partnerId;
        	calculator.financedAmount = vm.application.applSum;
        	calculator.personCode = vm.clnt.personCode;
        	calculator.tariffId = vm.tariffId;
        	Calculator.send(calculator, function(response){
        		if(response) {
        			vm.application.loanPayTot = response.calcPayTot;
        			vm.calculator.calcPayForYear = response.calcPayForYear;
        		}
        	});
        }
        
        $scope.$watch(
        	    function(){
        	    	return vm.application.applSum;
        	    },
        	    function(newVal,oldVal){
        	    	if(oldVal != newVal) {
        	    		if(newApplAmountIsValid(newVal)) calculate('ammount ');
        	    	}
        	    }
            );
        
        $scope.$watch(
        	    function(){
        	    	return vm.error;
        	    },
        	    function(isError){
        	    	if(vm.errorMessage != "" && vm.errorTimestamp > (new Date).getTime() - 10000) {
	        	    	if(vm.errorMessage != vm.prevErrorMessage) {
	        	            AlertService.error(vm.errorMessage);
	        	    	} else {
	        	    		AlertService.clear();
	        	    		AlertService.error(vm.errorMessage);
	        	    	}
	        	    	vm.prevErrorMessage = vm.errorMessage;
	        	    	vm.error = false;
        	    	}
        	    }
            );
        
        
        $scope.$watch(
    	        function(){
    	        	return vm.clnt.personCode;
    	        },
    	        function(newVal,oldVal){
    	        	if(newVal != oldVal) {
    	        		loadTariffs();
    	        	}
    	        }
        );

        vm.checkTariff = function() {
        	vm.application.partnerId = vm.office.prtId;
			loadTariffs();
		};   
		
		vm.updateClntWorkType = function(item) {
			vm.clnt.workType = item.id; 
			vm.emptyClntWorkType = false;
		}
	
		vm.updateGrntWorkType = function(item) {
			console.log(item);
			vm.grnt.workType = item.id; 
			vm.emptyGrntWorkType = false;
		}

		$scope.$watch(
        	    function(){
        	    	return vm.office.prtId;
        	    },
        	    function(newValue,oldValue){
        	    	if (newValue == 1){
            	    	var dataItem = vm.salesChannelData.get('PARTNER');
                        dataItem.set("disabled","true");
                        vm.isLatekoPartner = false;
        	    	}else{
        	    		vm.isLatekoPartner = true;	
        	    	};
        	    	if (mode == "new") vm.checkTariff();
        	    }
        );
                
        vm.setSurety = function(){
        	if(vm.showSurety) {
        		console.log('surety is ON');
        		console.log(vm.showSurety);
        		vm.grnt = {
                    		supplicantType: "SURETY",
                    		otherPhone: "",
                    		mobPhone: "",
                    		citizenship: "LVA"
                    	};
        	  	vm.grntMobilePhoneRequired = true;
        		initCustomGuarantorValidation();
        	} else {
        		vm.grntMobilePhoneRequired = false;
        		console.log('surety is OFF');
        		console.log(vm.showSurety);
        	}
        };

    	vm.workExperienceData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "code",
			        fields: {
			        	"code": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" }
			        	}
				}
			},
			transport: {
				read: function(e){
					Application.getWorkExperienceValues(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('getWorkExperienceValues error');
								e.error(error);
							}
					);
				}
			},
			sort: {
				field: "priority",
				dir: "asc"
			}
        });
    	vm.workExperienceData.read();
    	
        if( mode != "new" ) {
        	var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.applStatus, vm.result.checkResult) : "blocked-error";
        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
        }
        
		vm.validatorOptions =  {    
		  	rules: {
		  		amountRange: function(input) {
			              if (input.is("[data-role=numerictextbox]") && input.is("[name=applAmount]")) {
			                	  //for recovery after submit and fix error value
			                	  var response = newApplAmountIsValid(input.data("kendoNumericTextBox").value());
			                	  return response; 
			              } else return true;
		  		}
		  	}
	    };
        
        function submitButtonDisable (){
        	if(vm.confirmSubmitClicked == false){
        		vm.confirmSubmitClicked = true;
        		console.log("submitButtonDisable");
        		vm.pleaseWaitOperationID = AlertService.add({
        			type: "info",
        			msg: "modules.application.message.pleaseWaitOperation",
        			timeout: 30000
        		});
        		vm.confirmSubmitTimeout = setTimeout(function(){submitButtonEnable();},30000);
        	} 
        };
        
        function submitButtonEnable (){
            vm.confirmSubmitClicked = false;
            console.log("submitButtonEnable");
            AlertService.closeAlert(vm.pleaseWaitOperationID.id, null);
            clearTimeout(vm.confirmSubmitTimeout);
        };
        
        vm.submit = function () {
        	// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	
        	var custVaild = true;
        	initCustomValidation();
        	var setFocusToField = 'first-field';
        	var errorMessage = '';
        	console.log("validating");

        	/* sales channel validation */
        	if (vm.hasRoleLateko) {
            	switch(mode) {
    	    		case "new":
    	    			if(vm.office.prtId == 1){
    	    				vm.application.partnerId = vm.office.prtId;
    	    				if(vm.application.salesChannel === "UNKNOWN" || vm.application.salesChannel === "PARTNER") {
    	                		vm.emptySalesChannel = true;
    	                	}else {
    	                    	vm.emptySalesChannel = false;
    	                	};
    	            	}else{
    	            		vm.application.partnerId = vm.office.prtId;
    	                	vm.application.salesChannel = "PARTNER";
    	                	vm.emptySalesChannel = false;
    	            	};
    	            	vm.application.officeId = vm.office.offId;
    	    			break;	
    	    		case "edit":
    	    			break;
    	    		case "view":
    	    			break;
    	    		default:
    	    	}
            } else {
            	if (mode == "new"){
            		vm.application.partnerId = vm.office.prtId;
                	vm.application.officeId = vm.office.offId;
                	vm.application.salesChannel = "PARTNER";
                	vm.emptySalesChannel = false;
            	}
            };
        	if(convertAgreement("MARKETING",vm.clntAgreement.marketingAllowed, vm.clnt.personCode).agr_value.toUpperCase() === "NULL") {
        		alertError("modules.application.short.validate.emptyMarketingAgreement");
        		vm.clntMarketingNoError = false;
        		return;
        	}
        	if (vm.emptySalesChannel){
        		return;
        	}
        	
        	/*------------------------------*/
        	if (!vm.clnt.workType){
        		console.log("empty clntWorkType");
        		vm.emptyClntWorkType = true;
        		vm.clntWorkTypeValid = false;
        		errorMessage = 'modules.application.validate.mainEmptyWorkType';
        		setFocusToField = 'clntWorkType';
        		custVaild = false;
        	} else {
        		if (vm.clnt.workType == 'EMPLOYEE'){
        			vm.clntWorkNameValid = vm.clnt.workName;
        			vm.clntWorkTitleValid = vm.clnt.workTitle;
        			vm.clntWorkExperienceValid = vm.clnt.workExperience;

        			if (!vm.clntWorkExperienceValid){
                		errorMessage = 'modules.application.validate.mainEmptyWorkExperience';
                		setFocusToField = 'clntWorkTitle';        				
        			}

        			if (!vm.clntWorkTitleValid){
                		errorMessage = 'modules.application.validate.mainEmptyWorkTitle';
                		setFocusToField = 'clntWorkTitle';        				
        			}

        			if (!vm.clntWorkNameValid){
                		errorMessage = 'modules.application.validate.mainEmptyWorkName';
                		setFocusToField = 'clntWorkName';        				
        			}
        		} else if (vm.clnt.workType == "SEP"){
        			vm.clntWorkRegnrValid = vm.clnt.workRegnr;
            		errorMessage = 'modules.application.validate.mainEmptyWorkRegnr';
            		setFocusToField = 'clntWorkRegnr';
        		} else if (vm.clnt.workType == "OTHER"){
        			vm.clntWorkNameValid = vm.clnt.workName;
            		errorMessage = 'modules.application.validate.mainEmptyWorkNameAlternative';
            		setFocusToField = 'clntWorkName';
        		}
        	}  
        	
        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  var name = (typeof $input.attr('name') != 'undefined') ? $input.attr('name') : "";
	                  
	                  if ($input.is("input[data-role=datepicker]")) {
    	                  if($input.context.required && $input.data("kendoDatePicker").value() === null) {
    	                	  console.log("wrong input:");
    	                	  console.log($input);
    	                	  custVaild = false;
    	                	  alertError("modules.application.validate.wrongDate");
    	                	  $formGroup.toggleClass('has-error', true);
    	                  } 
    	              }
	                  
	                  if($input[0].tagName == "BC-PHONE-NUMBER") {
	                	  //var id = $input[0].id;
	                	  var id = name;
	                	  if(!vm.showSurety && id.substring(0,4) == "grnt") {
	                		  eval('vm.'+id+'Required = false;');
	                		  eval('vm.'+id+'NoError = true;');
	                		  if($input.hasClass('ng-invalid')) eval('vm.applicationForm.'+id+'.$setValidity("phoneNumber", true);');
	                		  return true;
//	                	  } else if(vm.showSurety && id == "grntOtherPhone") {
//	                		  eval('vm.grntOtherPhoneRequired = false;');
//	                		  eval('vm.grntOtherPhoneNoError = true;');
//	                		  if($input.hasClass('ng-invalid')) eval('vm.applicationForm.grntOtherPhone.$setValidity("phoneNumber", true);');
//	                		  return true;
	                	  }
	                	  if (vm.clnt.contactPhone == "" && vm.applicationForm.contactPhone) vm.applicationForm.contactPhone.$setValidity("phoneNumber", true);
	                	  if($input.hasClass('ng-invalid')) {
	                		  $formGroup.toggleClass('has-error', true);
	                		  eval('vm.'+ id +'NoError = false;');
	                      	  if (id != "contactPhone") alertError("modules.application.validate.wrongNumber");
	                	  }
	                	  return true;
	                  }
	                  if($input.hasClass('ng-invalid') || $input.hasClass('k-invalid')) $formGroup.toggleClass('has-error', true);
	              });
	            }
        			
        	});

        	console.log("submiting");
        	vm.validator.validate();
        	if(!custVaild || !vm.clntWorkNameValid || !vm.clntWorkTypeValid || !vm.clntWorkTitleValid || !vm.clntWorkExperienceValid || !vm.clntWorkRegnrValid) {
        		
        		console.log("custValid is wrong");
        		if (errorMessage)
        			alertError(errorMessage);
        		else 
        			alertError('modules.application.validate.applWrong');
        		
        		if (!formHelper.setFocus(setFocusToField))
        			$('#' + setFocusToField).focus();
        		return true;
        	}
        	
        	if(vm.applicationForm.$invalid || !vm.validator.validate()){
        		console.log("form is invalid");
        		alertError('modules.application.validate.applWrong');
        		if(!vm.clntMobilePhoneNoError) vm.clntMobilePhoneNoError = false;
//            	if(!vm.clntOtherPhoneNoError) vm.clntOtherPhoneNoError = false;
            	if(vm.showSurety && !vm.grntMobilePhoneNoError) vm.grntMobilePhoneNoError = false;
  //          	if(vm.showSurety && !vm.grntOtherPhoneNoError) vm.grntOtherPhoneNoError = false;
            	if(!vm.clntContactPhoneNoError) vm.clntContactPhoneNoError = false;
        		formHelper.setFocus('first-field');
        		return;
        	}
        	
        	console.log("Application is VALID");

        	vm.application.supplicant = [];
        	var supplicant = [];
        	getClntAgreements();
        	supplicant.push(vm.clnt);
        	if(vm.showSurety) supplicant.push(vm.grnt); 
        	vm.application.supplicant = supplicant;
        	switch(mode) {
	        	case "new":
	        		if (vm.confirmSubmitClicked == true) break;
	        		submitButtonDisable();
	        		Application.save(vm.application, 
	                    	function(result){
	        					vm.application = result;
		                    	/*On success*/
	        					submitButtonEnable();
		                    	AlertService.success("modules.alert.success.save");
		                    	console.log("saving application: success");
//		                    	$location.update_path('/applications/'+result.id);
		                    	$state.go('modules.applications.detail',{id: result.id});
	                    	}, function(error){
	                    		/*On error*/
	                    		submitButtonEnable();
	                    		console.log("saving application: error");
	                    	});
	        		break;
	        	case "edit":
	        		if (vm.confirmSubmitClicked == true) break;
	        		submitButtonDisable();
	        		Application.update(vm.application, 
	                    	function(result){
	                    	/*On success*/
	        			    vm.application = result;
	        			    submitButtonEnable();
	                    	AlertService.success("modules.alert.success.update");
	                    	console.log("updating application: success");
	                    	$state.reload();
	                    	}, function(){
	                    	/*On error*/
	                    		//initCustomValidation();
	                    		submitButtonEnable();
	                    		console.log("updating application: error");
	                    	});
	        		break;
	        	case "view":
	        	default:
        	};
        };

    	function initCustomValidation(){
        	vm.clntMarketingNoError = true;
        	vm.clntMobilePhoneNoError = true;
//        	vm.clntOtherPhoneNoError = true;
        	vm.clntContactPhoneNoError = true;
        	vm.clntWorkTypeValid = true;
        	vm.clntWorkNameValid = true;
        	vm.clntWorkTitleValid = true;
        	vm.clntWorkExperienceValid = true;
        	vm.clntWorkRegnrValid = true;
    	}
    	function initCustomGuarantorValidation(){
	    	vm.grntMarketingNoError = true;
	    	vm.grntMobilePhoneNoError = true;
//	    	vm.grntOtherPhoneNoError = true;
	    	
			if(vm.grnt.agreement != null && vm.grnt.agreement.length > 0) {
	        	vm.grntAgreement = {};
	        	for(var i = 0; i < vm.grnt.agreement.length; i++) {
	        		switch (vm.grnt.agreement[i].agr_type) {
	        		case "MARKETING":
	        			vm.grntAgreement.marketingAllowed = convertResponceAgreement(vm.grnt.agreement[i].agr_value); 
	        			break;
	        		}
	        	}
//	        	if(!vm.grntAgreement.marketingAllowed) vm.vsaaOptionsDisable = true; 
	        } else {
	        	vm.grntAgreement = {};
	        	vm.grntAgreement.marketingAllowed = null; 
				vm.grntAgreement.socialInsurance = null;
				vm.grntAgreement.paidOut = null;
				vm.grntAgreement.paidAmount = null;
	        }
    	}
    	
    	function getResultMode(applStatus,checkResult){
    		var result = "";
    		switch(applStatus){
    			case "NEW":
	    		case "QUEUE_AUTO":
	    		case "QUEUE_MANUAL":
	    		case "IN_PROGRESS":
	    			result = "wait";
	    			break;	
	    		
	    		case "PROCESSED":
	    			switch(checkResult) {
	    				case "GRANTED":
	    					result = "granted";		
	    					break;
	    					
	    				case "REQUIREMENTS":
	    					result = "requirements";
	    					break;
	    					
	    				case "DENIED":
	    					result = "denied";
	    					break;
	    					
	    				case "OFFER":
	    					result = "offer";
	    					break;
	    				
	    				default:
	    					result = "no-result";
	    			}
	    			break;
	    		
	    		case "NEED_TO_PROCESS":
					result = "need-to-process";
					break;	
	    			
	    		case "CONTRACT_CREATED":
	    			result = "created";
	    			break;
	    			
	    		case "EXPIRED":
	    			result = "expired";
	    			break;
	    			
	    		case "BLOCKED":
	    		case "ERROR":
	    			result = "blocked-error";
	    			break;
	    		case "RECEIVED":
	    			result = "received";
	    			break;
    		}
    		return result;
    	}
    	
    	if(mode == "edit") {
    		startResultRefresh();
    	}
    	
        vm.infoLapa = function(){
        	console.log('Printing InfoLapa');
       		vm.pdflink = baseUrl + 'api/applications/' + vm.application.id + '/docs/infolapa';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };

        vm.printMain = function(){
        	console.log('Printing Main');
       		vm.pdflink = baseUrl + 'api/applications/' + vm.application.id + '/docs/main';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };        

        vm.printSurety = function(){
        	console.log('Printing Surety');
       		vm.pdflink = baseUrl + 'api/applications/' + vm.application.id + '/docs/surety';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };   
        
        
        vm.printNewMain = function(){
        	console.log('Printing New Main');
        	vm.application.supplicant = [];
        	var supplicant = [];
        	getClntAgreements();
        	supplicant.push(vm.clnt);
        	if(vm.showSurety) supplicant.push(vm.grnt); 
        	vm.application.supplicant = supplicant;
        	
        	Application.verify(
        			vm.application, 
                	function(result){
        				console.log("verifing application: success");
    					console.log(result);
                    	/*On success*/
    		            var enc = escape(
	    	            			btoa(
	    		            			unescape(
	    		            					encodeURIComponent(
	    		            							angular.toJson({application: [ vm.application ]})
	    		            					)
	    		            			)
	    		            		)
	    	            );
	    	       		vm.pdflink = baseUrl + 'api/applications/new/docs/main';
	    	           	vm.pdf = vm.pdflink + "?rq=" + enc + "&tmst="+ (new Date()).getTime();
	    	       		$window.open(vm.pdf, '_blank'); 
                	},
                	function(error){
                		/*On error*/
                		console.log("verifing application: error");
                	}
            );

        };        

        vm.getDataFromBonusForClnt = function () {
        	if(vm.clnt.personCode == null || vm.clnt.personCode == "") return;
        	Application.getPersonalDataFromBonus(
        			{personCode: vm.clnt.personCode}, 
                	function(result){
                    	/*On success*/
        				console.log("Loaded Personal Data for Client");
        				if(result.personCode != null && result.personCode !="") {
        					vm.clnt.firstName   = result.firstName;
        					vm.clnt.lastName    = result.lastName;
        					vm.clnt.passNumber  = result.passNumber;
        					vm.clnt.passExpire  = result.passExpire;
        					vm.clnt.mobPhone    = vm.clnt.mobPhone == null || vm.clnt.mobPhone == "" ? result.mobPhone : vm.clnt.mobPhone;
        					vm.clnt.otherPhone  = result.otherPhone;
        					vm.clnt.email       = vm.clnt.email == null ? result.email : vm.clnt.email;
        					vm.clnt.livPlace    = result.livPlace;
        					vm.clnt.livStreet   = result.livStreet;
        					vm.clnt.livHouse    = result.livHouse;
        					vm.clnt.livPostcode = result.livPostcode;
        					vm.clnt.regPlace    = result.regPlace;
        					vm.clnt.regStreet   = result.regStreet;
        					vm.clnt.regHouse    = result.regHouse;
        					vm.clnt.regPostcode = result.regPostcode;
        					vm.clnt.workName    = result.workName;
        					vm.clnt.workTitle   = result.workTitle;
        					vm.clnt.worksFrom   = result.worksFrom;
        					vm.clnt.workPlace   = result.workPlace;
        					vm.clnt.workStreet  = result.workStreet;
        					vm.clnt.workHouse   = result.workHouse;
        					vm.clnt.workPostcode = result.workPostcode;
        					vm.clnt.workIncome   = vm.clnt.workIncome == null ? result.workIncome : vm.clnt.workIncome;
        					vm.clnt.otherIncome  = result.otherIncome;
        					vm.clnt.loanPay      = vm.clnt.loanPay == null ? result.loanPay : vm.clnt.loanPay;
        					vm.clnt.dependantsCount = result.dependantsCount;
        					vm.clnt.familyIncome     = result.familyIncome;
        					vm.clnt.familyLoanPay    = result.familyLoanPay;
        					vm.clnt.contactFirstName = result.contactFirstName;
        					vm.clnt.contactLastName  = result.contactLastName;
        					vm.clnt.contactPhone     = result.contactPhone;
        					vm.clnt.politicalPerson  = result.politicalPerson;
        					vm.clnt.beneficiary      = result.beneficiary;
        					
//        					vm.application.salesChannel = vm.application.salesChannel == "UNKNOWN" ? "CC_CALL" : vm.application.salesChannel;
        				}
                	},
                	function(error){
                		/*On error*/
                		console.log("Error on Loading Personal Data for Client");
                	}
            );
        };
        
        vm.getDataFromBonusForGrnt = function () {
        	if(vm.grnt.personCode == null || vm.grnt.personCode == "") return;
        	Application.getPersonalDataFromBonus(
        			{personCode: vm.grnt.personCode}, 
                	function(result){
                    	/*On success*/
        				console.log("Loaded Personal Data for Guarantor");
        				if(result.personCode != null && result.personCode !="") {
        					vm.grnt.firstName   = result.firstName;
        					vm.grnt.lastName    = result.lastName;
        					vm.grnt.passNumber  = result.passNumber;
        					vm.grnt.passExpire  = result.passExpire;
        					vm.grnt.mobPhone    = result.mobPhone;
        					vm.grnt.otherPhone  = result.otherPhone;
        					vm.grnt.email       = result.email;
        					vm.grnt.livPlace    = result.livPlace;
        					vm.grnt.livStreet   = result.livStreet;
        					vm.grnt.livHouse    = result.livHouse;
        					vm.grnt.livPostcode = result.livPostcode;
        					vm.grnt.regPlace    = result.regPlace;
        					vm.grnt.regStreet   = result.regStreet;
        					vm.grnt.regHouse    = result.regHouse;
        					vm.grnt.regPostcode = result.regPostcode;
        					vm.grnt.workName    = result.workName;
        					vm.grnt.workTitle   = result.workTitle;
        					vm.grnt.worksFrom   = result.worksFrom;
        					vm.grnt.workPlace   = result.workPlace;
        					vm.grnt.workStreet  = result.workStreet;
        					vm.grnt.workHouse   = result.workHouse;
        					vm.grnt.workPostcode = result.workPostcode;
        					vm.grnt.workIncome   = result.workIncome;
        					vm.grnt.otherIncome  = result.otherIncome;
        					vm.grnt.loanPay      = result.loanPay;
        					vm.grnt.dependantsCount = result.dependantsCount;
        					vm.grnt.familyIncome     = result.familyIncome;
        					vm.grnt.familyLoanPay    = result.familyLoanPay;
        					vm.grnt.contactFirstName = result.contactFirstName;
        					vm.grnt.contactLastName  = result.contactLastName;
        					vm.grnt.contactPhone     = result.contactPhone;
        					vm.grnt.politicalPerson  = result.politicalPerson;
        					vm.grnt.beneficiary      = 0; // null value for this field
        				}
                	},
                	function(error){
                		/*On error*/
                		console.log("Error on Loading Personal Data for Guarantor");
                	}
            );
        };
        
        vm.printNewSurety = function(){
        	console.log('Printing New Surety');
        	vm.application.supplicant = [];
        	var supplicant = [];
        	supplicant.push(vm.clnt);
        	if(vm.showSurety) supplicant.push(vm.grnt); 
        	vm.application.supplicant = supplicant;
        	Application.verify(
        			vm.application, 
                	function(result){
        				console.log("verifing application: success");
    					console.log(result);
                    	/*On success*/
    		            var enc = escape(
	    		        			btoa(
	    		            			unescape(
	    		            					encodeURIComponent(
	    		            							angular.toJson({application: [ vm.application ]})
	    		            					)
	    		            			)
	    		            		)
	    		        );
	    	       		vm.pdflink = baseUrl + 'api/applications/new/docs/surety';
	    	           	vm.pdf = vm.pdflink + "?rq=" + enc + "&tmst="+ (new Date()).getTime();
	    	       		$window.open(vm.pdf, '_blank');				
                	},
                	function(error){
                		/*On error*/
                		console.log("verifing application: error");
                	}
            );
        };  
        
    	function startResultRefresh(){
    		var run = $interval(reload, vm.refresh);
	        
	    	vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
	    	
	        function reload(){
	        	console.log('refreshing ... ' + vm.refresh );
	        	Application.getResult(
	        			{id : $stateParams.id},
	        			function(response){
							vm.result = response;
							var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
				        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.applStatus, vm.result.checkResult) : "blocked-error";
				        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
					        vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
						},
						function(error){
							console.log('error on refresh result');
							if(error.data.err) {
								$interval.cancel(run);
								vm.resultMode = "server-error";
								alertError(error.data.msg);
							}
						}).$promise;
	        }
	        
	        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
	        	$interval.cancel(run);
	        	run = $interval(reload, vm.refresh);
	        }, true);
	        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
	        	$interval.cancel(run);
	        });
	        
	        $scope.$watch(
	        		function(){
	        			if(mode == 'new') return null;
	        			else return angular.toJson(vm.application);
	        		}, 
	        		function(newApp,oldApp) {
	        			if(!equalsApplWOLoanFields(newApp,oldApp)) {
	        				vm.disablePrint = true;
	        			}
	        		}
	        );
    	}
    	
    	function equalsApplWOLoanFields(newApp, oldApp){
    		var tempNewApp = null;
    		var tempOldApp = null;
    		
    		tempNewApp = angular.fromJson(newApp);
    		tempOldApp = angular.fromJson(oldApp);
    		
    		tempNewApp.loanTrm = undefined;
    		tempOldApp.loanTrm = undefined;
    		
    		tempNewApp.loanAdm = undefined;
    		tempOldApp.loanAdm = undefined;
    		
    		tempNewApp.loanPayTot = undefined;
    		tempOldApp.loanPayTot = undefined;
    		
    		tempNewApp.loanPayPrn = undefined;
    		tempOldApp.loanPayPrn = undefined;
    		
    		tempNewApp.loanPayInt = undefined;
    		tempOldApp.loanPayInt = undefined;
    		
    		tempNewApp.loanPaySam = undefined;
    		tempOldApp.loanPaySam = undefined;
    		
    		tempNewApp.loanRate = undefined;
    		tempOldApp.loanRate = undefined;
    		
    		tempNewApp.loanIrr = undefined;
    		tempOldApp.loanIrr = undefined;
    		
    		tempNewApp.loanTotal = undefined;
    		tempOldApp.loanTotal = undefined;
    		
    		if (angular.equals(tempNewApp, tempOldApp)){
    			return true;
    		}else return false;
    	}
    }
})();
