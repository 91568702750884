(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('AddressesClientController', AddressesClientController);

    AddressesClientController.$inject = ['$scope', '$state', 'currentClient', 'defaultPageSize', '$translate', 'AlertService'];

    function AddressesClientController ($scope, $state, currentClient, defaultPageSize, $translate, AlertService) {
        var vm = this;
        console.log("Addresses Client Controller started");
        vm.addresses = currentClient[0].address;
        
        vm.clientAddressesDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "id": { type: "number" },
			          "crtdOn": { type: "date" },
			          "updtOn": { type: "date" },
			          "crtdBy": { type: "string" },
			          "updtBy": { type: "string" },
			          "recipient": { type: "string" },
			          "workName": { type: "string" },
			          "place": { type: "string" },
			          "street": { type: "string" },
			          "house": { type: "string" },
			          "postcode": { type: "string" },
			          "comment": { type: "string" },
			          "address": { type: "string" },
			          "status": { type: "string" },
			          "stsTranslated": { type: "string" },
			          "type": { type: "string" },
			          "date": { type: "date" },
			          "typeTranslated": { type: "string" }
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                		var crtdDate = elem.crtdOn != null ? true : false;  
	                		var updtDate = elem.updtOn != null ? true : false;
	                		if(crtdDate && updtDate)
	                			elem.date = elem.updtOn > elem.crtdOn ? elem.updtOn : elem.crtdOn ;
	                		else if (crtdDate && !updtDate)
	                			elem.date = elem.crtdOn;
	                		else if (!crtdDate && updtDate)
	                			elem.date = elem.updtOn;
	                        elem.stsTranslated =  elem.status ? $translate.instant('modules.client.dataStatus.' + elem.status.toLowerCase()) : "";
	                        elem.typeTranslated = elem.type ? $translate.instant('modules.client.addressType.' + elem.type.toLowerCase()) : "";
	                    });
	                	return d;
	              }
    		},
			data: vm.addresses,
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "date",
                dir: "desc"
            }
        });
        
        vm.clientAddressesOptions = {
        		scrollable: true,
                sortable: true,                
                filterable: false,
                pageable: {
                	messages: {
                	      empty: "No data"
                	}
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.client.titles.addresses'></span>"
  		                    		 + "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.client.excel'>"
  		                             + "</span></button>"
		                    		 + "<button has-authority='ROLE_MANAGER' style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.clients.addresses.add'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.client.addAddress'>"
		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "client-addresses.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },
                columns: [
							{  
								headerTemplate: "<span translate='modules.client.table.default'>",
								template: "<i ng-show='#: isDefault #' style='transform: scale(1.5)' class='fa fa-check text-success' aria-hidden='true'></i>",
								attributes: {style:"text-align:center;"},
								width: "7ch"
							},{
			                    field: "crtdOn",
			                    title: "{{'modules.client.table.crtdOn'|translate}}",
			                    format: "{0:d}",
			                    width: "12ch"
		                    },{
			                    field: "updtOn",
			                    title: "{{'modules.client.table.updtOn'|translate}}",
			                    format: "{0:d}",
			                    width: "12ch"
		                    },{ 
								field: "address",
								title: "{{'modules.client.table.address'|translate}}",
		                        width: "20ch"
							},{ 
								field: "typeTranslated",
								title: "{{'modules.client.table.type'|translate}}",
								width: "12ch"
							},{ 
								field: "stsTranslated", 
								title: "{{'modules.client.table.status'|translate}}",
								width: "10ch"
							},{
	                          	title: "{{'modules.client.table.actions'|translate}}",
	                        	template: "<button has-authority='ROLE_LATEKO' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.clients.addresses.view({adrId: #:id#})'>"
	                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
	                                + "</button>",
			                        width: "7ch" 
	                          }
                    ]
 
        };
    }
})();
