(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteEmailClientController', DeleteEmailClientController);

    DeleteEmailClientController.$inject = ['$uibModalInstance', '$state', 'Client', 'AlertService'];

    function DeleteEmailClientController ($uibModalInstance, $state, Client, AlertService) {
        var vm = this;
        vm.currentEmail = $state.params.currentEmail;
        vm.clnId = $state.params.clnId;
        if($state.params.emlId == "") {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Client's email don't get for deleting");
        	return;
        }
        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (emlId) {
        	AlertService.success("MOCK Client's email deleted successfully, id = " + emlId);
        	$uibModalInstance.close(true);
            Client.deleteEmail({clnId: vm.clnId, emlId: emlId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
