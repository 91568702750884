(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CurrentOfficeController', CurrentOfficeController);

    CurrentOfficeController.$inject = ['$scope', '$state', '$stateParams', 'CurrentOffice', '$element', '$window', '$filter', 'Cookies', 'Principal', 'AlertService'];

    function CurrentOfficeController ($scope, $state, $stateParams, CurrentOffice, $element, $window, $filter, Cookies, Principal, AlertService) {
        var vm = this;
        console.log("CurrentOfficeController ...");
        vm.office = {};
        vm.partners = [];
        vm.noOffices = false;
        vm.loadOffices = loadOffices;
        
        Principal.identity().then(function(account) {
            vm.account = account;
            vm.office.prtId = Cookies.partnerID(vm.account.login);
            vm.office.offId = Cookies.officeID(vm.account.login);
        });
        
        vm.officesListDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"prtId": { type: "number" },
			        	"prtName": { type: "string" },
			        	"prtCode": { type: "string" },
			        	"offName": { type: "string" },
			        	"offAddress": { type: "string" }
			        }
				},
				parse: function(d){
                	$.each(d, function (idx, elem) {
                		var partner = {
                			id: elem.prtId,
                			prtName: elem.prtName,
                			prtCode: elem.prtCode
                		};
                		var foundDocType = $filter('filter')(vm.partners, {id: elem.prtId}, true);
                        if(foundDocType.length < 1) (vm.partners).push(partner);
                	});
                	vm.partnersListDataSource.read();
                	return d;
				}
			},
			transport: {
				read: function(e){
					CurrentOffice.getList(
							{},
							function(response){
								vm.noOffices = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('offices list error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "prtId",
				dir: "asc"
			}
        });
        
        vm.partnersListDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"prtName": { type: "string" },
			        	"prtCode": { type: "string" }
			        }
				}
			},
			data: vm.partners,
			sort: {
				field: "prtId",
				dir: "asc"
			}
        });
        
        vm.officesListOptions = {
        	    selectable: "Single",
        	    dataBound: function(event) {
        	        if (vm.office.offId > 0)
        	    	{
        	    	    var listView = event.sender;
        	            var itemWithID = listView.dataSource.data().find(function(item){
        	            	return item.id == vm.office.offId;
        	            });
        	            listView.select(listView.element.children('[data-uid="' + itemWithID.uid + '"]').first());
        	    	}
        	    },
        	  	change: function(event) {
        	        var selected = event.sender.dataItem(event.sender.select());
        	        vm.office.prtName = selected.prtName;
        	        vm.office.offName = selected.offName;
                	vm.office.prtId = selected.prtId;
                	vm.office.offId = selected.id;
                	vm.changeOffice = true;
                	resizeWrap(getWrapHeight());
        	    }
        };
        
        vm.partnersListOptions = {
        	    selectable: "Single",
        	    dataBound: function(event) {
        	    	if (vm.office.prtId > 0)
        	    	{
        	    	    var listView = event.sender;
        	            var itemWithID = listView.dataSource.data().find(function(item){
        	            	return item.id == vm.office.prtId;
        	            });
        	            listView.select(listView.element.children('[data-uid="' + itemWithID.uid + '"]').first());
        	            vm.officesListDataSource.filter({field: "prtId", operator: "eq", value: vm.office.prtId });
        	    	}
        	    },
        	  	change: function(event) {
        	  		vm.changeOffice = false;
        	        var selected = event.sender.dataItem(event.sender.select());
                	vm.office.prtId = selected.id;
                	vm.office.prtName = selected.prtName;
                	vm.officesListDataSource.filter({field: "prtId", operator: "eq", value: vm.office.prtId });
                	resizeWrap(getWrapHeight());
        	    }
        };
        
        loadOffices();
        
        function loadOffices(){
        	console.log("loading");
        	vm.officesListDataSource.read();
        }
        
        $scope.$watch(
    	        function(){
    	        	return $element.find('#offic').prop('offsetWidth');
    	        },
    	        function(){
    	        	resizeWrap(getWrapHeight());
    	        }
        );
        
        $scope.$watch(
        	    function(){
        	    	return vm.partnerSearch;
        	    },
        	    function(newValue,oldValue){
        	    	if(oldValue != newValue) {
        	    		if (newValue != ""){
        	    			vm.partnersListDataSource.filter({field: "prtName", operator: "contains", value: newValue });
            	    	    vm.officesListDataSource.filter({field: "prtName", operator: "contains", value: newValue });
        	    		}else{ 
        	    			vm.officeSearch = "";
        	    		}
        	    	}
        	    }
            );
        
        $scope.$watch(
        	    function(){
        	    	return vm.officeSearch;
        	    },
        	    function(newValue,oldValue){
        	    	if(oldValue != newValue) {
        	    		if (vm.partnerSearch > ""){	
        	    		    vm.officesListDataSource.filter([
        	    		        {field: "offName", operator: "contains", value: newValue }, 
        	    		        {field: "prtName", operator: "contains", value: vm.partnerSearch }]
        	    		    );
        	    		}else{
        	    			if (vm.office.prtId > 0){
        	    				vm.officesListDataSource.filter([
        	    				    {field: "offName", operator: "contains", value: newValue },
        	    				    {field: "prtId", operator: "eq", value: vm.office.prtId }]);
        	    			}else{
        	    				vm.officesListDataSource.filter([
        	    				    {field: "offName", operator: "contains", value: newValue }
        	    				]);    
        	    			}
        	    		}    
        	    	}
        	    }
        );
        
        $scope.$watch(
        	    function(){
        	    	return vm.office.prtId;
        	    },
        	    function(newValue,oldValue){
        	    	if(oldValue != newValue && vm.changeOffice == false) {
        	    		vm.office.offId = null;    
        	    	}
        	    	vm.changeOffice = false;
        	    }
        );
        
        $scope.$watch(
        	    function(){
        	    	return vm.office.offId;
        	    },
        	    function(newValue,oldValue){
        	    	if(oldValue != newValue) {
        	    	}
        	    }
        );
        
        function resizeWrap(ht){
        	vm.officesWrap = '350px';
        }
        
        function getWrapHeight() {
        	var ht = $element.find('#offic').prop('offsetHeight')-$element.find('#wrapOffice').prop('offsetTop')-23;
        	return $window.innerWidth < 768 ? 'auto' : ht + 'px';
        }
        
        vm.calculate = function(){
        };
        
        vm.submit = function(){
        	if (vm.office.prtId > 0 && vm.office.offId){
        		Cookies.setPartnerID(vm.account.login, vm.office.prtId);
            	Cookies.setOfficeID(vm.account.login, vm.office.offId);
            	AlertService.success("modules.currentOffice.message.savedSuccessfully", {partnerName: vm.office.prtName, officeName: vm.office.offName});
            	Principal.identity(true);
            	$state.go('modules.current-office', {}, {reload: true});
        	}else{
        		if (vm.office.prtId <= 0){
        			AlertService.error("modules.currentOffice.message.errorPartner");
        		};
        		if (vm.office.offId <= 0){
        			AlertService.error("modules.currentOffice.message.errorOffice");
        		};
        	}
        	
        };
    }
})();
