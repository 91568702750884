(function() {
	'use strict';
	angular.module('sigmaApp').factory('ChangeInterestRate', ChangeInterestRate);

	ChangeInterestRate.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function ChangeInterestRate($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/loans/rates';
		
		return $resource(resourceUrl, {}, {
			'getInterestRates' : {
				method : 'GET',
				url: resourceUrl + "/components/interest", 
				isArray : true,
			},
			'getPrincipalRates' : {
				method : 'GET',
				url: resourceUrl + "/components/principal", 
				isArray : true,
			},
			'createInterestRates' : {
				method : 'POST',
				isArray : false,
			},
			

        });	
	}
})();