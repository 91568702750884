(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeletePhoneClientController', DeletePhoneClientController);

    DeletePhoneClientController.$inject = ['$uibModalInstance', '$state', 'Client', 'AlertService'];

    function DeletePhoneClientController ($uibModalInstance, $state, Client, AlertService) {
        var vm = this;
        vm.currentPhone = $state.params.currentPhone;
        vm.clnId = $state.params.clnId;
        if($state.params.phnId == "") {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Client's phone don't get for deleting");
        	return;
        }
        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (phnId) {
        	AlertService.success("Client's phone deleted successfully, id = " + phnId);
        	$uibModalInstance.close(true);
            Client.deletePhone({clnId: vm.clnId, phnId: phnId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
