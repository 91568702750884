(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CreatedCardReportController', CreatedCardReportController);

    CreatedCardReportController.$inject = ['$window', '$scope', '$rootScope', '$state', 'stat', '$translate', 'shortRefresh', '$interval', 'baseUrl', 'shortDateFormats'];

    function CreatedCardReportController ($window, $scope, $rootScope, $state, stat, $translate, shortRefresh, $interval, baseUrl, shortDateFormats) {
        var vm = this;
        
        vm.getCreatedCardReport = getCreatedCardReport;
        
        vm.fromDate =  new Date();
        vm.toDate = new Date();
        
        vm.shortDateFormats = shortDateFormats;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
        $scope.$watch(function(){
    		return vm.fromDate;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && oldVal.toLocaleDateString('lt-LT') == vm.toDate.toLocaleDateString('lt-LT')) {
    			vm.toDate = newVal;
    			console.log(vm.toDate);
    		}
    		vm.betweenDates=vm.rangeDatesReverce();
    	});
        
        $scope.$watch(function(){
        	return vm.toDate;
		}, function(newVal,oldVal) {
			if (newVal != oldVal && newVal.valueOf() < vm.fromDate.valueOf()) {
				vm.toDate = vm.fromDate;
				console.log(vm.toDate);
			}
			vm.betweenDates=vm.rangeDatesReverce();
		});
        
        vm.betweenDatesDataSource = new kendo.data.DataSource({
        	data: [
        	 		{
        	 			key: 1,
        	 			value: $translate.instant("modules.report.date.thisMonth"),
        	    	},{
        	    		key: 2,
        	    		value: $translate.instant("modules.report.date.lastMonth"),
        	    	},{
        	    		key: 3,
        	    		value: $translate.instant("modules.report.date.thisWeek"),
        	    	},{
        	    		key: 4,
        	    		value: $translate.instant("modules.report.date.yesterday"),
        	    	},{
        	    		key: 5,
        	    		value: $translate.instant("modules.report.date.today"),
        	    	}
        	    ]
    	});
        
        $scope.$watch(function(){
    		return vm.betweenDates;
	    	}, function(newVal,oldVal) {
	    		if (newVal != oldVal && newVal != 0) {
	    			var range = rangeDates(newVal);
	    			vm.fromDate = range.fromDate;
	    			vm.toDate = range.toDate;
	    		}
    	});
        
        function rangeDates(id) {
        	var fromDate;
        	var toDate;
        	
        	switch (id) {
	        	case 1:
	        		var curr = new Date;
	        	 	fromDate  = new Date(curr.getFullYear(), curr.getMonth(), 1);
	        	 	toDate = new Date;
	        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
	        		break;
	        	case 2:
	        		var curr = new Date;
	        	 	fromDate = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
	        	 	toDate = new Date;
	        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth(), 0); 
	        		break;
	        	case 3:
	        		var curr = new Date;
	        		fromDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
	        		curr = new Date;
	        	 	toDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
	        		break;
	        	case 4:
	        		fromDate = new Date();
	        		fromDate.setDate(fromDate.getDate() - 1);
	        	 	toDate = fromDate;
	        		break;
	        	case 5:
	        		fromDate = new Date();
	        	 	toDate = new Date();
	        	    break;
        	}
        	
        	return {
        		fromDate: fromDate,
        		toDate: toDate
        	}
        }

        vm.rangeDatesReverce = function () {
        	
        	var fromDate = vm.fromDate;
        	var toDate = vm.toDate;
        	
        	var current = new Date;
        	
        	var currnetMonthLastDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        	var lastMonthLastDate = new Date(current.getFullYear(), current.getMonth(), 0);
        	
        	var bg = new Date;
        	var beginCurrentWeek = new Date(bg.setDate(bg.getDate() - bg.getDay() + 1));
			var en = new Date;
    	 	var endCurrentWeek = new Date(en.setDate(en.getDate() - en.getDay() + 7));
    	 	
        	if(fromDate.toLocaleDateString() == toDate.toLocaleDateString()) {
        		if(fromDate.toLocaleDateString() == current.toLocaleDateString()) {
        			return 5;
        		} else if(fromDate.getDate() == current.getDate() - 1 && fromDate.getFullYear() == current.getFullYear() && fromDate.getMonth() == current.getMonth()){
        			return 4;
        		} else return 0;
        	} else if(fromDate.getFullYear() == current.getFullYear()) {
        		if(fromDate.getMonth() == current.getMonth() && toDate.getMonth() == current.getMonth() && fromDate.getDate() == 1 && currnetMonthLastDate.getDate()) {
        			return 1;
        		} else if(fromDate.getMonth() == current.getMonth() - 1 && toDate.getMonth() == current.getMonth() - 1 && fromDate.getDate() == 1 && lastMonthLastDate.getDate()) {
        			return 2;
        		} else if(fromDate.toLocaleDateString() == beginCurrentWeek.toLocaleDateString() && toDate.toLocaleDateString() == endCurrentWeek.toLocaleDateString()) {
        			return 3;
        		} else return 0;
        	} else return 0;
        };
        
        function getCreatedCardReport(){
        	console.log('getCreatedCardReport');
        	vm.dateFrom = vm.fromDate.toLocaleDateString('lt-LT');
        	vm.dateTill = vm.toDate.toLocaleDateString('lt-LT');
        	console.log(vm.dateFrom);
            console.log(vm.dateTill);
        	vm.reportLink = baseUrl + 'api/reports/created-cards/';
        	vm.report = vm.reportLink + "?dateFrom=" + vm.dateFrom + "&dateTill=" + vm.dateTill;
        	$window.open(vm.report, '_blank');	
        };
        
    }
})();
