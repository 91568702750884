(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'defaultNonamePrefix'];

    function stateConfig($stateProvider, defaultNonamePrefix) {
        $stateProvider
        .state('modules.noname-card-generate', {
			url : '/noname-card-generate',
			parent: 'modules',
	        data: {
	        	authorities: ['ROLE_MANAGER'],
	        },
			views : {
				'inner@': {
                    controller: 'NonameCardController',
                    controllerAs: 'vm'
                },
				'content@' : {
					templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
					controller : 'CardHolderDetailLoanController',
					controllerAs : 'vm'
				}
			},
			resolve: {
    			translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		        	$translatePartialLoader.addPart('modules/loan');
		            $translatePartialLoader.addPart('global');                    
		            return $translate.refresh();
		        }],
		        menuWithHeader: function(){
	            	return [{rights:"ALLOW_NONAME_CARDS_GENERATE"}];
		        },
		        defaultValue : ['$stateParams', 'Card', function($stateParams, Card) {
					return Card.getDefaultNoname({prefix: defaultNonamePrefix}).$promise;
				}],
				cardHolder: function(){
		            	return null;
		        },
                cardHolderAddresses:  function(){
		            	return null;
		        },
				detailMode: function(){
					return {
		            	edit: false,
		            	create: false,
		            	noname: false,
		            	nonameCardGenerate:true
		        	};
		        }
			}
		});
    }
})();
