(function() {
	'use strict';

	angular.module('sigmaApp').component('history', {
		templateUrl : "app/modules/history.template.html",
		controller : historyController,
		bindings : {
			doc: '=',
			type : '='
		}
	});

	historyController.$inject = [ '$scope', '$element', '$attrs', 'History', 'defaultPageSize', '$filter', '$translate', '$window'];

	function historyController($scope, $element, $attrs, History, defaultPageSize, $filter, $translate, $window) {
		var ctrl = this;
		var types = {
			data: []
		};
		var users = {
			data: []
		};
        ctrl.dateFormat = kendo.culture().calendar.patterns.G;
        ctrl.detail = false;
		ctrl.historyDataSource = new kendo.data.DataSource({
			schema : {
				model : {
					fields : {
						"eventId" : {
							type : "number"
						},
						"docId" : {
							type : "number"
						},
						"type" : {
							type : "string"
						},
						"subject" : {
							type : "string"
						},
						"desc" : {
							type : "string"
						},
						"comment" : {
							type : "string"
						},
						"createdOn" : {
							type : "date"
						},
						"createdBy" : {
							type : "string"
						}
					}
				},
				parse: function(d){
                	$.each(d, function (idx, elem) {
                		var type = {
                			type: elem.type
                		};
                		var foundType = $filter('filter')(types.data, {type: elem.type}, true);
                        if(foundType.length < 1) (types.data).push(type);
                        
                        var user = {
                        	createdBy: elem.createdBy
                		};
                		var foundUser = $filter('filter')(users.data, {createdBy: elem.createdBy}, true);
                        if(foundUser.length < 1) (users.data).push(user);
                    });
                	return d;
				}
			},
			transport : {
				read : function(e) {
					History.get({
						docId : ctrl.doc,
						query_tipas: ctrl.type
					}, function(response) {
						e.success(response);
					}, function(error) {
						console.log('history list error');
						e.error(error);
					});
				}
			},
//			pageSize : defaultPageSize,
			serverPaging : false,
			serverFiltering : false,
			serverSorting : false,
			sort : {
				field : "createdOn",
				dir : "desc"
			}
		});

		ctrl.historyOptions = {
			scrollable: {
	            virtual: true
	        },
	        height: function(){
	        	return $window.innerHeight/2.5;
	        },
	        sortable: true,                
	        pageable: {
	        	refresh: true,
	        	previousNext: false,
	        	numeric: false,
	        	info:false,
	        	messages: {
	        	      empty: "No data"
	        	    }
	        },
			filterable : {
				extra : false,
				operators : {
					string : {
						contains : "contains",
					}
				}
			},
			toolbar : [ {
				template : "<span style='line-height:22px;' translate='modules.history.titles.list'></span>"
						+ "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
						+ "<span class='fa fa-file-excel-o'></span>"
						+ "&nbsp;<span translate='modules.history.excel'>"
						+ "</span></button>"
			} ],
			excel : {
				fileName : "history.xlsx",
                allPages: true
            },
            excelExport: function(e) {
            	e.workbook.sheets[0].columns.forEach(function(column){
            		column.autoWidth = true;
            	});
//            	e.workbook.sheets[0].columns = [
//            		{ width:100 },
//            		{ width:300 },
//            		{ width:400 },
//            		// ...
//            	];
                e.workbook.sheets[0].rows.forEach(function(row){
                  if(row.type != 'data'){
                  	row.cells.forEach(function(cell){
                      cell.value = $translate.instant(cell.value);
                    });
                  }
            	});
			},
			columns : [
					{
						field : "createdOn",
						title : "{{'modules.history.table.date'|translate}}",
						width : "18ch",
						format : "{0:G}",
				        filterable: {
	                    	ui: "datetimepicker"
	                    }
					},
					{
						field : "type",
						title : "{{'modules.history.table.type'|translate}}",
						width : "20ch",
						filterable : {
                            multi : true,
                            dataSource : new kendo.data.DataSource(types),
                            dataTextField : "type"
						}
					},
					{
						field : "createdBy",
						title : "{{'modules.history.table.added'|translate}}",
						width : "11ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(users),
                            dataTextField : "createdBy"
						}
					},
					{
						field : "subject",
						title : "{{'modules.history.table.subject'|translate}}",
						template : "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#:subject#'\" style='width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>#:subject#</span>"
					},
					{
						template : "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ng-click='$ctrl.view(#:eventId#)'>"
								+ "<span class='glyphicon glyphicon-eye-open'></span>"
								+ "</button>",
						title : "&nbsp;",
						width : "5ch"
					} ]

		};
		
		ctrl.view = function(id){
			ctrl.detail = true;
			ctrl.currentEvent = $filter('filter')(ctrl.historyDataSource.data(), {eventId: parseInt(id)}, true)[0];
		};
		
		ctrl.close = function(){
			ctrl.detail = false;
			ctrl.currentEvent = {};
		};
	}

})();
