(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HeaderMvcController', HeaderMvcController);

    HeaderMvcController.$inject = ['$scope', '$state', 'entity', 'CardStatus'];

    function HeaderMvcController ($scope, $state, entity, CardStatus) {
        var vm = this;
        console.log("Header Mvc Controller started");
        if(entity.hasOwnProperty('statCode') && entity.hasOwnProperty('isActive')) { 
        	vm.mvc = entity;
        	vm.statusStyle = CardStatus.getStyle(vm.mvc.statCode, vm.mvc.isActive);
        } else {
        	vm.mvc = {};
        	vm.statusStyle = 'default';
        }
        
    }
})();
