(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ChangeValueAgreementClientController', ChangeValueAgreementClientController);

    ChangeValueAgreementClientController.$inject = ['$uibModalInstance', '$state', 'Client', 'AlertService', 'agreement'];

    function ChangeValueAgreementClientController ($uibModalInstance, $state, Client, AlertService, agreement ) {
        
    	var vm = this;

    	vm.clnId = $state.params.clnId;
        vm.agrId = $state.params.agrId;
        
        vm.currentAgreement = agreement;
        
        if(vm.agrId == "") {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Client's agreement don't get for change value");
        	return;
        }
        
        vm.clear = clear;
        vm.changeValue = changeValue;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
        function changeValue() {
        	$uibModalInstance.close(true);
        	Client.changeAgreementValue(
					{clnId: vm.clnId,  agrId: vm.agrId}, vm.currentAgreement.agrValue,
					function(responce){
						AlertService.success("modules.agreement.message.agreementValueChangedSuccessfully");
						$state.go('modules.clients.agreements.view', {agrId: vm.agrId}, {reload: true});
					});
        }
    }
})();
