(function() {
	'use strict';
	angular.module('sigmaApp').factory('SalesChannel', SalesChannel);

	SalesChannel.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function SalesChannel($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/setup/sales-channel';
		
		return $resource(resourceUrl, {}, {
			'getSalesChannel' : {
				method : 'GET',
				url: resourceUrl,
				isArray: false
			},
			'getOffices' : {
				method : 'GET',
				url: resourceUrl + "/offices",
				isArray: true,
				transformResponse : function(data) {
					if (data) {
						try {
							data = angular.fromJson(data);
						} catch(e){
							data = "";
						}
					}
					return data;
				}
			},
			'getPartners' : {
				method : 'GET',
				url: resourceUrl + "/partners",
				isArray: true,
				transformResponse : function(data) {
					if (data) {
						try {
							data = angular.fromJson(data);
						} catch(e){
							data = "";
						}
					}
					return data;
				}
			},
			'assignSalesChannel' : {
				method : 'PUT',
				url: resourceUrl + "/update",
				isArray : false,
			},
        });	
	}
})();