(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ShareholdersReportController', ShareholdersReportController);

    ShareholdersReportController.$inject = ['$window', '$scope', '$rootScope', '$state', 'stat', '$translate', 'shortRefresh', '$interval', 'baseUrl', 'shortDateFormats', 'WsService', 'Report', '$filter'];

    function ShareholdersReportController ($window, $scope, $rootScope, $state, stat, $translate, shortRefresh, $interval, baseUrl, shortDateFormats, WsService, Report, $filter) {

    	console.log('ShareholdersReport start!');
    	
    	var vm = this;
        vm.generateShareholdersReport = generateShareholdersReport;
        vm.fromDate =  new Date();
        vm.toDate = new Date();
        vm.addThisMonth;
        vm.subtractMonth = subtractMonth();
        vm.dateKey = 'last6Months';
        
        var range = rangeDates(vm.dateKey);
		vm.fromDate = range.fromDate;
		vm.toDate = range.toDate;
		
		vm.shortDateFormats = shortDateFormats;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
        $scope.$watch(function(){
    		return vm.fromDate;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && oldVal.toLocaleDateString('lt-LT') == vm.toDate.toLocaleDateString('lt-LT')) {
    			vm.toDate = newVal;
    			console.log(vm.toDate);
    		}
    		vm.betweenDates=vm.rangeDatesReverce();
    	});
        
        $scope.$watch(function(){
        	return vm.toDate;
		}, function(newVal,oldVal) {
			if (newVal != oldVal && newVal.valueOf() < vm.fromDate.valueOf()) {
				vm.toDate = vm.fromDate;
				console.log(vm.toDate);
			}
			vm.betweenDates=vm.rangeDatesReverce();
		});
        
        vm.betweenDatesDataSource = new kendo.data.DataSource({
        	data: [
        	 		{
        	 			key: 'last3Months',
        	 			value: $translate.instant("modules.report.date.lastThreeMonths"),
        	    	},{
        	    		key: 'last6Months',
        	    		value: $translate.instant("modules.report.date.lastSixMonths"),
        	    	},{
        	    		key: 'last12Months',
        	    		value: $translate.instant("modules.report.date.lastTwelveMonths"),
        	    	},{
        	    		key: 'thisYear',
        	    		value: $translate.instant("modules.report.date.thisYear"),
        	    	},{
        	    		key: 'lastYear',
        	    		value: $translate.instant("modules.report.date.lastYear"),
        	    	}
        	    ]
    	});
        
        $scope.$watch(function(){
    		return vm.betweenDates;
	    	}, function(newVal,oldVal) {
	    		console.log(vm.betweenDates);
	    			var range = rangeDates(newVal);
	    			vm.fromDate = range.fromDate;
	    			vm.toDate = range.toDate;
    	});
        
        $scope.$watch(function(){
    		return vm.addThisMonth;
	    	}, function(newVal,oldVal) {
	    		if (newVal != oldVal) {
	    			var range = rangeDates(vm.dateKey);
	    			vm.fromDate = range.fromDate;
	    			vm.toDate = range.toDate;
	    		}
    	});
        
        function rangeDates(id) {
        	var fromDate;
        	var toDate;
        	vm.dateKey = id;
        	switch (id) {
	        	case 'last3Months':
	        		var subtr = subtractMonth(3);
	        		fromDate = subtr.fromDate;
	        		if (vm.addThisMonth){
	        			toDate = new Date;
	        		}else{
	        		    toDate = subtr.toDate;
	        		};
	        		break;
	        	case 'last6Months':
	        		var subtr = subtractMonth(6);
	        		fromDate = subtr.fromDate;
	        		if (vm.addThisMonth){
	        			toDate = new Date;
	        		}else{
	        		    toDate = subtr.toDate;
	        		};
	        		break;
	        	case 'last12Months':
	        		var subtr = subtractMonth(12);
	        		fromDate = subtr.fromDate;
	        		if (vm.addThisMonth){
	        			toDate = new Date;
	        		}else{
	        		    toDate = subtr.toDate;
	        		};
	        		break;
	        	case 'thisYear':
	        		fromDate = new Date();
	        		fromDate = new Date(fromDate.getFullYear(), 0, 1);
	        	 	if (vm.addThisMonth){
	        	 		toDate = new Date();
	        	 	}else{
	        	 		toDate = new Date();
		        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth(), 0);
	        	 	}
	        		break;
	        	case 'lastYear':
	        		fromDate = new Date();
	        		fromDate = new Date(fromDate.getFullYear() - 1, 0, 1);
	        	 	toDate = new Date();
	        	 	toDate = new Date(toDate.getFullYear() - 1, 12, 0);
	        	    break;
	        	case '':
	        		fromDate = new Date();
	        		toDate = new Date();
	        		break;
        	}
        	
        	return {
        		fromDate: fromDate,
        		toDate: toDate
        	}
        }
        
        function subtractMonth(months){
        	var curr = new Date;
        	var fromDate = new Date;
        	var toDate = new Date;
        	if (months == 12){
        	    if (curr.getMonth() == 0){
        	    	fromDate = new Date(curr.getFullYear() - 1, 0, 1);
        	     	toDate = new Date(curr.getFullYear() - 1, 11, 0);
        	    }else{
        	        fromDate = new Date(curr.getFullYear() - 1, curr.getMonth(), 1);
    	     	    toDate = new Date(curr.getFullYear(), curr.getMonth(), 0);
        	    }    
        	}else{
        	    var monthsDiff = curr.getMonth() - months;
        	    if (monthsDiff >= 0){
        	    	fromDate = new Date(curr.getFullYear(), curr.getMonth() - months, 1);
        	    	toDate = new Date(curr.getFullYear(), curr.getMonth(), 0);
        	    }else{
        	    	fromDate = new Date(curr.getFullYear() - 1, 12 - Math.abs(monthsDiff), 1);
        	    	toDate = new Date(curr.getFullYear(), curr.getMonth(), 0);
        	    }
        	}
        	return{
	     	    fromDate :fromDate,
	     	    toDate:toDate
	     	};
        };
        
        vm.rangeDatesReverce = function () {
        	
        	var fromDate = vm.fromDate;
        	var toDate = vm.toDate;
        	
        	return vm.dateKey;
        };
        
        var docStatuses = {
				data: []
			};
		var docCrtdBys = {
				data: []
		};
        
        var reloadEvents = ['DMS_DOC_CREATED', 'DMS_CONTENT_CREATED'];
        
        WsService.receive().then(null, null, function(activity) {
        	if(typeof activity === 'object') {
        		if(activity.hasOwnProperty('key') && activity.hasOwnProperty('type')) {
        			var reloadPage = $filter('filter')(reloadEvents, activity.type, false).length > 0  && activity.key == "SHAREHOLDERS" ? true : false ;  
                	if (reloadPage) { 
                		$state.reload();
                	}	
        		}
        	}
	    });
      	
        vm.shareholdersDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "num",
			        fields: {
			        	  "docNum": { type: "string" },
				          "docDate": { type: "date"},
				          "regName": { type: "string" },
				          "docStatus": { type: "string" },
				          "fileName": { type: "string" },
				          "crtdOn": { type: "date" },
				          "crtdBy": { type: "crtdBy" }
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
		                    var docStatus = {
		                    		docStatus: elem.docStatus
		                    };
		                    var foundDocStatus = $filter('filter')(docStatuses.data, {docStatus: elem.docStatus}, true);
		                    if(foundDocStatus.length < 1) (docStatuses.data).push(docStatus);
		                    
		              		var crtdBy = {
		              				crtdBy: elem.crtdBy
		              		};
		              		var foundDocCrtdBy = $filter('filter')(docCrtdBys.data, {crtdBy: elem.crtdBy}, true);
		                    if(foundDocCrtdBy.length < 1) (docCrtdBys.data).push(crtdBy);
	                        
	                    });
	                	return d;
	                }
    		},
    		transport: {
				read: function(e){

					Report.getShareholdersList(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('Shareholders list error');
								e.error(error);
							}
					);
				}
			},
			aggregate: [
//                        { field: "expectedDate", aggregate: "count"},
//                        { field: "prn", aggregate: "sum"},

            ],
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "docNum",
                dir: "desc"
            }
        });
        
        vm.shareholdersOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
    			filterable : {
    				extra : false,
    			},
                height: function(){
                	return $window.innerHeight/2.5;
                },                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info:false,
                	messages: {
                	      empty: $translate.instant('modules.loan.table.noData'),
                	    }
                },
                excel: {
                	fileName: "loan-schedules.xlsx",
                    allPages: true
                },
                columns: [
							{ 
								field: "docNum", 
								title: "{{'modules.report.report1.docNum'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "8ch",
							},{ 
								field: "fileName",
								title: "{{'modules.report.report1.fileName'|translate}}",
							    attributes: { "class": "text-left" },
		                        template : "<a ng-click='vm.getShareholdersReport(this)'>#:fileName#</a>",
							    width: "16ch",
							},{ 
								field: "docDate",
								title: "{{'modules.report.report1.docDate'|translate}}",
								width: "10ch",
								format: "{0:d}",
							},{ 
								field: "docStatus", 
		                        title: "{{'modules.report.report1.docStatus'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "12ch",
		                        filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(docStatuses),
		                            dataTextField : "docStatus"
								}
							},{
								field: "crtdOn",
								title: "{{'modules.report.report1.crtdOn'|translate}}",
								width: "16ch",
								format: "{0:G}",
							},{
								field: "crtdBy",
								title: "{{'modules.report.report1.crtdBy'|translate}}",
								attributes: { "class": "text-left" },
								width: "8ch",
								filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(docCrtdBys),
		                            dataTextField : "crtdBy"
								}
							}
							
                    ]
 
        };
        
        function generateShareholdersReport(){
        	console.log('generateShareholdersReport');
        	vm.dateFrom = vm.fromDate.toLocaleDateString('lt-LT');
        	vm.dateTill = vm.toDate.toLocaleDateString('lt-LT');
        	console.log(vm.dateFrom);
            console.log(vm.dateTill);
        	Report.generateShareholders(
        			{
        				dateFrom : vm.dateFrom,
        				dateTill : vm.dateTill
        			}
        	);
        };
        
        vm.getShareholdersReport = function(current){
        	console.log('getShareholdersReport: ' + current.dataItem.docNum);
        	vm.reportLink = baseUrl + 'api/dms/doc/' + current.dataItem.docNum;
        	vm.report = vm.reportLink + "?tmst="+ (new Date()).getTime();
        	$window.open(vm.report, '_blank');
        };
    }
})();
