(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ScheduleDetailLoanController', ScheduleDetailLoanController);

    ScheduleDetailLoanController.$inject = ['$scope', '$state', '$stateParams', 'schedule', '$rootScope', 'defaultPageSize', '$translate'];

    function ScheduleDetailLoanController ($scope, $state, $stateParams, schedule, $rootScope, defaultPageSize, $translate) {
        var vm = this;
        vm.kendo = kendo;
        console.log("ScheduleDetailLoanController started");
        vm.dateFormat = kendo.culture().calendar.patterns.d;
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        vm.editMode = false;
	    vm.currentSchedule = schedule[0];
	    
	    vm.loanScheduleDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "component": { type: "string" },
			          "accrued": { type: "number" },
			          "booked": { type: "number" },
			          "compensation": { type: "number" },
			        }
			      }
    		},
    		data: vm.currentSchedule.detail,
			aggregate: [
                        { field: "accrued", aggregate: "sum"},
                        { field: "booked", aggregate: "sum"},
                        { field: "compensation", aggregate: "sum"},
            ],
            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "component",
                dir: "desc"
            }
        });
	    
	    vm.loanScheduleOptions = {
                sortable: true,                
    			filterable : false,
                pageable: false,
                columns: [
							{ 
								field: "component", 
								title: "{{'modules.loan.table.component'|translate}}",
		                        width: "18ch",
		                        footerTemplate: "{{'modules.loan.table.total'|translate}}"
							},{ 
								field: "accrued",
								title: "{{'modules.loan.table.accrued'|translate}}",
								template: "#= (accrued == 0 || accrued == null) ? '' : kendo.toString(accrued, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "booked",
								title: "{{'modules.loan.table.booked'|translate}}",
								template: "#= (booked == 0 || booked == null) ? '' : kendo.toString(booked, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "compensation",
								title: "{{'modules.loan.table.compensation'|translate}}",
								template: "#= (compensation == 0 || compensation == null) ? '' : kendo.toString(compensation, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							}
                    ]
 
        };
	    
    }
    
})();
