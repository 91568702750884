(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailAgreementClientController', DetailAgreementClientController);

    DetailAgreementClientController.$inject = ['$scope', '$state', 'agreement', '$filter', '$stateParams', 'detailMode', 'AlertService', '$translate', 'Client', '$window', 'History', 'shortDateFormats'];

    function DetailAgreementClientController ($scope, $state, agreement, $filter, $stateParams, detailMode, AlertService, $translate, Client, $window, History, shortDateFormats) {
        
    	var vm = this;
        
    	var types = { data: [] };
    	var users = { data: [] };
    	
    	console.log("Detail agreement Client Controller started");
        
    	vm.kendo = kendo;
        
    	vm.valueEditMode = detailMode.agrValueEdit;
        vm.cancelEditMode = detailMode.agrCancellation;
        vm.isCanceled = detailMode.agrCancellation;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        vm.shortDateFormats = shortDateFormats;
        
    	vm.currentAgreement = agreement;
    	
    	vm.isVSAA = vm.currentAgreement.agrType.startsWith("VSAA");
    	console.log(vm.isVSAA);
    	
        if (vm.currentAgreement.agrStatus == "CANCELED"){
        	vm.isCanceled = true;
        	vm.currentCancel = agreement.cancel[0];
        }else{
        	var currentCancel = {
		        "cancelDate": null,
		        "cancelReason": ""
        	};
        	vm.currentCancel = currentCancel;
        }
        
        vm.clnId = $state.params.clnId;
        vm.agrId = $state.params.agrId;
        vm.type = "";
        
        vm.statusCode = getStatusCode(vm.currentAgreement.agrValue, vm.currentAgreement.agrStatus);
        if (getValueCode(vm.currentAgreement.agrValue) == "danger"){ 
        	vm.valueCode = "error";
        }else vm.valueCode = getValueCode(vm.currentAgreement.agrValue);
        	
        function getStatusCode (agrValue, agrStatus){
        	var statusCode;
        	if (agrStatus == 'CANCELED')
        		return 'warning';
        	statusCode = getValueCode(agrValue);
        	return statusCode;
        }
        
        function getValueCode (agrValue){
        	switch(agrValue){
            case 'AGREE':
            	return 'success';
            	break;
            case 'DISAG':
            	return 'danger';
            	break;
            case 'NULL':
            	return 'warning';
            	break;
            }
        }
        
        vm.clientAgreementsHistoryDataSource = new kendo.data.DataSource({
			schema : {
				model : {
					fields : {
						"eventId" : { type : "number" },
						"docId" : { type : "number" },
						"type" : { type : "string" },
						"subject" : { type : "string" },
						"desc" : { type : "string" },
						"comment" : { type : "string" },
						"createdOn" : { type : "date" },
						"createdBy" : { type : "string" }
					}
				},
				parse: function(d){
                	$.each(d, function (idx, elem) {
                		var type = {
                			type: elem.type
                		};
                		var foundType = $filter('filter')(types.data, {type: elem.type}, true);
                        if(foundType.length < 1) (types.data).push(type);
                        
                        var user = {
                        	createdBy: elem.createdBy
                		};
                		var foundUser = $filter('filter')(users.data, {createdBy: elem.createdBy}, true);
                        if(foundUser.length < 1) (users.data).push(user);
                    });
                	return d;
				}
			},
			transport : {
				read : function(e) {
					History.get({
						docId : vm.agrId,
						query_tipas: vm.type
					}, function(response) {
						e.success(response);
					}, function(error) {
						console.log('history list error');
						e.error(error);
					});
				}
			},
//			pageSize : defaultPageSize,
			serverPaging : false,
			serverFiltering : false,
			serverSorting : false,
			sort : {
				field : "createdOn",
				dir : "desc"
			}
		});

        vm.clientAgreementsHistoryOptions = {
			scrollable: {
	            virtual: true
	        },
	        height: function(){
	        	return $window.innerHeight/2.5;
	        },
	        sortable: true,                
	        pageable: {
	        	refresh: true,
	        	previousNext: false,
	        	numeric: false,
	        	info:false,
	        	messages: {
	        	      empty: "No data"
	        	    }
	        },
			filterable : {
				extra : false,
				operators : {
					string : {
						contains : "contains",
					}
				}
			},
			toolbar : [ {
				template : "<span style='line-height:22px;' translate='modules.agreement.titles.history'></span>"
						+ "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
						+ "<span class='fa fa-file-excel-o'></span>"
						+ "&nbsp;<span translate='modules.history.excel'>"
						+ "</span></button>"
			} ],
			excel : {
				fileName : "history.xlsx",
                allPages: true
            },
            excelExport: function(e) {
            	e.workbook.sheets[0].columns.forEach(function(column){
            		column.autoWidth = true;
            	});
//            	e.workbook.sheets[0].columns = [
//            		{ width:100 },
//            		{ width:300 },
//            		{ width:400 },
//            		// ...
//            	];
                e.workbook.sheets[0].rows.forEach(function(row){
                  if(row.type != 'data'){
                  	row.cells.forEach(function(cell){
                      cell.value = $translate.instant(cell.value);
                    });
                  }
            	});
			},
			columns : [
					{
						field : "createdOn",
						title : "{{'modules.history.table.date'|translate}}",
						width : "19ch",
						format : "{0:G}",
				        filterable: {
	                    	ui: "datetimepicker"
	                    }
					},
					{
						field : "type",
						title : "{{'modules.history.table.type'|translate}}",
						width : "20ch",
						filterable : {
                            multi : true,
                            dataSource : new kendo.data.DataSource(types),
                            dataTextField : "type"
						}
					},
					{
						field : "createdBy",
						title : "{{'modules.history.table.added'|translate}}",
						width : "11ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(users),
                            dataTextField : "createdBy"
						}
					},
					{
						field : "subject",
						title : "{{'modules.history.table.subject'|translate}}",
						template : "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#:subject#'\" style='width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>#:subject#</span>"
					},
					{
						template : "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ng-click='vm.view(#:eventId#)'>"
								+ "<span class='glyphicon glyphicon-eye-open'></span>"
								+ "</button>",
						title : "&nbsp;",
						width : "5ch"
					} ]

		};
        
        vm.view = function(id){
			vm.detail = true;
			vm.currentEvent = $filter('filter')(vm.clientAgreementsHistoryDataSource.data(), {eventId: parseInt(id)}, true)[0];
		};
        
        vm.saveCancellation = function(){
        	Client.cancelAgreement(
					{clnId: vm.clnId,  agrId: vm.agrId}, vm.currentCancel,
					function(responce){
						AlertService.success("modules.agreement.message.agreementCanceledSuccessfully");
						$state.go('modules.clients.agreements.view', {agrId: vm.agrId}, {reload: true});
					});
        };
        
		vm.cancel = function(){
			$state.go("modules.clients.agreements.view",{clnId: vm.clnId, agrId: vm.agrId},{reload:true});
		};
		
		vm.closeEvent = function(){
			vm.detail = false;
			vm.currentEvent = {};
		};
    }
})();
