(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('OperationsMvcController', OperationsMvcController);

    OperationsMvcController.$inject = ['$window', '$scope', '$rootScope', '$state', 'entity', 'defaultPageSize', '$translate', '$filter'];

    function OperationsMvcController ($window, $scope, $rootScope, $state, entity, defaultPageSize, $translate, $filter) {
        var vm = this;
		var recTypes = {
				data: []
			};
        console.log("MVC Operations Controller started");
        vm.fromDate = new Date($state.params.f);
        vm.toDate = new Date($state.params.t);
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
        //workaround, bug in kendo grid footer template angular translation + aggregate value in one cell
        var countString = $translate.instant('modules.mvc.table.count');
        
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        
        $scope.$watch(function(){
    		return vm.fromDate;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && newVal.valueOf() > vm.toDate.valueOf()) {
    			vm.toDate = newVal;
    		}
    		vm.betweenDates=vm.rangeDatesReverce();
    	});
        
        $scope.$watch(function(){
        	return vm.toDate;
		}, function(newVal,oldVal) {
			if (newVal != oldVal && newVal.valueOf() < vm.fromDate.valueOf()) {
				vm.fromDate = newVal;
			}
			vm.betweenDates=vm.rangeDatesReverce();
		});
        
        vm.update = function(){
        	// in 'toLocaleDateString('lt-LT')' - 'lt-LT' is equal to ISO8601 
        	$state.go('modules.mvc.operations',{f: vm.fromDate.toLocaleDateString('lt-LT'), t: vm.toDate.toLocaleDateString('lt-LT')}, {reload: true});
        };
        
        
	        vm.mvcOperationsDataSource = new kendo.data.DataSource({
		        schema: {
		        	model: {
					        fields: {
					          "id": { type: "number" },
					          "itemId": { type: "number" },
					          "setlDate": { type: "date" },
					          "amount": { type: "number" },
					          "recType": { type: "string" },
					          "curr": { type: "string" },
					          "tokenRef": { type: "string" },
					          "loanRef": { type: "string" },
					          "des": { type: "string" }
					        }
					      },
			              parse: function(d){
			                	$.each(d, function (idx, elem) {
			                        elem.amount = elem.recType == 'UNLOAD' ? - elem.amount : elem.amount;
			                        
		   		              		var recType = {
		   		              			recType: elem.recType
		   		              		};
		   	   		              	var foundRecType = $filter('filter')(recTypes.data, {recType: elem.recType}, true);
		   	   		                if(foundRecType.length < 1) (recTypes.data).push(recType);
			                        
			                    });
			                	return d;
			              }
		  		},
				data: entity.operations,
		        aggregate: [ 
		        	{ field: "id", aggregate: "count"},
		            { field: "amount", aggregate: "sum"},
		        ],
//		        pageSize: defaultPageSize,
		        serverPaging: false,
		        serverFiltering: false,
		        serverSorting: false,
		        sort: {
		              field: "id",
		              dir: "desc"
		        }
	        });
	        
	        vm.betweenDatesDataSource = new kendo.data.DataSource({
	        	data: [
	        	 		{
	        	 			key: 1,
	        	 			value: $translate.instant("modules.mvc.date.thisMonth"),
	        	    	},{
	        	    		key: 2,
	        	    		value: $translate.instant("modules.mvc.date.lastMonth"),
	        	    	},{
	        	    		key: 3,
	        	    		value: $translate.instant("modules.mvc.date.thisWeek"),
	        	    	},{
	        	    		key: 4,
	        	    		value: $translate.instant("modules.mvc.date.yesterday"),
	        	    	},{
	        	    		key: 5,
	        	    		value: $translate.instant("modules.mvc.date.today"),
	        	    	}
	        	    ]
	    	});
	        
	        $scope.$watch(function(){
	    		return vm.betweenDates;
		    	}, function(newVal,oldVal) {
		    		if (newVal != oldVal && newVal != 0) {
		    			var range = rangeDates(newVal);
		    			vm.fromDate = range.fromDate;
		    			vm.toDate = range.toDate;
		    		}
	    	});
	        
	        function rangeDates(id) {
	        	var fromDate;
	        	var toDate;
	        	
	        	switch (id) {
		        	case 1:
		        		var curr = new Date;
		        	 	fromDate  = new Date(curr.getFullYear(), curr.getMonth(), 1);
		        	 	toDate = new Date;
		        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
		        		break;
		        	case 2:
		        		var curr = new Date;
		        	 	fromDate = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
		        	 	toDate = new Date;
		        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth(), 0); 
		        		break;
		        	case 3:
		        		var curr = new Date;
		        		fromDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
		        		curr = new Date;
		        	 	toDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
		        		break;
		        	case 4:
		        		fromDate = new Date();
		        		fromDate.setDate(fromDate.getDate() - 1);
		        	 	toDate = fromDate;
		        		break;
		        	case 5:
		        		fromDate = new Date();
		        	 	toDate = new Date();
		        	    break;
	        	}
	        	
	        	return {
	        		fromDate: fromDate,
	        		toDate: toDate
	        	}
	        }

	        vm.rangeDatesReverce = function () {
	        	
	        	var fromDate = vm.fromDate;
	        	var toDate = vm.toDate;
	        	
	        	var current = new Date;
	        	
	        	var currnetMonthLastDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
	        	var lastMonthLastDate = new Date(current.getFullYear(), current.getMonth(), 0);
	        	
	        	var bg = new Date;
	        	var beginCurrentWeek = new Date(bg.setDate(bg.getDate() - bg.getDay() + 1));
				var en = new Date;
        	 	var endCurrentWeek = new Date(en.setDate(en.getDate() - en.getDay() + 7));
        	 	
	        	if(fromDate.toLocaleDateString() == toDate.toLocaleDateString()) {
	        		if(fromDate.toLocaleDateString() == current.toLocaleDateString()) {
	        			return 5;
	        		} else if(fromDate.getDate() == current.getDate() - 1 && fromDate.getFullYear() == current.getFullYear() && fromDate.getMonth() == current.getMonth()){
	        			return 4;
	        		} else return 0;
	        	} else if(fromDate.getFullYear() == current.getFullYear()) {
	        		if(fromDate.getMonth() == current.getMonth() && toDate.getMonth() == current.getMonth() && fromDate.getDate() == 1 && currnetMonthLastDate.getDate()) {
	        			return 1;
	        		} else if(fromDate.getMonth() == current.getMonth() - 1 && toDate.getMonth() == current.getMonth() - 1 && fromDate.getDate() == 1 && lastMonthLastDate.getDate()) {
	        			return 2;
	        		} else if(fromDate.toLocaleDateString() == beginCurrentWeek.toLocaleDateString() && toDate.toLocaleDateString() == endCurrentWeek.toLocaleDateString()) {
	        			return 3;
	        		} else return 0;
	        	} else return 0;
	        }
	        
	        vm.mvcOperationsOptions = {
	        		scrollable: true,
	                sortable: true,                
	                filterable: {
	                	extra: false,
	                	operators: {
	                        string: {
	                        	contains: "Contains",
	                        	eq: "Equal "
	                        },
	                        number: {
	                        	eq: "Equal "
	                        }
	                    }
	                },
	                pageable: {
	    	        	refresh: true,
	    	        	previousNext: false,
	    	        	info:false,
	    	        	numeric: false,
	                	messages: {
	                	      empty: "No data"
	                	}
	                },
	    	        height: function(){
	    	        	return $window.innerHeight/2;
	    	        },
	                toolbar: [
	                          { 
			                    template: "<span class='col-sm-2' style='padding-left:0;line-height:30px;' translate='modules.mvc.titles.operations'></span>"
			                    		 + "<div class='form-group-sm'><div class='col-sm-4'><input class='form-control' kendo-date-picker k-format=\"'d'\"  placeholder='{{vm.dateFormat}}' k-ng-model='vm.fromDate'></div>"
			                    		 + "<div class='col-sm-4'><input class='form-control' kendo-date-picker k-format=\"'d'\"  placeholder='{{vm.dateFormat}}' k-ng-model='vm.toDate'></div>"
			                    		 + "<div class='col-sm-4'><select kendo-drop-down-list k-data-text-field=\"'value'\" k-option-label=\"'-Select-'\" k-data-value-field=\"'key'\" k-value-primitive='true' style='width: 100%' k-ng-model='vm.betweenDates' k-data-source='vm.betweenDatesDataSource' ng-init='vm.betweenDates=vm.rangeDatesReverce()'></select></div></div>"
			                    		 + "<button has-authority='ROLE_ACCOUNTANT' ng-click='vm.update()' class='btn btn-default btn-sm'>"
			                    		 + "<span class='glyphicon glyphicon-refresh'></span>"
			                             + "</button>"			                    		 
			                    		 + "<button has-authority='ROLE_ACCOUNTANT' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
			                    		 + "<span class='fa fa-file-excel-o'></span>"
			                             + "&nbsp;<span translate='modules.mvc.excel'>"
			                             + "</span></button>"
	                          }
	                ],
	                excel: {
	                	fileName: "mvc-operations.xlsx",
	                    allPages: true,
	                    filterable: true
	                },
	                excelExport: function(e) {
	                	e.workbook.sheets[0].columns.forEach(function(column){
	                		column.autoWidth = true;
	                	});
//	                	e.workbook.sheets[0].columns = [
//	                		{ width:100 },
//	                		{ width:300 },
//	                		{ width:400 },
//	                		// ...
//	                	];
	                    e.workbook.sheets[0].rows.forEach(function(row){
	                      if(row.type != 'data'){
	                      	row.cells.forEach(function(cell){
	                          cell.value = $translate.instant(cell.value);
	                        });
	                      }
	                	});
	                },
	                columns: [
							{ 
								field: "id", 
								title: "{{'modules.mvc.table.id'|translate}}",
								width: "12ch", 
								sortable: true,
								filterable: false,
						        aggregates: ["count"],
						        footerTemplate: countString + ": #=count#",
						        footerAttributes: {class: "text-center"}
							},{ 
								field: "setlDate", 
						        title: "{{'modules.mvc.table.date'|translate}}",
						        width: "12ch",
						        format: "{0:d}",
						        filterable: false,
						        sortable: false
							},{ 
								field: "recType", 
						        title: "{{'modules.mvc.table.recType'|translate}}",
						        width: "12ch",
						        sortable: true,
								filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(recTypes),
		                            dataTextField : "recType"
								}
						    },{ 
								field: "amount",
								title: "{{'modules.mvc.table.sum'|translate}}",
							    attributes: { class: "text-right" },
							    sortable: true,
							    format: "{0:n2}",
						        aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    width: "15ch"
							},{ 
								field: "tokenRef",
								title: "{{'modules.mvc.table.token'|translate}}",
							    sortable: true,
							    width: "12ch"
							},{ 
								field: "loanRef",
								title: "{{'modules.mvc.table.reference'|translate}}",
							    sortable: true,
							    width: "14ch"
							},{
				                field: "des",
				                title: "{{'modules.mvc.table.description'|translate}}",
								template : "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#:des#'\" style='width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>#:des#</span>",
				                sortable: true
				            }  
					]	
	                            	
	        	};
	 
    	}
    
})();
