(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterCardViewController', WallesterCardViewController);

    WallesterCardViewController.$inject = ['$scope', '$state', 'Wallester', 'formHelper', 'card', '$stateParams', '$filter', 'defaultPageSize', 'menuWithHeader', 'events', 'CardStatus', '$timeout', "Loan", "AlertService", 'baseUrl', '$window'];

    function WallesterCardViewController ($scope, $state, Wallester, formHelper, card, $stateParams, $filter, defaultPageSize, menuWithHeader, events, CardStatus, $timeout, Loan, AlertService, baseUrl, $window) {
        var vm = this;
        vm.kendo = kendo;
        console.log("WallesterCardViewController");
        console.log(card);
        
		var statuses = {
				data: []
			};
		var recTypes = {
				data: []
			};
		var statDescs = {
				data: []
			};
		var eventTypes = {
				data: []
			};

    	vm.currentCard = card;
    	
        if($stateParams.card_id != null) 
        	vm.card_id = $stateParams.card_id;
        
        vm.statusStyle = CardStatus.getStyle(vm.currentCard.cardStatus, vm.currentCard.isActive);
        
        if(typeof vm.currentCard.bal != 'undefined' && vm.currentCard.bal.length > 0) {
        	vm.cardBalDataSource = new kendo.data.DataSource({
        		schema: {
  			      model: {
//  			        id: "token",
  			        fields: {
//  			          "token": { type: "string"},
  			          "balDtime": { type: "date" },
  			        }
  			      }
        		},
    			data: vm.currentCard.bal,
//    			pageSize: defaultPageSize,
    			sort: {
    				field: "balDtime",
    				dir: "desc"
    			}
            });
        }	
        	vm.cardBalOptions = {
            		scrollable: true,
                    sortable: true,
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                startswith: "Starts with",
                                eq: "Is equal to",
                                neq: "Is not equal to"
                            }
                        }
                    },
                    pageable: false,
	    	        height: 300,
                    columns: [{
	                    	field: "balDtime",
	                    	headerTemplate: "<span translate='modules.loan.table.balDate'>",
                            format      : "{0:yyyy-MM-dd HH:mm:ss}",
                            parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "15ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
                            field: "amtAvl",
                            headerTemplate: "<span translate='modules.loan.table.amtAvl'>",
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "blkAmt",
                            headerTemplate: "<span translate='modules.loan.table.blkAmt'>",
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "credLimit",
                            headerTemplate: "<span translate='modules.loan.table.credLimit'>",
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "finAmt",
                            headerTemplate: "<span translate='modules.loan.table.finAmt'>",
                            width: "15ch",
                            format: "{0:n2}",
                            attributes: {class:"text-right"},
                            filterable: false
                        }]
            };
        	
        
        
        	vm.cardCmdDataSource = new kendo.data.DataSource({
        		schema: {
    			      model: {
//    			        id: "token",
    			        fields: {
//    			          "token": { type: "string"},
    			          "cmdDtime": { type: "date" },
    			        }
    			      },
    			      parse: function(d){
    	              	$.each(d, function (idx, elem) {
    	                	var sts = {
    	                			sts: elem.sts
    	                	};
    	                	var foundStatus = $filter('filter')(statuses.data, {sts: elem.sts}, true);
    	                    if(foundStatus.length < 1) (statuses.data).push(sts);
    	                    
    	                	var recType = {
    	                			recType: elem.recType
    	                	};
    	                	var foundRecType = $filter('filter')(recTypes.data, {recType: elem.recType}, true);
    	                    if(foundRecType.length < 1) (recTypes.data).push(recType);
    	              	});
    	                return d;
    				}
          		},
    			data: vm.currentCard.cmd,
//    			pageSize: defaultPageSize,
    			sort: {
    				field: "cmdDtime",
    				dir: "desc"
    			}
            });

        	vm.cardCmdOptions = {
            		scrollable: true,
                    sortable: true,
                    filterable: {
                        extra: false,
                        operators: {
                            string: {
                                startswith: "Starts with",
                                eq: "Is equal to",
                                neq: "Is not equal to"
                            }
                        }
                    },
                    height: 300,
                    pageable: false,
                    columns: [{
	                    	field: "cmdDtime",
	                    	headerTemplate: "<span translate='modules.loan.table.cmdDate'>",
                            format      : "{0:yyyy-MM-dd HH:mm:ss}",
                            parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "20ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
                            field: "amount",
                            headerTemplate: "<span translate='modules.loan.table.amount'>",
                            format: "{0:n2}",
                            width: "20ch",
                            attributes: {class:"text-right"},
                            filterable: false
                        },{
                            field: "recType",
                            width: "20ch",
                            headerTemplate: "<span translate='modules.loan.table.recType'>",
    						filterable : {
                                multi : true,
                                dataSource : new kendo.data.DataSource(recTypes),
                                dataTextField : "recType"
    						}
                        },{
                            field: "sts",
                            headerTemplate: "<span translate='modules.loan.table.sts'>",
                            width: "20ch",
    						filterable : {
                                multi : true,
                                dataSource : new kendo.data.DataSource(statuses),
                                dataTextField : "sts"
    						}
                        },{
                            title: "",
                            minScreenWidth: 1043
                        }
                        ]
            };
        	

    	vm.limitsOptions = {
        		scrollable: false,
                sortable: false,
                filterable: false, 
                pageable: false,
                columns: [{
                    	field: "limitType",
                    	headerTemplate: "<span translate='modules.loan.table.limitType'>",
	                    width: "20ch"
                    },{
                        field: "daily",
                        headerTemplate: "<span translate='modules.loan.table.limitDaily'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                        field: "monthly",
                        headerTemplate: "<span translate='modules.loan.table.limitMonthly'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                        field: "transaction",
                        headerTemplate: "<span translate='modules.loan.table.limitTransaction'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                        field: "sep",
                        headerTemplate: "",
                    }]
        };

    	vm.limitsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "limitType": { type: "string"},
			          "daily": { type: "number" },
			          "monthly": { type: "number" },
			          "transaction": { type: "number" }
			        }
			      }
      		},
			data: [{
	    	    limitType : "Purchase",
	    	    daily : vm.currentCard.limDailyPurchase,
	    	    monthly : vm.currentCard.limMonthlyPurchase,
	    	    transaction : vm.currentCard.limTrnPurchase
	    	}, {
	    	    limitType : "Withdrawal",
	    	    daily : vm.currentCard.limDailyWithdrawal,
	    	    monthly : vm.currentCard.limMonthlyWithdrawal,
	    	    transaction : vm.currentCard.limTrnWithdrawal
	    	}, {
	    	    limitType : "Internet",
	    	    daily : vm.currentCard.limDailyInternetPurchase,
	    	    monthly : vm.currentCard.limMonthlyInternetPurchase,
	    	    transaction : vm.currentCard.limTrnInternetPurchase
	    	}, {
	    	    limitType : "Contactless",
	    	    daily : vm.currentCard.limDailyContactlessPurchase,
	    	    monthly : vm.currentCard.limMonthlyContactlessPurchase,
	    	    transaction : vm.currentCard.limTrnContactlessPurchase
	    	}]
        });
    	
        vm.eventsDataSource = new kendo.data.DataSource({
			data: events,
			aggregate: [
            	{ field: "tolken", aggregate: "count"}
            ],
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            schema: {
                model: {
                    fields: {
                        tolken: { type: "string" },
                        statDesc: { type: "string" },
                        eventDate: { type: "date" },
                        activationDate: { type: "date" },
                        eventType: { type: "string" },
                        id: { type: "string" }
                    }
                },
			    parse: function(d){
  	            	$.each(d, function (idx, elem) {
  	                	var eventType = {
  	                			eventType: elem.eventType
  	                	};
  	                	var foundEventType = $filter('filter')(eventTypes.data, {eventType: elem.eventType}, true);
  	                    if(foundEventType.length < 1) (eventTypes.data).push(eventType);
  	                    
  	                	var statDesc = {
  	                			statDesc: elem.statDesc
  	                	};
  	                	var foundStatDesc = $filter('filter')(statDescs.data, {statDesc: elem.statDesc}, true);
  	                    if(foundStatDesc.length < 1) (statDescs.data).push(statDesc);
  	              	});
  	                return d;
  				}
            },
            sort: { field: "eventDate", dir: "desc"}
        });
        
        vm.eventsOptions = {
                scrollable: true,
        		filterable: {
                    extra: false,
        		},
                sortable: true,
                pageable: false,
                height:300,
                columns: [ 
                          {
                              field:"id",
                              headerTemplate: "<span translate='modules.loan.table.no'>",
                              attributes: { "class": "text-center" },
                              filterable: false,
                              width: "9ch"
                          },
                          {
                              field: "tolken",
                              headerTemplate: "<span translate='modules.loan.table.cardNo'>",
                              sortable: false,
                              aggregates: ["count"], 
                              footerTemplate: "<span translate='modules.loan.table.total'></span>: #=count#",
                              footerAttributes: { "class": "text-center" },
                              attributes: { "class": "text-center" },
                              width: "20ch",
                              minScreenWidth: 896
                          },
                          {
                              field: "eventDate",
                              headerTemplate: "<span translate='modules.loan.table.eventDate'>",
                              format      : "{0:yyyy-MM-dd HH:mm:ss}",
                              parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
                              attributes: { "class": "text-center" },
                              width: "20ch"
                          },
                          {
                              field: "statDesc",
                              headerTemplate: "<span translate='modules.loan.table.statusCard'>",
                              attributes: { "class": "text-center" },
                              width: "15ch",
      						  filterable : {
                              	multi : true,
                                dataSource : new kendo.data.DataSource(statDescs),
                                dataTextField : "statDesc"
      						  }
                          },
                          {
                              field: "eventType",
                              headerTemplate: "<span translate='modules.loan.table.eventType'>",
                              attributes: { "class": "text-center" },
                              width: "20ch",
      						  filterable : {
                                multi : true,
                                dataSource : new kendo.data.DataSource(eventTypes),
                                dataTextField : "eventType"
    						  }
                          }, 
                          {
                              field: "activationDate",
                              headerTemplate: "<span translate='modules.loan.table.activationDate'>",
                              format: "{0:yyyy-MM-dd HH:mm:ss}",
                              parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
                              attributes: { "class": "text-center" },
                              width: "20ch",
                              minScreenWidth: 1130
                          },
                          {
                              title: "",
                              minScreenWidth: 1250
                          }
                      ]
        };
        
        $timeout(function() {
        	angular.element('#start').triggerHandler('click');
        }, 0);

        vm.tabSelected = function(grid) {
        	
        	angular.element('#'+grid).data("kendoGrid").resize();
        };
        
        vm.changeStatus = function(){
        	console.log("changeStatus");
        	$state.go('modules.loans.current.wallester-cards.edit-status', {card_id: $stateParams.card_id});
        };
        
        vm.activateCard = function(){
        	$state.go('modules.loans.current.wallester-cards.activate', {card_id: $stateParams.card_id});
        };

        vm.sendCardSecret = function(){
        	$state.go('modules.loans.current.wallester-cards.send-card-secret', {card_id: $stateParams.card_id});
        };

//        vm.changePin = function(){
//        	$state.go('modules.loans.current.wallester-cards.edit-pin', {card_id: $stateParams.card_id});
//        };
//        
//        vm.generatePin = function(){
//        	$state.go('modules.loans.current.wallester-cards.generate-pin', {card_id: $stateParams.card_id});
//        };

        vm.printCardConfirmation = function(){
        	console.log('Printing Card Confirmation');
       		vm.pdflink = baseUrl + 'api/loans/' + $state.params.loan_no + '/visa-cards/' + $stateParams.card_id + '/confirmation/print';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
    	console.log("431");
        
        vm.sendCardConfirmationToEsign = function(){
        	console.log('Send Card Confirmation to esign');
        	vm.confirmPrepareClicked = true;
        	var pleaseWaitOperationID = AlertService.info("modules.loan.message.pleaseWaitOperation", { loan_no: $state.params.loan_no});
        	vm.wsDisabled = true;
        	var params = $stateParams.card_id;
            Loan.sendToEsign(
            		{loan_no : $state.params.loan_no, card_id: $stateParams.card_id},
            		{type: "CARD_CONFIRMATION", params: params},
	                function (result) {
            			console.log(result);
                		AlertService.closeAlert(pleaseWaitOperationID.id, null);

            			if (result && result.status === "OK"){
	            			AlertService.success("modules.loan.message.successSendingToEsign", "");
            			} else {
            				console.log("ERROR!");
            				AlertService.error("modules.loan.message.somethingWentWrong", "");
            			}
            		},	
            		function(error){
            			AlertService.closeAlert(pleaseWaitOperationID.id, null);
            			console.log(error);
            			vm.confirmPrepareClicked = false;
            			vm.wsDisabled = false;
            		}
            ).$promise;	       	
        };
       
        vm.syncLoan = function(){
        	Loan.synchronization(
            		{loan_no : $stateParams.loan_no},
            		{},
            		function(result){
    		    		if(result) {
    		    			AlertService.success("modules.loan.message.loanSyncRefresh", { loan_no: $stateParams.loan_no});
    		    		}
    	    		},
    	    		function(error){
    	    			console.log(error);
    	    		}
            ).$promise;
        };
    }
    
})();
