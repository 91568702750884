(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('NewLoanController', NewLoanController);

    NewLoanController.$inject = ['$scope', '$state', '$stateParams', 'application', 'AlertService', 'Calculator', '$translate', 'detailMode', 'Loan', 'previousState', '$element', '$filter'];

    function NewLoanController ($scope, $state, $stateParams, application, AlertService, Calculator, $translate, detailMode, Loan, previousState, $element, $filter) {
        var vm = this;
        console.log("NewLoanController ...");
        vm.isNew = detailMode.isNew;
        vm.hasReservedLoanNo = application.hasOwnProperty('reservedLoanNo') && application.reservedLoanNo != "";
        vm.lastErrorMsg;
    	vm.confirmSubmitClicked = false;
    	vm.pleaseWaitOperationID = null;
        
        vm.back = function(){
        	
        	if(previousState.name == 'modules.applications')
        		$state.go('modules.applications', {}, {reload: true});
        	else 
        		$state.go('modules.applications.detail', {id: $state.params.applId}, {reload: true});
        };
        
        	vm.loan = {};
        	
	        if(application == null || application.id == null || application.id <= 0 || application.id == '') {
	        	var msg = 'Application is not provided';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications');
	        	return;
	        }
	        if(!(application.result != null && application.result.length > 0) || 
	        		!(application.supplicant != null && application.supplicant.length > 0)) {
	        	var msg = 'Result or supplicant is not provided';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications.detail',{ id: application.id });
	        	return;
	        }
	        

	        
	        vm.result = application.result[0];
	        
	        if(!vm.result.canCreateLoan) {
	        	var msg = 'Can not create Loan, wrong application result';
	        	console.log(msg);
	        	AlertService.warning(msg);
	        	$state.go('modules.applications.detail',{ id: application.id }, {reload:true});
	        	return;
	        }
	        
	        vm.supplicant = application.supplicant;

	        vm.loan.reservedLoanNo = application.reservedLoanNo;
	        vm.loan.applId = application.id;
	        vm.loan.productName = application.productName;
	        vm.loan.loanAmount = application.applSum;
	        vm.loan.currency = application.currency;
	        vm.loan.loanPayTot = application.loanPayTot;
	        vm.loan.partnerId = application.partnerId;
	        vm.loan.tgrId = application.tgrId;
	        vm.loan.notes = application.notes;
	        
	        angular.forEach(vm.supplicant, function(supplicant){
	            switch(supplicant.supplicantType) {
		    		case "MAIN":
		    			vm.loan.mainMobPhone = supplicant.mobPhone;
		    			vm.loan.mainOtherPhone = supplicant.otherPhone;
		    			vm.loan.mainSex = supplicant.sex;
		    			vm.loan.mainFirstName = supplicant.firstName;
		    			vm.loan.mainLastName = supplicant.lastName;
		    			vm.loan.mainPersonCode = supplicant.personCode;
		    			vm.loan.mainPassNumber = supplicant.passNumber;
		    			vm.loan.mainPassExpire = supplicant.passExpire;
		    			vm.loan.mainCitizenship = supplicant.citizenship;
		    			vm.loan.mainBirthDate = supplicant.birthDate;
		    			vm.loan.mainEmail = supplicant.email;
		    			vm.loan.mainVoicePass = supplicant.voicePass;
		    			break;
		    		case "SURETY":
		    			vm.loan.grntMobPhone = supplicant.mobPhone;
		    			vm.loan.grntOtherPhone = supplicant.otherPhone;
		    			vm.loan.grntSex = supplicant.sex;
		    			vm.loan.grntFirstName = supplicant.firstName;
		    			vm.loan.grntLastName = supplicant.lastName;
		    			vm.loan.grntPersonCode = supplicant.personCode;
		    			vm.loan.grntPassNumber = supplicant.passNumber;
		    			vm.loan.grntPassExpire = supplicant.passExpire;
		    			vm.loan.grntCitizenship = supplicant.citizenship;
		    			vm.loan.grntBirthDate = supplicant.birthDate;
		    			vm.loan.grntEmail = supplicant.email;
		    			vm.loan.grntVoicePass = supplicant.voicePass;
		    			break;
	            };	        	
	        });
        
		vm.validatorOptions =  {    
				  	rules: {
				  		amountRange: function(input) {
					              if (input.is("[data-role=numerictextbox]") && input.is("[name=applAmount]")) {
					                	  //for recovery after submit and fix error value
					                	  var response = newApplAmountIsValid(input.data("kendoNumericTextBox").value());
					                	  return response; 
					              } else return true;
				  		}
				  	}
		};    
	    
        function submitButtonDisable (){
        	if(vm.confirmSubmitClicked == false){
        		vm.confirmSubmitClicked = true;
        		console.log("submitButtonDisable");
        		vm.pleaseWaitOperationID = AlertService.add({
        			type: "info",
        			msg: "modules.loan.message.pleaseWaitOperation",
        			timeout: 30000
        		});
        		vm.confirmSubmitTimeout = setTimeout(function(){submitButtonEnable();},30000);
        	} 
        };
        
        function submitButtonEnable (){
            vm.confirmSubmitClicked = false;
            console.log("submitButtonEnable");
            AlertService.closeAlert(vm.pleaseWaitOperationID.id, null);
            clearTimeout(vm.confirmSubmitTimeout);
        };
		
        vm.sign = function(){
        	// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	if(vm.validator.validate()) {
        		submitButtonDisable();
	        	Loan.create(vm.loan, function(result){
	        		vm.hasReservedLoanNo = false;
	        		vm.loan = result;
	        		submitButtonEnable();
	        		$state.go("modules.loans.current.condition", {loan_no: vm.loan.loanNo }, {reload: true});
	        	});
        	}
        };
        
        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "F",
    			value: $translate.instant("modules.application.female")
    		},{
    			key: "M",
    			value: $translate.instant("modules.application.male")
    		}]
    	});

    	vm.tariffData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	
			        	}
				}
			},
			transport: {
				read: function(e){
					
					Calculator.query(
							{product : vm.loan.productName, person_code: vm.loan.mainPersonCode, prt_id: vm.loan.partnerId },
							function(response){
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });
        
    	vm.loanlOptions = {
        	    selectable: true,
        	    dataBound: function(event) {
        	    	var selected;
        	  		
        	    	if(vm.loan.tgrId > 0) {
        	    		selected = vm.loan.tgrId; 
        	    		    	    			
        	    		var selectedTatiff = $filter('filter')(event.sender.element.children(), {value: selected }, false)[0];
        	    		if( typeof selectedTatiff === 'undefined') {
        	    			
	        	    			var message = $translate.instant('modules.application.validate.applWrongTariff');
	        	    			if( vm.lastErrorMsg == message )
	        	    				AlertService.clear();
	        	    			else
	        	    				vm.lastErrorMsg = message;
	        	    			
	                            var formGrp = angular.element(event.sender.element.closest('.form-group.form-group-sm'));
	                            formGrp.toggleClass('has-error', true);
	                            
	        	    			AlertService.error(message);
	        	    			return;
	        	    		
        	    		}
        	    	} else {
        	    		var first = event.sender.element.children().first();
        	    		selected = first.val();
        	    	}
        	    	if(typeof selected == "number") {
        	    		selected = selected.toString();
        	    	}
        	    	
        	    	event.sender.value(selected);
        	    	event.sender.trigger("change");
        	    },
        	    cascade: function(event) {
        	    	if(event.sender.select() < 0) {
        	    		return;
        	    	}
        	    	
        	  		var formGrp = angular.element(event.sender.element.closest('.form-group.form-group-sm'));
                    formGrp.toggleClass('has-error', false);
        	  		
        	  		vm.onApplErrorHide = false;
        	        var selected = event.sender.dataItem(event.sender.select());
        	        
                	vm.minSum = selected.minAmount;
                	vm.maxSum = selected.maxAmount;
                	vm.defaultAmmount = selected.minAmount;
                	vm.tariffId = selected.id;

                	vm.loan.loanAmount = vm.loan.loanAmount > 0 ? vm.loan.loanAmount : vm.defaultAmmount;

    	    		var calc = newApplAmountIsValid(vm.loan.loanAmount);
    	    		if(calc) calculate('trigger');
    	    		else return;
        	    }
        };

        
        $scope.$watch(
        	    function(){
        	    	return vm.loan.loanAmount;
        	    },
        	    function(newVal,oldVal){
        	    	if(oldVal != newVal) {
        	    		if(newApplAmountIsValid(newVal)) calculate('ammount ');
        	    	}
        	    }
            );
    	
    	vm.tariffData.read();
    	
        function newApplAmountIsValid(newValue){
        	var response = false;
        		if(	newValue > vm.maxSum || 
                	newValue < vm.minSum || 
                	newValue == null || 
                	isNaN(newValue)) alertOnAppAmmountError();
            	else {
            		AlertService.clear();
            		vm.onApplErrorHide = false;
            		response = true;
            	}
        	return response;
        }
    	
        function alertOnAppAmmountError(){
        	vm.onApplErrorHide = true;
        	vm.loan.loanPayTot = null;
			var message = $translate.instant('modules.application.validate.applWrongAmountDetailed', { min: vm.minSum, max: vm.maxSum });
			if( vm.lastErrorMsg == message )
				AlertService.clear();
			else
				vm.lastErrorMsg = message;
			AlertService.error(message);	
        }
        
        function calculate(x){
        	if(vm.tariffId == null || isNaN(vm.loan.loanAmount) || vm.loan.loanAmount == null || vm.loan.loanAmount <= 0) return;
        	var calculator = {};
        	calculator.applAmount = vm.loan.loanAmount;
        	calculator.financedAmount = vm.loan.loanAmount;
        	calculator.personCode = vm.loan.mainPersonCode;
        	calculator.tariffId = vm.tariffId;
        	Calculator.send(calculator, function(response){
        		if(response) vm.loan.loanPayTot = response.calcPayTot;
        	});
        }
        
    }
    
})();
