(function() {
	'use strict';
	angular.module('sigmaApp').factory('Wallester', Wallester);

	Wallester.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Wallester($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/loans/:loan_no';

		return $resource(resourceUrl,{},
				{
					'getCards' : {
						method : 'GET',
						url: resourceUrl + '/visa-cards',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getPerson' : {
						method : 'GET',
						url: resourceUrl + '/visa-person',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getCard' : {
						method : 'GET',
						url: resourceUrl + '/visa-cards/:card_id',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getCardSecret' : {
						method : 'GET',
						url: resourceUrl + '/visa-cards/:card_id/sensitive?code=:secret',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getDataForCardSecret' : {
						method : 'GET',
						url: resourceUrl + '/visa-cards/:card_id/send-card-secret',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'sendCardSecret': {
						method:'POST',
						url:  resourceUrl + '/visa-cards/:card_id/send-card-secret',
						transformRequest : function(data) {
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'getHolderAddresses' : {
						method : 'GET',
						url: resourceUrl + '/card-holder/addresses',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getCardTemplates' : {
						method : 'GET',
						url: resourceUrl + '/visa-cards/templates',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getHolderEmails' : {
						method : 'GET',
						url: resourceUrl + '/card-holder/emails',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getFirstCardData' : {
						method : 'GET',
						url: resourceUrl + '/visa-first-card-data',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getAnotherCardData' : {
						method : 'GET',
						url: resourceUrl + '/visa-another-card-data',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
//					'getDefaultNoname' : {
//						method : 'GET',
//						url:  baseUrl + 'api/loans/cards/noname/default/:prefix',
//						isArray : false,
//						transformResponse : function(data) { 
//							if (data) {
//								data = angular.fromJson(data);
//							}
//							return data;
//						}
//					},
//					'nonameCardGenerate' : {
//						method : 'POST',
//						url:  baseUrl + 'api/loans/cards/noname/generate',
//						transformRequest : function(data) {
//							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
//							return angular.toJson(data);
//						},
//						transformResponse : function(data) {
//							var response = {};
//							if(data) {
//								response = angular.fromJson(data)
//							}
//							return response;
//						}
//					},
					'getEvents' : {
						method : 'GET',
						url: baseUrl + 'api/line/gps/events/bytoken/:token',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'updatePerson': {
						method:'PUT',
						url: resourceUrl + '/visa-person',
						transformRequest : function(data) {
		//					data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'createCard' : {
						method : 'POST',
						url: resourceUrl + '/visa-cards',
						transformRequest : function(data) {
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'createAnotherCard' : {
						method : 'POST',
						url: resourceUrl + '/visa-cards/another',
						transformRequest : function(data) {
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'linkCard' : {
						method : 'POST',
						url: resourceUrl + '/visa-cards/link/',
						transformRequest : function(data) {
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'activate': {
						method:'POST',
						url:  resourceUrl + '/visa-cards/activate',
						transformRequest : function(data) {
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
//					'createAnotherCard': {
//						method:'POST',
//						url: resourceUrl + '/cards/create',
//						transformRequest : function(data) {
//							return angular.toJson(data);
//						}
//					},
					'status': {
						method:'PATCH',
						url:  resourceUrl + '/visa-cards/changeCardStatus',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
//					'pin': {
//						method:'PUT',
//						url:  baseUrl + 'api/loans/cards/pin',
//						transformRequest : function(data) {
////							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
//							return angular.toJson(data);
//						},
//						transformResponse : function(data) {
//							var response = {};
//							if(data) {
//								response = angular.fromJson(data);
//							}
//							return response;
//						}
//					},
//					'generatePin': {
//						method:'PUT',
//						url:  baseUrl + 'api/loans/cards/generatepin',
//						transformRequest : function(data) {
////							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
//							return angular.toJson(data);
//						},
//						transformResponse : function(data) {
//							var response = {};
//							if(data) {
//								response = angular.fromJson(data);
//							}
//							return response;
//						}
//					},
//					'sendPin': {
//						method:'POST',
//						url: resourceUrl + '/cards/:token/sendpin'
//					}
					
				});
	}
})();