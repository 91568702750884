(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ConfirmationAnnulatePenaltyLoanController', ConfirmationAnnulatePenaltyLoanController);

    ConfirmationAnnulatePenaltyLoanController.$inject = ['$uibModalInstance', '$state', 'Loan', 'AlertService'];

    function ConfirmationAnnulatePenaltyLoanController ($uibModalInstance, $state, Loan, AlertService) {
        var vm = this;
        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	$uibModalInstance.close(true);
        	AlertService.error("modules.transaction.message.wrongOrEmptyLoanNo");
        }
        
        if($state.params.annulatePenaly == null || typeof $state.params.annulatePenaly != 'object') {
        	$uibModalInstance.dismiss('cancel');
        	$uibModalInstance.close(true);
        	AlertService.error("modules.transaction.message.wrongOrEmptyPenlatyForAnnulating");
        	$state.go('modules.loans.current.accounting.annulate-penalty', {loan_no: $state.params.loan_no}, {reload: true});
        }
 
        
        vm.loanNo = $state.params.loan_no;
        vm.annulatePenaly = $state.params.annulatePenaly;
        vm.onDate = kendo.toString(vm.annulatePenaly.onDate, 'd');
        vm.amount = kendo.toString(vm.annulatePenaly.amount, 'n2');
        vm.clear = clear;
        vm.confirmAnnulatePenalty = confirmAnnulatePenalty;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmAnnulatePenalty (loanNo) {
        	$uibModalInstance.close(true);
            Loan.annulatePenalty(
            		{ loan_no : loanNo },
            		{ onDate: vm.annulatePenaly.onDate, amount: vm.annulatePenaly.amount, reason: vm.annulatePenaly.reason },
	                function () {
            			AlertService.success("modules.transaction.message.successAnnulatingPenalty", { loan_no: loanNo, on_date: vm.onDate, amount: vm.amount, reason: vm.annulatePenaly.reason });
	                    $uibModalInstance.close(true);
	                });
        }
    }
})();
