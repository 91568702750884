(function() {
    'use strict';
    angular
        .module('sigmaApp')
        .factory('Rights', Rights)
        ;

    Rights.$inject = [];

    function Rights () {
        console.log("Rights service started");
        var rights;
        
        var init = function (rightsList) {
        	this.rights = [];
        	if(!rightsList) return;
        	var rl = rightsList.split(',');
            this.rights = $.map(rl,$.trim);
        };
        
        var isAllowed = function (request) {
        	return this.rights.indexOf(request) >= 0 ? true : false ;
        };
        
        var isAnyAllowed = function (requests) {
        	var response = false;
        	if(!requests) return false;
        	var rl = requests.split(',');
        	var extRights = this.rights;
        	rl = $.map(rl,$.trim);
        	rl.forEach(function(item) {
        		if (extRights.indexOf(item) >= 0) response = true;	
        		return response; 
        	});
        	return response;
        };
        
        return {
        	init: init,
        	isAllowed: isAllowed,
        	isAnyAllowed: isAnyAllowed
        };
    }
})();
