(function() {
	'use strict';

	angular.module('sigmaApp').component('debtActionLog', {
		templateUrl : "app/modules/debt-action-log-component/debt-action-log.template.html",
		controller : DebtActionLogController,
		bindings : {
			loanNo: '=',
			clnId: '='
		}
	});

	DebtActionLogController.$inject = ['formHelper', '$rootScope', 'AlertService', '$anchorScroll', '$location', '$scope', '$element', '$attrs', 'DebtActionLog', 'defaultPageSize', '$filter', '$translate', '$window', 'shortDateFormats', '$state'];

	function DebtActionLogController(formHelper, $rootScope, AlertService, $anchorScroll, $location, $scope, $element, $attrs, DebtActionLog, defaultPageSize, $filter, $translate, $window, shortDateFormats, $state) {
		var ctrl = this;
		ctrl.kendo = kendo;
		
		ctrl.isNoLoans = false;
		ctrl.isLoanView = false;
		ctrl.isActionView = false;
		ctrl.isActionListView = false;
		ctrl.currentState = "loanList";
		ctrl.shortDateFormats = shortDateFormats;
		ctrl.terminateDateFormat = kendo.culture().calendar.patterns.d;
		ctrl.selectedLoan = {};
		ctrl.selectedAction = {};
		ctrl.resultAction = {};
		ctrl.windowGridMaximized = false;
		ctrl.gridHeight = $window.innerHeight/2.5;
		ctrl.isLoanMode = ctrl.loanNo != null && ctrl.loanNo != "" && typeof ctrl.loanNo == "string" ? true : false;
		var totalHistSet = false;
		ctrl.expensesTotal = 0;
		ctrl.caclTerminateTotal = 0;
		ctrl.terminateTotal = 0;
		ctrl.updtTermTotal = 0;
		ctrl.details = {};
		ctrl.courtDecisionDate = null;
		ctrl.closeDate = new Date();
		
		ctrl.emptyDecision = {
				terminated: {
					    onDate: null,
						prn: 0,
						int: 0,
						moksa: 0,
						pen: 0,
						subtot: 0,
						addserv: 0,
						court: 0,
						tot: 0
				},
				paidBefore: {
					fromDate: null,
					prn: 0,
					int: 0,
					moksa: 0,
					pen: 0,
					subtot: 0,
					addserv: 0,
					court: 0,
					tot: 0
				},
				decision: {
					onDate: null,
					prn: 0,
					int: 0,
					moksa: 0,
					pen: 0,
					subtot: 0,
					addserv: 0,
					court: 0,
					tot: 0
				},
				paidAfter: {
					fromDate: null,
					prn: 0,
					int: 0,
					moksa: 0,
					pen: 0,
					subtot: 0,
					addserv: 0,
					court: 0,
					tot: 0
				},
				residual: {
					fromDate: null,
					prn: 0,
					int: 0,
					moksa: 0,
					pen: 0,
					subtot: 0,
					addserv: 0,
					court: 0,
					tot: 0
				}
		};
		ctrl.decision = ctrl.emptyDecision;
		
		var loanModeFilter = ctrl.isLoanMode ? {"logic":"or",
	        "filters": [
	                    {
    		                "field":"loanNum",
    		                "operator":"eq",
    		                "value": ctrl.loanNo
		                },{
		                	"field":"loanNum",
    		                "operator":"eq",
    		                "value":""
    		            },{
		                	"field":"loanNum",
    		                "operator":"eq",
    		                "value":null
    		            }
    		           ]
			} : {};
			
        var gridElement = $("#actionLogs");

		var loanNums = {
			data: []
		};
		var subStatuses = {
			data: []	
		};
		var crtdBys = {
			data: []
		};
		var sstsTitles = {
			data: []
		};
		$scope.loanTerminateSstsDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "10000",
    			value: $translate.instant("modules.debtActionLog.dontChange")
    		},{
    			key: "21",
    			value: $translate.instant("modules.debtActionLog.sstsCode.21")
    		},{
    			key: "8",
    			value: $translate.instant("modules.debtActionLog.sstsCode.8")
    		},{
    			key: "20",
    			value: $translate.instant("modules.debtActionLog.sstsCode.20")
    		},{
    			key: "15",
    			value: $translate.instant("modules.debtActionLog.sstsCode.15")
    		},{
    			key: "16",
    			value: $translate.instant("modules.debtActionLog.sstsCode.16")
    		},{
    			key: "40",
    			value: $translate.instant("modules.debtActionLog.sstsCode.40")
    		},{
    			key: "7",
    			value: $translate.instant("modules.debtActionLog.sstsCode.7")
    		}]
    	});
		ctrl.dateFormat = kendo.culture().calendar.patterns.G;
        
        function resizeGrid() {
        	gridElement.data("kendoGrid").resize();
        }		
        
		$scope.$watch(
				function() { 
					return ctrl.windowGridMaximized;
		    	},
		    	function(newVal, oldVal) {
		        	if(newVal != oldVal) {
		        		if(!ctrl.windowGridMaximized) {
		        			gridElement.css({height: ctrl.gridHeight + 'px'});
		        		} else {
		        			gridElement.css({height:$window.innerHeight - 100 + 'px'});
		        			var newHash = 'debt-action-log-panel';
		        		    if ($location.hash() !== newHash)
		        		    	$location.hash('debt-action-log-panel');
		        		    else
		        		        $anchorScroll();
		        		}
		        		resizeGrid();
		        	}
		    	}
		);

		ctrl.runActionInfo = function(){
			var selected = $filter('filter')(ctrl.actionDataSource.data(), {ssts: 10000}, true);
			if(selected.length > 0)
				fillAction(selected[0]);
		};
        
        ctrl.goToBack = function(){
        	if(ctrl.isActionView) {
        		
        		switch (ctrl.currentState){
        			case 'loanList':
        				if(!ctrl.isLoanMode)
        					goToLoanList();
        				else 
        					goToLoanDetail();
        				break;
        			case 'loanDetail':
        				goToLoanDetail();
        				break;
        			case 'actionList':
        			default:
        				goToActionList();
        				break;        				
        		}
        		
        	} else if(ctrl.isActionListView) {
        		switch (ctrl.currentState){
        			case 'actionList':
	    			case 'loanList':
	    				if(ctrl.isLoanMode)
	    					goToLoanDetail();
	    				else 
	    					goToLoanList();
	    				break;
	    			case 'loanDetail':
	    			default:
	    				goToLoanDetail();
	    				break;
        		}	        				
        	} else if(ctrl.isLoanView) {
        		switch (ctrl.currentState){
	        		default:	
	        		case 'loanList':
	    				if(!ctrl.isLoanMode)
	    					goToLoanList();
	    				else 
	    					goToLoanDetail();
	    				break;
	    		} 
        	}
        };
        
        function goToLoanList() {
    		ctrl.isLoanView = false;
    		ctrl.isActionListView = false;
    		ctrl.isActionView = false;
    		ctrl.selectedLoan = {};
    		ctrl.historyDataSource.filter([]);
    		ctrl.selectedAction = {};
    		ctrl.resultAction = {};
    		ctrl.currentState = "loanList";
        }
        function goToLoanDetail() {
    		ctrl.isLoanView = true;
    		ctrl.isActionListView = false;
    		ctrl.isActionView = false;
    		ctrl.selectedAction = {};
    		ctrl.resultAction = {};
    		ctrl.currentState = "loanDetail";
        }
        function goToActionList() {
    		ctrl.isLoanView = false;
    		ctrl.isActionListView = true;
    		ctrl.isActionView = false;
    		ctrl.selectedAction = {};
    		ctrl.resultAction = {};
    		ctrl.currentState = "actionList";
        }
        $(window).resize(function(){
          resizeGrid();
        });
        
		ctrl.selectLoan = function(item){
			ctrl.fillSelected(item);
			ctrl.historyDataSource.filter(setFilterForLoan(item.loanNum));
		};

		ctrl.fillSelected = function(item){
			ctrl.selectedLoan = item;
			ctrl.selectedLoan.loanDateShort = kendo.toString(ctrl.selectedLoan.loanDate, 'd');
			ctrl.selectedLoan.loanEndDateShort = kendo.toString(ctrl.selectedLoan.loanEndDate, 'd');
			ctrl.selectedLoan.lastPaymentDateShort = kendo.toString(ctrl.selectedLoan.lastPaymentDate, 'd');
			ctrl.selectedLoan.debtDateShort = kendo.toString(ctrl.selectedLoan.debtDate, 'd');
			ctrl.selectedLoan.loanAmountFormatted = kendo.toString(ctrl.selectedLoan.loanAmount, 'n2');
			ctrl.selectedLoan.loanMonthPayFormatted = kendo.toString(ctrl.selectedLoan.loanMonthPay, 'n2');
			ctrl.selectedLoan.debtSumFormatted = kendo.toString(ctrl.selectedLoan.debtSum, 'n2');
			ctrl.selectedLoan.debtPenFormatted = kendo.toString(ctrl.selectedLoan.debtPen, 'n2');
			ctrl.isLoanView=true;
			ctrl.currentState = "loanDetail";
		};
		
		function setFilterForLoan(loanNo) {
        	
			var historyLoanNumberFilter = new Array();
        	historyLoanNumberFilter.push(
        			{"logic":"or",
        		         "filters":[
        		                    {
		        		                "field":"loanNum",
		        		                "operator":"eq",
		        		                "value":loanNo
	        		                },{
	        		                	"field":"loanNum",
		        		                "operator":"eq",
		        		                "value":""
		        		            },{
	        		                	"field":"loanNum",
		        		                "operator":"eq",
		        		                "value":null
		        		            }
        		         ]
        			}
        	);
        	
        	return historyLoanNumberFilter;
		}
		
		ctrl.goToListOfActions = function(){
			ctrl.isActionListView = true;
			ctrl.isActionView = false;
			ctrl.isLoanView = ctrl.isLoanMode ? true: false;
		};
		
		ctrl.selectAction = function(action){
			ctrl.currentState = "actionList";
			fillAction(action);
		};

		function fillAction(action){
			ctrl.currentAmounts = [];
			ctrl.amount = {};
			ctrl.details = {};
			ctrl.isActionListView = false;
			ctrl.isActionView = true;
			ctrl.isLoanView = false;
			ctrl.selectedAction = action;
			ctrl.firstFormElement = "firstFormElementFor-" + action.endpoint;
			ctrl.formCode = action.formCode;
			ctrl.formTransCode = "modules.debtActionLog.action." + action.ssts;
			ctrl.resultAction = {};
			switch (ctrl.formCode){
			case "expenses":
				ctrl.amount.addserv_norm = 0;
				//ctrl.amount.stamp = 0;
				ctrl.amount.court = 0;
				ctrl.amount.bailiff = 0;
				ctrl.updateExpensesTotal();
				ctrl.details.onDate = new Date();
				ctrl.details.reason = "";        
				ctrl.details.clnId = ctrl.clnId; 
				ctrl.details.ssts = "10000"; // action.ssts; 
				if (getLoanNum().length > 0)
					ctrl.details.loanNum = getLoanNum();
				break;
			case "terminate":
				ctrl.terminationDate = new Date();
				ctrl.calcTermination(ctrl.terminationDate, ctrl.setAmounts);
				ctrl.details.reason = "";        
				ctrl.details.clnId = ctrl.clnId; 
				ctrl.details.ssts = "10000"; // action.ssts; 
				if (getLoanNum().length > 0)
					ctrl.details.loanNum = getLoanNum();
				break;
			case "updateTermination":
				DebtActionLog.getTerminationAmounts(
					{loan_no: getLoanNum()},
					function(response) {
						if (response.amt == null)
							ctrl.currentAmounts = [];
						else ctrl.currentAmounts = response.amt;
						ctrl.amount.prn = getAmountByComp(ctrl.currentAmounts, "PRN_COURT");
						ctrl.amount.int = getAmountByComp(ctrl.currentAmounts, "INT_COURT");
						ctrl.amount.moksa = getAmountByComp(ctrl.currentAmounts, "MOKSA_COURT");
						ctrl.amount.pen = getAmountByComp(ctrl.currentAmounts, "PEN_COURT");
						//ctrl.amount.stamp = getAmountByComp(ctrl.currentAmounts, "STAMP");
						ctrl.amount.addserv_norm = getAmountByComp(ctrl.currentAmounts, "ADDSERV_NORM");
						ctrl.amount.court = getAmountByComp(ctrl.currentAmounts, "COURT");
						ctrl.updateUpdtTermTotal();
						if (response.detail[0].onDate > "")
							ctrl.details.onDate = new Date(response.detail[0].onDate);
						else e.error("Termination date is not available!");
						ctrl.details.reason = "";        
						ctrl.details.clnId = ctrl.clnId; 
						ctrl.details.ssts = "10000"; // response.detail[0].ssts;
						if (getLoanNum().length > 0)
							ctrl.details.loanNum = getLoanNum();
					},
					function(error) {
						console.log('Action Logs list error for Loan');
						e.error(error);
					}
				);
				break;
			case "courtDecision":
				ctrl.decision = ctrl.emptyDecision;
				
				DebtActionLog.getTerminationAmounts(
					{loan_no: getLoanNum()},
					function(response) {
//						console.log("courtDecision - termination amounts received:");
//						console.log(response);
						
						ctrl.decision.terminated.prn = getAmountByComp(response.amt, "PRN_COURT");
						ctrl.decision.terminated.int = getAmountByComp(response.amt, "INT_COURT");
						ctrl.decision.terminated.moksa = getAmountByComp(response.amt, "MOKSA_COURT");
						ctrl.decision.terminated.pen = getAmountByComp(response.amt, "PEN_COURT");
						//ctrl.amount.stamp = getAmountByComp(response.amt, "STAMP");
						ctrl.decision.terminated.addserv = getAmountByComp(response.amt, "ADDSERV_NORM");
						ctrl.decision.terminated.court = getAmountByComp(response.amt, "COURT");
						if (response.detail[0].onDate > "")
							ctrl.decision.terminated.onDate = new Date(response.detail[0].onDate);
						else e.error("Termination date is not available!");
						if (getLoanNum().length > 0)
							ctrl.details.loanNum = getLoanNum();
						
						if (ctrl.courtDecisionDate == null){
							if (response.detail[0].courtDecisionDate == null)
								ctrl.courtDecisionDate = new Date();
							else 
								ctrl.courtDecisionDate = new Date(response.detail[0].courtDecisionDate); 
							ctrl.updateCourtDecisionAmounts();
						}
					},
					function(error) {
						console.log('Action Logs list error for Loan');
						e.error(error);
					}
				);
				break;
			case 'revertTermination':
				ctrl.details.reason = "";
				ctrl.details.clnId = ctrl.clnId; 
				//ctrl.details.ssts = action.ssts;
				ctrl.details.ssts = action.ssts;
				if (getLoanNum().length > 0)
					ctrl.details.loanNum = getLoanNum();
				break;
			case 'info':
				formHelper.setFocus(ctrl.firstFormElement);
				ctrl.resultAction.clnId = ctrl.clnId;
				if (getLoanNum().length > 0)
					ctrl.resultAction.loanNum = getLoanNum();
					ctrl.resultAction.ssts = action.ssts;
				break;
			}
		}
		
		ctrl.updateExpensesTotal = function(){
			var total = 0;
			if (ctrl.amount != null){
//				if (ctrl.amount.stamp != null)
//					total += ctrl.amount.stamp;
				if (ctrl.amount.addserv_norm != null)
					total += ctrl.amount.addserv_norm;
				if (ctrl.amount.court != null)
					total += ctrl.amount.court;
				if (ctrl.amount.bailiff != null)
					total += ctrl.amount.bailiff;
				
				ctrl.expensesTotal = kendo.toString(total, 'n2');
			}	
		};
		
		ctrl.updateCalcTerminateTotal = function(){
			var total = 0;
			if (ctrl.amount != null){
				if (ctrl.currentAmount.prn != null) total += ctrl.currentAmount.prn;
				if (ctrl.currentAmount.int != null) total += ctrl.currentAmount.int;
				if (ctrl.currentAmount.moksa != null) total += ctrl.currentAmount.moksa;
				if (ctrl.currentAmount.pen != null) total += ctrl.currentAmount.pen;
				
				ctrl.caclTerminateTotal = kendo.toString(total, 'n2');
			}
		};
		
		ctrl.updateTerminateTotal = function(){
			var total = 0;
			if (ctrl.amount != null){
				if (ctrl.amount.prn != null) total += ctrl.amount.prn;
				if (ctrl.amount.int != null) total += ctrl.amount.int;
				if (ctrl.amount.moksa != null) total += ctrl.amount.moksa;
				if (ctrl.amount.pen != null) total += ctrl.amount.pen;
				if (ctrl.amount.addserv_norm != null) total += ctrl.amount.addserv_norm;
				if (ctrl.amount.court != null) total += ctrl.amount.court;
				
				ctrl.terminateTotal = kendo.toString(total, 'n2');
			}
		};
		
		ctrl.updateUpdtTermTotal = function(){
			var total = 0;
			if (ctrl.amount != null){
				if (ctrl.amount.prn != null) total += ctrl.amount.prn;
				if (ctrl.amount.int != null) total += ctrl.amount.int;
				if (ctrl.amount.moksa != null) total += ctrl.amount.moksa;
				if (ctrl.amount.pen != null) total += ctrl.amount.pen;
				if (ctrl.amount.addserv_norm != null) total += ctrl.amount.addserv_norm;
				if (ctrl.amount.court != null) total += ctrl.amount.court;
				
				ctrl.updtTermTotal = kendo.toString(total, 'n2');
			}
		};
		
		ctrl.calcTermination = function(terminationDate, callBack){
			DebtActionLog.getCalculatedTerminationByLoanAndDate(
					{loan_no: getLoanNum(), date: terminationDate.toLocaleDateString('lt-LT')},
					function(response) {
						ctrl.currentAmounts = response;
						ctrl.currentAmount = {};
						ctrl.currentAmount.prn = getAmountByComp(ctrl.currentAmounts, "PRN_COURT");
						ctrl.currentAmount.int = getAmountByComp(ctrl.currentAmounts, "INT_COURT");
						ctrl.currentAmount.moksa = getAmountByComp(ctrl.currentAmounts, "MOKSA_COURT");
						ctrl.currentAmount.pen = getAmountByComp(ctrl.currentAmounts, "PEN_COURT");
						//ctrl.currentAmount.stamp = getAmountByComp(ctrl.currentAmounts, "STAMP");
						ctrl.currentAmount.addserv_norm = getAmountByComp(ctrl.currentAmounts, "ADDSERV_NORM");
						ctrl.currentAmount.court = getAmountByComp(ctrl.currentAmounts, "COURT");
						ctrl.details.onDate = terminationDate;
						ctrl.updateCalcTerminateTotal();
						callBack();
					}, 
					function(error) {
						console.log('Action Logs list error for Loan');
						e.error(error);
					}
			);
		}
		
		function getLoanNum(){
			var loanNum = "";
			if(ctrl.loanNo != null && ctrl.loanNo != "" && typeof ctrl.loanNo === "string"){
				var selected = $filter('filter')(ctrl.infoDataSource.data(), {loanNum: ctrl.loanNo}, true);
				loanNum = selected.length > 0 ? ctrl.loanNo: "";
			}
			return loanNum;
		}
		
		function getAmountByComp(calcTerm, comp){
			var amount = 0;
			calcTerm.forEach(function(element){
				if (element.comp == comp){
					amount = element.amt;
					return;
				}
			});
			return amount;
		}
		function getBeforeAmountByComp(calcTerm, comp){
			var amount = 0;
			calcTerm.forEach(function(element){
				if (element.comp == comp){
					amount = element.amtBefore;
					return;
				}
			});
			return amount;
		}
		function getAfterAmountByComp(calcTerm, comp){
			var amount = 0;
			calcTerm.forEach(function(element){
				if (element.comp == comp){
					amount = element.amtAfter;
					return;
				}
			});
			return amount;
		}
		
		ctrl.setAmounts = function setAmounts(){
			ctrl.amount = {};
		    ctrl.amount.prn = ctrl.currentAmount.prn;
		    ctrl.amount.int = ctrl.currentAmount.int;
		    ctrl.amount.moksa = ctrl.currentAmount.moksa;
		    ctrl.amount.pen = ctrl.currentAmount.pen;
		    //ctrl.amount.stamp = ctrl.currentAmount.stamp;
		    ctrl.amount.addserv_norm = ctrl.currentAmount.addserv_norm;
		    ctrl.amount.court = ctrl.currentAmount.court;
		    ctrl.updateTerminateTotal();
		}
		
		ctrl.submitAction = function(selected, result) {
//			console.log("submitaAction(selected, result)");
//			console.log(selected);
//			console.log(result);
			switch(selected.formCode){
			case "info":
				if (!result.fromDate)
					result.fromDate = new Date();
				break
			case "expenses":
				result.amt = getExpensesAmounts();
				if (ctrl.details.reason == "" || ctrl.details.reason == null){
					AlertService.error("modules.debtActionLog.message.emptyReason");
					return;
				}
				result.detail = [];
				result.detail.push(ctrl.details);
				break;
			case "terminate":
				result.amt = getTerminateAmounts();
				if (ctrl.details.reason == "" || ctrl.details.reason == null){
					AlertService.error("modules.debtActionLog.message.emptyReason");
					return;
				}
				result.detail = [];
				result.detail.push(ctrl.details);
				break;	
			case "updateTermination":
//				console.log("sublitAction - updateTermination")
//				console.log(ctrl.details.ssts);
				result.amt = getUpdateTerminationAmounts();
				if (ctrl.details.reason == "" || ctrl.details.reason == null){
					AlertService.error("modules.debtActionLog.message.emptyReason");
					return;
				}
				result.detail = [];
				result.detail.push(ctrl.details);
//				console.log("updateTermination() result.detail.ssts = ");
//				console.log(result.detail.ssts);
				break;	
			case "revertTermination":
				if (ctrl.details.reason == "" || ctrl.details.reason == null){
					AlertService.error("modules.debtActionLog.message.emptyReason");
					return;
				}
				result.detail = [];
				result.detail.push(ctrl.details);
				break;	
			case "courtDecision":
				result.detail = [];
				result.detail.push({loanNum: getLoanNum(), onDate: ctrl.courtDecisionDate, reason: ctrl.decision.decision.reason });
				result.amt = [];
				result.amt.push({comp: "PRN_COURT",    amt: ctrl.decision.decision.prn});
				result.amt.push({comp: "INT_COURT",    amt: ctrl.decision.decision.int});
				result.amt.push({comp: "MOKSA_COURT",  amt: ctrl.decision.decision.moksa});
				result.amt.push({comp: "PEN_COURT",    amt: ctrl.decision.decision.pen});
				result.amt.push({comp: "ADDSERV_NORM", amt: ctrl.decision.decision.addserv});
				result.amt.push({comp: "COURT",        amt: ctrl.decision.decision.court});
				break;
			case "closeLoan":
				result.detail = [];
				result.detail.push({loanNum: getLoanNum(), onDate: ctrl.closeDate, reason: ctrl.details.reason });
				break;
			}
//			console.log("Sending to server:");
//			console.log(result);
			DebtActionLog.runAction(
				{endPoint:selected.endpoint},
				result,
				function(response) {
	        		if(response && typeof response === "object" && !response.hasOwnProperty('nsError')) {
	        			AlertService.success("modules.debtActionLog.message.submitSuccessfuly");
	        		} else if(response && typeof response === "object" && response.hasOwnProperty('nsError')) {
	        			AlertService.warning("modules.debtActionLog.message." + response.nsError);
	        		} else {	
	        			AlertService.warning("modules.debtActionLog.message.onSaveDontResponse");
	        		}
	        		$state.reload();
	        		ctrl.goToBack();
	        		totalHistSet = false;
	        		ctrl.historyDataSource.read();
				}, 
				function(error) {
					console.log('DebtActionLog save error');
					AlertService.error("Saving error!");
				}
			);
		};		
		
		function getUpdateTerminationAmounts(){
//			console.log("getUpdateTerminationAmounts()");
//			console.log(ctrl.details.ssts);
			var amountsToSend = [];
			amountsToSend = ctrl.currentAmounts.slice();
			addCompAmount(amountsToSend, "PRN_COURT", ctrl.amount.prn);
			addCompAmount(amountsToSend, "INT_COURT", ctrl.amount.int);
			addCompAmount(amountsToSend, "MOKSA_COURT", ctrl.amount.moksa);
			addCompAmount(amountsToSend, "PEN_COURT", ctrl.amount.pen);
			//addCompAmount(amountsToSend, "STAMP", ctrl.amount.stamp); //turned off
			addCompAmount(amountsToSend, "ADDSERV_NORM", ctrl.amount.addserv_norm);
			addCompAmount(amountsToSend, "COURT", ctrl.amount.court);
			return amountsToSend;
		}
		
		function getExpensesAmounts(){
			var amountsToSend = [];
			amountsToSend = ctrl.currentAmounts.slice();
			//addCompAmount(amountsToSend, "STAMP", ctrl.amount.stamp); //turned off
			addCompAmount(amountsToSend, "ADDSERV_NORM", ctrl.amount.addserv_norm);
			addCompAmount(amountsToSend, "COURT", ctrl.amount.court);
			//addCompAmount(amountsToSend, "BAILIFF", ctrl.amount.bailiff); //turned off
			return amountsToSend;
		}
		
		function getTerminateAmounts(){
			var amountsToSend = [];
			amountsToSend = ctrl.currentAmounts.slice();
			addCompAmount(amountsToSend, "PRN_COURT", ctrl.amount.prn);
			addCompAmount(amountsToSend, "INT_COURT", ctrl.amount.int);
			addCompAmount(amountsToSend, "MOKSA_COURT", ctrl.amount.moksa);
			addCompAmount(amountsToSend, "PEN_COURT", ctrl.amount.pen);
			//addCompAmount(amountsToSend, "STAMP", ctrl.amount.stamp); //turned off
			addCompAmount(amountsToSend, "ADDSERV_NORM", ctrl.amount.addserv_norm);
			addCompAmount(amountsToSend, "COURT", ctrl.amount.court);
			return amountsToSend;
		}
		
		function addCompAmount(array, comp, amt){
			var wasFound = false;
			array.forEach(function(element){
				if (element.comp == comp){
					element.amt = amt;
					wasFound = true;
					return;
				}
			});
			if (wasFound == false){
				var element = {};
				element.comp = comp;
				element.amt = amt;
				array.push(element);
			}
			return array;
		}

		ctrl.setNumericTextBoxCss = function( widgetId, css, value) {
			$("#" + widgetId).data("kendoNumericTextBox").wrapper.find("input").css(css, value);
		}

		
		ctrl.updateCourtDecisionResidual = function() {
			ctrl.decision.residual.prn = ctrl.decision.decision.prn - ctrl.decision.paidAfter.prn;
			ctrl.decision.residual.int = ctrl.decision.decision.int - ctrl.decision.paidAfter.int;
			ctrl.decision.residual.moksa = ctrl.decision.decision.moksa - ctrl.decision.paidAfter.moksa;
			ctrl.decision.residual.pen = ctrl.decision.decision.pen - ctrl.decision.paidAfter.pen;
			ctrl.decision.residual.addserv = ctrl.decision.decision.addserv - ctrl.decision.paidAfter.addserv;
			ctrl.decision.residual.court = ctrl.decision.decision.court - ctrl.decision.paidAfter.court;
			
			ctrl.setNumericTextBoxCss("decisionPrn",     "color", ctrl.decision.decision.prn < 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("decisionInt",     "color", ctrl.decision.decision.int < 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("decisionMoksa",   "color", ctrl.decision.decision.moksa < 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("decisionPen",     "color", ctrl.decision.decision.pen < 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("decisionAddserv", "color", ctrl.decision.decision.addserv < 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("decisionCourt",   "color", ctrl.decision.decision.court < 0 ? "red" : "black");

			ctrl.setNumericTextBoxCss("paidBeforePrn",     "color", ctrl.decision.decision.prn     < 0 && ctrl.decision.paidBefore.prn > 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("paidBeforeInt",     "color", ctrl.decision.decision.int     < 0 && ctrl.decision.paidBefore.int > 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("paidBeforeMoksa",   "color", ctrl.decision.decision.moksa   < 0 && ctrl.decision.paidBefore.moksa > 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("paidBeforePen",     "color", ctrl.decision.decision.pen     < 0 && ctrl.decision.paidBefore.pen > 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("paidBeforeAddserv", "color", ctrl.decision.decision.addserv < 0 && ctrl.decision.paidBefore.addserv > 0 ? "red" : "black");
			ctrl.setNumericTextBoxCss("paidBeforeCourt",   "color", ctrl.decision.decision.court   < 0 && ctrl.decision.paidBefore.court > 0 ? "red" : "black");

			ctrl.setNumericTextBoxCss("paidBeforePrn",     "font-weight", ctrl.decision.decision.prn     < 0 && ctrl.decision.paidBefore.prn     > 0 ? "bold" : "normal");
			ctrl.setNumericTextBoxCss("paidBeforeInt",     "font-weight", ctrl.decision.decision.int     < 0 && ctrl.decision.paidBefore.int     > 0 ? "bold" : "normal");
			ctrl.setNumericTextBoxCss("paidBeforeMoksa",   "font-weight", ctrl.decision.decision.moksa   < 0 && ctrl.decision.paidBefore.moksa   > 0 ? "bold" : "normal");
			ctrl.setNumericTextBoxCss("paidBeforePen",     "font-weight", ctrl.decision.decision.pen     < 0 && ctrl.decision.paidBefore.pen     > 0 ? "bold" : "normal");
			ctrl.setNumericTextBoxCss("paidBeforeAddserv", "font-weight", ctrl.decision.decision.addserv < 0 && ctrl.decision.paidBefore.addserv > 0 ? "bold" : "normal");
			ctrl.setNumericTextBoxCss("paidBeforeCourt",   "font-weight", ctrl.decision.decision.court   < 0 && ctrl.decision.paidBefore.court   > 0 ? "bold" : "normal");

			if (ctrl.decision.residual.prn < 0) {
				ctrl.setNumericTextBoxCss("residualPrn",   "color",       "red");
				ctrl.setNumericTextBoxCss("residualPrn",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.prn > 0){
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "color",       "red");
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "font-weight", "bold");
				}
				else {
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "color",      "black");
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualPrn",   "color",       "black");
				ctrl.setNumericTextBoxCss("residualPrn",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.prn > 0){
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "color", 	   "blue");
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "font-weight", "bold");
				}
				else {
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterPrn",   "font-weight", "normal");
				}
			}
			
			if (ctrl.decision.residual.int < 0) {
				ctrl.setNumericTextBoxCss("residualInt",   "color", "red");
				ctrl.setNumericTextBoxCss("residualInt",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.int > 0){
					ctrl.setNumericTextBoxCss("paidAfterInt",   "color", "red");
					ctrl.setNumericTextBoxCss("paidAfterInt",   "font-weight", "bold");
				}
				else {
					ctrl.setNumericTextBoxCss("paidAfterInt",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterInt",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualInt",   "color", "black");
				ctrl.setNumericTextBoxCss("residualInt",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.int > 0){
					ctrl.setNumericTextBoxCss("paidAfterInt",   "color", "blue");
					ctrl.setNumericTextBoxCss("paidAfterInt",   "font-weight", "bold");
				}
				else {
					ctrl.setNumericTextBoxCss("paidAfterInt",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterInt",   "font-weight", "normal");
				}
			}

			if (ctrl.decision.residual.moksa < 0) {
				ctrl.setNumericTextBoxCss("residualMoksa",   "color", "red");
				ctrl.setNumericTextBoxCss("residualMoksa",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.moksa > 0){
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "color", "red");
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "font-weight", "bold");
					}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualMoksa",   "color", "black");
				ctrl.setNumericTextBoxCss("residualMoksa",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.moksa > 0) {
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "color", "blue");
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "font-weight", "bold");
				}
				else {  
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterMoksa",   "font-weight", "normal");
				}
			}

			if (ctrl.decision.residual.pen < 0) {
				ctrl.setNumericTextBoxCss("residualPen",   "color", "red");
				ctrl.setNumericTextBoxCss("residualPen",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.pen > 0) {
					ctrl.setNumericTextBoxCss("paidAfterPen",   "color", "red");
					ctrl.setNumericTextBoxCss("paidAfterPen",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterPen",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterPen",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualPen",   "color", "black");
				ctrl.setNumericTextBoxCss("residualPen",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.pen > 0) {
					ctrl.setNumericTextBoxCss("paidAfterPen",   "color", "blue");
					ctrl.setNumericTextBoxCss("paidAfterPen",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterPen",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterPen",   "font-weight", "normal");
				}
			}

			if (ctrl.decision.residual.addserv < 0) {
				ctrl.setNumericTextBoxCss("residualAddserv",   "color", "red");
				ctrl.setNumericTextBoxCss("residualAddserv",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.addserv > 0) {
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "color", "red");
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualAddserv",   "color", "black");
				ctrl.setNumericTextBoxCss("residualAddserv",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.addserv > 0) {
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "color", "blue");
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterAddserv",   "font-weight", "normal");
				}
			}

			if (ctrl.decision.residual.court < 0) {
				ctrl.setNumericTextBoxCss("residualCourt",   "color", "red");
				ctrl.setNumericTextBoxCss("residualCourt",   "font-weight", "bold");
				if (ctrl.decision.paidAfter.court > 0){
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "color", "red");
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "font-weight", "normal");
				}
			} else {
				ctrl.setNumericTextBoxCss("residualCourt",   "color", "black");
				ctrl.setNumericTextBoxCss("residualCourt",   "font-weight", "normal");
				if (ctrl.decision.paidAfter.court > 0) {
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "color", "blue");
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "font-weight", "bold");
				}
				else { 
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "color", "black");
					ctrl.setNumericTextBoxCss("paidAfterCourt",   "font-weight", "normal");
				}
			}

		}
		
		ctrl.updateCourtDecisionAmounts = function(){
		//	console.log("updateCourtDecisionAmounts(" + kendo.toString(ctrl.courtDecisionDate, "d") + ")");
			DebtActionLog.getAmountsPaidAfterTermination(
					{loan_no: getLoanNum(), date: ctrl.courtDecisionDate.toLocaleDateString('lt-LT')},
					function(response) {
//						console.log("courtDecision - amounts paid after termination received:");
//						console.log(response);
						
						if (response.amt2 == null){
							ctrl.decision.paidBefore.prn = 0;
							ctrl.decision.paidBefore.int = 0
							ctrl.decision.paidBefore.moksa =0
							ctrl.decision.paidBefore.pen = 0;
							ctrl.decision.paidBefore.addserv = 0;
							ctrl.decision.paidBefore.court = 0;
							ctrl.decision.paidAfter.prn = 0;
							ctrl.decision.paidAfter.int = 0
							ctrl.decision.paidAfter.moksa =0
							ctrl.decision.paidAfter.pen = 0;
							ctrl.decision.paidAfter.addserv = 0;
							ctrl.decision.paidAfter.court = 0;
							
						} else {
							ctrl.decision.paidBefore.prn     = getBeforeAmountByComp(response.amt2, "PRN_COURT");
							ctrl.decision.paidBefore.int     = getBeforeAmountByComp(response.amt2, "INT_COURT");
							ctrl.decision.paidBefore.moksa   = getBeforeAmountByComp(response.amt2, "MOKSA_COURT");
							ctrl.decision.paidBefore.pen     = getBeforeAmountByComp(response.amt2, "PEN_COURT");
							ctrl.decision.paidBefore.addserv = getBeforeAmountByComp(response.amt2, "ADDSERV_NORM");
							ctrl.decision.paidBefore.court   = getBeforeAmountByComp(response.amt2, "COURT");
							ctrl.decision.paidAfter.prn      = getAfterAmountByComp(response.amt2, "PRN_COURT");
							ctrl.decision.paidAfter.int      = getAfterAmountByComp(response.amt2, "INT_COURT");
							ctrl.decision.paidAfter.moksa    = getAfterAmountByComp(response.amt2, "MOKSA_COURT");
							ctrl.decision.paidAfter.pen      = getAfterAmountByComp(response.amt2, "PEN_COURT");
							ctrl.decision.paidAfter.addserv  = getAfterAmountByComp(response.amt2, "ADDSERV_NORM");
							ctrl.decision.paidAfter.court    = getAfterAmountByComp(response.amt2, "COURT");
						}
						ctrl.decision.decision.prn     = ctrl.decision.terminated.prn     - ctrl.decision.paidBefore.prn;
						ctrl.decision.decision.int     = ctrl.decision.terminated.int     - ctrl.decision.paidBefore.int;
						ctrl.decision.decision.moksa   = ctrl.decision.terminated.moksa   - ctrl.decision.paidBefore.moksa;
						ctrl.decision.decision.pen     = ctrl.decision.terminated.pen     - ctrl.decision.paidBefore.pen;
						ctrl.decision.decision.addserv = ctrl.decision.terminated.addserv - ctrl.decision.paidBefore.addserv;
						ctrl.decision.decision.court   = ctrl.decision.terminated.court   - ctrl.decision.paidBefore.court;

						ctrl.decision.residual.prn     = ctrl.decision.decision.prn     - ctrl.decision.paidAfter.prn;
						ctrl.decision.residual.int     = ctrl.decision.decision.int     - ctrl.decision.paidAfter.int;
						ctrl.decision.residual.moksa   = ctrl.decision.decision.moksa   - ctrl.decision.paidAfter.moksa;
						ctrl.decision.residual.pen     = ctrl.decision.decision.pen     - ctrl.decision.paidAfter.pen;
						ctrl.decision.residual.addserv = ctrl.decision.decision.addserv - ctrl.decision.paidAfter.addserv;
						ctrl.decision.residual.court   = ctrl.decision.decision.court   - ctrl.decision.paidAfter.court;
						
						ctrl.updateCourtDecisionResidual();
					}, 
					function(error) {
						console.log('Action Logs list error for Loan');
						e.error(error);
					}
			);
		};
		
		
		ctrl.infoOptions = {
        	    selectable: false,
        };

		ctrl.actionDataSource = new kendo.data.DataSource({
			schema : {
				model : {
					id: "ssts",
					fields : {
						"ssts" : {
							type : "number"
						},
						"name" : {
							type : "string"
						},
						"type" : {
							type : "string"
						},
						"endpoint" : {
							type : "string"
						},
						"viewOrder" : {
							type : "number"
						}
					}
				}
			},
			transport : {
				read : function(e) {
					if(ctrl.isLoanMode) {
						DebtActionLog.getPossibleActionsByLoan(
								{loan_no : ctrl.loanNo}, 
								function(response) {
									e.success(response);
								}, 
								function(error) {
									console.log('Possible actions for loan list error');
									e.error(error);
								}
						);
					} else {
						DebtActionLog.getPossibleActionsByClient(
								{clnId: ctrl.clnId}, 
								function(response) {
									e.success(response);
								}, 
								function(error) {
									console.log('Possible actions for client list error');
									e.error(error);
								}
						);						
					}
				}
			},
			serverPaging : false,
			serverFiltering : false,
			serverSorting : false,
			sort : {
				field : "number",
				dir : "asc"
			}
		});

		ctrl.infoModel = kendo.data.Model.define({
			id: "loanId",
			fields : {
				"loanId" : {
					type : "number"
				},
				"loanNum" : {
					type : "string"
				},
				"loanDate" : {
					type : "date"
				},
				"loanStatus" : {
					type : "string"
				},
				"loanAmount" : {
					type : "number"
				},
				"loanCrc" : {
					type : "string"
				},
				"loanTrm" : {
					type : "number"
				},
				"inspFullname" : {
					type : "string"
				},
				"loanMonthPay" : {
					type : "number"
				},
				"sstsName" : {
					type : "string"
				},
				"sstsCode" : {
					type : "number"
				},
				"debtSum" : {
					type : "number"
				},
				"debtPen" : {
					type : "number"
				},
				"debtDate" : {
					type : "date"
				},
				"debtDays" : {
					type : "number"
				},
				"lastPaymentDate" : {
					type : "date"
				},	
				"productCode" : {
					type : "string"
				},
				"productName" : {
					type : "string"
				},
				"loanCreatedBy" : {
					type : "string"
				},
				"partnerFullname" : {
					type : "string"
				},
				"clientFullname" : {
					type : "string"
				},
				"clientCode" : {
					type : "string"
				},
				"clientPhones" : {
					type : "string"
				},
				"loanSubstatus" : {
					type : "string"
				},
				"clientEmail" : {
					type : "string"
				}
			}
		});
		
		ctrl.historyDataSource = new kendo.data.DataSource({
			schema : {
				model : {
					id: "id",
					fields : {
						"id" : {
							type : "number"
						},
						"loanNum" : {
							type : "string"
						},
						"crtdOn" : {
							type : "date"
						},
						"crtdBy" : {
							type : "string"
						},
						"sstsName" : {
							type : "string"
						},
						"sstsTitle" : {
							type : "string"
						},
						"sstsCode" : {
							type : "number"
						},
						"subStatus" : {
							type : "number"
						},
						"debtSum" : {
							type : "number"
						},
						"debtPen" : {
							type : "number"
						},
						"debtOther" : {
							type : "number"
						},							
						"paidSum" : {
							type : "number"
						},
						"paidPen" : {
							type : "number"
						},
						"paidOther" : {
							type : "number"
						},							
						"docLink" : {
							type : "string"
						},
						"wakeDate" : {
							type : "date"
						},
						"sstsComment" : {
							type : "string"
						}
					}
				},
				parse: function(d){
                	$.each(d, function (idx, elem) {
                        var loanNum = {
                        		loanNum: elem.loanNum
                		};
                		var foundLoanNum = $filter('filter')(loanNums.data, {loanNum: elem.loanNum}, true);
                        if(foundLoanNum.length < 1) (loanNums.data).push(loanNum);

                        var substatus = {
                        		subStatus: elem.subStatus
                		};
                		var foundSubstatus = $filter('filter')(subStatuses.data, {subStatus: elem.subStatus}, true);
                        if(foundSubstatus.length < 1) (subStatuses.data).push(substatus);

                        var crtdBy = {
                        		crtdBy: elem.crtdBy
                		};
                		var foundCrtdBy = $filter('filter')(crtdBys.data, {crtdBy: elem.crtdBy}, true);
                        if(foundCrtdBy.length < 1) (crtdBys.data).push(crtdBy);
                        
                        var sstsTitle = {
                        		sstsTitle: elem.sstsName
                		};
                		var foundSstsTitle = $filter('filter')(sstsTitles.data, {sstsTitle: elem.sstsName}, true);
                        if(foundSstsTitle.length < 1) (sstsTitles.data).push(sstsTitle);
                    });
                	return d;
				}
				
			},
			transport : {
				read : function(e) {
					
					if(ctrl.isLoanMode) {
						
						DebtActionLog.getHistoryByLoan(
								{loan_no : ctrl.loanNo}, 
								function(response) {
									ctrl.infoDataSource = new kendo.data.DataSource({
										schema : {
											model : ctrl.infoModel
										},
										data: response.loanInfo,
										sort : {
											field : "loanDate",
											dir : "desc"
										}
									});
									ctrl.isNoLoans = response.loanInfo.length > 0 ? false : true ;
									if(!ctrl.isNoLoans) {
										var selected = $filter('filter')(response.loanInfo, {loanNum: ctrl.loanNo}, true);
										if(selected.length > 0) {
											ctrl.fillSelected(selected[0]);
										} else {
											ctrl.isLoanView = false;
											ctrl.selectedLoan = {};
											ctrl.isNoLoans = true;
										}
									} else {
										ctrl.isLoanView = false;
										ctrl.selectedLoan = {};
									}
									if(response.history) e.success(response.history);
									else e.success([]);
								}, 
								function(error) {
									console.log('Action Logs list error for Loan');
									e.error(error);
								}
						);
						
					} else { 
					
						DebtActionLog.getHistoryByClient(
								{clnId : ctrl.clnId}, 
								function(response) {
									ctrl.infoDataSource = new kendo.data.DataSource({
										schema : {
											model : ctrl.infoModel
										},
										data: response.loanInfo,
										sort : {
											field : "loanDate",
											dir : "desc"
										}
									});
									ctrl.isNoLoans = response.loanInfo.length > 0 ? false : true ;
									ctrl.isLoanView = false;
									ctrl.selectedLoan = {};
									if(response.history) e.success(response.history);
									else e.success([]);
								}, 
								function(error) {
									console.log('Action Logs list error for Client');
									e.error(error);
								}
						);
					}
				}
			},
			filter: loanModeFilter,
			serverPaging : false,
			serverFiltering : false,
			serverSorting : false,
			sort : {
				field : "crtdOn",
				dir : "desc"
			}
		});

		ctrl.historyOptions = {
			scrollable: {
	            virtual: true
	        },
	        height: function(){
	        	return ctrl.gridHeight;
	        },
	        rowTemplate: kendo.template($("#rowTemplate").html()),
	        altRowTemplate: kendo.template($("#altRowTemplate").html()),
	        sortable: true,                
	        pageable: {
	        	refresh: true,
	        	previousNext: false,
	        	numeric: false,
	        	info:true,
	        	messages: {
	        		empty: $translate.instant('modules.debtActionLog.noData'),
	        	    }
	        },
			filterable : {
				extra : false,
				operators : {
					string : {
						contains : "contains",
					}
				}
			},
			toolbar : [ {
				template: $("#debt-action-log-header-template").html()		
			} ],
			excel : {
				fileName : "debt-action-log.xlsx",
                allPages: true
            },
            excelExport: function(e) {
            	e.workbook.sheets[0].columns.forEach(function(column){
            		column.autoWidth = true;
            	});
//            	e.workbook.sheets[0].columns = [
//            		{ width:100 },
//            		{ width:300 },
//            		{ width:400 },
//            		// ...
//            	];
                e.workbook.sheets[0].rows.forEach(function(row){
                  if(row.type != 'data'){
                  	row.cells.forEach(function(cell){
                      cell.value = $translate.instant(cell.value);
                      if(cell.value == "<span class='fa fa-user'></span>") cell.value = $translate.instant('modules.debtActionLog.table.crtdBy');
                    });
                  }
            	});
			},
			// column width also set in template and alt-template of row
			columns : [
					{
						field : "loanNum",
						title : "{{'modules.debtActionLog.table.loanNum'|translate}}",
						width : "10ch",
						filterable: false
					},
					{
						field : "crtdOn",
						title : "{{'modules.debtActionLog.table.crtdOn'|translate}}",
						width : "12ch",
				        filterable: {
	                    	ui: "datepicker"
	                    }						
					},
					{
						field : "sstsTitle",
						title : "{{'modules.debtActionLog.table.ssts'|translate}}",
                        filterable: {
                            ui: sstsFilter
                        }
					},
					{
						field : "subStatus",
						title : "{{'modules.debtActionLog.substatus'|translate}}",
						width : "8ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(subStatuses),
                            dataTextField : "subStatus"
						}
					},
					{
						field : "crtdBy",
						title : "<span class='fa fa-user'></span>",
						width : "8ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(crtdBys),
                            dataTextField : "crtdBy"
						}
					},
					{
                        field: "debtSum",
						title : "{{'modules.debtActionLog.table.debtSum'|translate}}",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "9ch",
                        filterable: false
                    },
					{
                        field: "debtPen",
						title : "{{'modules.debtActionLog.table.debtPen'|translate}}",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "12ch",
                        filterable: false
                    }
                    ]
		};

        function sstsFilter(element) {
            element.kendoDropDownList({
                dataSource: new kendo.data.DataSource(sstsTitles),
                dataTextField: "sstsTitle",
                dataValueField: "sstsTitle",
                optionLabel: $translate.instant("modules.debtActionLog.selectStatus")
                	
            });
        }
        
        ctrl.historyDataSource.bind("change", function(e) {
        	if(ctrl.historyDataSource.total() == 0) return;
        	else if(!totalHistSet) {
        		totalHistSet = true;
	       // 	console.log("total " + ctrl.historyDataSource.total());
	        	ctrl.historyDataSource.pageSize(ctrl.historyDataSource.total());
	        	
        	}
        });
	}

})();
