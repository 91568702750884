(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DebtActionLogClientController', DebtActionLogClientController);

    DebtActionLogClientController.$inject = ['$scope', '$state'];

    function DebtActionLogClientController ($scope, $state) {
        var vm = this;
        console.log("DebtActionLogClientController started");
        vm.clnId = $state.params.clnId;
    }
    
})();
