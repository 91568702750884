(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoansController', LoansController);

    LoansController.$inject = ['$window', '$scope', '$state', 'Loan', '$stateParams', 'defaultPageSize', '$translate', '$filter'];

    function LoansController ($window, $scope, $state, Loan, $stateParams, defaultPageSize, $translate, $filter) {
        var vm = this;
        vm.kendo = kendo;
        console.log("LoansController ...");
		var loanTypes = {
				data: []
			};
		var loanStatuses = {
				data: []
			};
		var crtdBys = {
				data: []
		};
        var loansTop = angular.element(".loans-wrap").offset().top;
        var footerHeight = angular.element('.footer').innerHeight();
        vm.height = 0.9 * $window.innerHeight - footerHeight - loansTop;
        
        //workaround, bug in kendo grid footer template angular translation + aggregate value in one cell
        var countString = $translate.instant('modules.loan.table.count');
        
        vm.loansDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			          "id": { type: "number", validation:{required:true} },
			          "loanDate": { type: "date" },
			          "firstName": { type: "string" },
			          "lastName": { type: "string" },
			          "fullName": { type: "string"},
			          "personCode": { type: "string"},
			          "loanNum": { type: "string"},
			          "loanType": { type: "string"},
			          "loanSum": { type: "number" },
			          "crtdBy": { type: "string" },
			          "loanStatus": { type: "string" }
			    	}
				},
                parse: function(d){
                	$.each(d, function (idx, elem) {
                        elem.fullName = elem.firstName + " " + elem.lastName;
                        
                		var loanType = {
                				loanType: elem.loanType
                    	};
	                    var foundLoanType = $filter('filter')(loanTypes.data, {loanType: elem.loanType}, true);
	                    if(foundLoanType.length < 1) (loanTypes.data).push(loanType);
	                        
	                    var loanStatus = {
	                    		loanStatus: elem.loanStatus
	                    };
	                    var foundLoanStatus = $filter('filter')(loanStatuses.data, {loanStatus: elem.loanStatus}, true);
	                    if(foundLoanStatus.length < 1) (loanStatuses.data).push(loanStatus);
	                    
	              		var crtdBy = {
	              				crtdBy: elem.crtdBy
	              		};
	              		var foundCrtdBy = $filter('filter')(crtdBys.data, {crtdBy: elem.crtdBy}, true);
	                    if(foundCrtdBy.length < 1) (crtdBys.data).push(crtdBy);
                        
                    });
                	return d;
                }
			},
			transport: {
				read: function(e){
					
					Loan.query(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('loan list error');
								e.error(error);
							}
					);
				}
			},
            aggregate: [
                        { field: "loanType", aggregate: "count"},
                        { field: "loanSum", aggregate: "sum"}
                      ],
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
			sort: {
				field: "loanDate",
				dir: "desc"
			}
        });
        
        vm.loansOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                height: function(){
    	        	return vm.height;
                },
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: $translate.instant('modules.loan.table.noData'),
                	    }
                },
                toolbar: [
                          { 
		                    template: "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
		                    		 + "<span class='fa fa-file-excel-o'></span>"
		                             + "&nbsp;<span translate='modules.loan.excel'>"
		                             + "</span></button>"
                          }
                ],
                excel: {
                    fileName: "loans.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                          if(cell.value == "<span class='fa fa-user'></span>") cell.value = $translate.instant('modules.loan.table.created');
                        });
                      }
                	});
                },
                columns: [
					{
					    field: "loanType",
					    title: "{{'modules.loan.table.loanType'|translate}}",
					    aggregates: ["count"], 
                        footerTemplate: countString + ": #=count#",
					    width: "16ch",
						filterable : {
                            multi : true,
                            dataSource : new kendo.data.DataSource(loanTypes),
                            dataTextField : "loanType"
						}
					},{ 
						field: "loanStatus", 
						title: "{{'modules.loan.table.loanStatus'|translate}}",
						width: "12ch",
						filterable : {
                            multi : true,
                            dataSource : new kendo.data.DataSource(loanStatuses),
                            dataTextField : "loanStatus"
						}
					},{ 
						field: "loanNum", 
						title: "{{'modules.loan.table.loanNum'|translate}}",
						width: "12ch"
					},{
	                    field: "loanDate",
	                    title: "{{'modules.loan.table.loanDate'|translate}}",
	                    format: "{0:d}",
	                    width: "12ch",
	                    filterable: {
	                    	ui: "datepicker"
	                    }
                    },{
                        field: "fullName",
                        title: "{{'modules.loan.table.fullName'|translate}}"
                    },{
                        field: "personCode",
                        title: "{{'modules.loan.table.personCode'|translate}}",
                        width: "16ch"
                    },{
                        field: "loanSum",
                        title: "{{'modules.loan.table.loanSum'|translate}}",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "11ch",
                        filterable: false,
                        aggregates: ["sum"], 
                        footerTemplate: "#=kendo.toString(sum, 'n2')#",
                        footerAttributes: { style: "text-align: right; " }
                    },{
                        field: "crtdBy",
                        title: "<span class='fa fa-user'></span>",
	                    width: "10ch",
                        filterable : {
			            	multi : true,
			                dataSource : new kendo.data.DataSource(crtdBys),
			                dataTextField : "crtdBy"
                        }
                    },{
                    	title: "{{'modules.loan.actions'|translate}}",
                    	template: "<button has-authority='ROLE_USER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.condition({loan_no: \"#:loanNum#\"})'>"
                            + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                            + "</button>"
                        ,
                        width: "8ch" 
                    }]
        };
        
        function tipasFilter(element) {
            element.kendoDropDownList({
                dataSource: {
                  data: tipai
                },
                optionLabel: "--Pasirink reikðmæ--"
                        });
        } 
        function busenaFilter(element) {
            element.kendoDropDownList({
                dataSource: {
                  data: busenos
                }, optionLabel: "--Pasirink reikðmæ--"
                        });
        } 
        
    }
    
})();
