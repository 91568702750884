(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoansClientController', LoansClientController);

    LoansClientController.$inject = ['$scope', '$state', 'Loan', '$stateParams', 'defaultPageSize', '$translate'];

    function LoansClientController ($scope, $state, Loan, $stateParams, defaultPageSize, $translate) {
        var vm = this;
        vm.kendo = kendo;
        
        var record = 0;
        
        console.log("Loans Client Controller started");
        
        
        
        vm.clientLoansDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			          "id": { type: "number", validation:{required:true} },
			          "loanDate": { type: "date" },
			          "firstName": { type: "string" },
			          "lastName": { type: "string" },
			          "fullName": { type: "string"},
			          "personCode": { type: "string"},
			          "loanNum": { type: "string"},
			          "loanType": { type: "string"},
			          "loanSum": { type: "number" },
			          "loanStatus": { type: "string" }
			    	}
				},
              parse: function(d){
              	$.each(d, function (idx, elem) {
                      elem.fullName = elem.firstName + " " + elem.lastName;
                  });
              	return d;
              }
			},
          aggregate: [
            { field: "loanDate", aggregate: "count"},
            { field: "loanSum", aggregate: "sum"}
          ],
			transport: {
				read: function(e){
					
					Loan.getLoansByClientId(
							{cln: $stateParams.clnId},
							function(response){
								e.success(response);
							},
							function(error){
								console.log('loan list error');
								e.error(error);
							}
					);
				}
			},
			pageSize: defaultPageSize,
			sort: {
				field: "loanDate",
				dir: "desc"
			}
      });
        
        vm.clientLoansOptions = {
        		scrollable: true,
                sortable: true,                
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          {	
                        	name: "excel", 
                        	text: "Excel"
                          }],
                excel: {
                    fileName: "client-loans.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },
                columns: [
					{
	                    field: "loanDate",
	                    title: "{{'modules.loan.table.loanDate'|translate}}",
	                    format: "{0:d}",
	                    width: "11ch",
	                    aggregates: ["count"], 
                        footerTemplate: "{{'modules.loan.table.count' | translate }}: #=count#",
	                    filterable: {
	                    	ui: "datepicker"
	                    }
                    },{ 
						field: "loanNum", 
						title: "{{'modules.loan.table.loanNum'|translate}}",
						width: "14ch"
					},{
                        field: "loanSum",
                        title: "{{'modules.loan.table.loanSum'|translate}}",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "10ch",
                        aggregates: ["sum"], 
                        footerTemplate: "#=kendo.toString(sum, 'n2')#",
                        footerAttributes: { style: "text-align: right; " }
                    },{ 
						field: "loanStatus", 
						title: "{{'modules.loan.table.loanStatus'|translate}}",
						width: "11ch"
					},{
                    	title: "{{'modules.loan.actions'|translate}}",
                    	template: "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.link' | translate }}'\" class='btn btn-default k-button-icontext' ui-sref='modules.loans.current.condition({loan_no: \"#:loanNum#\"})'>"
                            + "<span class='glyphicon glyphicon-link'></span>"
                            + "</button>"
                        ,
                        width: "9ch" 
	                    }]
        };

        
    }
        
})();
