(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modules.change-interest-rate', {
            parent: 'modules',
            url: '/setup/change-interest-rate',
            data: {
            	authorities: ['ROLE_MANAGER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/change-interest-rate/change-interest-rate.template.html',
                    controller: 'ChangeInterestRateController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                	$translatePartialLoader.addPart('modules/changeInterestRate');
                	$translatePartialLoader.addPart('modules/accounting');
                	$translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        });
    }
})();
