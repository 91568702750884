(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailAddressClientController', DetailAddressClientController);

    DetailAddressClientController.$inject = ['$scope', '$state', 'currentClient', '$filter', '$stateParams', 'formHelper', 'detailMode', 'AlertService', '$translate', 'Client'];

    function DetailAddressClientController ($scope, $state, currentClient, $filter, $stateParams, formHelper, detailMode, AlertService, $translate, Client) {
        var vm = this;
        console.log("Detail Address Client Controller started");
        vm.clnId = currentClient[0].id;
        vm.kendo = kendo;
    	
    	vm.isViewOnly = !detailMode.edit;
    	vm.isNew = detailMode.create;
        
    	if(!vm.isViewOnly || vm.isNew) formHelper.setFocus('first-field');
    	
        if($state.params.adrId == "" && !vm.isNew) $state.go('modules.clients.addresses');
        	
        if(!vm.isNew) {	
        	vm.adrId = $state.params.adrId;
	        vm.addresses = currentClient[0].address;
	        vm.currentAddress = angular.copy($filter('filter')(vm.addresses, {id: parseInt($stateParams.adrId)}, true)[0]);
	        var adDate = vm.currentAddress.updtOn > vm.currentAddress.crtdOn ? vm.currentAddress.updtOn : vm.currentAddress.crtdOn;
	        vm.addressDate = kendo.toString(adDate, "d");
	        vm.anotherRecipient = vm.currentAddress.recipient ? true: false;
        } else {
        	vm.anotherRecipient = false;
        	vm.currentAddress = {};
        }   
        
        $scope.$watch(function(){
    		return vm.anotherRecipient;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && !newVal) {
    			vm.oldRecipient = vm.currentAddress.recipient;
    			vm.currentAddress.recipient = "";
    		} else if (newVal != oldVal && newVal && vm.oldRecipient) {
    			vm.currentAddress.recipient = vm.oldRecipient;
    		}
    	});
    
	

        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "UNKNOWN",
    			value: $translate.instant("modules.client.dataStatus.unknown")
    		},{
    			key: "CONFIRMED",
    			value: $translate.instant("modules.client.dataStatus.confirmed")
    		},{
    			key: "INVALID",
    			value: $translate.instant("modules.client.dataStatus.invalid")
    		}]
    	});
        
        vm.typeDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "ERROR",
    			value: $translate.instant("modules.client.addressType.error")
    		},{
    			key: "RESIDENTIAL",
    			value: $translate.instant("modules.client.addressType.residential")
    		},{
    			key: "DECLARED",
    			value: $translate.instant("modules.client.addressType.declared")
    		},{
    			key: "WORK",
    			value: $translate.instant("modules.client.addressType.work")
    		},{
    			key: "OTHER",
    			value: $translate.instant("modules.client.addressType.other")
    		},{
    			key: "CREDITINFO",
    			value: $translate.instant("modules.client.addressType.creditinfo")
    		}]
    	});
        
    	vm.save = function() {
    		vm.currentAddress.fclId = currentClient[0].id;
    		console.log("saving changes in Detail client ...");
    		if(vm.isNew) {
    			Client.createAddress(
    					{clnId: vm.clnId}, vm.currentAddress,
    					function(responce){
    						AlertService.success("modules.client.message.addressCreatedSuccessfully");
    						$state.go('modules.clients.addresses', {}, {reload: true});
    					});
    		} else {
    			Client.updateAddress(
    					{clnId: vm.clnId, adrId: vm.adrId}, vm.currentAddress,
    					function(responce){
    						AlertService.success("modules.client.message.addressUpdatedSuccessfully");
    						$state.go('modules.clients.addresses.view', {adrId: vm.adrId}, {reload: true});
    					});
    		}
    		
		};
		
		vm.cancel = function(){
			if(vm.isNew)
				$state.go("modules.clients.addresses");
			else 
				$state.go("modules.clients.addresses.view",{adrId:vm.adrId}, {reload: true});
		};
    }
})();
