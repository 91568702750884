(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DocsController', DocsController);

   DocsController.$inject = ['$scope', '$state', '$stateParams', '$location', "Docs", "AlertService", 'baseUrl' ];

    function DocsController ($scope, $state, $stateParams, $location, Docs, AlertService, baseUrl) {
        var vm = this;
        console.log("DocsController ...");
       
        vm.savePricelist = function() {
        	console.log("Prasideda savePricelist");
        	if( $("input[form='pricelist']").data("kendoUpload").getFiles().length = 1) {
        		    vm.filename = $("input[form='pricelist']").data("kendoUpload").getFiles()[0].name;
        			
        		    Docs.save({ 
        			   filename : vm.filename,
        			   filetype : "pricelist"
        			}, {},
        			   function(success){
                            AlertService.success("modules.docs.message.pricelistSavedSuccessfully");
                            $("input[form='pricelist']").data("kendoUpload").removeAllFiles();
                    }, function(error){
                            AlertService.error(error.statusText);
                    });
            }
        };
        
        vm.saveConditions = function() {
        	console.log("Start saveConditions");
        	if( $("input[form='conditions']").data("kendoUpload").getFiles().length = 1) {
        		    vm.filename = $("input[form='conditions']").data("kendoUpload").getFiles()[0].name;
        			
        		    Docs.save({ 
        			   filename : vm.filename,
        			   filetype : "conditions"
        			}, {},
        			   function(success){
                            AlertService.success("modules.docs.message.conditionsSavedSuccessfully");
                            $("input[form='conditions']").data("kendoUpload").removeAllFiles();
                    }, function(error){
                            AlertService.error(error.statusText);
                    });
            }
        };
        
        vm.resetPricelist = function(){
            console.log("Clear All uploads");
            $("input[form='pricelist']").data("kendoUpload").removeAllFiles();
        };
        
        vm.resetConditions = function(){
            console.log("Clear All uploads");
            $("input[form='conditions']").data("kendoUpload").removeAllFiles();
        };
        
        vm.validation = {
                allowedExtensions: [".pdf", ".PDF"]
        };
        
        vm.upload = {
        		saveUrl : baseUrl + "api/setup/docs/upload",
        		removeUrl : baseUrl + "api/setup/docs/remove",
        		autoUpload : true
        };
    }
    
})();
