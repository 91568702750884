(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ChangeCardStatusController', ChangeCardStatusController);

    ChangeCardStatusController.$inject = ['$scope', '$state', '$stateParams', "ChangeCardStatus", "AlertService", "$translate", "formHelper"];

    function ChangeCardStatusController ($scope, $state, $stateParams, ChangeCardStatus, AlertService, $translate, formHelper) {
        var vm = this;
        console.log("ChangeCardStatusController starting");
        
        vm.showErrorList = false;

        vm.cardAction = {
        		loans: null,
        		reason: null,
        		status: "",
        		sendSms: false
  		};
        
        vm.statusDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "activate",
    			value: $translate.instant("modules.changeCardStatus.activate")
    		},{
    			key: "block",
    			value: $translate.instant("modules.changeCardStatus.block")
    		}]
    	});
        
      	vm.errorForm = {};
      	
      	vm.maxLoans = 300; // max loans for saving
      	
      	formHelper.setFocus('card-reason');
      	
        vm.save = function() {
        	console.log("Changing loans for Card Status is starting");
        	vm.errorForm = {};
        	var request = {
        			loans: [],
        			reason: null,
            		sendSms: null,
            		status: null
        	};
        	
        	var Loan = function(loanNum) {
    		    this.loanNum = loanNum;
        	};
        	
        	if(vm.cardAction.loans == null || vm.cardAction.loans == "") {
        		AlertService.error('modules.changeCardStatus.message.loansIsNotAvailable');
        		vm.errorForm.loans = true;
        		return;
        	}

        	if(vm.cardAction.reason == null || vm.cardAction.reason == "") {
        		AlertService.error('modules.changeCardStatus.message.reasonIsNotAvailable');
        		vm.errorForm.reason = true;
        		return;
        	}
        	

        	if(vm.cardAction.status != "activate" && vm.cardAction.status != "block") {
        		AlertService.error('modules.changeCardStatus.message.statusIsNotAvailable');
        		vm.errorForm.status = true;
        		return;
        	}

        	request.reason = vm.cardAction.reason;
        	request.status = vm.cardAction.status;
        	request.sendSms = vm.cardAction.sendSms;
        	
        	vm.cardAction.loans.split(/(\r?\n)|((,|;|\.|\/)\W?)|(\.)|(\W+)/).forEach(function(value){
        		if(typeof value != "undefined" 
        			&& value.search(/,/) < 0  
        			&& value.search(/;/) < 0  
        			&& value.search(/\./) < 0 
        			&& value.search(/\r?\n/) < 0 
        			&& value.search(/\W+/) < 0 
        			&& isNaN(value)){
        			
        			var loan = new Loan(value);
        			
        			request.loans.push(loan);
        		}
        	});
        	
        	if(request.loans.length > vm.maxLoans) {
        		AlertService.error('modules.changeInterestRate.message.exceededMaxLoansForSaving', {maxLoans: vm.maxLoans, loans: request.loans.length});
        		return;
        	}

        	ChangeCardStatus.changeCardStatus(
        			request,
					function(response){
        				if(response) {
        					if (response.status == "ok" && response.errorList == "" ){
        						AlertService.success('modules.changeCardStatus.message.successChangeCardStatus');
        						$state.reload();
        					}
        					else{
        						console.log("Error:" + response.errorList);
        						vm.showErrorList = true;
        						vm.errorList = response.errorList;
        					}
        				} else {
        					AlertService.error('modules.changeCardStatus.message.errorChangeInterestRate');
        					return;
        				}
					},
					function(error){
						console.log('interest rates list error');
					}
			);
        };

        vm.cancel = function(){
            console.log("Cancel Change Card Status form");
            $state.reload();
        };
    }
    
})();
