(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('EdocViewController', EdocViewController);

    EdocViewController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$window', '$translate', '$filter', 'baseUrl', 'edocData', 'shortDateFormats'];

    function EdocViewController ($scope, $state, $stateParams, Loan, defaultPageSize, $window, $translate, $filter, baseUrl, edocData, shortDateFormats) {
        var vm = this;
        vm.kendo = kendo;
        vm.currentEdoc = edocData;
        vm.editMode = false;
        vm.shortDateFormats = shortDateFormats;
        
        console.log("EdocViewController started");
      
        vm.displayFile = function(){
        	console.log("openInNewTab(" + vm.currentEdoc.file_id + ")");
       		vm.pdflink = baseUrl + 'api/loans/' + $state.params.loan_no + '/edocs/' + vm.currentEdoc.file_id  + '/file';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
       
    }
})();
