(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('EdocsListController', EdocsListController);

    EdocsListController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$window', '$translate', '$filter', 'baseUrl'];

    function EdocsListController ($scope, $state, $stateParams, Loan, defaultPageSize, $window, $translate, $filter, baseUrl) {
        var vm = this;
        vm.kendo = kendo;
		var documentTypes = {
				data: []
			};
		var statusValues = {
				data: []
		}
        console.log("EdocsListController started");
        vm.loanEdocsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "id",
			        fields: {
			        	file_id: {type: "number"},
			        	crtd_on: {type: "date"},
	                    document_type: {type: "string"},
	                    document_date: {type: "date"},
	                    document_name: {type: "string"},
	                    sign_status: {type: "string"},
	                    sign_date: {type: "date"},
	                    ver: {type: "number"}
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	               //         elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                 //       console.log(elem);
	                        var docType = {
	                        		document_type: elem.document_type
		              		};
		              		var found = $filter('filter')(documentTypes.data, {document_type: elem.document_type}, true);
		                    if(found.length < 1) {
		                    	(documentTypes.data).push(docType);
		                    }
	                        
	                        var statusType = {
	                        		sign_status: elem.sign_status
		              		};
		              		var found = $filter('filter')(statusValues.data, {sign_status: elem.sign_status}, true);
		                    if(found.length < 1) {
		                    	(statusValues.data).push(statusType);
		                    }
		                    
	                    });
	                	return d;
	              }
    		},
    		transport: {
				read: function(e){

					Loan.getEdocs(
							{loan_no : $state.params.loan_no},
							function(response){
								e.success(response);
							},
							function(error){
								console.log('edocs list error');
								e.error(error);
							}
					);
				}
			},
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "file_id",
                dir: "desc"
            }
        });
        
        vm.loanEdocsOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
                height: function(){
                	return $window.innerHeight/3;
                },                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.edocs'></span>"
  		                    		 + "<button has-authority='ROLE_MANAGER' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-electronic-documents.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                            cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },  
                columns: [
                          { 
                        	  field: "file_id", 
                        	  title: "{{'modules.loan.table.fileId'|translate}}",
                        	  format: "{0:d}", 
                        	  filterable: false,
                        	  width: "7ch"
                          },{ 
                        	  field: "crtd_on", 
                        	  title: "{{'modules.loan.table.crtdOn'|translate}}",
                        	  format: "{0:g}", 
                        	  width: "17ch",
                        	  minScreenWidth: 992
                          },{ 
                        	  field: "document_type", 
                        	  title: "{{'modules.loan.table.documentType'|translate}}",
		                      filterable : {
					          	multi : true,
					            dataSource : new kendo.data.DataSource(documentTypes),
					            dataTextField : "document_type"
		                      },
                        	  width: "25ch"
                          },{ 
                        	  field: "document_name", 
                        	  title: "{{'modules.loan.table.documentName'|translate}}",
                        	  minScreenWidth: 992
                          },{ 
                        	  field: "sign_status", 
                        	  title: "{{'modules.loan.table.signStatus'|translate}}",
                        	  format: "{0:g}", 
                        	  width: "14ch",
                        	  filterable : {
  					          	multi : true,
  					            dataSource : new kendo.data.DataSource(statusValues),
  					            dataTextField : "sign_status"
  		                      },
                        	  minScreenWidth: 992
                          },{
                        	  template: "<button has-authority='ROLE_MANAGER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.edocs.view({file_id: #:file_id#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                + "<button has-authority='ROLE_MANAGER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.openInNewTab' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ng-click='vm.openInNewTab(#:file_id#)' >"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-open-file'></span>"
                                + "</button>"
                                
                                ,
		                        title: "&nbsp;", 
		                        width: "8ch" 
                          }
                ]
        };
      
        vm.openInNewTab = function(fileId){
        	console.log("openInNewTab(" + fileId + ")");
       		vm.pdflink = baseUrl + 'api/loans/' + $state.params.loan_no + '/edocs/' + fileId + '/file';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
       
    }
})();
