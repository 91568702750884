(function() {
	'use strict';
	angular.module('sigmaApp').factory('Loan', Loan);

	Loan.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Loan($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/loans/:loan_no';
		
		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'activate' : {
						method : 'POST',
						url: resourceUrl + "/activate"
					},
					'annulatePenalty' : {
						method : 'POST',
						transformRequest : function(data) {
							data.onDate = DateUtils.convertLocalDateToServer(data.onDate);
							return angular.toJson(data);
						},
						url: resourceUrl + "/penalties"
					},
					'prepare' : {
						method : 'POST',
						url: resourceUrl + "/prepare"
					},
					'create' : {
						method : 'POST',
						url: baseUrl + 'api/loans',
						transformRequest : function(data) {
							//data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
								if(response.err === "Error") {
									var msg = "";
									angular.forEach(response.message.split('|'), function(item){
										msg += $filter('translate')(item) + '\n'; 
									});
									response.message = msg;
								}
							}
							return response;
						}
					},

					'delete' : {
						method : 'DELETE'
					},
					'get' : {
						method : 'GET',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getLoansByClientId' : {
						method : 'GET',
						url: baseUrl + 'api/loans/byclientid',
						isArray : true,
						params:{cln: null},
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getPayments' : {
						method : 'GET',
						url: resourceUrl + "/payments", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getAccrues' : {
						method : 'GET',
						url: resourceUrl + "/acr", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getSchedules' : {
						method : 'GET',
						url: resourceUrl + "/schedules", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getSchedule' : {
						method : 'GET',
						url: resourceUrl + "/schedules/:num", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getOperations' : {
						method : 'GET',
						url: resourceUrl + "/operations", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getInterests' : {
						method : 'GET',
						url: resourceUrl + "/interestrates", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getComponentByLoanNo' : {
						method : 'GET',
						url: resourceUrl + "/accounting/pap", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getRateComponentByLoanNo' : {
						method : 'GET',
						url: resourceUrl + "/accounting/rate-components", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updateInterest': {
						method:'PUT',
						url: resourceUrl + "/interestrates", 
						transformRequest : function(data) {
							data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
							return angular.toJson(data);
						}
					},
					'createInterest': {
						method:'POST',
						url: resourceUrl + "/interestrates",
						transformRequest : function(data) {
							data.dateFrom = DateUtils.convertLocalDateToServer(data.dateFrom);
							return angular.toJson(data);
						}
					},
					'deleteInterest': {
						method:'DELETE',
						url: resourceUrl + "/interestrates/:rateId"
					},
					'getHistory' : {
						method : 'GET',
						url: resourceUrl + "/history", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getTransactions' : {
						method : 'GET',
						url: resourceUrl + "/accounting/transactions", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getTransaction' : {
						method : 'GET',
						url: baseUrl + "api/accounting/transactions/:cdh_id",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getDebtsByLoanNoAndDate' : {
						method : 'GET',
						url: resourceUrl + "/debts/:to_date", 
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
									console.log(data);
									if (data.gracePerFrom){
										data.gracePerFrom = DateUtils.convertLocalDateToServer(data.gracePerFrom);
										console.log(DateUtils.convertLocalDateToServer(data.gracePerFrom));
									}
									if (data.gracePerTill)
										data.gracePerTill = DateUtils.convertLocalDateToServer(data.gracePerTill);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'updateTransaction' : {
						method : 'PUT',
						url: baseUrl + "api/accounting/transactions",
						isArray : false,
						transformRequest : function(data) {
							data.date = DateUtils.convertLocalDateToServer(data.date);
							return angular.toJson(data);
						}
					},
					'getBalance' : {
						method : 'GET',
						url: resourceUrl + "/accounting/balances/:balDate",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'synchronization': {
						method:'POST',
						url: resourceUrl + '/sync'
					},
					'sendToEsign' : {
						method : 'POST',
						url: resourceUrl + "/sendToEsign"
					},
					'getEdocs' : {
						method : 'GET',
						url: resourceUrl + "/edocs", 
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getEdoc' : {
						method : 'GET',
						url: resourceUrl + "/edocs/:file_id", 
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
									if (data.sign_date)
										data.sign_date = DateUtils.convertDateTimeFromServer(data.sign_date);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getPenStopDate' : {
						method : 'GET',
						url: resourceUrl + "/pen-stop-date",
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'bookAndCloseOnDate': {
						method:'POST',
						url: resourceUrl + "/bookAndCloseActiveLoan"
					},					
					'setPenStopDate': {
						method:'POST',
						url: resourceUrl + "/pen-stop-date"
					},
				});
	}
})();