(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ChangeInterestRateController', ChangeInterestRateController);

    ChangeInterestRateController.$inject = ['$scope', '$state', '$stateParams', '$location', "ChangeInterestRate", "AlertService", "$translate", "formHelper", "DateUtils"];

    function ChangeInterestRateController ($scope, $state, $stateParams, $location, ChangeInterestRate, AlertService, $translate, formHelper, DateUtils) {
        var vm = this;
        console.log("ChangeInterestRateController starting");
      	vm.dateFormat = kendo.culture().calendar.patterns.d;
      	vm.interestRate = {
      			loans: null,
      			reason: null,
      			dateFrom: null,
      			dateTill: null
      	};
      	vm.errorForm = {};
      	
      	vm.maxLoans = 300; // max loans for saving
      	
      	vm.grid = "int-grid";
      	formHelper.setFocus('interest-reason');
      	
        vm.componentInterestDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
	                    dateFrom: {type: "date"},
	                    value: {type: "number", validation: { min: 0 } },
	                    minPayAmt: {type: "number", validation: { min: 0 }},
	                    component: {type: "string", editable: false},
	                    componentTr: {type: "string", editable: false}
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                    });
	                	return d;
	              }
    		},
    		transport: {
				read: function(e){

					ChangeInterestRate.getInterestRates(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('interest rates list error');
								e.error(error);
							}
					);
				}
			},
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "component",
                dir: "asc"
            }
        });
        
        vm.componentInterestOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
                //height: auto,                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: false,
//                toolbar: [
//                          { 
//  		                    template: "<span style='line-height:22px;' translate='modules.changeInterestRate.interestRates'></span>"
//                            }
//                ],
                columns: [
                          { 
                        	  field: "component", 
                        	  title: "{{'modules.changeInterestRate.component'|translate}}",
                        	  width: "18ch"
                          },{ 
                        	  field: "value", 
                        	  title: "{{'modules.changeInterestRate.rate'|translate}}",
                        	  width: "16ch", 
                        	  format: "{0:n3}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "componentTr", 
                        	  title: "{{'modules.changeInterestRate.componentTr'|translate}}"
                          }
                ],
                editable: true
        };
        
        vm.componentPrincipalDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
	                    dateFrom: {type: "date"},
	                    value: {type: "number", validation: { min: 0 } },
	                    minPayAmt: {type: "number", validation: { min: 0 }},
	                    component: {type: "string", editable: false},
	                    componentTr: {type: "string", editable: false}
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                    });
	                	return d;
	              }
    		},
    		transport: {
				read: function(e){

					ChangeInterestRate.getPrincipalRates(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('interest rates list error');
								e.error(error);
							}
					);
				}
			},
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "component",
                dir: "asc"
            }
        });
        
        vm.componentPrincipalOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
                //height: auto,                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: false,
//                toolbar: [
//                          { 
//  		                    template: "<span style='line-height:22px;' translate='modules.changeInterestRate.interestRates'></span>"
//                            }
//                ],
                columns: [
                          { 
                        	  field: "component", 
                        	  title: "{{'modules.changeInterestRate.component'|translate}}",
                        	  width: "18ch"
                          },{ 
                        	  field: "value", 
                        	  title: "{{'modules.changeInterestRate.rate'|translate}}",
                        	  width: "16ch", 
                        	  format: "{0:n3}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "minPayAmt", 
                        	  title: "{{'modules.changeInterestRate.minPayAmt'|translate}}",
                        	  width: "16ch", 
                        	  format: "{0:n2}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "componentTr", 
                        	  title: "{{'modules.changeInterestRate.componentTr'|translate}}"
                          }
                ],
                editable: true
        };
        
        vm.tabSelected = function(grid) {
        	vm.grid = grid;
        };
        
        vm.save = function() {
        	console.log("Changing loans for Interest Rate is starting");
        	vm.errorForm = {};
        	var request = {
        			dateFrom: null,
        			dateTill: null,
        			reason: null,
        			rates: [],
        			loans: []
        	};
        	var Rate = function(dateFrom, component, value, minPayAmt){
        			this.dateFrom = DateUtils.convertLocalDateToServer(dateFrom);
        			this.component = component;
        			this.value = value;
        			this.minPayAmt = minPayAmt;
        	};
        	var Loan = function(dateFrom, loanNum) {
        			this.dateFrom = DateUtils.convertLocalDateToServer(dateFrom);
        		    this.loanNum = loanNum;
        	};
        	
        	if(vm.interestRate.loans == null || vm.interestRate.loans == "") {
        		AlertService.error('modules.changeInterestRate.message.loansIsNotAvailable');
        		vm.errorForm.loans = true;
        		return;
        	}

        	if(vm.interestRate.reason == null || vm.interestRate.reason == "") {
        		AlertService.error('modules.changeInterestRate.message.reasonIsNotAvailable');
        		vm.errorForm.reason = true;
        		return;
        	}
        	
        	if(vm.interestRate.dateFrom == null) {
        		AlertService.error('modules.changeInterestRate.message.dateFromIsNotAvailable');
        		vm.errorForm.dateFrom = true;
        		return;
        	}

        	if(vm.interestRate.dateTill == null) {
        		AlertService.error('modules.changeInterestRate.message.dateTillIsNotAvailable');
        		vm.errorForm.dateTill = true;
        		return;
        	}

        	if(vm.interestRate.dateTill < vm.interestRate.dateFrom) {
        		AlertService.error('modules.changeInterestRate.message.dateTillIsBeforeDateFrom');
        		vm.errorForm.dateTill = true;
        		return;
        	}
        	
        	request.dateFrom = DateUtils.convertLocalDateToServer(vm.interestRate.dateFrom);
        	request.dateTill = DateUtils.convertLocalDateToServer(vm.interestRate.dateTill);
        	request.reason = vm.interestRate.reason;
        	
        	vm.interestRate.loans.split(/(\r?\n)|((,|;|\.|\/)\W?)|(\.)|(\W+)/).forEach(function(value){
        		if(typeof value != "undefined" 
        			&& value.search(/,/) < 0  
        			&& value.search(/;/) < 0  
        			&& value.search(/\./) < 0 
        			&& value.search(/\r?\n/) < 0 
        			&& value.search(/\W+/) < 0 
        			&& isNaN(value)){
        			
        			var loan = new Loan(vm.interestRate.dateFrom, value);
        			
        			request.loans.push(loan);
        		}
        	});
        	
        	if(request.loans.length > vm.maxLoans) {
        		AlertService.error('modules.changeInterestRate.message.exceededMaxLoansForSaving', {maxLoans: vm.maxLoans, loans: request.loans.length});
        		return;
        	}
        	
        	var isRateNotSet = false;
        	var rateError = "";
        	
        	if (vm.grid == "int-grid"){
        		angular.forEach(vm.componentInterestDataSource.data(), function(value, key) {
            		if(value.value != null) {
    	        		var rate = new Rate(vm.interestRate.dateFrom, value.component, value.value, value.minPayAmt);
    	        		request.rates.push(rate);
            		} else 
            			isRateNotSet = true;
            			rateError = 'modules.changeInterestRate.message.oneOfTheRateIsNotSet';
            	});
        	}
        	
        	if (vm.grid == "prn-grid"){
        		angular.forEach(vm.componentPrincipalDataSource.data(), function(value, key) {
            		if(value.value != null && value.minPayAmt != null) {
    	        		var rate = new Rate(vm.interestRate.dateFrom, value.component, value.value, value.minPayAmt);
    	        		request.rates.push(rate);
            		} else 
            			isRateNotSet = true;
            			rateError = 'modules.changeInterestRate.message.minPayAmtOrRateIsNolFilled';
            	});
        	}
        	
        	if(isRateNotSet) {
    			AlertService.error(rateError);
        		return;
    		}
        	
        	var requestWrapper = {
        			request: {}
        	};
        	requestWrapper.request = request;
        	
        	ChangeInterestRate.createInterestRates(
        			request,
					function(response){
        				if(response) {
	        				AlertService.success('modules.changeInterestRate.message.successChangeInterestRate');
	        				$state.reload();
        				} else {
        					AlertService.error('modules.changeInterestRate.message.errorChangeInterestRate');
        					return;
        				}
					},
					function(error){
						console.log('interest rates list error');
					}
			);
        };
        

        $scope.$watch(
        		function(){
        			return vm.interestRate.reason;
        		}, 
        		function(newApp,oldApp) {
        			if(newApp != oldApp) {
	        			if(newApp != null && newApp != "")
	        				vm.errorForm.reason = false;
	        			else
	        				vm.errorForm.reason = true;
        			}
        		}
        );
        
        $scope.$watch(
        		function(){
        			return vm.interestRate.loans;
        		}, 
        		function(newApp,oldApp) {
        			if(newApp != oldApp) {
	        			if(newApp != null && newApp != "")
	        				vm.errorForm.loans = false;
	        			else
	        				vm.errorForm.loans = true;
        			}
        		}
        );
        
        $scope.$watch(
        		function(){
        			return vm.interestRate.dateFrom;
        		}, 
        		function(newApp,oldApp) {
        			if(newApp != oldApp) {
	        			if(newApp != null && newApp != "")
	        				vm.errorForm.dateFrom = false;
	        			else
	        				vm.errorForm.dateFrom = true;
        			}
        		}
        );
        
        $scope.$watch(
        		function(){
        			return vm.interestRate.dateTill;
        		}, 
        		function(newApp,oldApp) {
        			if(newApp != oldApp) {
	        			if(newApp != null && newApp != "" && newApp >= vm.interestRate.dateFrom)
	        				vm.errorForm.dateTill = false;
	        			else
	        				vm.errorForm.dateTill = true;
        			}
        		}
        );
        
        
        vm.cancel = function(){
            console.log("Cancel Change Interest Rate form");
            $state.reload();
        };
        
        

    }
    
})();
