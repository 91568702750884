(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DateDebtsLoanController', DateDebtsLoanController);

    DateDebtsLoanController.$inject = ['$scope', '$state', 'Loan', 'shortDateFormats', '$uibModalInstance'];

    function DateDebtsLoanController ($scope, $state, Loan, shortDateFormats, $uibModalInstance) {
        var vm = this;
        vm.kendo = kendo;
        
        console.log("DateDebtsLoanController started");
        
        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorDebtsToDate");
        	return;
        }
        
        if(typeof $state.params.toDate == 'undefined') {
        	vm.toDate = new Date();
        } else {
        	vm.toDate = new Date($state.params.toDate) != "Invalid Date" ? new Date($state.params.toDate) : new Date();
        }
        
        vm.shortDateFormats = shortDateFormats;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        vm.loanNo = $state.params.loan_no;
        
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.calculate  = function(loanNo,toDate) {
    		Loan.getDebtsByLoanNoAndDate(
    				{loan_no : loanNo, to_date : toDate.toLocaleDateString('lt-LT')},
    				function(response){
    					console.log(response);
    					vm.debts = response;
    				},
    				function(error){
    					console.log('debts error');
    					console.log(error);
    				}
    		);
        };
        
        vm.calculate(vm.loanNo, vm.toDate);

        vm.formatDate = formatDate;
        function formatDate(dt) {
        	return vm.kendo.toString(new Date(dt), 'd');
        }
    }	
})();
