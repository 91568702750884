(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterPersonDetailController', WallesterPersonDetailController);

    WallesterPersonDetailController.$inject = ['$scope', '$state', 'Wallester', 'formHelper', 'defaultValue', '$translate', '$rootScope', 'AlertService', 'shortDateFormats', '$element', 'detailMode', 'cardHolder', 'cardHolderAddresses', '$filter', 'menuWithHeader', 'defaultNonamePrefix', 'Principal'];

    function WallesterPersonDetailController ($scope, $state, Wallester, formHelper, defaultValue, $translate, $rootScope, AlertService, shortDateFormats, $element, detailMode, cardHolder, cardHolderAddresses, $filter, menuWithHeader, defaultNonamePrefix, Principal) {
        var vm = this;
        vm.kendo = kendo;
    	vm.shortDateFormats = shortDateFormats;
    	vm.phoneNoError = true;
    	
    	vm.canEdit = detailMode.edit ? detailMode.edit : false;
    	vm.canCreate = detailMode.create ? detailMode.create : false;
		vm.canNoname = detailMode.noname ? detailMode.noname : false;
		vm.canCreateAnotherCard = detailMode.createAnotherCard ? detailMode.createAnotherCard : false;
		vm.canNonameCardGenerate = detailMode.nonameCardGenerate ? detailMode.nonameCardGenerate : false;
		
		vm.readOnlyEmail = !(vm.canEdit || vm.canCreate || vm.canNonameCardGenerate);
		vm.displayDeliveryAddress = false;
		
    	Principal.hasAuthority("ROLE_PARTNER").then(function (result) {
            if (result) {
        		vm.editCreateNotGenerate = true;
        		vm.readOnly = !(vm.canCreate || vm.canNonameCardGenerate);;
        		
            } else {
        		vm.editCreateNotGenerate = vm.canNonameCardGenerate ? false : !vm.canEdit || vm.canCreate ;
        		vm.readOnly = !(vm.canEdit || vm.canCreate || vm.canNonameCardGenerate);
            }
        });
		
        console.log("Card Holder Detail Loan Controller started, canEdit: " + vm.canEdit + ", canCreate: " + vm.canCreate + ", canNoname: " + vm.canNoname + ", canCreateAnotherCard: " + vm.canCreateAnotherCard);
        
        var declaredAddress = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "DECLARED"}, false)[0] : null;
        var residentialAddress = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "RESIDENTIAL"}, false)[0] : null;
        var branchAddresses = cardHolderAddresses != null ? $filter('filter')(cardHolderAddresses, {type: "BRANCH"}, false) : null;
        vm.selectedBranch = cardHolderAddresses != null ? $filter('filter')(branchAddresses, {isDefault: true}, false)[0] : null;

        
        if(vm.canCreate || vm.canNoname) {
        	vm.holder = defaultValue != null ? defaultValue : {};
        } else if(vm.canNonameCardGenerate) {
        	console.log("Noname generate");
        	vm.firstFreeRefPref = $filter('uppercase')(defaultNonamePrefix);
        	vm.holder = defaultValue != null ? defaultValue : {};
        	vm.firstFreeRefNumPart = defaultValue.firstFreeRefNumPart != null ? defaultValue.firstFreeRefNumPart : 1;
        	vm.numberOfCards = defaultValue.numberOfCards != null ? defaultValue.numberOfCards : 1;
        } else {
        	vm.holder = cardHolder != null ? angular.copy(cardHolder) : {};
        }

        if(vm.canEdit || (vm.canCreate && !vm.canNoname) || vm.canNonameCardGenerate) formHelper.setFocus('first-field');
        else if(vm.canNoname) formHelper.setFocus('token-field');
        
    	vm.dateFormat = kendo.culture().calendar.patterns.d;
    	vm.patternNationality = "[a-zA-Z]{2}|[a-zA-Z]{3}";

        vm.isPepValue = "1";
        if (vm.holder && vm.holder.isPep != null)
        	vm.isPepValue = vm.holder.isPep ? "2" : "1";
        vm.isBeneficialOwnerValue = "2";
        if (vm.holder && vm.holder.isBeneficialOwner != null)
        	vm.isBeneficialOwnerValue = vm.holder.isBeneficialOwner ? "2" : "1";
        vm.isSanctionsRelatedValue = "1";
        if (vm.holder && vm.holder.isSanctionsRelated != null)
        	vm.isSanctionsRelatedValue = vm.holder.isSanctionsRelated ? "2" : "1";
        vm.isAdverseMediaInvolvedValue = "1";
        if (vm.holder && vm.holder.isAdverseMediaInvolved != null)
        	vm.isAdverseMediaInvolvedValue = vm.holder.isAdverseMediaInvolved ? "2" : "1";
        
        console.log("vm.holder");
        console.log(vm.holder);
        console.log(vm.isAdverseMediaInvolvedValue);


        
        vm.setDelivery = function() {
        	changeAddress();
        };
        
        vm.cancel = function(){
        	console.log('cancel');
        	if(detailMode.create || detailMode.noname) $state.go('modules.loans.current.condition');
        	if(detailMode.edit) {
        		vm.holder = cardHolder != null ? angular.copy(cardHolder) : {};
        		$state.go('modules.loans.current.wallester-person');
        	}
        };
 /*       	
        vm.save	= function(){
        	console.log('save');
        	if(!validate()) return;

	        Card.createHolder(
	        		{loan_no : $state.params.loan_no},
	        		vm.holder,
	        		function(result){
			     		if(result) {
			     			AlertService.success("modules.loan.message.createSuccessfully");
			     			vm.holder = result;
			     			$state.go('modules.loans.current.wallester-person',{},{reload:true});
			     		}
		     		},
		     		function(error){
		     			console.log(error);
		     		}
	        ).$promise;
        };
 */
        /*
        vm.saveNoname	= function(){
        	console.log('save with noname card');
        	vm.saveNonameClicked = true;
        	if(!validate()) return;

        	if(typeof vm.token == 'undifined' || vm.token == "" || vm.token == null || vm.token.length != 9) {
     			AlertService.error("modules.loan.message.wrongOrEmptyToken");
     			vm.saveNonameClicked = false;
     			return;
        	}
        	var pleaseWaitCardJoiningID = AlertService.info("modules.loan.message.pleaseWaitCardJoining", { loan_no: $state.params.loan_no});
        	Card.linkNonameCard(
	        		{loan_no : $state.params.loan_no, token : vm.token},
	        		vm.holder,
	        		function(result){
	        			AlertService.closeAlert(pleaseWaitCardJoiningID.id, null);
			     		AlertService.success("modules.loan.message.linkNonameSuccessfully");
			     		vm.holder = result;
			     		$state.go('modules.loans.current.wallester-person',{},{reload:true});
		     		},
		     		function(error){
		     			vm.saveNonameClicked = false;
		     			AlertService.closeAlert(pleaseWaitCardJoiningID.id, null);
		     			console.log(error);
		     		}
	        ).$promise;
        };
        */
        /*
        vm.nonameCardsGenerate = function() {
        	console.log('Generate noname cards');
        	if(!validate()) return;
        	
        	vm.holder.firstFreeRefNumPart = vm.firstFreeRefNumPart;
        	vm.holder.numberOfCards = vm.numberOfCards;
        	vm.holder.refPrefix = vm.firstFreeRefPref;
        	
        	Card.nonameCardGenerate(
	        		vm.holder,
	        		function(result){
			     		if(result) {
			     			AlertService.success("modules.loan.message.cardsIsPreparedForGenerate");
			     			$state.reload();
			     		}
		     		},
		     		function(error){
		     			console.log(error);
		     		}
	        ).$promise;
        	
        };
        */
        /*
        vm.saveAnotherCard = function(){
        	console.log('save with another card');

        	Card.createAnotherCard(
	        		{loan_no : $state.params.loan_no},
	        		{},
	        		function(result){
			     		if(result) {
			     			AlertService.success("modules.loan.message.successCreateAnotherCard", { loan_no: $state.params.loan_no });
			     			vm.holder = result;
			     			$state.go('modules.loans.current.cards',{},{reload:true});
			     		}
		     		},
		     		function(error){
		     			console.log(error);
		     		}
	        ).$promise;
        };
        */
        
	    vm.update = function(){
	    	if(!validateForUpdate()) return;
        	Wallester.updatePerson(
            		{loan_no : $state.params.loan_no},
            		vm.holder,
            		function(result){
    		    		if(result) {
    		    			AlertService.success("modules.loan.message.updateSuccessfully");
    		    			vm.holder = result;
    		    			$state.go('modules.loans.current.wallester-person',{},{reload:true});
    		    		}
    	    		},
    	    		function(error){
    	    			console.log(error);
    	    		}
            ).$promise;
        };
        
        vm.edit	= function(){
        	console.log('edit');
        	$state.go('modules.loans.current.wallester-person.edit');
        };
        
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });

        vm.titleDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "Mr.",
    			value: "Mr."
    		},{
    			key: "Miss",
    			value: "Miss"
    		},{
    			key: "Mrs.",
    			value: "Mrs."
    		},{
    			key: "Ms.",
    			value: "Ms."
    		}]
    	});

        vm.sexDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "FEMALE",
    			value: $translate.instant("modules.loan.female")
    		},{
    			key: "MALE",
    			value: $translate.instant("modules.loan.male")
    		}]
    	});

        vm.expectedTurnoverDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "From0to1500",
    			value: "From 0 to 1500"
    		},{
    			key: "From1501to3000",
    			value: "From 1501 to 3000"
    		},{
    			key: "From3001to5000",
    			value: "From 3001 to 5000"
    		},{
    			key: "From5001to15000",
    			value: "From 5001 to 15000"
    		},{
    			key: "From15001AndMore",
    			value: "From 15001 And More"
    		}]
    	});
        
        vm.riskDataSource = new kendo.data.DataSource({
    		data: [
			{
    			key: "Low",
    			value: $translate.instant("modules.loan.riskLow")
    		},{
    			key: "Medium",
    			value: $translate.instant("modules.loan.riskMedium")
    		},{
    			key: "High",
    			value: $translate.instant("modules.loan.riskHigh")
    		}]
    	});

        
        vm.branchChange = function(){
//        	console.log('TEST');
        };
        var widthCombo = 0;
        vm.branchDataSource = new kendo.data.DataSource({
        	schema: {
				model: {
			        id: "id",
			        fields: {
			          "id": { type: "number"},
			          "house": { type: "string" },
			          "labelName": { type: "string" },
			          "place": { type: "string" },
			          "postCode": { type: "string"},
			          "stateCode": { type: "string"},
			          "street": { type: "string"},
			          "addressLine2": { type: "string"},
			          "fullAddress": { type: "string" },
			    	}
				},
                parse: function(d){
	              	$.each(d, function (idx, elem) {
	              		  var itemWidth =  elem.labelName.length + elem.fullAddress.length + 2;
	              		  widthCombo = widthCombo > itemWidth ? widthCombo : itemWidth;   
	                  });
	              	return d;
	            }
			},
        	data: branchAddresses
    	});
        
        vm.loadAddress = function(selcted){
        	vm.addressOfDelivery = selcted;
        	switch(vm.addressOfDelivery) {
        	
        		case 0:
        			loadAddressInForm(residentialAddress);
        			break;
        		case 1:
        			loadAddressInForm(declaredAddress);
        			break;
        		case 2:
        			if(typeof vm.selectedBranch == 'undefined') {
        	        	AlertService.error("modules.loan.message.addressOfDeliveryIsNotSelected");
        	        	return;
        	        }
        			loadAddressInForm(vm.selectedBranch);
        			break;
        	}
        };
        
        vm.branchOptions = {
        		template: "<strong>#:labelName#</strong>.&nbsp;#:fullAddress#",
        		open: function(e){
        			e.sender.list[0].style.minWidth = widthCombo + "ch";
        		},
        		select: function(e) {
        			vm.addressOfDelivery = 2;
        			loadAddressInForm(e.dataItem);
        			return;
        		}
        };
        
        function loadAddressInForm(selectedAddress) {
        	vm.holder.comments = typeof declaredAddress != 'undefined' ? declaredAddress.fullAddress : "";
        	if(typeof selectedAddress != 'undefined' && selectedAddress != null) {
	        	vm.holder.address1 = selectedAddress.street + " " +  selectedAddress.house;
	        	vm.holder.address2 = selectedAddress.addressLine2;
	        	vm.holder.town = selectedAddress.place;
	        	vm.holder.postCode = selectedAddress.postCode;
	        	vm.holder.countryCode = selectedAddress.stateCode;
        	} else {
        		vm.holder.address1 = "";
        		vm.holder.address2 = "";
	        	vm.holder.town = "";
	        	vm.holder.postCode = "";
	        	vm.holder.countryCode = "";
        	}
        }

        
        $scope.$watch(
        		function() { 
        			return vm.holder.sex;
	        	}, 
	        	function(newVal, oldVal) {
		        	if(vm.holder.title != null) {
		        		if(newVal == "F") vm.holder.title = "Ms.";
		        		if(newVal == "M") vm.holder.title = "Mr.";
		        	}
        		
	        	}
        );
        
        function validateForUpdate() {
        	
        	// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	var isPersonValid = true;
        	
        	
        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  
	                  if ($input.is("input[data-role=datepicker]")) {
    	                  if($input.context.required && $input.data("kendoDatePicker").value() === null) {
    	                	  isPersonValid = false;
    	                	  $formGroup.toggleClass('has-error', true);
    	                  } 
    	              }
	                  
	                  if($input[0].tagName == "BC-PHONE-NUMBER") {
	                	  if($input.hasClass('ng-invalid')) eval('vm.phoneNoError = false;');
	                	  eval('vm.cardHolderForm.mobPhone.$setValidity("phoneNumber", true);');
	                	  return true;
	                  }
	                  
	                  if($input.hasClass('ng-invalid') || $input.hasClass('k-invalid')) $formGroup.toggleClass('has-error', true);
	              });
	            }
        			
        	});
        	
        	console.log("isPersonValid=" + isPersonValid + ", vm.carholderForm.invalid=" +  vm.cardHolderForm.$invalid + ", vm.validator.validate=" + vm.validator.validate());
        	if(!isPersonValid || vm.cardHolderForm.$invalid || !vm.validator.validate()){
        		AlertService.error("modules.loan.message.errorInForm");
        	//	vm.validator.showValidationSummary();
        		return false;
        	}
        	
        	return true;
        }
        
    }
    
})();
