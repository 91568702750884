(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HeaderLoanController', HeaderLoanController);

    HeaderLoanController.$inject = ['$scope', '$state', 'menuWithHeader', '$translate'];

    function HeaderLoanController ($scope, $state, menuWithHeader, $translate) {
        var vm = this;
        console.log("Header Loan Controller started");
        
        vm.loan = menuWithHeader[0];
        
        vm.cardStatusInfo= "";
        if (vm.loan.activeCardStatus != null){
        	vm.cardStatusInfo = ", " + vm.loan.activeCardStatus;
        	
        	if (vm.loan.cardStatusDate != null)
        		vm.cardStatusInfo = vm.cardStatusInfo + " no " + kendo.toString( new Date(vm.loan.cardStatusDate), 'd');
        	
        }

        vm.fullName = vm.loan.firstName + " " + vm.loan.lastName;
        vm.copyFullNameClp = function (){
        	copyTextToClipboard(vm.fullName);
        };

        vm.copyPersonCodeClp = function (){
        	copyTextToClipboard(vm.loan.personCode);
        };
       
        if (vm.loan.status == "TERMINATED"){
           	vm.isTerminated = true;
           	vm.loanTermDate = $translate.instant('modules.loan.from') + " " + kendo.toString( new Date(vm.loan.loanStopDate), 'd'); 
        }
        else if (vm.loan.status == "REPAID"){
           	vm.isRepaid = true;
           	vm.loanRepaidDate = $translate.instant('modules.loan.from') + " " + kendo.toString( new Date(vm.loan.loanRepaidDate), 'd'); 
        }
        else if (vm.loan.status == "CLOSED"){
           	vm.isClosed = true;
           	vm.loanCloseDate = $translate.instant('modules.loan.from') + " " + kendo.toString( new Date(vm.loan.loanCloseDate), 'd'); 
        }
       vm.loan.hasSubstatus = (vm.loan.subStatus != null && vm.loan.subStatus != 0);
    	   
       if (vm.loan.isDoubt){
         vm.loanDoubtDate = "DOUBT " + $translate.instant('modules.loan.from') + " " + kendo.toString( new Date(vm.loan.doubtFrom), 'd'); 
       }

       if (vm.loan.isWrittenoff){
           vm.loanWroffDate = "WRITTENOFF " + $translate.instant('modules.loan.from') + " " + kendo.toString( new Date(vm.loan.writeoffDate), 'd'); 
       }
//        var currentPageTemplate = $route.current.templateUrl;
//        $templateCache.remove(currentPageTemplate);
//        $route.reload();
        
//        if(header == null)
//        	vm.loan = entity;
//        else 
//        	vm.loan = header;
        
        function copyTextToClipboard(text) {
			  var textArea = document.createElement("textarea");
			
			  //
			  // *** This styling is an extra step which is likely not required. ***
			  //
			  // Why is it here? To ensure:
			  // 1. the element is able to have focus and selection.
			  // 2. if element was to flash render it has minimal visual impact.
			  // 3. less flakyness with selection and copying which **might** occur if
			  //    the textarea element is not visible.
			  //
			  // The likelihood is the element won't even render, not even a flash,
			  // so some of these are just precautions. However in IE the element
			  // is visible whilst the popup box asking the user for permission for
			  // the web page to copy to the clipboard.
			  //
			
			  // Place in top-left corner of screen regardless of scroll position.
			  textArea.style.position = 'fixed';
			  textArea.style.top = 0;
			  textArea.style.left = 0;
			
			  // Ensure it has a small width and height. Setting to 1px / 1em
			  // doesn't work as this gives a negative w/h on some browsers.
			  textArea.style.width = '2em';
			  textArea.style.height = '2em';
			
			  // We don't need padding, reducing the size if it does flash render.
			  textArea.style.padding = 0;
			
			  // Clean up any borders.
			  textArea.style.border = 'none';
			  textArea.style.outline = 'none';
			  textArea.style.boxShadow = 'none';
			
			  // Avoid flash of white box if rendered for any reason.
			  textArea.style.background = 'transparent';
			
			
			  textArea.value = text;
			
			  document.body.appendChild(textArea);
			
			  textArea.select();
			
			  try {
			    var successful = document.execCommand('copy');
			    var msg = successful ? 'successful' : 'unsuccessful';
			    console.log('Copying text command was ' + msg);
			  } catch (err) {
			    console.log('Oops, unable to copy');
			  }
			
			  document.body.removeChild(textArea);
		  }
        
    }
})();
