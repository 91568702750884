(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteTaskBpmController', DeleteTaskBpmController);

    DeleteTaskBpmController.$inject = ['$uibModalInstance', '$state', 'Bpm', 'AlertService'];

    function DeleteTaskBpmController ($uibModalInstance, $state, Bpm, AlertService) {
        var vm = this;
        
        if($state.params.currentTask == null) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("BPM Task don't get for deleting");
        	return;
        }
        
        vm.currentTask = $state.params.currentTask;
        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (taskId) {
        	AlertService.success("Task deleted successfully, id = " + taskId);
        	$uibModalInstance.close(true);
            Bpm.deleteTask({taskId: taskId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
