(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardListLoanController', CardListLoanController);

    CardListLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', '$translate', 'baseUrl', '$window'];

    function CardListLoanController ($scope, $state, Card, formHelper, $translate, baseUrl, $window) {
        var vm = this;
        vm.kendo = kendo;
        console.log("Card List controller");
        
        vm.cardsDataSource = new kendo.data.DataSource({
        	schema: {
			      model: {
			        fields: {
			          "statCode": { type: "string" },
			          "status": { type: "string" },
			          "isActive": { type: "boolean"}
			        },
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.status = $translate.instant('modules.cardStatus.' + elem.statCode);
	                    });
	                	return d;
	              }
      		},
    		transport: {
				read: function(e){

					Card.get(
							{loan_no : $state.params.loan_no},
							function(response){
								e.success(response);
							},
							function(error){
								console.log('cards list error');
								e.error(error);
							}
					);
				}
			}
        });
        
        vm.cardsOptions = {
                sortable: true,                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: false,
                toolbar: [
                          { 
		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.cardsList'></span>"
		                    		 + "<button has-any-authority='ROLE_LATEKO,ROLE_PARTNER' is-allowed='ALLOW_LOAN_CARDS_CREATE' style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loans.current.create-another-card'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.loan.createAnotherCard'>"
		                             + "</span></button>"
                          }],
                columns: [{
	                  field: "token",
	                  headerTemplate: "<span translate='modules.loan.table.token'>",
		              width: "11ch"
                    },{
	                  field: "reference",
	                  headerTemplate: "<span translate='modules.loan.table.reference'>",
	                  minScreenWidth: 1130,
	                  width: "22ch"
	              	},{
		              field: "embossName",
		              headerTemplate: "<span translate='modules.loan.table.embossName'>"
		            },{
	                  field: "status",
	                  headerTemplate: "<span translate='modules.loan.table.status'>",
	                  minScreenWidth: 992,
	                  width: "16ch"
	              	},{
		                field: "isActive",
		                headerTemplate: "<span translate='modules.loan.cardIsActive'>",
		                minScreenWidth: 992,
		                width: "8ch",
		                template: "<span style='transform: scale(1.3);' ng-show='#:isActive#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'modules.loan.cardIsActive' | translate }}'\" class='fa fa-check-circle-o text-success'></span>" +
		                		"<span style='transform: scale(1.3);' ng-show='#:!isActive#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'modules.loan.cardIsNotActive' | translate }}'\" class='fa fa-ban text-danger'></span>",
		                attributes: {class:"text-center"},
		            },{
                        field: "assignedLimit",
                        headerTemplate: "<span translate='modules.loan.table.assignedLimit'>",
                        width: "15ch",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        filterable: false
                    },{
                    	headerTemplate: "<span translate='modules.loan.actions'>",
                    	template: "<button has-any-authority='ROLE_LATEKO,ROLE_PARTNER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.cards.view({token: #:token#})'>"
                                + "<span class='glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                ,
                        title: "&nbsp;", 
                        width: "10ch" 
                    }]
        };
        
        vm.openCardBalanceReport = function(){
        	vm.cardBalanceReportLink = baseUrl + 'api/loans/' +  $state.params.loan_no + '/reports/card-balance';
        //	console.log(vm.evolventLink);
        	vm.cardBalanceReport = vm.cardBalanceReportLink + "?tmst="+ (new Date()).getTime();
       // 	console.log(vm.evolvent);
        	$window.open(vm.cardBalanceReport, '_blank');
        };

      
       
    }
    
})();
