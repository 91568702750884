(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modules.applications', {
            parent: 'modules',
            url: '/applications',
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/applications.html',
                    controller: 'ApplicationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('modules/utils');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }]
            }
        })
        .state('modules.applications.detail', {
            parent: 'modules',
            url: '/applications/{id}',
            params: { loan_no: null }, 
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/application-dialog.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Application', function($stateParams, Application) {
                    return Application.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
				detailMode: function(){
                	return {
                		edit: true,
                		create: false
                	};
                }
            }
        })
        .state('modules.applications.detail.delete', {
			url : '/delete',
			parent : 'modules.applications.detail',
	        data: {
	            authorities: ['ROLE_EXECUTIVE', 'ROLE_ADMIN'],
	        },
	        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	            $uibModal.open({
	                templateUrl: 'app/modules/application/delete.application.template.html',
	                controller: 'DeleteApplicationController',
	                controllerAs: 'vm',
	                size: 'md'
	            });
	        }]
	    })
        .state('modules.applications.new', {
            parent: 'modules',
            url: '/applications/new/',
            params: { personCode: null, applSum: null, tgrId: null, prtId: null },
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/application-dialog.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: function () {
                	
                    return {
                    	productName: "HERCUS_CARD",
                    	salesChannel: "UNKNOWN",
                    	supplicant: [{
                    		supplicantType: "MAIN",
                    		otherPhone: "",
                    		mobPhone: "",
                    		contactPhone: "",
                    		citizenship: "LVA",
                    		politicalPerson: "1",
                    		beneficiary: "2"
                    	},{
                    		supplicantType: "SURETY",
                    		otherPhone: "",
                    		mobPhone: "",
                    		citizenship: "LVA",
                    		politicalPerson: "1"
                    	}]
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
				detailMode: function(){
                	return {
                		edit: true,
                		create: true
                	};
                }
            }
        })
        .state('modules.applications.new-from-short', {
            parent: 'modules',
            url: '/applications/new-from-short/',
            params: { shortAppl: null },
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/application/application-dialog.html',
                    controller: 'ApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: function () {
                	
                    return {
                    	productName: "HERCUS_CARD",
                    	salesChannel: "UNKNOWN",
                    	supplicant: [{
                    		supplicantType: "MAIN",
                    		otherPhone: "",
                    		mobPhone: "",
                    		contactPhone: "",
                    		citizenship: "LVA"
                    	},{
                    		supplicantType: "SURETY",
                    		otherPhone: "",
                    		mobPhone: "",
                    		citizenship: "LVA"
                    	}]
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
				detailMode: function(){
                	return {
                		edit: true,
                		create: true
                	};
                }
            }
        })
               ;
    }

})();
