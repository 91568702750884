(function() {
    'use strict';
    angular
        .module('sigmaApp')
        .factory('Cookies', Cookies);

    Cookies.$inject = ['$cookies'];

    function Cookies ($cookies) {
        console.log("Cookies service started");
        
        function afterYear(){
        	var now = new Date();
            // this will set the expiration to 12 months
            return new Date(now.getFullYear()+1, now.getMonth(), now.getDate());
        }
        
        var getPartnerID = function(login) {
            var partnerCookieName = login + "PartnerID";
            return $cookies.get(partnerCookieName);
        }; 
        
        var getOfficeID = function(login) {
            var officeCookieName = login + "OfficeID";
        	return $cookies.get(officeCookieName);
        };
        
        function setPartnerID(login, value){
        	var partnerCookieName = login + "PartnerID";
        	$cookies.put(partnerCookieName, value, {'path': '\/', 'expires': afterYear()});
        };
        
        function setOfficeID (login, value){
        	var partnerCookieName = login + "OfficeID";
        	$cookies.put(partnerCookieName, value, {'path': '\/', 'expires': afterYear()});
        };
        
        return {
        	partnerID: getPartnerID,
        	officeID: getOfficeID,
        	setPartnerID: setPartnerID,
        	setOfficeID: setOfficeID
        };
    }
})();
