(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('MvcMenuController', MvcMenuController);

    MvcMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation'];

    function MvcMenuController ($scope, $state, $stateParams, eehNavigation) {
        var vm = this;
        console.log("MvcMenuController ...");
        
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var from = yesterday.toLocaleDateString('lt-LT');
        var to = yesterday.toLocaleDateString('lt-LT');
        
        eehNavigation
		        .menuItem('sidenavMvc.operations', {
		          text: 'modules.mvc.menu.operations',
		          iconClass: 'fa-list-alt',
		          state: 'modules.mvc.operations({f:"' + from + '", t:"' + to + '"})',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavMvc.balances', {
		          text: 'modules.mvc.menu.balances',
		          iconClass: 'fa-balance-scale',
		          state: 'modules.mvc.balances',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavMvc.events', {
		          text: 'modules.mvc.menu.events',
		          iconClass: 'fa-calendar',
		          state: 'modules.mvc.events',
		          weight: -10,
		          isVisible: true
		        })
		        .iconBaseClass('fa');
        
        
    }
    
})();
