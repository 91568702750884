(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('OperationListLoanController', OperationListLoanController);

    OperationListLoanController.$inject = ['$rootScope', '$scope', '$state', 'Loan', 'defaultPageSize', '$translate', '$window', '$filter'];

    function OperationListLoanController ($rootScope, $scope, $state, Loan, defaultPageSize, $translate, $window, $filter) {
        var vm = this;
		var types = {
				data: []
			};
		var statTrns = {
				data: []
			};
		vm.fromDate = new Date;
		vm.toDate = new Date;
		vm.minDate = new Date;
		vm.maxDate = new Date;
		vm.dateFormat = kendo.culture().calendar.patterns.d;
    	$rootScope.$on('$translateChangeSuccess', function () {
    		vm.dateFormat = kendo.culture().calendar.patterns.d;
        });
        
        $scope.$watch(function(){
    		return vm.fromDate;
    	}, function(newVal,oldVal) {
    		if (newVal != oldVal && newVal.valueOf() > vm.toDate.valueOf()) {
    			vm.toDate = newVal;
    		}
    		vm.betweenDates=vm.rangeDatesReverce();
    	});
        
        $scope.$watch(function(){
        	return vm.toDate;
		}, function(newVal,oldVal) {
			if (newVal != oldVal && newVal.valueOf() < vm.fromDate.valueOf()) {
				vm.fromDate = newVal;
			}
			vm.betweenDates=vm.rangeDatesReverce();
		});
        

        
        
    	vm.detail = false;
        vm.kendo = kendo;
        console.log("OperationListLoanController started");
      	var countTrn = $translate.instant('modules.loan.table.count');
      	var totalBill = $translate.instant('modules.loan.table.total');
      	
        vm.betweenDatesDataSource = new kendo.data.DataSource({
        	data: [
					{
						key: 6,
						value: $translate.instant("modules.mvc.date.allTheTime"),
					},{
        	 			key: 1,
        	 			value: $translate.instant("modules.mvc.date.thisMonth"),
        	    	},{
        	    		key: 2,
        	    		value: $translate.instant("modules.mvc.date.lastMonth"),
        	    	},{
        	    		key: 3,
        	    		value: $translate.instant("modules.mvc.date.thisWeek"),
        	    	},{
        	    		key: 4,
        	    		value: $translate.instant("modules.mvc.date.yesterday"),
        	    	},{
        	    		key: 5,
        	    		value: $translate.instant("modules.mvc.date.today"),
        	    	}
        	    ]
    	});
      	
        vm.loanOperationsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			          "num": { type: "number" },
			          "token": {type: "string"}, 
			          "setlDate": { type: "date" },
			          "cardDate": { type: "date" },
			          "trnAmt": { type: "number" },
			          "trnCurr": { type: "string" },
			          "billAmt": { type: "number" },
			          "billCurr": { type: "string" },
			          "oprType": { type: "string" },
			          "stat": { type: "string" },
			          "statTrn": { type: "string" },
			          "stsDesc": { type: "string" },
			          "trnDesc": { type: "string" },
			          "itemId": { type: "number"},
			          "cdhId": { type: "number" },
			          "crtdOn": { type: "date" },
			          "crtdBy": { type: "string" },
			          "updtOn": { type: "date" },
			          "updtBy": { type: "string" }
			        }
			      },
   				  parse: function(d){
   		              	$.each(d, function (idx, elem) {
   		              		var trnDate = new Date(elem.cardDate);

   		              		vm.fromDate = vm.fromDate > trnDate ? trnDate : vm.fromDate;
   		              		vm.toDate = vm.toDate < trnDate ? trnDate : vm.toDate;
   		              		vm.minDate = vm.fromDate;
   		              		vm.maxDate = vm.toDate;
   		              		
   		              		var type = {
   		              			oprType: elem.oprType
   		              		};
   		              		var foundType = $filter('filter')(types.data, {oprType: elem.oprType}, true);
   		                    if(foundType.length < 1) (types.data).push(type);

	                        elem.statTrn = $translate.instant('modules.transactionStatuses.' + elem.stat).toUpperCase();
	                        
   		              		var statTrn = {
   	   		              		statTrn: elem.statTrn
   	   		              	};
   	   		              	var foundStatTrn = $filter('filter')(statTrns.data, {statTrn: elem.statTrn}, true);
   	   		                if(foundStatTrn.length < 1) (statTrns.data).push(statTrn);
	                        
	                    });
   		              	
   		              	return d;
   				  }
    		},
			transport : {
				read : function(e) {
					Loan.getOperations(
							{loan_no : $state.params.loan_no}, 
							function(response) {
								e.success(response);
							}, 
							function(error) {
								console.log('operations list error');
								e.error(error);
							})
							.$promise;;
				}
			},
            aggregate: [
                        { field: "cardDate", aggregate: "count"},
                        { field: "billAmt", aggregate: "sum"},
                      ],
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "num",
                dir: "desc"
            }
        });
        
        vm.update = function(){
        	// in 'toLocaleDateString('lt-LT')' - 'lt-LT' is equal to ISO8601 
        	//$state.go('modules.mvc.operations',{f: vm.fromDate.toLocaleDateString('lt-LT'), t: vm.toDate.toLocaleDateString('lt-LT')}, {reload: true});
        	console.log('Filter by date');
        	var cardDateFilter = new Array();
        	cardDateFilter.push({ field: "cardDate", operator: "gte", value: vm.fromDate });
        	cardDateFilter.push({ field: "cardDate", operator: "lte", value: vm.toDate });
        	vm.loanOperationsDataSource.filter(cardDateFilter);
        };
        
        $scope.$watch(function(){
    		return vm.betweenDates;
	    	}, function(newVal,oldVal) {
	    		if (newVal != oldVal && newVal != 0) {
	    			var range = rangeDates(newVal);
	    			vm.fromDate = range.fromDate;
	    			vm.toDate = range.toDate;
	    		}
    	});
        
        function rangeDates(id) {
        	var fromDate;
        	var toDate;
        	
        	switch (id) {
	        	case 1:
	        		var curr = new Date;
	        	 	fromDate  = new Date(curr.getFullYear(), curr.getMonth(), 1);
	        	 	toDate = new Date;
	        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0);
	        		break;
	        	case 2:
	        		var curr = new Date;
	        	 	fromDate = new Date(curr.getFullYear(), curr.getMonth() - 1, 1);
	        	 	toDate = new Date;
	        	 	toDate = new Date(toDate.getFullYear(), toDate.getMonth(), 0); 
	        		break;
	        	case 3:
	        		var curr = new Date;
	        		fromDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 1));
	        		curr = new Date;
	        	 	toDate = new Date(curr.setDate(curr.getDate() - curr.getDay() + 7));
	        		break;
	        	case 4:
	        		fromDate = new Date();
	        		fromDate.setDate(fromDate.getDate() - 1);
	        	 	toDate = fromDate;
	        		break;
	        	case 5:
	        		fromDate = new Date();
	        	 	toDate = new Date();
	        	    break;
	        	case 6:
	        	 	fromDate  = vm.minDate;
	        	 	toDate = vm.maxDate;
	        		break;
        	}
        	
        	return {
        		fromDate: fromDate,
        		toDate: toDate
        	};
        }

        vm.rangeDatesReverce = function () {
        	
        	var fromDate = vm.fromDate;
        	var toDate = vm.toDate;
        	
        	var current = new Date;
        	
        	var currnetMonthLastDate = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        	var lastMonthLastDate = new Date(current.getFullYear(), current.getMonth(), 0);
        	
        	var bg = new Date;
        	var beginCurrentWeek = new Date(bg.setDate(bg.getDate() - bg.getDay() + 1));
			var en = new Date;
    	 	var endCurrentWeek = new Date(en.setDate(en.getDate() - en.getDay() + 7));
    	 	
        	if(fromDate.toLocaleDateString() == toDate.toLocaleDateString()) {
        		if(fromDate.toLocaleDateString() == current.toLocaleDateString()) {
        			return 5;
        		} else if(fromDate.getDate() == current.getDate() - 1 && fromDate.getFullYear() == current.getFullYear() && fromDate.getMonth() == current.getMonth()){
        			return 4;
        		} else return 0;
        	} else if(fromDate.getFullYear() == current.getFullYear()) {
        		if(fromDate.getMonth() == current.getMonth() && toDate.getMonth() == current.getMonth() && fromDate.getDate() == 1 && currnetMonthLastDate.getDate()) {
        			return 1;
        		} else if(fromDate.getMonth() == current.getMonth() - 1 && toDate.getMonth() == current.getMonth() - 1 && fromDate.getDate() == 1 && lastMonthLastDate.getDate()) {
        			return 2;
        		} else if(fromDate.toLocaleDateString() == beginCurrentWeek.toLocaleDateString() && toDate.toLocaleDateString() == endCurrentWeek.toLocaleDateString()) {
        			return 3;
        		} else if(fromDate.valueOf() == vm.minDate.valueOf() && toDate.valueOf() == vm.maxDate.valueOf()) {
        			return 6;
        		} else return 0;
        	} else return 0;
        };
        
        vm.loanOperationsOptions = {
        		scrollable: {
    	            virtual: true
    	        },
    	        height: function(){
    	        	return $window.innerHeight/2 - 15;
    	        },
    	        sortable: true,                
    	        pageable: {
    	        	refresh: true,
    	        	previousNext: false,
    	        	info:false,
    	        	numeric: false,
    	        	messages: {
    	        	      empty: "No data"
    	        	    }
    	        },
    			filterable : {
    				extra : false,
    				operators : {
    					string : {
    						contains : "contains",
    					}
    				}
    			},
                toolbar: [
                          { 
		                    template: "<span class='col-sm-2' style='padding-left:0;line-height:30px;' translate='modules.loan.titles.operations'></span>"
			                    	 + "<div class='form-group-sm'><div class='col-sm-4'><input class='form-control' kendo-date-picker k-format=\"'d'\"  placeholder='{{vm.dateFormat}}' k-ng-model='vm.fromDate'></div>"
		                    		 + "<div class='col-sm-4'><input class='form-control' kendo-date-picker k-format=\"'d'\"  placeholder='{{vm.dateFormat}}' k-ng-model='vm.toDate'></div>"
		                    		 + "<div class='col-sm-4'><select kendo-drop-down-list k-data-text-field=\"'value'\" k-option-label=\"'-Select-'\" k-data-value-field=\"'key'\" k-value-primitive='true' style='width: 100%' k-ng-model='vm.betweenDates' k-data-source='vm.betweenDatesDataSource' ng-init='vm.betweenDates=vm.rangeDatesReverce()'></select></div></div>"
		                    		 + "<button ng-click='vm.update()' class='btn btn-default btn-sm'>"
		                    		 + "<span class='glyphicon glyphicon-refresh'></span>"
		                             + "</button>"			                    		 
		                    		 + "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
		                    		 + "<span class='fa fa-file-excel-o'></span>"
		                             + "&nbsp;<span translate='modules.loan.excel'>"
		                             + "</span></button>"
                          }
                ],
                excel: {
                	fileName: "loan-operations.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                          if(cell.value == "&nbsp;") cell.value = "";
                        });
                      }
                	});
                },                
                columns: [
						{ 
							field: "num",
							title: "{{'modules.loan.table.no'|translate}}",
							width: "5ch", 
							sortable: true,
							filterable: false
						},{ 
                          	title: "{{'modules.loan.table.card'|translate}}",
			                columns: [
										{ 
											field: "cardDate", 
					                        title: "{{'modules.loan.table.date'|translate}}",
					                        width: "12ch",
					                        format: "{0:d}",
					                        filterable: true,
					                        sortable: true,
					                        aggregates: ["count"], 
					                        footerTemplate: countTrn + ": #=count#",
					                        footerAttributes: {class: "text-center"}
										},{ 
											field: "trnAmt",
											title: "{{'modules.loan.table.sum'|translate}}",
											template: "#= (trnAmt == 0 || trnAmt == null) ? '' : kendo.toString(trnAmt, 'n2')#",
										    attributes: { class: "text-right" },
										    sortable: true,
										    filterable: true,
										    width: "10ch"
										},{ 
											field: "trnCurr",
											title : "&nbsp;",
											template: "#= (trnAmt == 0 || trnAmt == null) ? '' : trnCurr #",
										    sortable: false,
										    filterable: false,
										    width: "6ch"
										}
							]
						},{ 
                            headerTemplate: "<span translate='modules.loan.table.account'>",
                            title: "{{'modules.loan.table.no'|translate}}",
                            columns: [
		                            	{ 
											field: "setlDate", 
					                        title: "{{'modules.loan.table.date'|translate}}",
					                        width: "12ch",
					                        format: "{0:d}",
					                        filterable: true,
					                        sortable: true,
					                        footerTemplate: totalBill + ":",
					                        footerAttributes: {class: "text-right"}
										},{ 
											field: "billAmt",
											title: "{{'modules.loan.table.sum'|translate}}",
											template: "#= (billAmt == 0 || billAmt == null) ? '' : kendo.toString(billAmt, 'n2')#",
										    attributes: { class: "text-right" },
										    sortable: true,
										    filterable: true,
										    width: "10ch",
					                        footerTemplate: "#=kendo.toString(sum, 'n2')#",
					                        footerAttributes: {class: "text-right"}
										},{ 
											field: "billCurr",
											title : "&nbsp;",
											template: "#= (billAmt == 0 || billAmt == null) ? '' : billCurr #",
										    sortable: false,
										    filterable: false,
										    width: "6ch"
										} 
							] }, {
								field: "oprType",
								title: "{{'modules.loan.table.oprType'|translate}}",
			                    width: "9ch",
								filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(types),
		                            dataTextField : "oprType"
								}
							},
							{
								field: "statTrn",
								title: "{{'modules.loan.table.sts'|translate}}",
			                    width: "12ch",
								filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(statTrns),
		                            dataTextField : "statTrn"
								}
							},
							{
                                field: "trnDesc",
                                title: "{{'modules.loan.table.note'|translate}}",
                                template : "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#:trnDesc#'\" style='width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>#:trnDesc#</span>",
                                filterable: true,
                                sortable: false,
			                },
			                {
								template : "<button has-authority='ROLE_LATEKO' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ng-click='vm.view(this)'>"
										+ "<span class='glyphicon glyphicon-eye-open'></span>"
										+ "</button>",
								title : "&nbsp;",
								width : "5ch"
							}
							]
                          };
        
        vm.view = function(current){
			vm.detail = true;
			vm.currentOperation = current.dataItem;
			vm.currentOperation.trnDesc = vm.currentOperation.trnDesc.replace(/\|/g,'\r\n');
		};
		
		vm.close = function(){
			vm.detail = false;
			vm.currentOperation = {};
		};
       
    }
    
})();
