(function() {
	'use strict';
	angular.module('sigmaApp').factory('Bpm', Bpm);

	Bpm.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Bpm($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/bpm';

		return $resource(resourceUrl,{taskId: '@taskId'},
				{
					'getTasks' : {
						method : 'GET',
						url: resourceUrl + "/tasks",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getStat' : {
						method : 'GET',
						url: resourceUrl + "/tasks/stat",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deleteTask' : {
						method : 'DELETE',
						url: resourceUrl + "/tasks/:taskId"
					},
					'restartTask' : {
						method : 'PUT',
						url: resourceUrl + "/tasks/:taskId/retry"
					},
					'updateTask' : {
						method : 'PUT',
						url: resourceUrl + "/tasks/:taskId"
					}
				
				});
	}
})();