(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterSettlementReportController', WallesterSettlementReportController);

    WallesterSettlementReportController.$inject = ['$scope', '$state', 'Report', 'defaultPageSize', '$filter', '$window', '$translate', 'baseUrl', 'WsService', 'shortDateFormats'];

    function WallesterSettlementReportController ($scope, $state, Report, defaultPageSize, $filter, $window, $translate, baseUrl, WsService, shortDateFormats) {
        var vm = this;
        vm.kendo = kendo;
        vm.reportDate = new Date();
        vm.generateReport = generateReport;
        
        console.log("WallesterSettlementReportController started");
        
        var docStatuses = {
				data: []
			};
		var docCrtdBys = {
				data: []
		};
        
        var reloadEvents = ['DMS_DOC_CREATED', 'DMS_CONTENT_CREATED'];
        
        WsService.receive().then(null, null, function(activity) {
        	if(typeof activity === 'object') {
        		if(activity.hasOwnProperty('key') && activity.hasOwnProperty('type')) {
        			var reloadPage = $filter('filter')(reloadEvents, activity.type, false).length > 0  && activity.key == "WALLESTER_SETTLEMENT" ? true : false ;  
                	if (reloadPage) { 
                		$state.reload();
                	}	
        		}
        	}
	    });
      	
        vm.reportDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "num",
			        fields: {
			        	  "docNum": { type: "string" },
				          "docDate": { type: "date"},
				          "regName": { type: "string" },
				          "docStatus": { type: "string" },
				          "fileName": { type: "string" },
				          "crtdOn": { type: "date" },
				          "crtdBy": { type: "crtdBy" }
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
		                    var docStatus = {
		                    		docStatus: elem.docStatus
		                    };
		                    var foundDocStatus = $filter('filter')(docStatuses.data, {docStatus: elem.docStatus}, true);
		                    if(foundDocStatus.length < 1) (docStatuses.data).push(docStatus);
		                    
		              		var crtdBy = {
		              				crtdBy: elem.crtdBy
		              		};
		              		var foundDocCrtdBy = $filter('filter')(docCrtdBys.data, {crtdBy: elem.crtdBy}, true);
		                    if(foundDocCrtdBy.length < 1) (docCrtdBys.data).push(crtdBy);
	                        
	                    });
	                	return d;
	                }
    		},
    		transport: {
				read: function(e){

					Report.getWallesterSettlementList(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('wallesterSettlement list error');
								e.error(error);
							}
					);
				}
			},
			aggregate: [
//                        { field: "expectedDate", aggregate: "count"},
//                        { field: "prn", aggregate: "sum"},

            ],
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "docNum",
                dir: "desc"
            }
        });
        
        vm.reportOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
    			filterable : {
    				extra : false,
    			},
                height: function(){
                	return $window.innerHeight/2.5;
                },                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info:false,
                	messages: {
                	      empty: $translate.instant('modules.loan.table.noData'),
                	    }
                },
                columns: [
							{ 
								field: "docNum", 
								title: "{{'modules.report.report1.docNum'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "8ch",
							},{ 
								field: "fileName",
								title: "{{'modules.report.report1.fileName'|translate}}",
							    attributes: { "class": "text-left" },
		                        template : "<a ng-click='vm.getReport(this)'>#:fileName#</a>",
							    width: "16ch",
							},{ 
								field: "docDate",
								title: "{{'modules.report.report1.docDate'|translate}}",
								width: "10ch",
								format: "{0:d}",
							},{ 
								field: "docStatus", 
		                        title: "{{'modules.report.report1.docStatus'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "12ch",
		                        filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(docStatuses),
		                            dataTextField : "docStatus"
								}
							},{
								field: "crtdOn",
								title: "{{'modules.report.report1.crtdOn'|translate}}",
								width: "16ch",
								format: "{0:G}",
							},{
								field: "crtdBy",
								title: "{{'modules.report.report1.crtdBy'|translate}}",
								attributes: { "class": "text-left" },
								width: "8ch",
								filterable : {
		                            multi : true,
		                            dataSource : new kendo.data.DataSource(docCrtdBys),
		                            dataTextField : "crtdBy"
								}
							}
							
                    ]
 
        };
        
        function generateReport(){
        	console.log('generateReport wallesterSettlement');
        	var repDate = vm.reportDate.toLocaleDateString('lt-LT');

        	Report.generateWallesterSettlementReport({
        		exactDate: repDate
        	});
        	
        };
        
        vm.getReport = function(current){
        	console.log('getReport wallesterSettlement: ' + current.dataItem.docNum);
        	vm.reportLink = baseUrl + 'api/dms/doc/' + current.dataItem.docNum;
        	vm.report = vm.reportLink + "?tmst="+ (new Date()).getTime();
        	$window.open(vm.report, '_blank');
        };
    }
    
})();
