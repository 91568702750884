(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DatePenaltyLoanController', DatePenaltyLoanController);

    DatePenaltyLoanController.$inject = ['$scope', '$state', 'Loan', 'shortDateFormats', '$uibModalInstance', 'AlertService'];

    function DatePenaltyLoanController ($scope, $state, Loan, shortDateFormats, $uibModalInstance, AlertService) {
        var vm = this;
        vm.kendo = kendo;
        console.log("DatePenaltyLoanController started");
        vm.reason = "";
        vm.saveDisabled = false;
        
        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorDebtsToDate");
        	return;
        }
        
        Loan.getPenStopDate(
        	{loan_no : $state.params.loan_no},
        	function(response){
        		vm.penStopDate = new Date(response.penStopDate);
        		vm.loanNo = response.loanNo;
        	},
        	function(error){
        		console.log('debts error');
        		console.log(error);
        		AlertService.error(error);
        	}
        );

        vm.shortDateFormats = shortDateFormats;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.setStopDate  = function(loanNo,penStopDate,reason) {
        	vm.saveDisabled = true;
        	if (vm.penStopDate != null)
        		vm.penStopDateFormated = vm.penStopDate.toLocaleDateString('lt-LT');
        	else vm.penStopDateFormated = null;
    		Loan.setPenStopDate(
    				{loan_no : loanNo},
    				{pen_stop_date : vm.penStopDateFormated, reason : reason},
    				function(response){
    					console.log("Penalty date updated!");
    					AlertService.success("modules.alert.success.save");
    					vm.saveDisabled = false;
    					vm.clear();
    				},
    				function(error){
    					console.log('Penalty date update error!');
    					console.log(error);
    					AlertService.error(error);
    					vm.saveDisabled = false;
    				}
    		);
        };
    }	
})();
