(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardSendPinLoanController', CardSendPinLoanController);

    CardSendPinLoanController.$inject = ['$uibModalInstance', '$state', 'Card', 'AlertService'];

    function CardSendPinLoanController ($uibModalInstance, $state, Card, AlertService) {
        var vm = this;

        if($state.params.token == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorCardSendPin");
        	return;
        }
 
        
        vm.token = $state.params.token; 
        vm.clear = clear;
        vm.confirmSend = confirmSend;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmSend (token) {
        	$uibModalInstance.close(true);
        	Card.sendPin(
            		{loan_no : $state.params.loan_no, token : token },
            		{},
            		function(result){
    		    		if(result) {
    		    			AlertService.success("modules.loan.message.successCardSendPin", { token: token});
    		    			$uibModalInstance.close(true);
    		    		}
    	    		},
    	    		function(error){
    	    			console.log(error);
    	    		}
            ).$promise;
        }
    }
})();
