(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardEditPinLoanController', CardEditPinLoanController);

    CardEditPinLoanController.$inject = ['$scope', '$state', 'Card', 'formHelper', 'card', '$stateParams', '$filter', 'AlertService', 'CardStatus', 'pinUpdateMode'];

    function CardEditPinLoanController ($scope, $state, Card, formHelper, card, $stateParams, $filter, AlertService, CardStatus, pinUpdateMode) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardEditPinLoanController");
        vm.pinUpdateManual = pinUpdateMode.manual;
        formHelper.setFocus('first-field');
        
        if($stateParams.token != null) {
	        vm.currentCard = card;
	        vm.statusStyle = CardStatus.getStyle(vm.currentCard.statCode, vm.currentCard.isActive);
        }
        
        vm.save = function(){
        	
        	if(vm.pinUpdateManual && vm.newPinForm.$invalid){
        		AlertService.error("modules.loan.message.missingNewPin");
        		return;
        	}
        	
        	vm.formCard = angular.copy(vm.currentCard);
	    	vm.cmd = angular.copy(vm.formCard.cmd);
	    	delete vm.formCard.cmd;
	    	vm.bal = angular.copy(vm.formCard.bal);
	    	delete vm.formCard.bal;
	    	vm.formCard.sendBySms = vm.pinUpdateManual ? vm.sendBySms : true ;
	    	vm.formCard.newPin = vm.pinUpdateManual ? vm.newPin : '';
	    	vm.formCard.oldPin = vm.pinUpdateManual ? vm.oldPin : '';
	    	
	    	vm.formCard.reference = $stateParams.loan_no;
        	
	    	if(vm.pinUpdateManual) {
		    	Card.pin(vm.formCard,
	        			function(result){
			        		if(result) {
			        			AlertService.success("modules.loan.message.pinChangedSuccessfully");
			        			vm.formCard = result;
			        			delete vm.formCard.sendBySms;
			        			delete vm.formCard.reference;
			        			delete vm.formCard.oldPin;
			        			delete vm.formCard.newPin;
			        			vm.currentCard = angular.copy(result);
			        			vm.currentCard.cmd = vm.cmd;
			        			vm.currentCard.bal = vm.bal;
			        			$state.go('modules.loans.current.cards.view', {id: $stateParams.id, token: $stateParams.token});
			        		}
		        		},
		        		function(error){
		        			console.log(error);
		        		}
	        	).$promise;
        	} else {	
		    	Card.generatePin(vm.formCard,
	        			function(result){
			        		if(result) {
			        			AlertService.success("modules.loan.message.pinGeneratedSuccessfully");
			        			vm.formCard = result;
			        			delete vm.formCard.sendBySms;
			        			delete vm.formCard.reference;
			        			delete vm.formCard.oldPin;
			        			delete vm.formCard.newPin;
			        			vm.currentCard = angular.copy(result);
			        			vm.currentCard.cmd = vm.cmd;
			        			vm.currentCard.bal = vm.bal;
			        			$state.go('modules.loans.current.cards.view', {id: $stateParams.id, token: $stateParams.token});
			        		}
		        		},
		        		function(error){
		        			console.log(error);
		        		}
	        	).$promise;
        	}
        };
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.cards.view', {id: $stateParams.id, token: $stateParams.token});
        };
        
    }
    
})();
