(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CloseActiveLoanController', CloseActiveLoanController);

    CloseActiveLoanController.$inject = ['$scope', '$state', 'Loan', 'shortDateFormats', '$uibModalInstance', 'AlertService'];

    function CloseActiveLoanController ($scope, $state, Loan, shortDateFormats, $uibModalInstance, AlertService) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CloseActiveLoanController started");
        vm.reason = "";
        vm.saveDisabled = false;
        vm.closeDate = new Date();
        vm.loanNo = $state.params.loan_no;
        
        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorDebtsToDate");
        	return;
        }
        
        vm.shortDateFormats = shortDateFormats;
        vm.dateFormat = kendo.culture().calendar.patterns.d;
        
        vm.clear = clear;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        vm.closeOnDate  = function(loanNo, closeDate, reason) {
        	vm.saveDisabled = true;
        	if (vm.closeDate != null)
        		vm.closeDateDateFormated = vm.closeDate.toLocaleDateString('lt-LT');
        	else vm.closeDateFormated = null;
    		Loan.bookAndCloseOnDate(
    				{loan_no : loanNo},
    				{close_date : vm.closeDateDateFormated, reason : reason},
    				function(response){
    					console.log("Loan closed on date!");
    					AlertService.success("modules.alert.success.save");
    					vm.saveDisabled = false;
    					vm.clear();
    				},
    				function(error){
    					console.log('Loan close error!');
    					console.log(error);
    					AlertService.error(error);
    					vm.saveDisabled = false;
    				}
    		);
        };
    }	
})();
