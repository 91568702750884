(function() {
	'use strict';

	angular.module('sigmaApp').component('audit', {
		templateUrl : "app/modules/audit.template.html",
		controller : auditController,
		bindings : {
			crtdBy : '=',
			crtdOn : '=',
			updtBy : '=',
			updtOn : '=',
			inPosition : '='	
		}
	});

	auditController.$inject = [ '$scope', '$element', '$attrs' ];

	function auditController($scope, $element, $attrs) {
		
		var ctrl = this;
		var createdDateTime = ctrl.crtdOn != null ? new Date(ctrl.crtdOn) : null ;
		var updatedDateTime = ctrl.updtOn != null ? new Date(ctrl.updtOn) : null ;
		updatedDateTime = updatedDateTime || createdDateTime > updatedDateTime ? updatedDateTime :  createdDateTime ;
		ctrl.cCrtdOn = createdDateTime != null ? kendo.toString(createdDateTime, 'g') : "";
		ctrl.cUpdtOn = updatedDateTime != null ? kendo.toString(updatedDateTime, 'g') : "";
		ctrl.cCrtdBy = ctrl.crtdBy; 
		ctrl.cUpdtBy = ctrl.updtBy;
		if($attrs.inPosition == 'modal') {
			ctrl.isModal = {
					"margin-top": "-21px"
			};
		}
	}

})();
