(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ReportMenuController', ReportMenuController);

    ReportMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation'];

    function ReportMenuController ($scope, $state, $stateParams, eehNavigation) {
        var vm = this;
        console.log("ReportMenuController ...");
       
        eehNavigation
	        	.menuItem('sidenavReport.dashboard', {
		          text: 'modules.report.menu.dashboard',
		          iconClass: 'fa-pie-chart',
		          state: 'modules.report.dashboard',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.application', {
		          text: 'modules.report.menu.application',
		          iconClass: 'fa-pie-chart',
		          state: 'modules.report.application',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.report1', {
		          text: 'modules.report.menu.report1',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.report1',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.marketing', {
		          text: 'modules.report.menu.marketing',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.marketing',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.card', {
		          text: 'modules.report.menu.card',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.card',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.shareholders', {
		          text: 'modules.report.menu.shareholders',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.shareholders',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.sales-channel', {
		          text: 'modules.report.menu.sales-channel',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.sales-channel',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.commissions', {
		          text: 'modules.report.menu.commissions',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.commissions',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.edocs', {
		          text: 'modules.report.menu.edocs',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.edocs',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavReport.wallesterSettlement', {
		          text: 'modules.report.menu.wallesterSettlement',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.report.wallesterSettlement',
		          weight: -10,
		          isVisible: true
		        })
		        .iconBaseClass('fa');
        
    }
    
})();
