(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('BalancesMvcController', BalancesMvcController);

    BalancesMvcController.$inject = ['$scope', '$state', 'entity', 'defaultPageSize', '$translate', '$window'];

    function BalancesMvcController ($scope, $state, entity, defaultPageSize, $translate, $window) {
        var vm = this;
        console.log("MVC Balances Controller started");
	        vm.mvcBalancesDataSource = new kendo.data.DataSource({
	    		schema: {
				      model: {
	//			        id: "token",
				        fields: {
	//			          "token": { type: "string"},
				          "balDtime": { type: "date" },
				        }
				      }
	      		},
	  			data: entity.balances,
//	  			pageSize: defaultPageSize,
	  			sort: {
	  				field: "balDtime",
	  				dir: "desc"
	  			}
	        });
	        
	        vm.mvcBalancesOptions = {
	        		scrollable: true,
	                sortable: true,
	                filterable: {
	                    extra: false,
	                    operators: {
	                        string: {
	                            startswith: "Starts with",
	                            eq: "Is equal to",
	                            neq: "Is not equal to"
	                        }
	                    }
	                },
	    	        height: function(){
	    	        	return $window.innerHeight/2;
	    	        },
	                pageable: {
	    	        	refresh: true,
	    	        	previousNext: false,
	    	        	info:false,
	    	        	numeric: false,
	                	messages: {
	                	      empty: "No data"
	                	}
	                },
	                toolbar: [
	                          { 
			                    template: "<span style='line-height:22px;' translate='modules.mvc.titles.balances'></span>"
			                    		 + "<button has-authority='ROLE_ACCOUNTANT' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
			                    		 + "<span class='fa fa-file-excel-o'></span>"
			                             + "&nbsp;<span translate='modules.mvc.excel'>"
			                             + "</span></button>"
	                          }
	                ],
	                excel: {
	                	fileName: "mvc-balances.xlsx",
	                    allPages: true
	                },
	                excelExport: function(e) {
	                	e.workbook.sheets[0].columns.forEach(function(column){
	                		column.autoWidth = true;
	                	});
//	                	e.workbook.sheets[0].columns = [
//	                		{ width:100 },
//	                		{ width:300 },
//	                		{ width:400 },
//	                		// ...
//	                	];
	                    e.workbook.sheets[0].rows.forEach(function(row){
	                      if(row.type != 'data'){
	                      	row.cells.forEach(function(cell){
	                          cell.value = $translate.instant(cell.value);
	                        });
	                      }
	                	});
	                },
	                columns: [{
	                    	field: "balDtime",
	                    	title: "{{'modules.mvc.table.balDate'|translate}}",
	                        format: "{0:G}",
	                        parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
		                    width: "15ch",
		                    filterable: {
		                    	ui: "datepicker"
		                    }
	                    },{
	                        field: "amtAvl",
	                        title: "{{'modules.mvc.table.amtAvl'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "blkAmt",
	                        title: "{{'modules.mvc.table.blkAmt'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "credLimit",
	                        title: "{{'modules.mvc.table.credLimit'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    },{
	                        field: "finAmt",
	                        title: "{{'modules.mvc.table.finAmt'|translate}}",
	                        width: "15ch",
	                        format: "{0:n2}",
	                        attributes: {class:"text-right"},
	                        filterable: false
	                    }]
	 
	        };
    }
})();
