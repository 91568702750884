(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterCardEditStatusController', WallesterCardEditStatusController);

    WallesterCardEditStatusController.$inject = ['$scope', '$state', 'Wallester', 'formHelper', 'card', '$stateParams', '$filter', 'AlertService', '$translate', 'CardStatus'];

    function WallesterCardEditStatusController ($scope, $state, Wallester, formHelper, card, $stateParams, $filter, AlertService, $translate, CardStatus) {
        var vm = this;
        vm.kendo = kendo;
        console.log("WallesterCardEditStatusController");
        vm.newStatus = null;
        
        if($stateParams.card_id != null) {
	        vm.currentCard = card;
	        vm.statusStyle = CardStatus.getStyle(vm.currentCard.cardStatus, vm.currentCard.isActive);
        }
   //     vm.statCode = angular.copy(vm.currentCard.statCode);
        
        vm.statusDataSource = new kendo.data.DataSource({
    		data: []
        });
        
        var detailedStatus = vm.currentCard.cardStatus;
        if (detailedStatus === 'Blocked' && vm.currentCard.blockType)
        	detailedStatus = vm.currentCard.blockType;
        
        if (vm.currentCard.isActive){
        	if (detailedStatus != "Active")
        		vm.statusDataSource.add({ key: "Active", value: $translate.instant('modules.cardStatus.Active') });
        	if (detailedStatus != "BlockedByCardholderViaPhone")
        		vm.statusDataSource.add({ key: "BlockedByCardholderViaPhone", value: $translate.instant('modules.cardStatus.BlockedByCardholderViaPhone') });
        	if (detailedStatus != "BlockedByClient")
        		vm.statusDataSource.add({ key: "BlockedByClient", value: $translate.instant('modules.cardStatus.BlockedByClient') });
        	if (detailedStatus != "Lost")
        		vm.statusDataSource.add({ key: "Lost", value: $translate.instant('modules.cardStatus.Lost') });
        	if (detailedStatus != "Stolen")
        		vm.statusDataSource.add({ key: "Stolen", value: $translate.instant('modules.cardStatus.Stolen') });
        	
        } else {
        	vm.statusDataSource.add({ key: "NotDelivered", value: $translate.instant('modules.cardStatus.NotDelivered') });
        }
        
        
        
        vm.save = function(){
        	console.log("newStatus: " + vm.newStatus);
        	console.log("reason: " + vm.reason);
        	
        	vm.validator.validate();
        	if(vm.wallesterCardChangeStatus.$invalid || !vm.validator.validate()){
        		var errorFields = "";
        		AlertService.error("modules.loan.message.errorInForm");
        		return false;
        	}
        	
        	Wallester.status({loan_no : $state.params.loan_no},
        			{ loan_no: $stateParams.loan_no, card_id: $stateParams.card_id, new_status: vm.newStatus, reason: vm.reason},
        			function(result){
		        		if(result) {
		        			AlertService.success("modules.loan.message.statusChangedSuccessfully");
		        			$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id});
		        		}
	        		},
	        		function(error){
	        			console.log(error);
	        		}
        	).$promise;
        	
        };
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id});
        };
        
    }
    
})();

