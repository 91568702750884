(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ConditionsLoanController', ConditionsLoanController);

    ConditionsLoanController.$inject = ['$scope', '$state', 'menuWithHeader', 'Rights', 'baseUrl', '$window', '$interval', 'WsService', '$filter', 'Loan', 'AlertService', '$translate'];

    function ConditionsLoanController ($scope, $state, menuWithHeader, Rights, baseUrl, $window, $interval, WsService, $filter, Loan, AlertService, $translate) {
        var vm = this;
        console.log("Loan Conditions Controller started");
        vm.showAlter = true;
        var showAlterOnStart = 2000;
        $interval(function(){
        	vm.showAlter = false;
        },showAlterOnStart,1);
        vm.loan = menuWithHeader[0];
        vm.today = (new Date()).toLocaleDateString('lt-LT');
        
        vm.loanNo = $state.params.loan_no;
        
        vm.toPayLabel = vm.loan.debtTotal > 0 ? $translate.instant('modules.loan.toPayDebtTotal') : $translate.instant('modules.loan.toPay') ;
        
        vm.loanDate = vm.loan.loanDate != null ? kendo.toString( new Date(vm.loan.loanDate), 'd') : "";
        vm.debtDate = vm.loan.debtDate !=null ? kendo.toString( new Date(vm.loan.debtDate), 'd') : "";
        vm.graceEnabledOn = vm.loan.graceEnabledOn != null ? kendo.toString(new Date(vm.loan.graceEnabledOn), 'd') : "";
        vm.combMinPay = kendo.toString(vm.loan.trfMinPayPrc/100, "p") + " (min. " + kendo.toString(vm.loan.trfMinPayAmt, "n") + " " + vm.loan.currency + ")";
        vm.toPay = vm.loan.debtTotal > 0 ? kendo.toString( vm.loan.payTotal, ',####.00') + " (" + kendo.toString( vm.loan.debtTotal, ',####.00') + ")" : kendo.toString( vm.loan.payTotal, ',####.00') ;
        
        if (vm.loan.status == "TERMINATED"){
        	vm.toPayLabel = $translate.instant('modules.loan.toPayDebt');
        	vm.toPay = kendo.toString( vm.loan.debtTotal, ',####.00');
        }
        
        var reloadEvents = ['LOAN_RECALCULATED', 'LOAN_UPDATED'];
        vm.wsDisabled = false;
        
        WsService.receive().then(null, null, function(activity) {
        	if (!vm.wsDisabled){
        	    if(typeof activity === 'object') {
        	    	if(activity.hasOwnProperty('key') && activity.hasOwnProperty('type')) {
        	    		var reloadPage = $filter('filter')(reloadEvents, activity.type, false).length > 0  && activity.key == $state.params.loan_no ? true : false ;  
                    	if (reloadPage) { 
                    		console.log("reload page");
                    		$state.reload();
                    	}	
        	    	}
        	    }
        	}
	    });

        vm.printLoan = function(){
        	console.log('Printing Loan');
       		vm.pdflink = baseUrl + 'api/loans/' + $state.params.loan_no + '/docs/printpack';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
        
        vm.printAmendment = function(){
        	console.log('Printing amendment');
       		vm.pdflink = baseUrl + 'api/loans/' + $state.params.loan_no + '/docs/printamendment';
           	vm.pdf = vm.pdflink + "?tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
        
        vm.sendContractToEsign = function(){
        	console.log('Send Loan contract to esign');
        	vm.confirmPrepareClicked = true;
        	var pleaseWaitOperationID = AlertService.info("modules.loan.message.pleaseWaitOperation", { loan_no: $state.params.loan_no});
        	vm.wsDisabled = true;
            Loan.sendToEsign(
            		{loan_no : $state.params.loan_no},
            		{type: "CARD_LOAN_PACK"},
	                function (result) {
            			console.log(result);
                		AlertService.closeAlert(pleaseWaitOperationID.id, null);

            			if (result && result.status === "OK"){
	            			AlertService.success("modules.loan.message.successSendingToEsign", "");
            			} else {
            				console.log("ERROR!");
            				AlertService.error("modules.loan.message.somethingWentWrong", "");
            			}
            		},	
            		function(error){
            			AlertService.closeAlert(pleaseWaitOperationID.id, null);
            			console.log(error);
            			vm.confirmPrepareClicked = false;
            			vm.wsDisabled = false;
            		}
            ).$promise;	       	
        };
        
        vm.sendAmendmentToEsign = function(){
        	console.log('Send amendment to esign');
        	vm.confirmPrepareClicked = true;
        	var pleaseWaitOperationID = AlertService.info("modules.loan.message.pleaseWaitOperation", { loan_no: $state.params.loan_no});
        	vm.wsDisabled = true;
            Loan.sendToEsign(
            		{loan_no : $state.params.loan_no},
            		{type: "HC_AMENDMENT"},
	                function (result) {
            			console.log(result);
                		AlertService.closeAlert(pleaseWaitOperationID.id, null);

            			if (result && result.status === "OK"){
	            			AlertService.success("modules.loan.message.successSendingToEsign", "");
            			} else {
            				console.log("ERROR!");
            				AlertService.error("modules.loan.message.somethingWentWrong", "");
            			}
            		},	
            		function(error){
            			AlertService.closeAlert(pleaseWaitOperationID.id, null);
            			console.log(error);
            			vm.confirmPrepareClicked = false;
            			vm.wsDisabled = false;
            		}
            ).$promise;	       	
        };
        
        vm.penReport = function(){
        	console.log('Penalties report');
        	vm.reportLink = baseUrl + 'api/loans/' +  $state.params.loan_no + '/reports/split-pay';
        	vm.report = vm.reportLink + "?tmst="+ (new Date()).getTime();
        	$window.open(vm.report, '_blank');
        };
        
        vm.evolventLoan = function(){
        	console.log('Evolvent report');
        	vm.evolventLink = baseUrl + 'api/loans/' +  $state.params.loan_no + '/reports/evolvent';
        	console.log(vm.evolventLink);
        	vm.evolvent = vm.evolventLink + "?tmst="+ (new Date()).getTime();
        	console.log(vm.evolvent);
        	$window.open(vm.evolvent, '_blank');
        };
        
        vm.syncLoan = function(){
        	Loan.synchronization(
            		{loan_no : $state.params.loan_no},
            		{},
            		function(result){
    		    		if(result) {
    		    			AlertService.success("modules.loan.message.loanSyncAutoRefresh", { loan_no: $state.params.loan_no});
    		    		}
    	    		},
    	    		function(error){
    	    			console.log(error);
    	    		}
            ).$promise;
        };
        vm.confirmPrepare = function() {
        	vm.confirmPrepareClicked = true;
        	var pleaseWaitOperationID = AlertService.info("modules.loan.message.pleaseWaitOperation", { loan_no: $state.params.loan_no});
        	vm.wsDisabled = true;
            Loan.prepare(
            		{loan_no : $state.params.loan_no},
            		{},
	                function (result) {
                		AlertService.closeAlert(pleaseWaitOperationID.id, null);
            			AlertService.success("modules.loan.message.successLoanPreparing", { loan_no: $state.params.loan_no });
            			$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true}).then(function(){
                			vm.wsDisabled = false;
                		});
            		},	
            		function(error){
            			AlertService.closeAlert(pleaseWaitOperationID.id, null);
            			console.log(error);
            			vm.confirmPrepareClicked = false;
            			vm.wsDisabled = false;
            		}
            ).$promise;	
	    };
	    
	    vm.confirmActivate = function() {
        	vm.confirmActivateClicked = true;
        	var pleaseWaitOperationID = AlertService.info("modules.loan.message.pleaseWaitOperation", { loan_no: $state.params.loan_no});
        	vm.wsDisabled = true;
            Loan.activate(
            		{loan_no : $state.params.loan_no},
            		{},
            		function (result) {
            		    AlertService.closeAlert(pleaseWaitOperationID.id, null);
                		AlertService.success("modules.loan.message.successLoanActivating", { loan_no: $state.params.loan_no});
                		$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true}).then(function(){
                			vm.wsDisabled = false;
                		});
                    },
                    function (error) {
                    	AlertService.closeAlert(pleaseWaitOperationID.id, null);
                    	console.log(error);
                    	vm.confirmActivateClicked = false;
                    	vm.wsDisabled = false;
                    }
            ).$promise;
        };
        
	    
	    vm.clearConfirmActivate = function(){
	    	vm.confirmActivateClicked = false;
	    };
    }
})();
