(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .directive('docLink', docLink)
    	.directive('onFinishRenderFix', onFinishRenderFix)
    	.directive('isAllowed', isAllowed)
    	.directive('isAnyAllowed', isAnyAllowed)
    	.directive('kendoValidationFix', kendoValidationFix)
    	.directive('timedRecalc', timedRecalc)
    	;
    	
    	isAllowed.$inject = ['Rights', '$compile'];
    	isAnyAllowed.$inject = ['Rights', '$compile'];
    	timedRecalc.$inject = ['$rootScope', '$interval','calcRefresh'];    	
    	
    	function timedRecalc ($rootScope, $interval, calcRefresh) {
            var directive = {
                restrict: 'A',
                link: linkFunc,
                scope: {
                	calculate: '&timedRecalc',
                	applAmount: '=timedRecalcAmount',
                	financedAmount: '= timedRecalcFin',
                	personCode: '= timedRecalcCode'
                }
            };

            return directive;

            function linkFunc (scope, element, attrs, formCtrl) {
                var inFocus = false;
                var isWaiting = false;
                var run = $interval(recalculate, calcRefresh);
                
                element.find('input[name="applAmount"],input[name="personCode"],input[name="financedAmount"]')
                	.bind("focus", function (event) {
                		inFocus = true;
                	}).bind("keyup", function (event) {
                		if(inFocus) {
                			isWaiting = true;
                			$interval.cancel(run);
                			run = $interval(recalculate, calcRefresh);
                		} 
                	}).bind("blur", function (event) {
                		inFocus = false;
                		isWaiting = false;
                		$interval.cancel(run);
                	});
                
                function recalculate(){
                	if(!isWaiting) 
                		return;
                	scope.personCode = element.find('input[name="personCode"]').val();
                	if(element.find('input[name="applAmount"]').length > 0) {
                    	scope.applAmount = parseFloat(element.find('input[name="applAmount"]').val());
                	}
                	if(element.find('input[name="financedAmount"]').length > 0) {
                		scope.financedAmount = parseFloat(element.find('input[name="financedAmount"]').val());
                	}
                	isWaiting = false;
                }
                
                $rootScope.$on('$stateChangeStart', 
                		function(event, toState, toParams, fromState, fromParams) { 
                			$interval.cancel(run);
                });
            	
            }
        }
    	
    	
        function kendoValidationFix () {
            var directive = {
                restrict: 'A',
                require: 'form',
                link: linkFunc,
                scope: {
                	validator: '=kendoValidationFix',
                	options: '=kOptions'
                }
            };

            return directive;

            function linkFunc (scope, element, attrs, formCtrl) {
            	scope.validator =  element.kendoValidator({
                	validateOnBlur: true,
                	validateInput: function(e) {
                        var formGrp = angular.element(e.input.closest('.form-group.form-group-sm')[0]);
                        formGrp.toggleClass('has-error', !e.valid);
                    },        	
                	rules: {
            			datepicker: function(input) {
            	              if (input.is("[data-role=datepicker]")) {
            	                  if(input.context.required) {
            	                	  //for recovery after submit and fix error value
            	                	  var formGrp = angular.element(input.closest('.form-group.form-group-sm')[0]);
            	                	  formGrp.toggleClass('has-error', input.data("kendoDatePicker").value() === null);
            	                	  
            	                	  return input.data("kendoDatePicker").value() !== null;
            	                  }
            	                  else 
            	                	  return true;
            	                } else 
            	                	return true;
            	            }
            		}
                }).data("kendoValidator");
            	if(typeof scope.options != 'undefined') {
	            	for (var attrname in scope.options.rules) { 
	            		scope.validator.options.rules[attrname] = scope.options.rules[attrname]; 
	            	}
            	}
            }
        }
    	
    	function docLink() {
    		
    		var directive = {
    			template: '<a ui-sref="{{link}}">{{ innerText }}</a>',
    			replace: true,
    			restrict: 'EA',
    			scope: {
    				docId: '@docId',
    				docCode: '@docCode',
    				innerText: '@innerText'
    			},
    			controller: ['$scope', function($scope) {
    				
    				switch($scope.docCode.toUpperCase()){
    					case "APPL_HERCUS":
    						$scope.link = "modules.applications.detail({id:"+ $scope.docId+"})";
    						break;
    					case "SAPPL_HERCUS":
    						$scope.link = "modules.short-applications.detail({id:"+ $scope.docId+"})";
    						break;    					
    					case "LOAN_HERCUS":
    						$scope.link = "modules.loans.current.condition({loan_no:'"+ $scope.docId+"'})";
    						break;
    					case "CLIENT":
    						$scope.link = "modules.clients.view({clnId:"+ $scope.docId+"})";
    						break;
    					default:
    						console.log("Unknown document type: " + $scope.docCode.toUpperCase());
    						$scope.link = "";
    				}
    		
    			}]
    		};

    		return directive;
    	}

    	function isAllowed(Rights,$compile) {
    		
    		var directive = {
    			restrict: 'A',
    		    priority: -100,
    		    terminal: false,
    			scope: {
    				isAllowed: '@isAllowed'
    			},
    			compile: function(tElement, tAttrs) {
    					tElement.removeAttr('is-allowed');
    					tElement.removeAttr('inactive-state');
    					return function(scope, element, attrs) {
    						var notAllowed = !Rights.isAllowed(scope.isAllowed);
    						if(notAllowed) {
    							var state = typeof attrs.inactiveState === 'undefined' ? 'hidden' : attrs.inactiveState ;    
    							switch (state) {
    								case 'disabled':
    		    				    	attrs.$set('ngDisabled', notAllowed);   
    		    				    	attrs.$set('ngReadonly', notAllowed);
    									break;
    								case 'hidden':
        							default:	
        								attrs.$set('ngDisabled', notAllowed);
    		    				    	attrs.$set('ngHide', notAllowed);
    		    				    	attrs.$set('ngReadonly', notAllowed);
        								break;
    							}
		    				    $compile(element)(scope);
    						}
    					};
    			}
    		};

    		return directive;
    	}    	
    	
    	function isAnyAllowed(Rights,$compile) {
    		
    		var directive = {
    			restrict: 'A',
    		    priority: -100,
    		    terminal: false,
    			scope: {
    				isAnyAllowed: '@isAnyAllowed'
    			},
    			compile: function(tElement, tAttrs) {
    					tElement.removeAttr('is-any-allowed');
    					tElement.removeAttr('inactive-state');
    					return function(scope, element, attrs) {
    						var notAllowed = !Rights.isAnyAllowed(scope.isAnyAllowed);
    						if(notAllowed) {
    							var state = typeof attrs.inactiveState === 'undefined' ? 'hidden' : attrs.inactiveState ;    
    							switch (state) {
    								case 'disabled':
    		    				    	attrs.$set('ngDisabled', notAllowed);
    		    				    	attrs.$set('ngReadonly', notAllowed);
    									break;
    								case 'hidden':
        							default:	
        								attrs.$set('ngDisabled', notAllowed);
    		    				    	attrs.$set('ngHide', notAllowed);
    		    				    	attrs.$set('ngReadonly', notAllowed);
        								break;
    							}
		    				    $compile(element)(scope);
    						}
    					};
    			}
    		};

    		return directive;
    	}   
    	
    	function onFinishRenderFix(){
    		return function(scope, element, attrs){
                //var isTouchDevice = 'ontouchstart' in document.documentElement;
                //After last item is rendered, do the enclosed things
//                if(scope.$last && isTouchDevice === true){
//                    $('.overlay').removeClass('touch-placeholder');
//                    $('.button-planes').remove();
//                    $('.button-cars').remove();
//                    //Other things you want to do  
//                }
    			
    			var input = angular.element(element);
    			input.addClass("k-textboxssss");
            }
    	}
})();
