(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ShortApplicationController', ShortApplicationController);

    ShortApplicationController.$inject = ['Principal', 'detailMode', '$stateParams', '$rootScope','$scope', '$state', 'entity', '$translate', 'ShortApplication', 'AlertService', '$element', 'formHelper', '$location', '$interval', 'shortRefresh', 'longRefresh', 'Rights', '$window', 'baseUrl', 'shortDateFormats', '$filter', 'Cookies'];

    function ShortApplicationController (Principal, detailMode, $stateParams, $rootScope, $scope, $state, entity, $translate, ShortApplication, AlertService, $element, formHelper, $location, $interval, shortRefresh, longRefresh, Rights, $window, baseUrl, shortDateFormats, $filter, Cookies) {
    	formHelper.setFocus('first-field');
    	var vm = this;
    	vm.shortDateFormats = shortDateFormats;
    	vm.errorMessage = "";
    	vm.prevErrorMessage = "";
    	vm.errorTimestamp = null;
    	vm.error = false;
    	vm.detailMode = detailMode;
    	vm.confirmSubmitClicked = false;
    	vm.pleaseWaitOperationID = null;
    	vm.office = {};
    	vm.isLatekoPartner = false;
    	vm.salesPartnerName = "";
    	
    	vm.short = entity;
    	vm.sapplDate = vm.short.sapplDate != null ? kendo.toString( new Date(vm.short.sapplDate), 'd') : "";
    	
    	Principal.identity().then(function(account) {
            vm.account = account;
            vm.office.prtId = parseInt(Cookies.partnerID(vm.account.login));
            vm.office.offId = parseInt(Cookies.officeID(vm.account.login));
            if (vm.office.prtId <= 0 || vm.office.prtId == null || vm.office.offId <= 0 || vm.office.offId == null){
            	AlertService.error("global.messages.error.wrongPartnerOffice");
            };
        });
    	
    	Principal.hasAuthority("ROLE_LATEKO").then(function (result) {
    		if (result) {
    			vm.hasRoleLateko = true;
    		}else{
    			vm.hasRoleLateko = false;
    		};
    	});
    	
    	vm.salesChannelData = new kendo.data.DataSource({
    		data: [
    	    		{
    	    			id: "OTHER",
    					order: 6,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "WEB",
    					order: 1,
    					hidden: false,
    					disabled: false,
    	    		},{
    	    			id: "LANDING_PAGE",
    					order: 2,
    					hidden: false, 
    					disabled: false,   	    			
    	    		},{
    	    			id: "PARTNER",
    					order: 4,
    					hidden: false,
    					disabled: false,
    					option: [
    					         {
    					        	 id: "A",
    					        	 title: "titleA"
    					         },{
    					        	 id: "B",
    					        	 title: "titleA"
    					         },
    					]
    	    		},{
    	    			id: "CC_CALL",
    					order: 3,
    					hidden: false,  
    					disabled: false,  	    			
    	    		},{
    	    			id: "BRANCHES",
    					order: 5,
    					hidden: false,   
    					disabled: false, 	    			
    	    		}    
    		],
			sort: {
				field: "order",
				dir: "asc"
			}
    	});
    	
    	initCustomValidation();
    	
    	var mode = "view";
    	mode = detailMode.create ? "new" : mode;
    	
    	
        vm.result = vm.short.result ? vm.short.result[0] : {};
        
        if (vm.short.partnerId > 1){
        	vm.salesPartnerName = "(" + vm.short.partnerName + ")";
        }
        
        if(vm.short.agreement != null && vm.short.agreement.length > 0) {
        	vm.agreement = {};
        	for(var i = 0; i < vm.short.agreement.length; i++) {
        		switch (vm.short.agreement[i].agr_type) {
        		case "MARKETING":
        			vm.agreement.marketingAllowed = convertResponceAgreement(vm.short.agreement[i].agr_value); 
        			vm.agreement.marketingAllowedDate = vm.short.agreement[i].agr_date;
        			break;
        		}
        	}
//        	if(!vm.agreement.marketingAllowed) vm.vsaaOptionsDisable = true; 
        } else {
        	vm.agreement = {};
        	vm.agreement.marketingAllowed = null; 
        	vm.agreement.marketingAllowedDate = null;
			vm.agreement.socialInsurance = null;
			vm.agreement.socialInsuranceDate = null;
			vm.agreement.paidOut = null;
			vm.agreement.paidOutDate = null;
			vm.agreement.paidAmount = null;
			vm.agreement.paidAmountDate = null;
        }
         
        
        /* fake */
        
//        vm.result = {
//        		isEditable: true,
//        }
//        
//        
        if(vm.result.isEditable && mode != "new"){
        	mode =  "edit";
        	vm.isSalesChannelViewOnly = true;
        	vm.isViewOnly = false;
        } else if (mode != "new"){
        	vm.isViewOnly = true;
        	vm.isSalesChannelViewOnly = true;
        } else if(mode == "new") {
        	vm.isViewOnly = false;
        	vm.isSalesChannelViewOnly = false;
        }
        
        console.log("short application controller in '" + mode + "' mode");
        
        if(mode == "new") {
	    	if($stateParams.applSum != null) 
	    		vm.short.sapplSum = $stateParams.applSum;
	    	if($stateParams.personCode != null) 
	    		vm.short.personCode = $stateParams.personCode;
	    	if($stateParams.tgrId != null) 
	    		vm.short.tgrId = $stateParams.tgrId;
	    	if($stateParams.prtId != null && $stateParams.prtId > 1){
	    		vm.short.prtId = $stateParams.prtId;
	    		vm.short.salesChannel = "PARTNER";
	    	}
        }
        
    	vm.isNew = function(){
    		return mode == "new";
    	};
        
        function alertError(msg, params){
        	vm.errorMessage = $translate.instant(msg, params);
			vm.errorTimestamp = (new Date).getTime();
			vm.error = true;
        }
        
        $scope.$watch(
        	    function(){
        	    	return vm.error;
        	    },
        	    function(isError){
        	    	if(vm.errorMessage != "" && vm.errorTimestamp > (new Date).getTime() - 10000) {
	        	    	if(vm.errorMessage != vm.prevErrorMessage) {
	        	            AlertService.error(vm.errorMessage);
	        	    	} else {
	        	    		AlertService.clear();
	        	    		AlertService.error(vm.errorMessage);
	        	    	}
	        	    	vm.prevErrorMessage = vm.errorMessage;
	        	    	vm.error = false;
        	    	}
        	    }
            );
        
        $scope.$watch(
        	    function(){
        	    	return vm.office.prtId;
        	    },
        	    function(newValue,oldValue){
        	    	if (newValue == 1){
            	    	var dataItem = vm.salesChannelData.get('PARTNER');
                        dataItem.set("disabled","true");
                        vm.isLatekoPartner = false;
        	    	}else{
        	    		vm.isLatekoPartner = true;	
        	    	};
        	    }
        );
        
        if( mode != "new" ) {
        	var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.sapplStatus, vm.result.checkResult) : "blocked-error";
        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
        }
        
		vm.validatorOptions =  {    
		  	rules: {
		  		positiveIncome: function(input) {
			    	if (input.is("[data-role=numerictextbox]") && input.is("[name=wrkInc]")) {
			                	  //for recovery after submit and fix error value
			                	  if(input.data("kendoNumericTextBox").value() >= 0)
			                		  return true;
			                	  else {
			                		  vm.wrkIncError = true;
			                		  return false;
			                	  }
			              } else return true;
		  		},
				positiveObligation: function(input) {
			    	if (input.is("[data-role=numerictextbox]") && input.is("[name=monthOblig]")) {
			                	  //for recovery after submit and fix error value
			                	  if(input.data("kendoNumericTextBox").value() >= 0)
			                		  return true;
			                	  else {
			                		  vm.monthObligError = true;
			                		  return false;
			                	  }
			              } else return true;
		  		},
				positiveSum: function(input) {
			    	if (input.is("[data-role=numerictextbox]") && input.is("[name=sapplSum]")) {
			                	  //for recovery after submit and fix error value
			                	  if(input.data("kendoNumericTextBox").value() >= 0)
			                		  return true;
			                	  else {
			                		  vm.sapplSumError = true;
			                		  return false;
			                	  }
			              } else return true;
		  		}
		  	}
	    };
        
		function getAgreements(){
			console.log("Get agreements");
        	/* agreements validation */
        	var agrMarketing = convertAgreement("MARKETING",vm.agreement.marketingAllowed,vm.agreement.marketingAllowedDate);
        	
        	/*------------------------------*/
        	
        	vm.short.agreement = [];
        	var agreement = [];
        	
        	agreement.push(agrMarketing);
       	
        	vm.short.agreement = agreement;
			
		};
        
        function submitButtonDisable (){
        	if(vm.confirmSubmitClicked == false){
        		vm.confirmSubmitClicked = true;
        		console.log("submitButtonDisable");
        		vm.pleaseWaitOperationID = AlertService.add({
        			type: "info",
        			msg: "modules.application.message.pleaseWaitOperation",
        			timeout: 30000
        		});
        		vm.confirmSubmitTimeout = setTimeout(function(){submitButtonEnable();},30000);
        	} 
        };
        
        function submitButtonEnable (){
            vm.confirmSubmitClicked = false;
            console.log("submitButtonEnable");
            AlertService.closeAlert(vm.pleaseWaitOperationID.id, null);
            clearTimeout(vm.confirmSubmitTimeout);
        };
		
		vm.shortSubmit = function () {
        	
			// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	
        	/* sales channel validation */
            if (vm.hasRoleLateko) {
            	switch(mode) {
    	    		case "new":
    	    			if(vm.office.prtId == 1){
    	    				vm.short.partnerId = vm.office.prtId;
    	    				if(vm.short.salesChannel === "UNKNOWN" || vm.short.salesChannel === "PARTNER") {
    	                		vm.emptySalesChannel = true;
    	                	}else {
    	                    	vm.emptySalesChannel = false;
    	                	};
    	            	}else{
    	            		vm.short.partnerId = vm.office.prtId;
    	                	vm.short.salesChannel = "PARTNER";
    	                	vm.emptySalesChannel = false;
    	            	};
    	            	vm.short.officeId = vm.office.offId;
    	    			break;	
    	    		case "edit":
    	    			break;
    	    		case "view":
    	    			break;
    	    		default:
    	    	}
            } else {
            	if(mode == "new"){
            		vm.short.partnerId = vm.office.prtId;
                	vm.short.officeId = vm.office.offId;
                	vm.short.salesChannel = "PARTNER";
                	vm.emptySalesChannel = false;
            	}
            };
        	if(convertAgreement("MARKETING",vm.agreement.marketingAllowed,vm.agreement.marketingAllowedDate).agr_value.toUpperCase() === "NULL") {
        		alertError("modules.application.short.validate.emptyMarketingAgreement");
        		vm.marketingNoError = false;
        		return;
        	}
        	if (vm.emptySalesChannel){
        		return;
        	}
        	
        	//kvietimas getAgreements
        	getAgreements();
        	
        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  var name = (typeof $input.attr('name') != 'undefined') ? $input.attr('name') : "";
	                  
	                  
	                  if($input[0].tagName == "BC-PHONE-NUMBER") {
	                	  //var id = $input[0].id;
	                	  var id = name;
	                	  if($input.hasClass('ng-invalid')) {
	                		  $formGroup.toggleClass('has-error', true);
	                		  eval('vm.'+ id +'NoError = false;');
	                      	  alertError("modules.application.validate.wrongNumber");
	                	  }
	                	  return true;
	                  }
	                  if($input.hasClass('ng-invalid') || $input.hasClass('k-invalid')) $formGroup.toggleClass('has-error', true);
	              });
	            }
        			
        	});

        	console.log("submiting");

        	
        	if(vm.applicationForm.$invalid || !vm.validator.validate()){
        		alertError('modules.application.validate.applWrong');
            	if(!vm.mobPhoneNoError) vm.mobPhoneNoError = false;
        		formHelper.setFocus('first-field');
        		return;
        	}
        	
        	console.log("Application is VALID");

        	switch(mode) {
	        	case "new":
	        		if (vm.confirmSubmitClicked == true) break;
	        		submitButtonDisable();
	        		vm.short.result = [];
	        		ShortApplication.save(vm.short, 
	                    	function(result){
	        					vm.short = result;
		                    	/*On success*/
	        					submitButtonEnable();
		                    	AlertService.success("modules.alert.success.save");
		                    	console.log("saving application: success");
		                    	$state.go('modules.short-applications.detail',{id: result.id});
	                    	}, function(error){
	                    		/*On error*/
	                    		submitButtonEnable();
	                    		console.log("saving application: error");
	                    	});
	        		break;
	        	case "edit":
	        		if (vm.confirmSubmitClicked == true) break;
	        		submitButtonDisable();
	        		ShortApplication.update(vm.short, 
	                    	function(result){
	                    	/*On success*/
	        			    vm.short = result;
	        			    submitButtonEnable();
	                    	AlertService.success("modules.alert.success.update");
	                    	console.log("updating application: success");
	                    	$state.reload();
	                    	}, function(){
	                    	/*On error*/
	                    		//initCustomValidation();
	                    		submitButtonEnable();
	                    		console.log("updating application: error");
	                    	});
	        		break;
	        	case "view":
	        	default:
        	};
        };

        function convertResponceAgreement(tValue){
        	if(tValue != null && typeof(tValue) === "string") {
        		if(tValue.toUpperCase() === "AGREE")
        			return true;
        		else if(tValue.toUpperCase() === "DISAG")
        			return false;
        		else 
        			return null;
        	} else 
        		return null;
        }
        
        function convertAgreement(key,bValue,date) {
        	var value = "NULL";
        	if(bValue != null && typeof(bValue) === "boolean"){
        		if(bValue)
        			value = "AGREE";
        		else
        	        value = "DISAG";
        	}
        	return {
        		agr_type: key,
        	    agr_value: value,
        	    agr_date: date
        	};
        }
        
    	function initCustomValidation(){
        	vm.marketingNoError = true; 
        	vm.mobPhoneNoError = true;
        	vm.wrkIncError = false;
        	vm.monthObligError = false;
        	vm.sapplSumError = false; 
    	}
    	
    	function getResultMode(sapplStatus,checkResult){
    		var result = "";
    		switch(sapplStatus){
    			case "NEW":
	    		case "QUEUE_AUTO":
	    		case "QUEUE_MANUAL":
	    		case "IN_PROGRESS":
	    			result = "wait";
	    			break;	
	    		
	    		case "PROCESSED":
	    			switch(checkResult) {
	    				case "GRANTED":
	    					result = "granted";		
	    					break;
	    					
	    				case "REQUIREMENTS":
	    					result = "requirements";
	    					break;
	    					
	    				case "DENIED":
	    					result = "denied";
	    					break;
	    					
	    				default:
	    					result = "no-result";
	    			}
	    			break;
	    		
	    		case "CONTRACT_CREATED":
	    			result = "created";
	    			break;
	    			
	    		case "EXPIRED":
	    			result = "expired";
	    			break;
	    			
	    		case "BLOCKED":
	    		case "ERROR":
	    			result = "blocked-error";
	    			break;
	    		case "RECEIVED":
	    			result = "received";
	    			break;
    		}
    		return result;
    	}
    	
    	if(mode == "edit") {
    		startResultRefresh();
    	}
                
        vm.printForm = function(){
        	console.log('Printing Form Short Application');
        	console.log(mode);
        	getAgreements();
        	
        	var shortAppForVerify = angular.copy(vm.short);
        	delete(shortAppForVerify.result);
        	delete(shortAppForVerify.agreement);
        	
        	var shortAppForPrint = angular.copy(vm.short);
        	delete(shortAppForPrint.result);
        	
        	if (mode == "new"){
        		ShortApplication.verify(
            			shortAppForVerify, 
                    	function(result){
            				console.log("verifing short application: success");
        					console.log(result);
                        	/*On success*/
        					
        		            var enc = escape(
    	    	            			btoa(
    	    		            			unescape(
    	    		            					encodeURIComponent(
    	    		            							angular.toJson(shortAppForPrint)
    	    		            					)
    	    		            			)
    	    		            		)
    	    	            );
    	    	       		vm.pdflink = baseUrl + 'api/short-applications/docs/sappl';
    	    	           	vm.pdf = vm.pdflink + "?rq=" + enc + "&tmst="+ (new Date()).getTime();
    	    	       		$window.open(vm.pdf, '_blank');  					
                    	},
                    	function(error){
                    		/*On error*/
                    		console.log("verifing application: error");
                    	}
                );
        	}else{
        		var enc = escape(
                			btoa(
	                			unescape(
	                					encodeURIComponent(
	                							angular.toJson(shortAppForPrint)
	                					)
	                			)
	                		)
                );
       		    vm.pdflink = baseUrl + 'api/short-applications/docs/sappl';
           	    vm.pdf = vm.pdflink + "?rq=" + enc + "&tmst="+ (new Date()).getTime();
       		    $window.open(vm.pdf, '_blank');
        	}

        };        

        vm.getDataFromBonus = function () {
        	if(vm.short.personCode == null || vm.short.personCode == "") return;
        	ShortApplication.getPersonalDataFromBonus(
        			{personCode: vm.short.personCode}, 
                	function(result){
                    	/*On success*/
        				console.log("Loaded Personal Data for Client");
        				if(result.personCode != null && result.personCode !="") {
        					vm.short.firstName   = result.firstName;
        					vm.short.lastName    = result.lastName;
        					vm.short.mobPhone    = result.mobPhone;
        					vm.short.email       = result.email;
        					vm.short.wrkInc		= result.workIncome;
        					vm.short.monthOblig      = result.loanPay; //monthOblig ?= loanPay
//        					vm.short.salesChannel = "CC_CALL";
        				}
                	},
                	function(error){
                		/*On error*/
                		console.log("Error on Loading Personal Data for Client");
                	}
            );
        };
        
    	function startResultRefresh(){
    		var run = $interval(reload, vm.refresh);
	        
	    	vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
	    	
	        function reload(){
	        	console.log('refreshing ... ' + vm.refresh );
	        	ShortApplication.getResult(
	        			{id : $stateParams.id},
	        			function(response){
							vm.result = response;
							var checkError = vm.result.checkError ? vm.result.checkError.toUpperCase() : "";
				        	vm.resultMode = checkError == "OK" || checkError == "" ? getResultMode(vm.result.sapplStatus, vm.result.checkResult) : "blocked-error";
				        	vm.requirements = vm.result.request_list ? vm.result.request_list.split('#') : "";
					        vm.refresh = vm.resultMode == "wait" ? shortRefresh : longRefresh;
						},
						function(error){
							console.log('error on refresh result');
							if(error.data.err) {
								$interval.cancel(run);
								vm.resultMode = "server-error";
								alertError(error.data.msg);
							}
						}).$promise;
	        }
	        
	        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
	        	$interval.cancel(run);
	        	run = $interval(reload, vm.refresh);
	        }, true);
	        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
	        	$interval.cancel(run);
	        });
	        
	        $scope.$watch(
	        		function(){
	        			if(mode == 'new') return null;
	        			else return angular.toJson(vm.short);
	        		}, 
	        		function(newApp,oldApp) {
	        			if(!angular.equals(newApp,oldApp)) {
	        				vm.disablePrint = true;
	        			}
	        		}
	        );
	        
	        $scope.$watch(
	        		function(){
	        			if(mode == 'new') return null;
	        			else return angular.toJson(vm.agreement);
	        		}, 
	        		function(newApp,oldApp) {
	        			if(!angular.equals(newApp,oldApp)) {
	        				vm.disablePrint = true;
	        			}
	        		}
	        );
	        
	        vm.changeEvent = function(){
	        	if(mode != 'new')
	        		vm.disablePrint = true;
	        };
	        
    	}
    }
})();
