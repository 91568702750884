(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('TransactionEditLoanController', TransactionEditLoanController);

    TransactionEditLoanController.$inject = ['Principal', '$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$translate', 'detailMode', 'transaction'];

    function TransactionEditLoanController (Principal, $scope, $state, $stateParams, Loan, defaultPageSize, $translate, detailMode, transaction) {
        var vm = this;
        vm.kendo = kendo;
        vm.editMode = detailMode.edit;
      	vm.createMode = detailMode.create;
      	vm.currentTransaction = transaction ? transaction : {} ;
      	vm.tranId = $stateParams.tranId;
      	vm.balDate = new Date(vm.currentTransaction.date).toLocaleDateString('lt-LT');
      	vm.disableSave = true;
      	
      	Principal.identity().then(function (account) {
      		vm.right = account.login === 'valsin' || account.authorities.indexOf("ROLE_DEVELOPER") >= 0;
      		if(!vm.right) $state.go('modules.loans.current.condition',{reload:true});
      	});
      	
      	vm.dateFormat = kendo.culture().calendar.patterns.d;
      	vm.pickerOptions = {
      		change: function(e){
      			$state.go("modules.loans.current.accounting.balances", { balDate: vm.balDate, tranId: vm.tranId }, {reload: true});
      		}
      	};
        console.log("TransactionEditLoanController started");
        
        vm.save = function(){
        	if(!vm.right) return;
        	
        	vm.currentTransaction.lines = vm.loanTransactionDataSource.data();

        	Loan.updateTransaction(
        			vm.currentTransaction,
        			function(response){
        				$state.go('modules.loans.current.accounting.transaction',{tranId: vm.tranId}, {reload:true});
        			},
        			function(error){
						console.log("transaction update error, id: " +  vm.tranId);
        			}
        	).$promise;
        };
        
        
        $scope.$watch(
        		function(){
        			vm.currentTransaction.lines = vm.loanTransactionDataSource.data();
        			return angular.toJson(vm.currentTransaction);
        		}, 
        		function(newTr,oldTr) {
        			if(!angular.equals(newTr,oldTr)) {
        				vm.disableSave = false;
        			}
        		}
        );
        
        vm.loanTransactionDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "id",  
			    	fields: {
			    	  "id": {type: "number" },
			    	  "lineNo": { type: "number" }, //editable: false, 
			          "cGl": { type: "string" },
			          "component": { type: "string" },
			          "cre": { type: "number" },
			          "subAcc": { type: "string" },
			          "crtdBy": { type: "string" },
			          "crtdOn": { type: "date" },
			          "deb": { type: "number" },
			          "gl": { type: "string" },
			          "updtBy": { type: "string" },
			          "updtOn": { type: "date" },
			          "ver": { type: "number" },
			          "rem": { type: "string" }
			        }
			      }
    		},
    		data: vm.currentTransaction.lines,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
	        aggregate: [ 
			            { field: "deb", aggregate: "sum"},
			            { field: "cre", aggregate: "sum"},
			],
            sort: [
                   { field: "lineNo", dir: "asc" }
            ]
        });
        vm.loanTransactionOptions = {
        		scrollable: true,
                sortable: false,                
                pageable: false,
                filterable: false,
                edit: function(e) {
                    var commandCell = e.container.find("td:last");
                    commandCell.html(
                    		"<a ng-show='vm.right' class='btn btn-xs btn-primary k-grid-update'><span class='glyphicon glyphicon-ok-sign'></span></a>" +
                    		"<a ng-show='vm.right' class='btn btn-xs btn-default k-grid-cancel'><span class='glyphicon glyphicon-ban-circle'></span></a>"
                    );
                },
                toolbar: [
					{ 
						template: "<button ng-show='vm.right' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-add'>"
					      	+ "<span class='glyphicon glyphicon-plus-sign'></span>"
					        + "&nbsp;<span translate='modules.transaction.addLine'>"
					        + "</span></button>"
					}
                ],
                columns: [
                          	{ 
								field: "lineNo", 
							    title: "{{'modules.transaction.table.no'|translate}}",
							    format: "{0:n0}",
							    width: "7ch",
							},{ 
								field: "component", 
							    title: "{{'modules.transaction.table.component'|translate}}",
							    width: "15ch"
							},{ 
								field: "gl", 
							    title: "{{'modules.transaction.table.gl'|translate}}",
							    width: "8ch",
							    footerTemplate: "{{'modules.transaction.table.total'|translate}}:",
						        footerAttributes: {class: "text-right"}
							},{ 
								field: "deb",
								title: "{{'modules.transaction.table.deb'|translate}}",
							    attributes: { class: "text-right" },
							    aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    format: "{0:n2}",
							    width: "10ch"
							},{ 
								field: "cre",
								title: "{{'modules.transaction.table.cre'|translate}}",
							    attributes: { class: "text-right" },
							    aggregates: ["sum"], 
						        footerTemplate: "#= kendo.toString(sum, 'n2') #",
						        footerAttributes: {class: "text-right"},
							    format: "{0:n2}",
							    width: "10ch"
							},{ 
								field: "subAcc",
								title: "{{'modules.transaction.table.subAcc'|translate}}",
								width: "14ch"
							},{ 
								field: "cGl",
								title: "{{'modules.transaction.table.cGl'|translate}}",
								width: "8ch"
							},{ 
								field: "rem",
								title: "{{'modules.transaction.table.notes'|translate}}"
							},{ 
								command: [
							            { name: "edit", template: "<a ng-show='vm.right' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.edit' | translate }}'\" class='btn btn-xs btn-default k-grid-edit'><span class='glyphicon glyphicon-pencil'></a>" }, //k-button 
							            { name: "destroy", template: "<a ng-show='vm.right' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.delete' | translate }}'\" class='btn btn-xs btn-danger k-grid-delete'><span class='glyphicon glyphicon-remove-circle'></span></a>" }, //k-button
							    ],
							    title: "&nbsp;", width: "8ch" 
							}
						],
				editable: "inline"
                            	
        };
        
    }
    
})();
