(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modules.short-applications', {
            parent: 'modules',
            url: '/short-applications',
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/short-application/short-applications.html',
                    controller: 'ShortApplicationsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('modules/utils');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }]
            }
        })
        .state('modules.short-applications.detail', {
            parent: 'modules',
            url: '/short-applications/{id}',
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/short-application/short-application-dialog.html',
                    controller: 'ShortApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'ShortApplication', function($stateParams, ShortApplication) {
                    return ShortApplication.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
				detailMode: function(){
                	return {
                		edit: true,
                		create: false
                	};
                }
            }
        })
        .state('modules.short-applications.detail.delete', {
			url : '/delete',
			parent : 'modules.short-applications.detail',
	        data: {
	            authorities: ['ROLE_EXECUTIVE', 'ROLE_ADMIN'],
	        },
	        onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	            $uibModal.open({
	                templateUrl: 'app/modules/short-application/delete.short-application.template.html',
	                controller: 'DeleteShortApplicationController',
	                controllerAs: 'vm',
	                size: 'md'
	            });
	        }]
	    })
        .state('modules.short-applications.new', {
            parent: 'modules',
            url: '/short-applications/new/',
            params: { personCode: null, applSum: null, tgrId: null, prtId: null },
            data: {
            	authorities: ['ROLE_USER'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/short-application/short-application-dialog.html',
                    controller: 'ShortApplicationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('modules/application');
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }],
                entity: function () {
                	var now = new Date(); 
                    return {
	                    	firstName: "",
	                    	lastName: "",
	                    	personCode: "",
	                    	salesChannel: "UNKNOWN",
	                    	mobPhone: "",
	                    	email: "",
	                    	wrkInc: null,
	                    	monthOblig: null,
	                    	sapplDate: now,
	                    	partnerId: 1,
	                    	politicalPerson: 1,
	                    	beneficiary: 2
	                    	//,
	                    	//sapplSum: 0
                    };
                },
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'modules.applications',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }],
				detailMode: function(){
                	return {
                		edit: true,
                		create: true
                	};
                }
            }
        });
    }

})();
