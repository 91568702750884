(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('PrepareLoanController', PrepareLoanController);

    PrepareLoanController.$inject = ['$uibModalInstance', '$state', 'Loan', 'AlertService'];

    function PrepareLoanController ($uibModalInstance, $state, Loan, AlertService) {
        var vm = this;

        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorLoanActivating");
        	return;
        }
 
        
        vm.loanNo = $state.params.loan_no; 
        vm.clear = clear;
        vm.confirmPrepare = confirmPrepare;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmPrepare (loanNo) {
        	$uibModalInstance.close(true);
            Loan.prepare(
            		{ loan_no : loanNo },
            		{ },
	                function () {
            			AlertService.success("modules.loan.message.successLoanPreparing", { loan_no: loanNo });
	                    $uibModalInstance.close(true);
	                });
        }
    }
})();
