(function() {
	'use strict';
	angular.module('sigmaApp').factory('Docs', Docs);

	Docs.$inject = [ '$resource', 'baseUrl'];

	function Docs($resource, baseUrl) {
		var resourceUrl = baseUrl + 'api/setup/docs/save';
		
		return $resource(resourceUrl, {}, {
			'save': { method: 'POST' },
        });	
	}
})();