(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteLoanController', DeleteLoanController);

    DeleteLoanController.$inject = ['$uibModalInstance', '$state', 'Loan', 'AlertService'];

    function DeleteLoanController ($uibModalInstance, $state, Loan, AlertService) {
        var vm = this;

        if($state.params.loan_no == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.loan.message.errorLoanDeleting");
        	return;
        }
 
        
        vm.loanNo = $state.params.loan_no; 
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
        }

        function confirmDelete (loanNo) {
        	$uibModalInstance.close(true);
            Loan.delete({loan_no: loanNo},
                function (result) {
            		AlertService.success("modules.loan.message.successLoanDeleting", { loan_no: loanNo});
                    $uibModalInstance.close(true);
                    $state.go('modules.loans.new', {applId: $state.params.applId});
                },
                function (error) {
            		AlertService.error("modules.loan.message.errorLoanDeleting");
                    $uibModalInstance.close(true);
                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
                });
        }
    }
})();
