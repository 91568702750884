(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('NonameCardController', NonameCardController);

    NonameCardController.$inject = ['$scope', '$state', '$stateParams', 'Rights', 'menuWithHeader'];

    function NonameCardController ($scope, $state, $stateParams, Rights, menuWithHeader) {
        console.log("NonameCardGenerateController ...");
        Rights.init(menuWithHeader[0].rights);
    }
    
})();
