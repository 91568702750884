(function() {
	'use strict';
	angular.module('sigmaApp').factory('DebtActionLog', DebtActionLog);

	DebtActionLog.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function DebtActionLog($resource, baseUrl, DateUtils) {
		
		var resourceUrl = baseUrl + 'api/insp/action-logs/';

		return $resource(resourceUrl,{endPoint: '@endPoint'},
				{	
			        'getCalculatedTerminationByLoanAndDate' : {
			        	method : 'GET',
			        	url: resourceUrl + ':loan_no/calc-termination/:date',
			        	isArray : true,
			        	transformResponse : function(data) {
			        		if (data) {
			        			try {
			        				data = angular.fromJson(data);
			        			} catch(e){
			        				data = "";
			        			}
			        		}
			        		return data;
			        	}
			        },
					'getHistoryByClient' : {
						method : 'GET',
						url: resourceUrl + 'by-client/:clnId',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getHistoryByLoan' : {
						method : 'GET',
						url: resourceUrl + ':loan_no',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getPossibleActionsByClient' : {
						method : 'GET',
						url: resourceUrl + 'by-client/:clnId/possible-actions',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getPossibleActionsByLoan' : {
						method : 'GET',
						url: resourceUrl + ':loan_no/possible-actions',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getTerminationAmounts' :{
						method : 'GET',
						url: resourceUrl + ':loan_no/termination-amounts',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getAmountsPaidAfterTermination' :{
						method : 'GET',
						url: resourceUrl + ':loan_no/paid-after-termination/:date',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'runAction' : {
						method : 'POST',
						url: baseUrl + 'api/insp/action-logs/:endPoint',
						transformRequest : function(data) {
							if (data.detail != null)
								data.detail[0].onDate = DateUtils.convertLocalDateToServer(data.detail[0].onDate);
							if (data.fromDate != null)
								data.fromDate = DateUtils.convertLocalDateToServer(data.fromDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = {nsError:"wrongData"};
								}
							}
							return data;
						}
					}
				});
	}
})();