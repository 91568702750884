(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LoanMenuController', LoanMenuController);

    LoanMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation', 'menuWithHeader', 'Rights'];

    function LoanMenuController ($scope, $state, $stateParams, eehNavigation, menuWithHeader, Rights) {
        var vm = this;
        console.log("LoanMenuController ...");
        
        
        Rights.init(menuWithHeader[0].rights);
        
        eehNavigation
			    .menuItem('sidenav.loan', {
		          text: 'modules.loan.menu.loan',
		          iconClass: 'fa-list-alt',
		          isCollapsed: false,
		          weight: -10
		        })
		        .menuItem('sidenav.loan.conditions', {
		          text: 'modules.loan.menu.conditions',
		          iconClass: 'fa-check-circle-o',
		          state: 'modules.loans.current.condition',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CONDITION_VIEW')		          
		        })
		        .menuItem('sidenav.loan.operations', {
		          text: 'modules.loan.menu.operations',
		          iconClass: 'fa-random',
		          state: 'modules.loans.current.operations',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_OPERATIONS_VIEW')
		        })
		        .menuItem('sidenav.loan.payments', {
		          text: 'modules.loan.menu.payments',
		          iconClass: 'fa-money',
		          state: 'modules.loans.current.payments',
		          weight: -10,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_PAYMENTS_VIEW')
		        })
		        .menuItem('sidenav.loan.schedules', {
		          text: 'modules.loan.menu.schedules',
		          iconClass: 'fa-calendar',
		          state: 'modules.loans.current.schedules',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_SCHEDULES_VIEW')
		        })
		        .menuItem('sidenav.loan.interests', {
		          text: 'modules.loan.menu.interestRates',
		          iconClass: 'fa-percent',
		          state: 'modules.loans.current.interests',
		          weight: -8,
		          isVisible: Rights.isAnyAllowed('ALLOW_LOAN_INTERESTS_VIEW, ALLOW_LOAN_INTERESTS_EDIT, ALLOW_LOAN_INTERESTS_DELETE')
		        })
		        .menuItem('sidenav.loan.accrues', {
		          text: 'modules.loan.menu.accrues',
		          iconClass: 'fa-bar-chart',
		          state: 'modules.loans.current.accrues',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_ACCRUE_VIEW')
		        })		        
		        .menuItem('sidenav.loan.accountingTransactions', {
		          text: 'modules.loan.menu.accountingTransactions',
		          iconClass: 'fa-retweet',
		          state: 'modules.loans.current.accounting',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_ACCOUNTING_TRANSACTIONS_VIEW')
		        })
		        .menuItem('sidenav.loan.debtActionLog', {
		          text: 'modules.loan.menu.debtActionLog',
		          iconClass: 'fa-list-alt',
		          state: 'modules.loans.current.debt-action-log',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_DEBT_ACTION_LOG_VIEW')
		        })
		        .menuItem('sidenav.loan.edocs', {
		          text: 'modules.loan.menu.edocs',
		          iconClass: 'fa-list-alt',
		          state: 'modules.loans.current.edocs',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_EDOCS')
		        })
		        .menuItem('sidenav.loan.history', {
		          text: 'modules.loan.menu.history',
		          iconClass: 'fa-history',
		          state: 'modules.loans.current.history',
		          weight: -8,
		          isVisible: Rights.isAllowed('ALLOW_LOAN_HISTORY_VIEW')
		        })
		        .menuItem('sidenav.cards-wallester', {
		          text: 'modules.loan.menu.cards-wallester',
		          iconClass: 'fa-credit-card',
		          isCollapsed: false,
		          weight: -6,
		        })
		        .menuItem('sidenav.cards-wallester.wallester-firstcard-create', {
			      text: 'modules.loan.menu.orderCard',
			      iconClass: 'fa-handshake-o',
			      weight: -6,
			      state: 'modules.loans.current.wallester-firstcard-create',
			      isVisible: Rights.isAllowed('ALLOW_LOAN_WAL_FIRSTCARD_CREATE')
			    })
			    .menuItem('sidenav.cards-wallester.linkNoNameCard', {
		          text: 'modules.loan.menu.orderNoNameCard',
		          iconClass: 'fa-handshake-o',
		          weight: -6,
		          state: 'modules.loans.current.link-noname-card-wallester',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_NONAME_WAL_CARD_LINK')
		        })
		        .menuItem('sidenav.cards-wallester.person', {
		          text: 'modules.loan.menu.cardHolder',
		          iconClass: 'fa-user-circle-o',
		          weight: -6,
		          state: 'modules.loans.current.wallester-person',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_WAL_PERSON_VIEW')
		        })
		        .menuItem('sidenav.cards-wallester.list', {
		          text: 'modules.loan.menu.cardsList',
		          iconClass: 'fa-credit-card',
		          weight: -6,
		          state: 'modules.loans.current.wallester-cards',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_WAL_CARDS_VIEW')
		        })
		        .menuItem('sidenav.cards', {
		          text: 'modules.loan.menu.cards',
		          iconClass: 'fa-credit-card',
		          isCollapsed: false,
		          weight: -6,
		        })
		        .menuItem('sidenav.cards.order', {
			      text: 'modules.loan.menu.orderCard',
			      iconClass: 'fa-handshake-o',
			      weight: -6,
			      state: 'modules.loans.current.order-card',
			      isVisible: Rights.isAllowed('ALLOW_LOAN_CARDHOLDER_CREATE')
			    })
			    .menuItem('sidenav.cards.orderNoNameCard', {
		          text: 'modules.loan.menu.orderNoNameCard',
		          iconClass: 'fa-handshake-o',
		          weight: -6,
		          state: 'modules.loans.current.order-noname-card',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_NONAME_CARDHOLDER_LINK')
		        })
		        .menuItem('sidenav.cards.holder', {
		          text: 'modules.loan.menu.cardHolder',
		          iconClass: 'fa-user-circle-o',
		          weight: -6,
		          state: 'modules.loans.current.cardholder',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CARDHOLDER_VIEW')
		        })
		        .menuItem('sidenav.cards.list', {
		          text: 'modules.loan.menu.cardsList',
		          iconClass: 'fa-credit-card',
		          weight: -6,
		          state: 'modules.loans.current.cards',
		          isVisible: Rights.isAllowed('ALLOW_LOAN_CARDS_VIEW')
		        })
		        .iconBaseClass('fa');
    }
    
})();
