(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HeaderClientController', HeaderClientController);

    HeaderClientController.$inject = ['$scope', '$state', 'currentClient'];

    function HeaderClientController ($scope, $state, currentClient) {
        var vm = this;
        console.log("Header Client Controller started");
        
        
        vm.currentClient = currentClient[0];
        
        vm.fullName = vm.currentClient.name + " " + vm.currentClient.surname;
        
    }
})();
