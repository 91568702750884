(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterCardSendSecretController', WallesterCardSendSecretController);

    WallesterCardSendSecretController.$inject = ['$scope', '$state', 'details', '$stateParams', 'AlertService', 'Wallester'];

    function WallesterCardSendSecretController ($scope, $state, details, $stateParams, AlertService, Wallester) {
        var vm = this;
        console.log('HAHA - WallesterCardSendSecretController');

        vm.cardStatus = '';
        vm.maskedPan = 'xxxx xxxx xxxx xxxx';
        vm.embossingName = '';
        vm.recipientPhone = '';
        vm.secretType = 'CPIN';
        
        if (details){
        	vm.maskedPan = details.maskedCardNumber + ", " + details.cardStatus + ", " + details.embossingName;
        	vm.embossingName = details.embossingName;
        	vm.cardStatus = details.cardStatus;
        	vm.recipientPhone = details.defaultMobPhone;
        }
        
    	vm.secretTypeData = new kendo.data.DataSource({
    		data: [
    	    		{
    	    			id: "CPIN",
    					order: 1,
    	    		},{
    	    			id: "3DSP",
    					order: 2,
    	    		},{
    	    			id: "CVV2",
    					order: 3,
    	    		},{
    	    			id: "CNUMB",
    					order: 4,
    	    		}    
    		],
			sort: {
				field: "order",
				dir: "asc"
			}
    	});
    	
    	vm.sendSms = function (){
    		var requestBody = {
    			secretCode: vm.secretType,
    			mobPhone: vm.recipientPhone
    		}
//    		console.log(requestBody);
    		
    		
        	Wallester.sendCardSecret( {loan_no : $state.params.loan_no, card_id: $stateParams.card_id},
        			requestBody,
        			function(result){
		        		if(result) {
		        			AlertService.success("modules.loan.sendCardSecret.sendSuccessMessage");
		        //			vm.formCard = result;
		        //			delete vm.formCard.reference;
		        //			delete vm.formCard.reason;
		        //			vm.currentCard = angular.copy(result);
		        //			vm.currentCard.bal = vm.bal;
		    //    			$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id}, {reload: false});
		        		}
	        		},
	        		function(error){
	        			console.log(error);
	        		}
        	).$promise;    		
    	}
        vm.cancel = function(){
        	$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id});
        };
        
    }
    
})();

