(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('TransactionListLoanController', TransactionListLoanController);

    TransactionListLoanController.$inject = ['Principal', '$scope', '$state', 'Loan', 'defaultPageSize', '$translate', '$window', '$filter'];

    function TransactionListLoanController (Principal, $scope, $state, Loan, defaultPageSize, $translate, $window, $filter) {
        var vm = this;
        vm.kendo = kendo;
		var codes = {
				data: []
			};
		var codesTranslated = {
				data: []
			};
        console.log("TransactionListLoanController started");
        console.log($state.current.parent.page);
      	Principal.identity().then(function (account) {
      		vm.right = account.login === 'valsin' || account.authorities.indexOf("ROLE_DEVELOPER") >= 0 || account.authorities.indexOf("ROLE_ACCOUNTANT") >= 0;
      		if(!vm.right) $state.go('modules.loans.current.condition',{reload:true});
      	});
        vm.loanTransactionsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "tranId",
			    	fields: {
			          "code": { type: "string" },
			          "codeTranslated": { type: "string" },
			          "crtdBy": { type: "string" },
			          "crtdOn": { type: "date" },
			          "date": { type: "date" },
			          "des": { type: "string" },
			          "loanNo": { type: "string" },
			          "number": { type: "string" },
			          "sts": { type: "number" },
			          "totalCre": { type: "number" },
			          "totalDeb": { type: "number" },
			          "tranId": { type: "number" },
			          "updtBy": { type: "string" },
			          "updtOn": { type: "date" },
			          "ver": { type: "number" }
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.codeTranslated = elem.code ? $translate.instant('modules.accounting.transactionType.' + elem.code) : "";
	                        
	                        var code = {
 		              				code: elem.code
 		              		};
 		              		var foundCode = $filter('filter')(codes.data, {code: elem.code}, true);
 		                    if(foundCode.length < 1) (codes.data).push(code);
 		                    
	                        var codeTranslated = {
	                        		codeTranslated: elem.codeTranslated
 		              		};
 		              		var foundCodeTranslated = $filter('filter')(codesTranslated.data, {codeTranslated: elem.codeTranslated}, true);
 		                    if(foundCodeTranslated.length < 1) (codesTranslated.data).push(codeTranslated);
 		                    
	                    });
	                	return d;
	              }
    		},
    		transport: {
				read: function(e){
					Loan.getTransactions(
							{loan_no : $state.params.loan_no},
							function(response){
								if(vm.right) e.success(response);
							},
							function(error){
								console.log('transactions list error');
								e.error(error);
							}
					).$promise;
				}
			},
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "date",
                dir: "desc"
            }
        });
        
        vm.loanTransactionsOptions = {
                scrollable: {
                    virtual: true
                },
                height: function(){
                	return $window.innerHeight/2.5;
                },
                sortable: true,                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info:false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          { 
		                    template: "<span style='line-height:22px;' translate='modules.transaction.titles.accountingTransactions'></span>"

		                    		 + "<button ng-show='vm.right' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
		                    		 + "<span class='fa fa-file-excel-o'></span>"
		                             + "&nbsp;<span translate='modules.transaction.excel'>"
		                             
		                    		 + "<button ng-show='vm.right' style='margin-right:1em;' class='pull-right btn btn-warning btn-xs' ui-sref='modules.loans.current.accounting.annulate-penalty'>"
		                    		 + "<span class='fa fa-ban'></span>"
		                             + "&nbsp;<span translate='modules.transaction.annulatePenalty'>"
//  		                             + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loans.current.accounting.transaction.create'>"
//		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
//		                             + "&nbsp;<span translate='modules.loan.add'>"
//		                             + "</span></button>"
                          }
                ],
                excel: {
                	fileName: "loan-accounting-transactions.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },                
                columns: [
							{ 
								field: "date", 
						        title: "{{'modules.transaction.table.date'|translate}}",
						        width: "11ch",
						        format: "{0:d}",
						        filterable: {
			                    	ui: "datepicker"
			                    }
							},{ 
								field: "code", 
							    title: "{{'modules.transaction.table.code'|translate}}",
							    width: "11ch",
		                        filterable : {
					            	multi : true,
					                dataSource : new kendo.data.DataSource(codes),
					                dataTextField : "code"
		                        },
							    sortable: true
							},{ 
								field: "number", 
							    title: "{{'modules.transaction.table.number'|translate}}",
							    width: "12ch",
							    minScreenWidth: 1216,
							    sortable: true
							},{ 
								field: "totalDeb",
								title: "{{'modules.transaction.table.deb'|translate}}",
							    attributes: { class: "text-right" },
							    sortable: true,
							    format: "{0:n2}",
							    width: "11ch"
							},{ 
								field: "totalCre",
								title: "{{'modules.transaction.table.cre'|translate}}",
							    attributes: { class: "text-right" },
							    sortable: true,
							    format: "{0:n2}",
							    width: "11ch"
							},{ 
								field: "codeTranslated", 
							    title: "{{'modules.transaction.table.codeTranslated'|translate}}",
							    filterable : {
					            	multi : true,
					                dataSource : new kendo.data.DataSource(codesTranslated),
					                dataTextField : "codeTranslated"
		                        },
							    sortable: true,
							    minScreenWidth: 992,
							},{
		                    	title: "{{'modules.transaction.table.actions'|translate}}",
		                    	template: "<button ng-show='vm.right' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.accounting.transaction({tranId: \"#:tranId#\"})'>" //ui-sref-opts='{reload: true}'
		                            + "<span class='glyphicon glyphicon-eye-open'></span>"
		                            + "</button>"
		                            + "<button ng-show='vm.right' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'modules.transaction.titles.balances' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.accounting.balances({balDate: \"#:date.toLocaleDateString('lt-LT')#\", tranId: \"#:tranId#\"})'>"  //ui-sref-opts='{reload: true}'
		                            + "<span class='fa fa-balance-scale'></span>"
		                            + "</button>"
		                        ,
		                        width: "9ch" 
		                    }
						]
                            	
        };
       
    }
    
})();
