(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ApplicationReportController', ApplicationReportController);

    ApplicationReportController.$inject = ['$scope', '$rootScope', '$state', 'stat', '$translate', 'shortRefresh', '$interval', 'baseUrl'];

    function ApplicationReportController ($scope, $rootScope, $state, stat, $translate, shortRefresh, $interval, baseUrl) {
        var vm = this;
        vm.reload = reload;
        vm.customColor = customColor;
        vm.test = 'red';
        vm.testar = ['red', "blue"];
        console.log(vm.test);
        function reload(){
        	vm.reportApplicationCountDataSource.read();
        	vm.reportApplicationTotalCountDataSource.read();
        };
        
        vm.reportApplicationCountDataSource = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=APPL_STATUS_COUNT&skip=&lastdays=60",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				}
			},
			group : [{ field: "kpiDesc", dir: "asc"}],
			serverPaging : false,
		});
        
        vm.reportApplicationTotalCountDataSource = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=APPL_STATUS_COUNT&skip=&lastdays=0",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			sort :{ field: "kpiDesc", dir: "asc" },
			serverPaging : false,
		});
        
        function customColor() {
			var chart = angular.element("#chartApplCount").data("kendoChart");
			
			for ( var index in chart.options.series) {
				var attr = chart.options.series[index];
				console.log(attr.data[0].kpi);
				if (attr.data && attr.data[0] && attr.data[0].kpi)
				switch (attr.data[0].kpi) {
				case "APPL_STATUS_LOAN_COUNT":
					chart.options.series[index].color = "darkgreen";
					break;
				case "APPL_STATUS_OTHER_COUNT":
					chart.options.series[index].color = "dimgrey";
					break;
				case "APPL_STATUS_INPROGRESS_COUNT":
					chart.options.series[index].color = "blue";
					break;
				case "APPL_STATUS_GRANTED_COUNT":
					chart.options.series[index].color = "lightgreen";
					break;
                case "APPL_STATUS_DENIED_COUNT":
                    chart.options.series[index].color = "red";
                    break;
                case "APPL_STATUS_REQ_COUNT":
                    chart.options.series[index].color = "yellow";
                    break;
				}
			}
		}
    }
})();
