(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('TasksBpmController', TasksBpmController);

    TasksBpmController.$inject = ['$scope', '$state', 'Bpm', 'defaultPageSize', '$translate', '$filter', '$window'];

    function TasksBpmController ($scope, $state, Bpm, defaultPageSize, $translate, $filter, $window) {
        var vm = this;
		var executors = {
				data: []
		};
        console.log("TasksBpmController started");
        vm.tasks = [];
        vm.bpmTasksDataSource = new kendo.data.DataSource({
	        schema: {
	        	model: {
	        			id: "taskId",
				        fields: {
				          "taskId": { type: "number" },
				          "businessKey": { type: "string" },
				          "executor": { type: "string" },
				          "dueDate": { type: "date" },
				          "enqueued": { type: "date" },
				          "leasedTill": { type: "date" },
				          "priority": { type: "number" },
				          "retries": { type: "number" },
				          "errorMessage": { type: "string" },
				          "stackTrace": { type: "string" }
				        }
	        	},
	        	parse: function(d){
		              	$.each(d, function (idx, elem) {
		              		var executor = {
		              				executor: elem.executor
		              		};
		              		var foundExecutor = $filter('filter')(executors.data, {executor: elem.executor}, true);
		                    if(foundExecutor.length < 1) (executors.data).push(executor);
		                });
		              	return d;
				  }
	  		},
	  		transport: {
				read: function(e){
	  				Bpm.getTasks(
							function(response){
								vm.tasks = response;
								e.success(response);
							},
							function(error){
								console.log('task list error');
								e.error(error);
							}
					);
				}
			},
	        pageSize: defaultPageSize,
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
	        sort: {
	              field: "taskId",
	              dir: "desc"
	        }
        });
        
        vm.bpmTasksOptions = {
        		scrollable: {
    	            virtual: true
    	        },
    	        height: function(){
    	        	return $window.innerHeight/2.5;
    	        },
    	        sortable: true,                
    	        pageable: {
    	        	refresh: true,
    	        	previousNext: false,
    	        	numeric: false,
    	        	messages: {
    	        	      empty: "No data"
    	        	    }
    	        },                
    			filterable : {
    				extra : false,
    				operators : {
    					string : {
    						contains : "contains",
    					},
    					number : {
    						eq : "Equal to",
    						gte : "Greater than or equal",
    					}
    				}
    			},
                toolbar: [
                          { 
    		                    template: "<span style='line-height:22px;' translate='modules.bpm.titles.tasks'></span>"
 		                    		 + "<button style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
 		                    		 + "<span class='fa fa-file-excel-o'></span>"
 		                             + "&nbsp;<span translate='modules.bpm.excel'>"
 		                             + "</span></button>"
                          }
                ],
                excel: {
                	fileName: "bpm-tasks.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },                
                columns: [
						{ 
							field: "taskId", 
							title: "{{'modules.bpm.table.id'|translate}}",
							width: "9ch", 
							sortable: true,
							filterable: false
						},{ 
							field: "businessKey", 
							title: "{{'modules.bpm.table.businessKey'|translate}}",
							width: "20ch", 
							sortable: true,
							filterable: true
						},{ 
							field: "retries",
							title: "{{'modules.bpm.table.retries'|translate}}",
						    attributes: { class: "text-right" },
						    sortable: false,
						    filterable: true,
						    width: "10ch"
						},{
			                field: "executor",
			                title: "{{'modules.bpm.table.executor'|translate}}",
			                sortable: false,
	                        filterable : {
				            	multi : true,
				                dataSource : new kendo.data.DataSource(executors),
				                dataTextField : "executor"
	                        }
			            },{
                          	title: "",
                        	template: "<button kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ng-click='vm.view(#:taskId#)'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>",
		                    width: "7ch" 
                          }  
				]	
                            	
        	};
        
	        vm.view = function(id) {
	            $state.go('modules.bpm.tasks.detail',{taskId: id, tasks: vm.tasks});
	        };
        
        };
    
    
})();
