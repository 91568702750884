(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CardHolderSendCredentialsLoanController', CardHolderSendCredentialsLoanController);

    CardHolderSendCredentialsLoanController.$inject = ['$scope', '$state', 'Card', '$translate', 'AlertService', '$filter'];

    function CardHolderSendCredentialsLoanController ($scope, $state, Card, $translate, AlertService, $filter) {
        var vm = this;
        vm.kendo = kendo;
        console.log("CardHolderSendCredentialsLoanController started");
        vm.noEmails = false;
    	vm.select = 1;
    	vm.inProcess = false;
    	
    	vm.selectOptional = function(){
    		var listView = $("#clients-mail").data("kendoListView");
    		listView.select(listView.element.children().last());
    	};
    	
        vm.emailsData = new kendo.data.DataSource({
        	schema: {
			      model: {
			    	id: "priority",
			        fields: {
			        	"priority": { type: "number" },
			        	"labelName": { type: "string" },
			        	"email": { type: "string" }
			        }
				}
			},
			transport: {
				read: function(e){

					Card.getHolderEmails(
							{loan_no : $state.params.loan_no},
							function(response){
				        		vm.noEmails = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('failed to get cardholder emails');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "asc"
			}
        });

        vm.emailsOptions = {
        	    selectable: "Single",
        	    dataBound: function(event) {
        	    	var first = event.sender.element.children().first();
        	        event.sender.select(first);
        	    },
        	  	change: function(event) {
        	        var selected = event.sender.dataItem(event.sender.select());
                	vm.targetEmailNo = selected.priority;
                	vm.targetEmail = selected.email;
                	vm.targetEmailLabel = selected.labelName;
        	    }
        };
        
        vm.emailsData.fetch(function(){
        	vm.emailsData.add({
            	priority: 3,
                labelName: 'optionalEmail',
                email: ''
            });
        });
        
        vm.send	= function(){
        	console.log('sendCredentialsEmail');
        	if(!vm.sendUsername && !vm.sendPassword) {
        		AlertService.error("modules.loan.message.emptyUsernameAndPasword");
        		return;
        	}	
        	
        	switch (vm.targetEmailLabel) {
        		case 'optionalEmail':
        			if(vm.optionalEmail == null || vm.optionalEmail == '') {
        				AlertService.error("modules.loan.message.optionalEmailIsEmpty");
        				return;
        			} else 
        				vm.optionalEmailToSend = vm.optionalEmail;
        			break;
        		case 'crmEmail':
        			vm.optionalEmailToSend = vm.targetEmail;
        			break;	
	    		case 'cardHolderEmail':
	    		default:
	    			vm.optionalEmail = '';
        	} 
        	vm.inProcess = true;
            AlertService.add({
                type: "info",
                msg: "modules.loan.message.sendingCredentialsEmailInProgress",
                timeout: 40000000
            });
        	
	        Card.sendCredentialsEmail(
	        		{	
	        			loan_no : $state.params.loan_no
	        		},{
	        			username : vm.sendUsername,
	        			password : vm.sendPassword,
	        			optionalEmail : vm.optionalEmailToSend 
	        		},
	        		function(result){
			     		if(result) {
			     			AlertService.clear();
			     			AlertService.success("modules.loan.message.sendCredentialsEmailSuccessfully");
			     			vm.inProcess = false;
			     			$state.go('modules.loans.current.cardholder',{},{reload:true});
			     		}
		     		},
		     		function(error){
		     			vm.inProcess = false;
		     			AlertService.clear();
		     			console.log(error);
		     		}
	        ).$promise;
	        
        };
        
    }
    
})();
