(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ActivateWallesterCardLoanController', ActivateWallesterCardLoanController);

    ActivateWallesterCardLoanController.$inject = ['$scope', '$state', 'Wallester', 'formHelper', 'card', '$stateParams', '$filter', 'AlertService', '$translate', 'CardStatus'];

    function ActivateWallesterCardLoanController ($scope, $state, Wallester, formHelper, card, $stateParams, $filter, AlertService, $translate, CardStatus) {
        var vm = this;
        vm.kendo = kendo;
        console.log("ActivateWallesterCardLoanController");
        console.log(card);

        if($stateParams.card_id != null) {
	        vm.currentCard = card;
	        vm.statusStyle = CardStatus.getStyle(vm.currentCard.cardStatus, vm.currentCard.isActive);
	        if(vm.currentCard.isActive)
	        	$state.go('modules.loans.current.cards.view', {id: $stateParams.id, token: $stateParams.token}, {reload: false});
        }
        
        
        
        vm.save = function(){

        	vm.formCard = angular.copy(vm.currentCard);
	//    	vm.bal = angular.copy(vm.formCard.bal);
	//    	delete vm.formCard.bal;
        	
	//    	vm.formCard.reason = vm.reason;
	    	
        	Wallester.activate( {loan_no : $state.params.loan_no},
        			{ loan_no: $stateParams.loan_no, card_id: $stateParams.card_id, reason: vm.reason},
        			function(result){
		        		if(result) {
		        			AlertService.success("modules.loan.message.activatedSuccessfully");
		        //			vm.formCard = result;
		        //			delete vm.formCard.reference;
		        //			delete vm.formCard.reason;
		        //			vm.currentCard = angular.copy(result);
		        //			vm.currentCard.bal = vm.bal;
		        			$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id}, {reload: false});
		        		}
	        		},
	        		function(error){
	        			console.log(error);
	        		}
        	).$promise;
        	
        };
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id});
        };
        
    }
    
})();

