(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteCancellationAgreementClientController', DeleteCancellationAgreementClientController);

    DeleteCancellationAgreementClientController.$inject = ['$uibModalInstance', '$state', 'Client', 'AlertService'];

    function DeleteCancellationAgreementClientController ($uibModalInstance, $state, Client, AlertService ) {
        
    	var vm = this;

    	vm.clnId = $state.params.clnId;
        vm.agrId = $state.params.agrId;
        
        console.log(vm.clnId + " " + vm.agrId);
        if(vm.agrId == "") {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Client's agreement don't get for cancellation delete");
        	return;
        }
        
        vm.clear = clear;
        vm.deleteCancellation = deleteCancellation;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        function deleteCancellation() {
        	$uibModalInstance.close(true);
            Client.deleteAgreementCancellation({clnId: vm.clnId, agrId: vm.agrId}, { },
                function () {
            	AlertService.success("modules.agreement.message.agreementCancellationDeletedSuccessfully");
				$state.go('modules.clients.agreements.view', {agrId: vm.agrId, clnId: vm.clnId}, {reload: true});
                });
        }
    }
})();
