(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DebtActionLogLoanController', DebtActionLogLoanController);

    DebtActionLogLoanController.$inject = ['$scope', '$state', 'menuWithHeader'];

    function DebtActionLogLoanController ($scope, $state, menuWithHeader) {
        var vm = this;
        console.log("DebtActionLogLoanController started");
        vm.loanId =  menuWithHeader[0].loanNum;
        vm.clnId =  menuWithHeader[0].clnId;
    }
    
})();
