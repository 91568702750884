(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('modules.demows', {
            parent: 'modules',
            url: '/demo/ws',
            data: {
            	authorities: ['ROLE_USER', 'ROLE_LATEKO'],
            },
            views: {
                'content@': {
                    templateUrl: 'app/modules/ws_test/ws_test.template.html',
                    controller: 'DemoWsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');                    
                    return $translate.refresh();
                }]
            },
            onEnter: ['WsService', function(WsService) {
            	WsService.subscribe();
            }],
            onExit: ['WsService', function(WsService) {
            	WsService.unsubscribe();
            }]
        });
    }
})();
