(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ScheduleListLoanController', ScheduleListLoanController);

    ScheduleListLoanController.$inject = ['$scope', '$state', 'Loan', 'defaultPageSize', '$filter', '$window', '$translate'];

    function ScheduleListLoanController ($scope, $state, Loan, defaultPageSize, $filter, $window, $translate) {
        var vm = this;
        vm.kendo = kendo;
        console.log("ScheduleListLoanController started");
      	
        vm.loanSchedulesDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "num",
			        fields: {
			          "num": { type: "string" },
			          "period": { type: "string"},
			          "expectedDate": { type: "date" },
			          "bookDate": { type: "date" },
			          "perFrom": { type: "date" },
			          "perTill": { type: "date" },
			          "prn": { type: "number" },
			          "int": { type: "number" },
			          "adm": { type: "number" },
			          "residue": { type: "number" },
			          "total": { type: "number" },
			          "penSum": { type: "number" },
			          "penStatus": { type: "string" },
			          "penResidual": { type: "number" },
			          "graceApplied": { type: "boolean" }
			        }
			      },
			      parse: function(d){
						$.each(d, function (idx, elem) {
							elem.period = kendo.toString(kendo.parseDate(elem.perFrom), 'd') + "-" + kendo.toString(kendo.parseDate(elem.perTill), 'd');
							if ( kendo.parseDate(new Date()) > kendo.parseDate(elem.expectedDate)) {
								if (elem.penResidual == 0 && elem.residual == 0 ){
									elem.penStatus = $translate.instant('modules.loan.table.penStatusFullyPaid');
								}
								else {
								    elem.penStatus = $translate.instant('modules.loan.table.penStatusNotPaid');
								}
							}	
							else elem.penStatus = "";  
						});
						return d;
					}
    		},
    		transport: {
				read: function(e){

					Loan.getSchedules(
							{loan_no : $state.params.loan_no},
							function(response){
								e.success(response);
							},
							function(error){
								console.log('schedules list error');
								e.error(error);
							}
					);
				}
			},
			aggregate: [
                        { field: "expectedDate", aggregate: "count"},
                        { field: "prn", aggregate: "sum"},
                        { field: "int", aggregate: "sum"},
                        { field: "adm", aggregate: "sum"},
                        { field: "total", aggregate: "sum"},
                        { field: "penSum", aggregate: "sum"}
            ],
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "num",
                dir: "desc"
            }
        });
        
        vm.loanSchedulesOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
    			filterable : {
    				extra : false,
    				operators : {
    					string : {
    						contains : $translate.instant('modules.loan.table.contains'),
    					},
    					number : {
    						eq : $translate.instant('modules.loan.table.eq'),
    						gte : $translate.instant('modules.loan.table.gte'),
    						lte : $translate.instant('modules.loan.table.lte')
    					}
    				}
    			},
                height: function(){
                	return $window.innerHeight/2.5;
                },                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info:false,
                	messages: {
                	      empty: $translate.instant('modules.loan.table.noData'),
                	    }
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.schedules'></span>"
  		                    		 + "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-schedules.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },  
                columns: [
							{ 
								field: "num", 
								title: "{{'modules.loan.table.no'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "8ch",
		                        footerTemplate: "{{'modules.loan.table.total'|translate}}"
							},{ 
								field: "period",
								title: "{{'modules.loan.table.period'|translate}}",
		                        attributes: { "class": "text-left" },
		                        width: "24ch",
		                        minScreenWidth: 1200
							},{ 
								field: "bookDate", 
		                        title: "{{'modules.loan.table.bookDate'|translate}}",
		                        width: "14ch",
		                        format: "{0:d}",
		                        minScreenWidth: 1100	
							},{ 
								field: "expectedDate", 
		                        title: "{{'modules.loan.table.date'|translate}}",
		                        width: "12ch",
		                        format: "{0:d}",
		                        aggregates: ["count"],
		                        footerTemplate: "#=kendo.toString(count, 'n0')#",
		                        footerAttributes: { style: "text-align: right;" }
							},{ 
								field: "residue",
								title: "{{'modules.loan.table.residueAmt'|translate}}",
								template: "#= (residue == 0 || residue == null) ? '' : kendo.toString(residue, 'n2') #",
								filterable: false,
							    attributes: { "class": "text-right" },
							    minScreenWidth: 1000
							},{ 
								field: "prn",
								title: "{{'modules.loan.table.prnAmt'|translate}}",
								template: "#= (prn == 0 || prn == null) ? '' : kendo.toString(prn, 'n2') #",
								filterable: false,
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "int",
								title: "{{'modules.loan.table.intAmt'|translate}}",
								template: "#= (int == 0 || int == null) ? '' : kendo.toString(int, 'n2') #",
								filterable: false,
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "adm",
								title: "{{'modules.loan.table.admAmt'|translate}}",
								template: "#= (adm == 0 || adm == null) ? '' : kendo.toString(adm, 'n2') #",
								filterable: false,
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "total",
								title: "{{'modules.loan.table.total'|translate}}",
								template: "#= (total == 0 || total == null) ? '' : kendo.toString(total, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							},{ 
								field: "penSum",
								title: "{{'modules.loan.table.penSum'|translate}}",
								template: "#= (penSum == 0 || penSum == null) ? '' : kendo.toString(penSum, 'n2') #",
								aggregates: ["sum"],
								footerTemplate: "#=kendo.toString(sum, 'n2')#",
							    attributes: { "class": "text-right" },
							    footerAttributes: { style: "text-align: right;" } 
							}, {
								field: "graceApplied",
		                        title: "Grace",
		                        sortable: false,
		                        filterable: false,
		                        minScreenWidth: 1400,
								template: "#= (graceApplied) ? 'GRACE' : '' #",
		                        width: "8ch"
							},{ 
								field: "penStatus",
								title: "{{'modules.loan.table.penStatus'|translate}}",
								filterable : {
			                     	multi : true
							     },
							    attributes: { "class": "text-right" },
							},{
	                        	template: "<button has-any-authority='ROLE_LATEKO,ROLE_PARTNER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.schedules.view({schId: \"#:num#\"})'>" 
	                                + "<span class='glyphicon glyphicon-eye-open'></span>"
	                                + "</button>",
			                    title: "&nbsp;", 
			                    width: "6ch" 
	                          }
                    ]
 
        };
       
    }
    
})();
