(function () {
    "use strict";

    angular.module("sigmaApp")

    .constant("baseUrl", "/w/s/")
    .constant("defaultPageSize", 10)
    .constant("shortRefresh", 20000)
    .constant("longRefresh", 60000)
    .constant("defaultNonamePrefix", "nnc")    
    .constant("calcRefresh", 1000)
    .constant("shortDateFormats", ['dd/MM/yyyy', 'dd-MM-yyyy', 'yyyy-MM-dd', 'yyyyMMdd', 'ddMMyyyy', 'yyyy/MM/dd', 'yyyy,MM,dd', 'dd,MM,yyyy'])
    ;
})();