(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DashboardBpmController', DashboardBpmController);

    DashboardBpmController.$inject = ['$scope', '$rootScope', '$state', 'stat', '$translate', 'Bpm', 'shortRefresh', '$interval'];

    function DashboardBpmController ($scope, $rootScope, $state, stat, $translate, Bpm, shortRefresh, $interval) {
        var vm = this;
        vm.refresh = shortRefresh;
        
        console.log("DashboardBpmController started");
        vm.bpmRetriesDataSource = new kendo.data.DataSource({
	        schema: {
	        	model: {
				        fields: {
				          "amount": { type: "number" },
				          "kpi": { type: "string" },
				          "kpiGroup": { type: "string" },
				          "date": { type: "date" },
				          "name": { type: "string" }
				        }
				},
				parse: function(d){
					$.each(d, function (idx, elem) {
						var measureUnit = $translate.instant(elem.description[0].measureUnit);
						elem.name = $translate.instant(elem.description[0].name, { key: elem.kpi, measureUnit: measureUnit });
					});
					return d;
				}
	  		},
			data: stat,
			filter: { field: "kpiGroup", operator: "eq", value: "RETRIES" }
        });
        
        vm.bpmExecutorDataSource = new kendo.data.DataSource({
	        schema: {
	        	model: {
				        fields: {
				          "amount": { type: "number" },
				          "kpi": { type: "string" },
				          "kpiGroup": { type: "string" },
				          "date": { type: "date" },
				          "name": { type: "string" }
				        }
				},
				parse: function(d){
					$.each(d, function (idx, elem) {
						var measureUnit = $translate.instant(elem.description[0].measureUnit);
						elem.name = $translate.instant(elem.description[0].name, { key: elem.kpi, measureUnit: measureUnit });
					});
					return d;
				}
	  		},
			data: stat,
			filter: { field: "kpiGroup", operator: "eq", value: "EXECUTOR" }
        });
       
        var run = $interval(reload, vm.refresh);
        
        vm.reload = reload;
        
        function reload(){
        	$state.go('modules.bpm.dashboard',{},{reload:true});
        }
        
        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
        	$interval.cancel(run);
        	run = $interval(reload, vm.refresh);
        }, true);
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
        	$interval.cancel(run);
        });
    }
})();
