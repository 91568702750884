(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('AgreementsClientController', AgreementsClientController);

    AgreementsClientController.$inject = ['$scope', '$state', 'Client', 'defaultPageSize', '$translate', '$stateParams', '$window', '$filter'];

    function AgreementsClientController ($scope, $state, Client, defaultPageSize, $translate, $stateParams, $window, $filter) {
        
    	var vm = this;
    	vm.kendo = kendo;

    	var docTypes = { data: [] };
    	var docRefs = { data: [] };
    	var agreeTypes = { data: [] };
    	var agreeValues = { data: [] };
    	var agreeStatuses = { data: [] };
    	
    	console.log("Agreements Client Controller started");
        
        vm.clientAgreementsDataSource = new kendo.data.DataSource({
        	schema: {
			    model: {
			        id: "id",
			        fields: {
				          "id": { type: "number" },
				          "personId": { type: "number" },
				          "docRef": { type: "string" },
				          "docModule": { type: "string" },
				          "agrNumber": { type: "string" },
				          "agrDate": { type: "date" },
				          "rem": { type: "rem" },
				          "agrValue": { type: "" },
				          "agrType": { type: "" },
				          "agrStatus": { type: "" },
				          "crtdBy": { type: "string" },
				          "crtdOn": { type: "date" },
				          "updtBy": { type: "string" },
				          "updtOn": { type: "date" },
				          "ver": { type: "ver" }
				    }
				},
				parse: function(d){
                	$.each(d, function (idx, elem) {
                		elem.docModuleTranslated = $translate.instant('modules.agreement.table.docModules.' + elem.docModule);
                		var docType = {
                			docModuleTranslated: elem.docModuleTranslated
                		};
                		var foundDocType = $filter('filter')(docTypes.data, {docModuleTranslated: elem.docModuleTranslated}, true);
                        if(foundDocType.length < 1) (docTypes.data).push(docType);
                        
                        var docNumber = {
                        	docRef: elem.docRef
                    	};
                    	var foundDocRefs = $filter('filter')(docRefs.data, {docRef: elem.docRef}, true);
                        if(foundDocRefs.length < 1) (docRefs.data).push(docNumber);
                        
                        elem.agrTypeTranslated = $translate.instant('modules.agreement.agrTypes.' + elem.agrType);
                        var agreeType = {
                        	agrTypeTranslated: elem.agrTypeTranslated
                        };
                        var foundAgreeType = $filter('filter')(agreeTypes.data, {agrTypeTranslated: elem.agrTypeTranslated}, true);
                        if(foundAgreeType.length < 1) (agreeTypes.data).push(agreeType);
                        
                        elem.agrValueTranslated = $translate.instant('modules.agreement.agrValues.' + elem.agrValue);
                        var agreeValue = {
                        		agrValueTranslated: elem.agrValueTranslated
                        };
                        var foundAgreeValues = $filter('filter')(agreeValues.data, {agrValueTranslated: elem.agrValueTranslated}, true);
                        if(foundAgreeValues.length < 1) (agreeValues.data).push(agreeValue);
                        
                        var agreeStatus = {
                        	agrStatus: elem.agrStatus
                        };
                        var foundAgreeStatuses = $filter('filter')(agreeStatuses.data, {agrStatus: elem.agrStatus}, true);
                        if(foundAgreeStatuses.length < 1) (agreeStatuses.data).push(agreeStatus);
                    });
                	return d;
				}
			},
		  	transport: {
		  		read: function(e){
		  			Client.getAgreements(
		  					{clnId: $stateParams.clnId},
		  					function(response){
		  						e.success(response);
		  					},
		  					function(error){
		  						console.log('Agreementss list error');
		  						e.error(error);
		  					}
		  			);
		  		}
		  	},
		  	pageSize: defaultPageSize,
			sort : [{
				field : "docRef",
				dir : "desc"
			},
			{
				field : "agrType",
				dir : "asc"
			}]
        });
        
        vm.clientAgreementsOptions = {
        		scrollable: true,
        		height: function(){
    	        	return $window.innerHeight/2.5;
    	        },
                sortable: true,                
                pageable: {
                	refresh: true,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          {	
                        	  template: "<span style='line-height:22px;' translate='modules.agreement.titles.agreements'></span>"
		                    		 + "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
		                    		 + "<span class='fa fa-file-excel-o'></span>"
		                             + "&nbsp;<span translate='modules.client.excel'>"
		                             + "</span></button>"
                          }],
                excel: {
                    fileName: "client-agreements.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },
                columns: [
                    {
	                    field: "updtOn",
	                    title: "{{'modules.agreement.table.agrDate'|translate}}",
	                    format: "{0:d}",
	                    width: "11ch",
	                    filterable: {
	                    	ui: "datepicker"
	                    }
                    },{ 
                    	field: "docModuleTranslated", 
                    	title: "{{'modules.agreement.table.docModule'|translate}}",
                    	template: "<span translate='modules.agreement.table.docModules.#:docModule#'></span>",
                    	width: "11ch",
                    	filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(docTypes),
                            dataTextField : "docModuleTranslated"
						}
                    },{ 
						field: "docRef", 
						title: "{{'modules.agreement.table.docRef'|translate}}",
						width: "8ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(docRefs),
                            dataTextField : "docRef"
						}
					},{ 
						field: "agrTypeTranslated", 
						title: "{{'modules.agreement.table.agrType'|translate}}",
						template: "<span translate='modules.agreement.agrTypes.#:agrType#'></span>",
						width: "14ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(agreeTypes),
                            dataTextField : "agrTypeTranslated"
						}
					},{ 
						field: "agrValueTranslated", 
						title: "{{'modules.agreement.table.agrValue'|translate}}",
						template: "<span translate='modules.agreement.agrValues.#:agrValue#'></span>",
						width: "8ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(agreeValues),
                            dataTextField : "agrValueTranslated"
						}
					},{ 
						field: "agrStatus", 
						title: "{{'modules.agreement.table.agrStatus'|translate}}",
						width: "11ch",
						filterable : {
                            multi : true ,
                            dataSource : new kendo.data.DataSource(agreeStatuses),
                            dataTextField : "agrStatus"
						}
					},{
						title: "{{'modules.agreement.table.actions'|translate}}",
                    	template: "<button has-authority='ROLE_LATEKO' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.clients.agreements.view({agrId: #:id#})'>"
                            + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                            + "</button>",
	                        width: "5ch" 
                    }]
        };
    
    }
})();
