(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('EventsMvcController', EventsMvcController);

    EventsMvcController.$inject = ['$scope', '$state', 'entity', 'defaultPageSize', '$translate', '$window'];

    function EventsMvcController ($scope, $state, entity, defaultPageSize, $translate, $window) {
        var vm = this;
        console.log("MVC Events Controller started");
	        vm.mvcEventsDataSource = new kendo.data.DataSource({
				data: entity.events,
//				aggregate: [
//	            	{ field: "tolken", aggregate: "count"}
//	            ],
//	            pageSize: defaultPageSize,
	            serverPaging: false,
	            serverFiltering: false,
	            serverSorting: false,
	            schema: {
	                model: {
	                    fields: {
	                        token: { type: "string" },
	                        statDesc: { type: "string" },
	                        eventDate: { type: "date" },
	                        activationDate: { type: "date" },
	                        eventType: { type: "string" },
	                        id: { type: "string" }
	                    }
	                }
	            },
	            sort: { field: "id", dir: "desc"}
	        });
	        
	        vm.mvcEventsOptions = {
	        		filterable: false,
	                sortable: true,
	                pageable: {
	    	        	refresh: true,
	    	        	previousNext: false,
	    	        	info:false,
	    	        	numeric: false,
	                	messages: {
	                	      empty: "No data"
	                	}
	                },
	    	        height: function(){
	    	        	return $window.innerHeight/2;
	    	        },
	                toolbar: [
	                          { 
			                    template: "<span style='line-height:22px;' translate='modules.mvc.titles.events'></span>"
			                    		 + "<button has-authority='ROLE_ACCOUNTANT' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
			                    		 + "<span class='fa fa-file-excel-o'></span>"
			                             + "&nbsp;<span translate='modules.mvc.excel'>"
			                             + "</span></button>"
	                          }
	                ],
	                excel: {
	                	fileName: "mvc-events.xlsx",
	                    allPages: true
	                },
	                excelExport: function(e) {
	                	e.workbook.sheets[0].columns.forEach(function(column){
	                		column.autoWidth = true;
	                	});
//	                	e.workbook.sheets[0].columns = [
//	                		{ width:100 },
//	                		{ width:300 },
//	                		{ width:400 },
//	                		// ...
//	                	];
	                    e.workbook.sheets[0].rows.forEach(function(row){
	                      if(row.type != 'data'){
	                      	row.cells.forEach(function(cell){
	                          cell.value = $translate.instant(cell.value);
	                        });
	                      }
	                	});
	                },
	                columns: [ 
	                          {
	                              field:"id",
	                              title: "{{'modules.mvc.table.no'|translate}}",
	                              width: "11ch"
	                          },
	                          {
	                              field: "eventDate",
	                              title: "{{'modules.mvc.table.eventDate'|translate}}",
	                              format      : "{0:G}",
	                              parseFormats: ["yyyy-MM-dd'T'HH:mm:ss.zzz"],
	                              width: "19ch"
	                          },
	                          {
	                              field: "statCode",
	                              title: "{{'modules.mvc.table.statusCard'|translate}}",
	                              attributes: { "class": "text-center" },
	                              width: "9ch"
	                          },
	                          {
	                              field: "eventType",
	                              title: "{{'modules.mvc.table.eventType'|translate}}",
	                              width: "15ch"
	                          }, 
	                          {
	                              field: "des",
	                              title: "{{'modules.mvc.table.description'|translate}}",
	                              template : "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#:des#'\" style='width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;'>#:des#</span>"

	                          }
	                      ]
	 
	        };
    
    }
})();
