(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('LineTransactionDetailLoanController', LineTransactionDetailLoanController);

    LineTransactionDetailLoanController.$inject = ['Principal', '$scope', '$stateParams', '$state', 'AlertService', '$uibModalInstance', 'detailMode'];

    function LineTransactionDetailLoanController(Principal, $scope, $stateParams, $state, AlertService, $uibModalInstance, detailMode) {
        var vm = this;
      	Principal.identity().then(function (account) {
      		vm.right = account.login === 'valsin' || account.authorities.indexOf("ROLE_DEVELOPER") >= 0 || account.authorities.indexOf("ROLE_ACCOUNTANT") >= 0;
      		if(!vm.right) $state.go('modules.loans.current.condition',{reload:true});
      	});
        
        if(typeof $stateParams.currentLine =="undefined" || $stateParams.currentLine == null || !$stateParams.currentLine) {
        	console.log($stateParams.currentLine);
        	$state.go('modules.loans.current.accountingTransactions.detail', {tranId: $stateParams.tranId}, {reload: true});
//        	$uibModalInstance.close(true);
        } 
        vm.currentLine = $stateParams.currentLine;
        vm.kendo = kendo;
        vm.clear = clear;
        
        vm.editMode = detailMode.edit;
      	vm.createMode = detailMode.create;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
        
    }
})();
