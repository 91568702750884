(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
	        .state('modules.loans', {
				url : '/loans',
				parent : 'modules',
	            data: {
	            	authorities: ['ROLE_USER'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loans.template.html',
						controller : 'LoansController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.new', {
				url : '/new/fromAppl/{applId}',
				parent : 'modules.loans',
	            data: {
	            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/new.loan.template.html',
						controller : 'NewLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/application');
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/audit');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                application: ['$stateParams', 'Application', function($stateParams, Application) {
	                	if($stateParams.applId == null ) return null;
	                    return Application.get({id : $stateParams.applId}).$promise;
	                }],
	                detailMode: function(){
	                	return {
	                		isNew: true
	                	};
	                },
	                previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }]
	                
				}
			})
        	.state('modules.loans.current', {
				url : '/{loan_no}',
				parent : 'modules.loans',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_USER'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/loan/loan.template.html',
						controller : 'LoanMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');
	                	$translatePartialLoader.addPart('modules/audit');
	                    return $translate.refresh();
	                }],
	                menuWithHeader: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.get({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.delete', {
				url : '/delete',
				parent : 'modules.loans.current',
				params: {applId: null},
	            data: {
	            	authorities: ['ROLE_MANAGER'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/delete.loan.template.html',
	                    controller: 'DeleteLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                });
	            }]
	        })
	       	.state('modules.loans.current.condition', {
				url : '/',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_USER'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/condition/conditions.loan.template.html',
						controller : 'ConditionsLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				},
	            onEnter: ['WsService', function(WsService) {
	            	WsService.subscribe();
	            }],
	            onExit: ['WsService', function(WsService) {
	            	WsService.unsubscribe();
	            }]
			})
			.state('modules.loans.current.activate', {
				url : '/activate',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/activate.loan.template.html',
	                    controller: 'ActivateLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                	$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
	        })
			.state('modules.loans.current.prepare', {
				url : '/prepare',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/prepare.loan.template.html',
	                    controller: 'PrepareLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                	$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
	        })
	        .state('modules.loans.current.debts', {
				url : '/debts/{toDate}',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/debts/date.debts.loan.template.html',
	                    controller: 'DateDebtsLoanController',
	                    controllerAs: 'vm',
	                    size: 'lg',
//	                    resolve: {
//	                    	debtsByLoanNoAndDate: ['$stateParams', 'Loan', function($stateParams, Loan) {
//	                    		console.log($state);
//	    	                    return Loan.getDebtsByLoanNoAndDate({loan_no : $stateParams.loan_no, to_date : $stateParams.toDate}).$promise;
//	    	                }]
//	    				}
	                }).result.then(function() {
	                	$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
			})
			.state('modules.loans.current.pen-stop-date', {
				url : '/pen-stop-date',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_EXECUTIVE'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/penalties/date.penalty.loan.template.html',
	                    controller: 'DatePenaltyLoanController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                }).result.then(function() {
	                	$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
			})
			.state('modules.loans.current.close-active', {
				url : '/close-active',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_EXECUTIVE','ROLE_ADMIN'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/close-active/close-active.loan.template.html',
	                    controller: 'CloseActiveLoanController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                }).result.then(function() {
	                	$state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.condition', {loan_no: $state.params.loan_no});
	                });
	            }]
			})
			.state('modules.loans.current.payments', {
				url : '/payments',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/payments/payment.list.loan.template.html',
						controller : 'PaymentListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.schedules', {
				url : '/schedules',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/schedules/schedule.list.loan.template.html',
						controller : 'ScheduleListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.schedules.view', {
				url : '/{schId}',
				parent : 'modules.loans.current.schedules',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/schedules/schedule.list.loan.template.html',
						controller : 'ScheduleListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/schedules/schedule.detail.loan.template.html',
						controller : 'ScheduleDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                schedule: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getSchedule({loan_no : $stateParams.loan_no, num : $stateParams.schId}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.operations', {
				url : '/operations',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/operations/operation.list.loan.template.html',
						controller : 'OperationListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/mvc');
	                	$translatePartialLoader.addPart('modules/card.status');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.interests', {
				url : '/interests',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_MANAGER'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                interests: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getInterests({loan_no : $stateParams.loan_no}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.interests.view', {
				url : '/:rateId',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_MANAGER'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.loans',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.loans.current.interests.edit', {
				url : '/:rateId/edit',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_MANAGER'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                }
				}
			})
			.state('modules.loans.current.interests.create', {
				url : '/create/',
				parent : 'modules.loans.current.interests',
	            data: {
	            	authorities: ['ROLE_MANAGER'],
	            },
				views : {
					'loan-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.list.loan.template.html',
						controller : 'InterestListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/interests/interest.detail.loan.template.html',
						controller : 'InterestDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.applications',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                }
				}
			})
			.state('modules.loans.current.accrues', {
				url : '/accrues',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/accrue/accrues.list.loan.template.html',
						controller : 'AccruesListLoanController',
						controllerAs : 'vm'
					},
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.accounting', {
				url : '/accounting',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.accounting.annulate-penalty', {
				url : '/annulate-penalty',
				parent : 'modules.loans.current.accounting',
	            data: {
	            	authorities: ['ROLE_ACCOUNTANT'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/transactions/annulate-penalty.loan.template.html',
						controller : 'AnnulatePenaltyLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.accounting.annulate-penalty.confirmation', {
				url : '/confirmation',
				parent : 'modules.loans.current.accounting.annulate-penalty',
				params: {annulatePenaly:null},
	            data: {
	            	authorities: ['ROLE_ACCOUNTANT'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/transactions/confirmation.annulate-penalty.loan.template.html',
	                    controller: 'ConfirmationAnnulatePenaltyLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                	$state.go('modules.loans.current.accounting', {loan_no: $state.params.loan_no}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.accounting.annulate-penalty', {loan_no: $state.params.loan_no});
	                });
	            }]
	        })
			.state('modules.loans.current.accounting.transaction', {
				url : '/transactions/:tranId',
				parent : 'modules.loans.current.accounting',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/transactions/transaction.detail.loan.template.html',
						controller : 'TransactionDetailLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                },
	                transaction: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getTransaction({cdh_id : $stateParams.tranId}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.accounting.transaction.edit', {
				url : '/edit',
				parent : 'modules.loans.current.accounting.transaction',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/transactions/transaction.detail.loan.template.html',
						controller : 'TransactionEditLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: false
	                	};
	                },
	                transaction: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getTransaction({cdh_id : $stateParams.tranId}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.accounting.transaction.create', {
				url : '/create',
				parent : 'modules.loans.current.accounting.transaction',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/transactions/transaction.detail.loan.template.html',
						controller : 'TransactionEditLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                detailMode: function(){
	                	return {
	                		edit: true,
	                		create: true
	                	};
	                },
	                transaction: function(){
	                	return null;
	                },
				}
			})
			.state('modules.loans.current.accounting.transaction.delete', {
				url : '/delete',
				parent : 'modules.loans.current.accounting.transaction',
				params: {tranId: null},
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
	                    templateUrl: 'app/modules/loan/transactions/transaction.delete.loan.template.html',
	                    controller: 'TransactionDeleteLoanController',
	                    controllerAs: 'vm',
	                    size: 'md'
	                }).result.then(function() {
	                    $state.go('modules.loans.current.accounting', {}, {reload: true});
	                }, function() {
	                    $state.go('modules.loans.current.accounting.transaction.edit', {tranId : $stateParams.tranId}, {reload: true});
	                });
	            }]
	        })
			.state('modules.loans.current.accounting.transaction.lineView', {
				url : '/line',
				params:{currentLine: null},
				parent : 'modules.loans.current.accounting.transaction',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
	            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
	                $uibModal.open({
						templateUrl : 'app/modules/loan/transactions/line.transaction.detail.loan.template.html',
						controller : 'LineTransactionDetailLoanController',
	                    controllerAs: 'vm',
	                    size: 'md',
	                    resolve: {
	    	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	    	                	$translatePartialLoader.addPart('modules/loan');
	    	                	$translatePartialLoader.addPart('modules/transaction');
	    	                	$translatePartialLoader.addPart('modules/accounting');
	    	                	$translatePartialLoader.addPart('global');                    
	    	                    return $translate.refresh();
	    	                }],
	    	                detailMode: function(){
	    	                	return {
	    	                		edit: false,
	    	                		create: false
	    	                	};
	    	                }
	    				}
	                }).result.then(function() {
	                    $state.go('modules.loans.current.accounting.transaction', {tranId: $stateParams.tranId}, { reload: true });
	                }, function() {
	                    $state.go('^');
	                });
	            }]

			})
			.state('modules.loans.current.accounting.balances', {
				url : '/balances/:balDate',
				parent : 'modules.loans.current.accounting',
				params: {tranId: null},
	            data: {
	            	authorities: ['ROLE_DEVELOPER'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/transactions/transaction.list.loan.template.html',
						controller : 'TransactionListLoanController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/transactions/transaction.balance.loan.template.html',
						controller : 'TransactionBalanceLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/transaction');
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                detailMode: function(){
	                	return {
	                		edit: false,
	                		create: false
	                	};
	                },
	                balance: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getBalance({loan_no : $stateParams.loan_no, balDate : $stateParams.balDate}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.debt-action-log', {
				url : '/debt-action-log',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/debt-action-log/debt-action-log.loan.template.html',
						controller : 'DebtActionLogLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/accounting');
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/debtActionLog');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.edocs', {
				url : '/edocs',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/edocs/edocs-list.loan.template.html',
						controller : 'EdocsListController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
			.state('modules.loans.current.edocs.view', {
				url : '/:file_id',
				parent : 'modules.loans.current.edocs',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-sub-header' : {
						templateUrl : 'app/modules/loan/edocs/edocs-list.loan.template.html',
						controller : 'EdocsListController',
						controllerAs : 'vm'
					},
					'loan-body@modules.loans.current' : {
						templateUrl : 'app/modules/loan/edocs/edoc-view.loan.template.html',
						controller : 'EdocViewController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
	                edocData: ['$stateParams', 'Loan', function($stateParams, Loan) {
	                    return Loan.getEdoc({loan_no : $stateParams.loan_no, file_id : $stateParams.file_id}).$promise;
	                }]
				}
			})
			.state('modules.loans.current.history', {
				url : '/history',
				parent : 'modules.loans.current',
	            data: {
	            	authorities: ['ROLE_LATEKO'],
	            },
				views : {
					'loan-header' : {
						templateUrl : 'app/modules/loan/header/header.loan.template.html',
						controller : 'HeaderLoanController',
						controllerAs : 'vm'
					},
					'loan-body' : {
						templateUrl : 'app/modules/loan/history/history.loan.template.html',
						controller : 'HistoryLoanController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/loan');
	                	$translatePartialLoader.addPart('modules/history');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	        .state('modules.loans.current.order-card', {
					url : '/order-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
							controller : 'CardHolderDetailLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Card', function($stateParams, Card) {
							return Card.getDefault({loan_no : $stateParams.loan_no}).$promise;
						}],
						cardHolder: function(){
		                	return null;
		                },
		                cardHolderAddresses: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: true,
		                		noname: false
		                	};
		                }
					}
				})
	        .state('modules.loans.current.wallester-firstcard-create', {
					url : '/visa-first-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-first-card.template.html',
							controller : 'WallesterFirstCardController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Wallester', function($stateParams, Wallester) {
							return Wallester.getFirstCardData({loan_no : $stateParams.loan_no}).$promise;
						}],
						cardTemplates: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCardTemplates({loan_no : $stateParams.loan_no}).$promise;
		                }],
		                cardHolderAddresses: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: true,
		                		noname: false
		                	};
		                }
					}
				})
	        .state('modules.loans.current.link-noname-card-wallester', {
					url : '/visa-link-anonymous-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-link-noname-card.template.html',
							controller : 'WallesterLinkNonameCardController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Wallester', function($stateParams, Wallester) {
							return Wallester.getFirstCardData({loan_no : $stateParams.loan_no}).$promise;
						}],
						cardTemplates: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCardTemplates({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: true,
		                		noname: false
		                	};
		                }
					}
				})
				.state('modules.loans.current.order-noname-card', {
					url : '/order-noname-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
							controller : 'CardHolderDetailLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Card', function($stateParams, Card) {
							return Card.getDefault({loan_no : $stateParams.loan_no}).$promise;
						}],
						cardHolder: function(){
		                	return null;
		                },
		                cardHolderAddresses: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: true,
		                		noname: true
		                	};
		                }
					}
				})
				.state('modules.loans.current.cardholder', {
					url : '/cardholder',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
							controller : 'CardHolderDetailLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                	$translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                cardHolder: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolder({loan_no : $stateParams.loan_no}).$promise;
		                }],
		                defaultValue: function(){
		                	return null;
		                },
		                cardHolderAddresses: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: false
		                	};
		                }
		            }
				})
				.state('modules.loans.current.wallester-person', {
					url : '/visa-cardholder',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-person.detail.template.html',
							controller : 'WallesterPersonDetailController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                	$translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                cardHolder: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getPerson({loan_no : $stateParams.loan_no}).$promise;
		                }],
		                defaultValue: function(){
		                	return null;
		                },
		                cardHolderAddresses: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: false
		                	};
		                }
		            }
				})
				.state('modules.loans.current.wallester-person.edit', {
					url : '/edit',
					parent : 'modules.loans.current.wallester-person',
		            data: {
		            	authorities: ['ROLE_MANAGER', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-person.detail.template.html',
							controller : 'WallesterPersonDetailController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue: function(){
		                	return null;
		                },
						detailMode: function(){
		                	return {
		                		edit: true,
		                		create: false
		                	};
		                }
		            }
				})
				.state('modules.loans.current.cardholder.edit', {
					url : '/edit',
					parent : 'modules.loans.current.cardholder',
		            data: {
		            	authorities: ['ROLE_MANAGER', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
							controller : 'CardHolderDetailLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue: function(){
		                	return null;
		                },
						detailMode: function(){
		                	return {
		                		edit: true,
		                		create: false
		                	};
		                }
		            }
				})
				.state('modules.loans.current.cardholder.send-credentials', {
					url : '/send-credentials',
					parent : 'modules.loans.current.cardholder',
		            data: {
		            	authorities: ['ROLE_MANAGER'],
		            },
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card-holder.send-credentials.loan.template.html',
							controller : 'CardHolderSendCredentialsLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				})
				.state('modules.loans.current.cards', {
					url : '/cards',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans' : function(){
							return "";
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards', {
					url : '/visa-cards',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans' : function(){
							return "";
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }]
		            }
				
				})
				.state('modules.loans.current.create-another-card', {
					url : '/create-another-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card/card-holder.detail.loan.template.html',
							controller : 'CardHolderDetailLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                cardHolder: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getHolder({loan_no : $stateParams.loan_no}).$promise;
		                }],
		                defaultValue: function(){
		                	return null;
		                },
		                cardHolderAddresses: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return null;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: false,
		                		noname: false,
		                		createAnotherCard: true
		                	};
		                }
					}
				})
				.state('modules.loans.current.cards.view', {
					url : '/{token}/view',
					parent : 'modules.loans.current.cards',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.view.loan.template.html',
							controller : 'CardViewLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getCardByToken({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }],
		                events: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getEvents({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards.view', {
					url : '/{card_id}/view',
					parent : 'modules.loans.current.wallester-cards',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.view.template.html',
							controller : 'WallesterCardViewController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCard({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }],
		                events: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getEvents({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards.activate', {
					url : '/{card_id}/activate',
					parent : 'modules.loans.current.wallester-cards',
		            data: {
		            	authorities: ['ROLE_LATEKO'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/activate.card.loan.template.html',
							controller : 'ActivateWallesterCardLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCard({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }]
		            }
				})				
				.state('modules.loans.current.create-another-wallester-card', {
					url : '/create-another-wallester-card',
					parent : 'modules.loans.current',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-body' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-another-card.template.html',
							controller : 'WallesterAnotherCardController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/loan');
		                	$translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                defaultValue : ['$stateParams', 'Wallester', function($stateParams, Wallester) {
							return Wallester.getAnotherCardData({loan_no : $stateParams.loan_no}).$promise;
						}],
						cardTemplates: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCardTemplates({loan_no : $stateParams.loan_no}).$promise;
		                }],
		                cardHolderAddresses: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getHolderAddresses({loan_no : $stateParams.loan_no}).$promise;
		                }],
						detailMode: function(){
		                	return {
		                		edit: false,
		                		create: true
		                	};
		                }
					}
				})
				.state('modules.loans.current.cards.activate', {
					url : '/{token}/activate',
					parent : 'modules.loans.current.cards',
		            data: {
		            	authorities: ['ROLE_LATEKO'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/activate.card.loan.template.html',
							controller : 'ActivateCardLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCard({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }]
		            }
				})				
				.state('modules.loans.current.cards.edit-status', {
					url : '/{token}/edit-status',
					parent : 'modules.loans.current.cards',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.edit.status.loan.template.html',
							controller : 'CardEditStatusLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getCardByToken({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards.showSecret', {
					url : '/{card_id}/sensitive?secret',
					parent : 'modules.loans.current.wallester-cards',
		            data: {
		            	authorities: ['ROLE_EXECUTIVE', 'ROLE_DEVELOPER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/card.sensitive-data.template.html',
							controller : 'WallesterCardSensitiveDataController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                secret: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCardSecret({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id, secret: $stateParams.secret}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards.send-card-secret', {
					url : '/{card_id}/send-card-secret',
					parent : 'modules.loans.current.wallester-cards',
		            data: {
		            	authorities: ['ROLE_EXECUTIVE', 'ROLE_DEVELOPER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/card.send-secret.template.html',
							controller : 'WallesterCardSendSecretController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                details: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getDataForCardSecret({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.wallester-cards.edit-status', {
					url : '/{card_id}/edit-status',
					parent : 'modules.loans.current.wallester-cards',
		            data: {
		            	authorities: ['ROLE_LATEKO'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card-wallester/wallester-card.list.loan.template.html',
							controller : 'WallesterCardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card-wallester/card.edit.status.loan.template.html',
							controller : 'WallesterCardEditStatusController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Wallester', function($stateParams, Wallester) {
		                    return Wallester.getCard({loan_no : $stateParams.loan_no, card_id : $stateParams.card_id}).$promise;
		                }]
		            }
				
				})
				.state('modules.loans.current.cards.edit-pin', {
					url : '/{token}/edit-pin',
					parent : 'modules.loans.current.cards',
		            data: {
		            	authorities: ['ROLE_MANAGER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.edit.pin.loan.template.html',
							controller : 'CardEditPinLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getCardByToken({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }],
		                pinUpdateMode: function(){
		                	return {
		                		manual: true
		                	};
		                }
		            }
				
				})
				.state('modules.loans.current.cards.generate-pin', {
					url : '/{token}/generate-pin',
					parent : 'modules.loans.current.cards',
		            data: {
		            	authorities: ['ROLE_MANAGER'],
		            },
					views : {
						'loan-header' : {
							templateUrl : 'app/modules/loan/header/header.loan.template.html',
							controller : 'HeaderLoanController',
							controllerAs : 'vm'
						},
						'loan-sub-header' : {
							templateUrl : 'app/modules/loan/card/card.list.loan.template.html',
							controller : 'CardListLoanController',
							controllerAs : 'vm'
						},
						'loan-body@modules.loans.current' : {
							templateUrl : 'app/modules/loan/card/card.edit.pin.loan.template.html',
							controller : 'CardEditPinLoanController',
							controllerAs : 'vm'
						}
					},
					resolve: {
		                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
		                	$translatePartialLoader.addPart('modules/card.status');
		                    $translatePartialLoader.addPart('modules/loan');
		                    $translatePartialLoader.addPart('global');                    
		                    return $translate.refresh();
		                }],
		                card: ['$stateParams', 'Card', function($stateParams, Card) {
		                    return Card.getCardByToken({loan_no : $stateParams.loan_no, token : $stateParams.token}).$promise;
		                }],
		                pinUpdateMode: function(){
		                	return {
		                		manual: false
		                	};
		                }
		            }
				
				})
				.state('modules.loans.current.cards.send-pin', {
					url : '/{token}/send-pin',
		            data: {
		            	authorities: ['ROLE_LATEKO', 'ROLE_PARTNER'],
		            },
					parent : 'modules.loans.current.cards',
					onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
		                $uibModal.open({
		                    templateUrl: 'app/modules/loan/card/card.send.pin.loan.template.html',
		                    controller: 'CardSendPinLoanController',
		                    controllerAs: 'vm',
		                    size: 'md'
		                }).result.then(function() {
		                	$state.go('modules.loans.current.cards.view', {loan_no : $stateParams.loan_no, token : $stateParams.token},{reload: true});
		                }, function() {
		                    $state.go('modules.loans.current.cards.view', {loan_no : $stateParams.loan_no, token : $stateParams.token},{reload: true});
		                });
		            }]
				
				})
	        ;
    }
})();
