(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('WallesterCardSensitiveDataController', WallesterCardSensitiveDataController);

    WallesterCardSensitiveDataController.$inject = ['$scope', '$state', 'secret', '$stateParams', 'AlertService'];

    function WallesterCardSensitiveDataController ($scope, $state, secret, $stateParams, AlertService) {
        var vm = this;
        console.log('HAHA - WallesterCardSensitiveDataController');
        
        console.log(secret);
        vm.secret = "";
        if (secret && secret.value)
        	vm.secret = secret.value;
        
        vm.cancel = function(){
        	$state.go('modules.loans.current.wallester-cards.view', {card_id: $stateParams.card_id});
        };
        
    }
    
})();

