(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteApplicationController', DeleteApplicationController);

    DeleteApplicationController.$inject = ['$uibModalInstance', '$state', 'Application', 'AlertService'];

    function DeleteApplicationController ($uibModalInstance, $state, Application, AlertService) {
        var vm = this;

        if($state.params.id == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.application.message.errorApplicationDeleting");
        	return;
        }
 
        
        vm.applId = $state.params.id; 
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
            $state.go('modules.applications.detail', {id: $state.params.id});
        }

        function confirmDelete (applId) {
        	$uibModalInstance.close(true);
            Application.delete({id: applId},
                function (result) {
        	    	AlertService.success("modules.application.message.successApplicationDeleting", { id: applId});
                    $uibModalInstance.close(true);
                    $state.go('modules.applications', null, { reload: true });
                },
                function (error) {
        	    	AlertService.error("modules.application.message.errorApplicationDeleting", { id: applId});
                    $uibModalInstance.close(true);
                    $state.go('modules.applications.detail', {id: $state.params.id});
                });
        }
    }
})();
