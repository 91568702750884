(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('InterestListLoanController', InterestListLoanController);

    InterestListLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$window', '$translate', '$filter'];

    function InterestListLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, $window, $translate, $filter) {
        var vm = this;
        vm.kendo = kendo;
		var components = {
				data: []
			};
        console.log("InterestListLoanController started");
        vm.loanInterestsDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			    	id: "id",
			        fields: {
			        	id: {type: "number"},
	                    loanId: {type: "number"},
	                    dateFrom: {type: "date"},
	                    rateValue: {type: "number"},
	                    minpayAmt: {type: "number"},
	                    component: {type: "string"},
	                    componentTr: {type: "string"},
	                    crtdOn: {type: "date"},
	                    updtOn: {type: "date"},
	                    crtdBy: {type: "string"},
	                    updtBy: {type: "string"},
	                    ver: {type: "number"}
			        }
			      },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                        
	                        var component = {
	                        		component: elem.component
 		              		};
 		              		var foundComponent = $filter('filter')(components.data, {component: elem.component}, true);
 		                    if(foundComponent.length < 1) (components.data).push(component);
	                        
	                        
	                    });
	                	return d;
	              }
    		},
    		transport: {
				read: function(e){

					Loan.getInterests(
							{loan_no : $state.params.loan_no},
							function(response){
								e.success(response);
							},
							function(error){
								console.log('interest rates list error');
								e.error(error);
							}
					);
				}
			},
//            pageSize: defaultPageSize,
            serverPaging: false,
            serverFiltering: false,
            serverSorting: false,
            sort: {
                field: "dateFrom",
                dir: "desc"
            }
        });
        
        vm.loanInterestsOptions = {
                scrollable: {
                    virtual: true
                },
                sortable: true,                
                height: function(){
                	return $window.innerHeight/2.5;
                },                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: "No data"
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.interestRates'></span>"
  		                    		 + "<button has-authority='ROLE_MANAGER' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
  		                             + "<button has-authority='ROLE_MANAGER' style='margin-right:1em;' class='pull-right btn btn-default btn-xs' ui-sref='modules.loans.current.interests.create'>"
		                    		 + "<span class='glyphicon glyphicon-plus'></span>"
		                             + "&nbsp;<span translate='modules.loan.add'>"
		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-rates.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },  
                columns: [
                          { 
                        	  field: "dateFrom", 
                        	  title: "{{'modules.loan.table.from'|translate}}",
                        	  format: "{0:d}", 
                        	  width: "12ch"
                          },{ 
                        	  field: "component", 
                        	  title: "{{'modules.loan.table.component'|translate}}",
		                      filterable : {
					          	multi : true,
					            dataSource : new kendo.data.DataSource(components),
					            dataTextField : "component"
		                      },
                        	  width: "16ch"
                          },{ 
                        	  field: "rateValue", 
                        	  title: "{{'modules.loan.table.rateValue'|translate}}",
                        	  width: "14ch", 
                        	  format: "{0:n2}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "minPayAmt", 
                        	  title: "{{'modules.loan.table.minPayAmt'|translate}}",
                        	  width: "15ch", 
                        	  format: "{0:n2}", 
                        	  attributes: { "class": "text-right" }
                          },{ 
                        	  field: "reason", 
                        	  title: "{{'modules.loan.table.reason'|translate}}",
                        	  minScreenWidth: 992
                          },{ 
                        	  field: "updtOn", 
                        	  title: "{{'modules.loan.table.edtDate'|translate}}",
                        	  format: "{0:g}", 
                        	  width: "17ch",
                        	  minScreenWidth: 992
                          },{
                        	  template: "<button has-authority='ROLE_MANAGER' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.loans.current.interests.view({rateId: #:id#})'>"
                                + "<span class='glyphicon glyphicon glyphicon glyphicon-eye-open'></span>"
                                + "</button>",
		                        title: "&nbsp;", 
		                        width: "6ch" 
                          }
                ]
        };
      
       
    }
    
})();
