(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('InterestDetailLoanController', InterestDetailLoanController);

    InterestDetailLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', 'interests', '$filter', 'detailMode', 'previousState', '$translate', 'shortDateFormats', 'AlertService', '$element'];

    function InterestDetailLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, interests, $filter, detailMode, previousState, $translate, shortDateFormats, AlertService, $element) {
        var vm = this;
        vm.kendo = kendo;
        var widthCombo = 0;
        vm.editMode = detailMode.edit;
      	vm.createMode = detailMode.create;
      	vm.shortDateFormats = shortDateFormats;
        console.log("InterestDetailLoanController started");
        if(!vm.createMode) {
	      	vm.currentInterest = angular.copy($filter('filter')(interests, {id: parseInt($stateParams.rateId)}, true)[0]);
        } else {
        	vm.currentInterest = {};
        	vm.currentInterest.dateFrom = new Date();
        }
      	
      	vm.edit = function(){
      		$state.go('modules.loans.current.interests.edit', {rateId: $stateParams.rateId});
      	};
      	
      	vm.cancel = function(){
      		$state.go('modules.loans.current.interests.view', {rateId: $stateParams.rateId});
      	};
      	
      	vm.remove = function(){
      		console.log('deleting');
      		Loan.deleteInterest({loan_no: $stateParams.loan_no, rateId: vm.currentInterest.id},function(response){
      			$state.go('modules.loans.current.interests', {}, {reload: true});
      		});
      	};
      	
      	vm.save = function(){
        	// strange hack for kendo validation - to show error on custom validation on submit       	
        	vm.validator.validate();
        	
        	var custVaild = true;

        	$element.find('.form-group').each(function(){
        		var $formGroup = angular.element(this);
        		var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model],input[k-ng-model],select[k-ng-model]');
	            if ($inputs.length > 0) {
	              $inputs.each(function() {
	                  var $input = angular.element(this);
	                  if ($input.is("input[data-role=datepicker]")) {
    	                  if($input.context.required && $input.data("kendoDatePicker").value() === null) {
    	                	  custVaild = false;
    	                	  $formGroup.toggleClass('has-error', true);
    	                  } 
    	              }
	              });
	            }
	        });
	        
      		if(vm.interestRateForm.$invalid || !vm.validator.validate() || !custVaild) {
      			AlertService.error("modules.loan.message.errorInForm");
      			return;
      		}
        	
      		console.log('saving');
      		if(vm.createMode)
	      		Loan.createInterest({loan_no: $stateParams.loan_no},vm.currentInterest,function(response){
	      			vm.currentInterest = response;
	      			if(vm.currentInterest.id > 0)
		      			AlertService.success("modules.loan.message.interestRateCreatedSuccessfully");
	      				$state.go('modules.loans.current.interests.view', {rateId: vm.currentInterest.id}, {reload: true});
	      		}, function(error){
	                /*On error*/
	                console.log("creating interest rate: error");
	            });
      		else
      			Loan.updateInterest({loan_no: $stateParams.loan_no},vm.currentInterest,function(response){
	      			vm.currentInterest = response;
	      			AlertService.success("modules.loan.message.interestRateUpdatedSuccessfully");
	      			$state.go('modules.loans.current.interests.view', {rateId: vm.currentInterest.id}, {reload: true});
	      		}, function(error){
	               /*On error*/
	                console.log("updating interest rate: error");
	            });
      	};
      	
    	vm.componentData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        fields: {
			        	"component": { type: "string" },
			        	"componentTr": { type: "string" }		    	
			        	}
				  },
			      parse: function(d){
	                	$.each(d, function (idx, elem) {
	                        elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
	                        var itemWidth =  elem.component.length + elem.componentTr.length + 5;
		              		widthCombo = widthCombo > itemWidth ? widthCombo : itemWidth;
	                    });
	                	return d;
	              }
			},
			transport: {
				read: function(e){
					
					Loan.getRateComponentByLoanNo(
							{loan_no : $stateParams.loan_no },
							function(response){
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "component",
				dir: "desc"
			}
        });

    	vm.componentOptions = {
    			template: "<strong>#:component#</strong>.&nbsp;#:componentTr#",
        	    selectable: true,
        		open: function(e){
        			e.sender.list[0].style.minWidth = widthCombo + "ch";
        		},
        	    cascade: function(event) {
        	        var selected = event.sender.dataItem(event.sender.select());
                	vm.currentInterest.componentTr = selected.componentTr;
                	return;
        	    }
        };
      	
		vm.validatorOptions =  {    
			  	rules: {
			  		amountRange: function(input) {
				              if (input.is("[data-role=numerictextbox]") && input.is("[name=applAmount]")) {
				                	  //for recovery after submit and fix error value
				                	  var response = newApplAmountIsValid(input.data("kendoNumericTextBox").value());
				                	  return response; 
				              } else return true;
			  		}
			  	}
		};

    }
    
})();
