(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .directive('showValidation', showValidation);
    function showValidation () {
        var directive = {
            restrict: 'A',
            require: 'form',
            link: linkFunc
        };

        return directive;

        function linkFunc (scope, element, attrs, formCtrl) {
	            element.find('.form-group').each(function() {
	                var $formGroup = angular.element(this);
	                var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],bc-phone-number[ng-model]');
	                if ($inputs.length > 0) {
	                    $inputs.each(function() {
	                        var $input = angular.element(this);
	                        var inputName = $input.attr('name');
	                        
	                        if($input[0].type == 'radio') return true;
	                        if($input[0].tagName == "BC-PHONE-NUMBER") return true;
	
	                        if(typeof formCtrl[inputName] != 'undefined') {
	
			                    scope.$watch(function() {
			                    	return formCtrl[inputName].$invalid && formCtrl[inputName].$touched; 
			                    }, function(isInvalid) {
			                        $formGroup.toggleClass('has-error', isInvalid);
			                    });
	                        }
	                    });
	                }
	            });
        }
    }
})();
