(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DetailTaskBpmController', DetailTaskBpmController);

    DetailTaskBpmController.$inject = ['$scope', '$state', 'Bpm', 'AlertService', 'detailMode', 'formHelper', '$filter'];

    function DetailTaskBpmController ($scope, $state, Bpm, AlertService, detailMode, formHelper, $filter) {
        var vm = this;
        console.log("DetailTaskBpmController started");
        
    	vm.isViewOnly = !detailMode.edit;
    	if(detailMode.edit) formHelper.setFocus('first-field');
    	
        if($state.params.taskId != null && $state.params.tasks !=null) {
        	vm.currentTask = {};
        	vm.currentTaskOriginal = $filter('filter')($state.params.tasks, {taskId: parseInt($state.params.taskId)}, true)[0];
        	angular.copy(vm.currentTaskOriginal,vm.currentTask);
	        vm.isErrorStyle = vm.currentTask.retries > 0 ? true: false;
	        vm.retry = function() {
	    		console.log("retrying task ...");
	    		
	    		Bpm.restartTask({taskId: vm.currentTask.taskId},
	                    function (responce) {
			    			AlertService.success("Task is set for retrying successfully");
			    			$state.go('modules.bpm.tasks', {}, {reload: true});
	                    });
	
			};
			
	        vm.cancel = function(){
	        	vm.currentTask = vm.currentTaskOriginal;
	        	$state.go('modules.bpm.tasks.detail');
	        };
	        
	        vm.save = function(){
	    		Bpm.updateTask(vm.currentTask,
	                    function (responce) {
			    			AlertService.success("Task is updated successfully");
			    			$state.go('modules.bpm.tasks',{},{reload:true});
	                    });
	        };
	        
        } else $state.go('modules.bpm.tasks');
        

    }
    
})();
