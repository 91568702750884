(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteShortApplicationController', DeleteShortApplicationController);

    DeleteShortApplicationController.$inject = ['$uibModalInstance', '$state', 'ShortApplication', 'AlertService'];

    function DeleteShortApplicationController ($uibModalInstance, $state, ShortApplication, AlertService) {
        var vm = this;

        if($state.params.id == null ) {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("modules.application.message.errorApplicationDeleting");
        	return;
        }
 
        
        vm.sapplId = $state.params.id; 
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (sapplId) {
        	$uibModalInstance.close(true);
            ShortApplication.delete({id: sapplId},
                function (result) {
            		AlertService.success("modules.application.message.successShortApplicationDeleting", { id: sapplId});
                    $uibModalInstance.close(true);
                    $state.go('modules.short-applications', null, { reload: true });
                },
                function (error) {
            		AlertService.error("modules.application.message.errorShortApplicationDeleting", { id: sapplId});
                    $uibModalInstance.close(true);
                    $state.go('modules.short-applications.detail', {id: $state.params.id});
                });
        }
    }
})();
