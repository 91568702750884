(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ShortApplicationsController', ShortApplicationsController);

    ShortApplicationsController.$inject = ['$window', '$rootScope', '$scope', '$state', 'defaultPageSize', 'ShortApplication', '$q', '$interval', 'shortRefresh', 'longRefresh', '$translate', '$filter', 'shortDateFormats', 'Principal', 'Cookies', 'AlertService'];

    function ShortApplicationsController ($window, $rootScope, $scope, $state, defaultPageSize, ShortApplication, $q, $interval, shortRefresh, longRefresh, $translate, $filter, shortDateFormats, Principal, Cookies, AlertService) {
        var vm = this;
        vm.refresh = longRefresh;
        vm.office = {};
        
        console.log("short application list controller");
		var updtBys = {
				data: []
		};
        
		var checkResultsTranslated = {
				data: []
			};
		vm.dateFormat = kendo.culture().calendar.patterns.d;
		vm.shortDateFormats = shortDateFormats;
//		var dates = [];
		
    	Principal.identity().then(function(account) {
            vm.account = account;
            vm.office.prtId = parseInt(Cookies.partnerID(vm.account.login));
            vm.office.offId = parseInt(Cookies.officeID(vm.account.login));
            if (vm.office.prtId <= 0 || vm.office.prtId == null || vm.office.offId <= 0 || vm.office.offId == null){
            	AlertService.error("global.messages.error.wrongPartnerOffice");
            };
            loadData();
        });
        
        var applsTop = angular.element(".appls-wrap").offset().top;
        var footerHeight = angular.element('.footer').innerHeight();
        vm.height = .9 * $window.innerHeight - footerHeight - applsTop + 'px';
        
        vm.mainData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			          "id": { type: "number", validation:{required:true} },
			          "sapplDate": { type: "date" },
			          "partnerId": { type: "number" },
			          "partnerName": { type: "string" },
			          "personCode": { type: "string" },
			          "firstName": { type: "string" },
			          "lastName": { type: "string" },
			          "fullName": { type: "string"},
			          "sapplSum": { type: "number" },
			          "sapplStatus": { type: "string" },
			          "checkResult": { type: "string" },
			          "checkResultTranslated": { type: "string" },
			          "isEditable": { type: "boolean" },
			          "canCreateLongAppl": { type: "boolean" },
			          "updtBy": { type: "string" },
			          "updated": { type: "date" }
			    	}
				},
                parse: function(d){
                	$.each(d, function (idx, elem) {
                        elem.fullName = elem.firstName + " " + elem.lastName;
                        
	              		var updtBy = {
	              				updtBy: elem.updtBy
	              		};
	              		var foundUpdtBy = $filter('filter')(updtBys.data, {updtBy: elem.updtBy}, true);
	                    if(foundUpdtBy.length < 1) (updtBys.data).push(updtBy);
                        
                        elem.checkResultTranslated = $translate.instant('modules.application.checkResult.' + elem.checkResult);
                        
//	                    var date = kendo.toString(elem.applDate, "d");
//	                    var foundDate = $filter('filter')(dates, date, true);
//	                    if(foundDate.length < 1) (dates).push(date);
                        
	                    var checkResultTranslated = {
	                    		checkResultTranslated: elem.checkResultTranslated,
	                    };
	                    var foundCheckResultTranslated = $filter('filter')(checkResultsTranslated.data, {checkResultTranslated: elem.checkResultTranslated}, true);
	                    if(foundCheckResultTranslated.length < 1) (checkResultsTranslated.data).push(checkResultTranslated);
	                    
                    });
                	return d;
                }
			},
			transport: {
				read: function(e){
					
					ShortApplication.query(
							function(response){
								e.success(response);
							},
							function(error){
								console.log('application list error');
								e.error(error);
							}
					);
				}
			},
			sort: {
				field: "updated",
				dir: "desc"
			}
        });
        
        vm.mainOptions = {
                height: vm.height,  
                scrollable: {
                    virtual: true
                },
                sortable: true,                
                pageable: {
                	refresh: true,
                	previousNext: false,
                	numeric: false,
                	info: false,
                	messages: {
                	      empty: $translate.instant('modules.application.noData'),
                	    }
                },
                filterable: {
                    extra: false,
                    operators: {
                        string: {
                            contains: "Contains",
                        }
                    }
                },
                toolbar: [
                          { 
  		                    template: "<button has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.application.excel'>"
  		                             + "</span></button>"
  		                             + "<button has-any-authority='ROLE_PARTNER,ROLE_LATEKO' ui-sref='modules.short-applications.new' style='margin-right:1em;' class='pull-right btn btn-primary btn-xs'>"
  		                    		 + "<span class='fa fa-plus'></span>"
  		                             + "&nbsp;<span translate='modules.application.create-short-application'>"
  		                             + "</span></button>"
                            }
                  ],
                excel: {
                    fileName: "short-applications.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                          if(cell.value == "<span class='fa fa-user'></span>") cell.value = $translate.instant('modules.application.list.updated');
                        });
                      }
                	});
                },
                columns: [{
	                    field: "sapplDate",
	                    title: "{{'modules.application.list.applDate'|translate}}",
	                    format: "{0:d}",
	                    width: "14ch",
	                    filterable: {
//                			ui: function(element) {
//                				//angular.element(element).toggleClass('TEST');
//                                element.kendoAutoComplete({
//                                    dataSource: {
//                                    	data: dates
//                                    }
//                                });
//	                        },
//	                    	ui: "datepicker",
                			ui: function(element) {
	                            element.kendoDatePicker();
	                            angular.element(element).attr("placeholder",vm.dateFormat);
	                        },
	                    	operators: {
	                    		date: {
	                    		       eq: $translate.instant('modules.utils.operators.eq'),
	                    		       neq: $translate.instant('modules.utils.operators.neq'),
	                    		       gte: $translate.instant('modules.utils.operators.gte'),
	                    		       lte: $translate.instant('modules.utils.operators.lte')
	                    		     }
	                    	}
	                    }
                    },{
	                    field: "partnerName",
	                    title: "{{'modules.application.list.partnerName'|translate}}",
	                    minScreenWidth: 1072,
	                    width: "20ch"
                    },{
	                    field: "personCode",
	                    title: "{{'modules.application.list.personCode'|translate}}",
	                    width: "20ch"
                    },{
                        field: "fullName",
                        title: "{{'modules.application.list.fullName'|translate}}",
                    },{
                        field: "sapplSum",
                        title: "{{'modules.application.list.applSum'|translate}}",
                        format: "{0:n2}",
                        attributes: {class:"text-right"},
                        width: "11ch",
                        filterable: false
                    },{
                        field: "checkResultTranslated",
                        title: "{{'modules.application.list.checkResult'|translate}}",
                        template: "<span translate='modules.application.checkResult.#:checkResult#'></span>",
	                    width: "20ch",
	                    filterable : {
			            	multi : true,
			                dataSource : new kendo.data.DataSource(checkResultsTranslated),
			                dataTextField : "checkResultTranslated"
                        }
                    },{
                        field: "updtBy",
                        title: "<span class='fa fa-user'></span>",
	                    width: "10ch",
                        filterable : {
			            	multi : true,
			                dataSource : new kendo.data.DataSource(updtBys),
			                dataTextField : "updtBy"
                        }
                    },{
                    	title: "{{'modules.application.list.actions'|translate}}",
                    	template: "<button has-authority='ROLE_USER' ng-hide='#:isEditable#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.view' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.short-applications.detail({id: #:id#})'>"
                                + "<span class='glyphicon glyphicon-eye-open'></span>"
                                + "</button>"
                                + "<button has-any-authority='ROLE_USER' ng-show='#:isEditable#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'entity.action.edit' | translate }}'\" class='btn btn-xs btn-default k-button-icontext' ui-sref='modules.short-applications.detail({id: #:id#})'>"
                                + "<span class='glyphicon glyphicon-pencil'></span>"
                                + "</button>"
                                + "<button has-any-authority='ROLE_PARTNER,ROLE_LATEKO' ng-show='#:canCreateLongAppl#' kendo-tooltip k-position=\"'top'\" k-content=\"'{{ 'modules.application.result.messages.goToLongAppl' | translate }}'\" class='btn btn-xs btn-success k-button-icontext' ng-click='vm.goToLongAppl(this)'>"
                                + "<span class='fa fa-file-text-o'></span>"
                                + "</button>",
                        width: "13ch" 
                    }]
        };

        vm.goToLongAppl = function(current) {
        	ShortApplication.get({id : current.dataItem.id}).$promise.then(function(item){
        		$state.go('modules.applications.new-from-short', { shortAppl: item });	
        	});
        };
        
        var run = $interval(reload, vm.refresh);
        
        function loadData(){
        	if (vm.office.prtId > 1){
                vm.mainData.query({
    		    	serverFiltering: false,
                	filter: {field: "partnerId", operator: "eq", value: vm.office.prtId }
                });
            }else{
            	vm.mainData.read();
            };
        };
        
        function reload(){
        	loadData();
        	vm.mainData.fetch(function(){
	        	  var data = vm.mainData.data();
	        	  var inProgressCounter = 0;
	        	  for (var i = 0; i < data.length; i++) {
		        	  if( data[i].sapplStatus == "NEW" ||
		                  data[i].sapplStatus == "QUEUE_AUTO" || 
		                  data[i].sapplStatus == "QUEUE_MANUAL" || 
		                  data[i].sapplStatus == "IN_PROGRESS" ) inProgressCounter++;
	        	  }
	        	  vm.refresh = inProgressCounter > 0 ? shortRefresh: longRefresh;
	        });
        }
        
        $scope.$watch(function() { return vm.refresh;}, function(newVal, oldVal) {
        	$interval.cancel(run);
        	run = $interval(reload, vm.refresh);
        }, true);
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
        	$interval.cancel(run);
        });
    }
})();
