(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('ClientMenuController', ClientMenuController);

    ClientMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation'];

    function ClientMenuController ($scope, $state, $stateParams, eehNavigation) {
        var vm = this;
        console.log("ClientMenuController ...");
        
        eehNavigation
			    .menuItem('sidenavClient.client', {
			      text: 'modules.client.menu.client',
			      iconClass: 'fa-address-card-o',
			      isCollapsed: false,
			      weight: -10
			    })
		        .menuItem('sidenavClient.client.view', {
		          text: 'modules.client.menu.view',
		          iconClass: 'fa-list-alt',
		          state: 'modules.clients.view',
		          weight: -10
		        })
		        .menuItem('sidenavClient.client.addresses', {
		          text: 'modules.client.menu.addresses',
		          iconClass: 'fa-random',
		          state: 'modules.clients.addresses',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.client.phones', {
		          text: 'modules.client.menu.phones',
		          iconClass: 'fa-phone',
		          state: 'modules.clients.phones',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.client.emails', {
		          text: 'modules.client.menu.emails',
		          iconClass: 'fa-envelope-o',
		          state: 'modules.clients.emails',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.client.agreements', {
		          text: 'modules.client.menu.agreements',
		          iconClass: 'fa fa-check-square-o',
		          state: 'modules.clients.agreements',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.client.history', {
		          text: 'modules.client.menu.history',
		          iconClass: 'fa-history',
		          state: 'modules.clients.history',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.other', {
			      text: 'modules.client.menu.other',
			      iconClass: 'fa-files-o',
			      isCollapsed: false,
			      weight: -10
			    })
		        .menuItem('sidenavClient.other.loans', {
		          text: 'modules.client.menu.loans',
		          iconClass: 'fa-file-text-o',
		          state: 'modules.clients.loans',
		          weight: -10,
		        })
		        .menuItem('sidenavClient.other.debtActionLog', {
		          text: 'modules.client.menu.debtActionLog',
		          iconClass: 'fa-list-alt',
		          state: 'modules.clients.debt-action-log',
		          weight: -8
		        })
		        .iconBaseClass('fa');
    }
    
})();
