(function() {
	'use strict';
	angular.module('sigmaApp').factory('Calculator', Calculator);

	Calculator.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Calculator($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api/applications/';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						url: resourceUrl + 'tariffs',
						params: {product: null, person_code: null },
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'send' : {
						method : 'POST',
						url: resourceUrl + 'calculator',
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
				});
	}
})();