(function() {
	'use strict';
	angular.module('sigmaApp').factory('Search', Search);

	Search.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Search($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/search/';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						isArray : true,
						params: {look4: null},
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
									data.docDate = DateUtils.convertLocalDateFromServer(data.docDate);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					}
				});
	}
})();