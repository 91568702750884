(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('AnnulatePenaltyLoanController', AnnulatePenaltyLoanController);

    AnnulatePenaltyLoanController.$inject = ['Principal', '$scope', '$state', '$stateParams', 'Loan', '$translate', 'AlertService'];

    function AnnulatePenaltyLoanController (Principal, $scope, $state, $stateParams, Loan, $translate, AlertService) {
        var vm = this;
        vm.kendo = kendo;
      	vm.annulatePenalty = {};
      	vm.loanNo = $stateParams.loanNo;
      	
      	Principal.identity().then(function (account) {
      		vm.right = account.login === 'valsin' || account.authorities.indexOf("ROLE_DEVELOPER") >= 0 || account.authorities.indexOf("ROLE_ACCOUNTANT") >= 0;
      		if(!vm.right) $state.go('modules.loans.current.condition',{reload:true});
      	});
      	
      	vm.dateFormat = kendo.culture().calendar.patterns.d;
        console.log("AnnulatePenaltyLoanController started");
        
        vm.executeAnnulatePenalty = function(){
        	if(vm.annulatePenalty.onDate == null) {
        		vm.emptyDate = true; 
        		AlertService.error("modules.transaction.message.errorEmptyDateAnnulatePenalty");
        		return;
        	}
        	if(vm.annulatePenalty.reason == null || vm.annulatePenalty.reason == "") {
        		vm.emptyReason = true;  
        		AlertService.error("modules.transaction.message.errorEmptyReasonAnnulatePenalty");
        		return;
        	}
        	if(vm.annulatePenalty.amount <= 0 || vm.annulatePenalty.amount == null) {
        		vm.wrongAmount = true;
        		AlertService.error("modules.transaction.message.errorEmptyOrNegativeAmountAnnulatePenalty");
        		return;
        	}
        	
        	$state.go('modules.loans.current.accounting.annulate-penalty.confirmation',{annulatePenaly: vm.annulatePenalty});
        	
        };
              
        $scope.$watch(
        		function(){
        			return vm.annulatePenalty.onDate;
        		}, 
        		function(newApp,oldApp) {
        			if(!angular.equals(newApp,oldApp))
        				if(newApp == null) 
        					vm.emptyDate = true; 
        				else
        					vm.emptyDate = false;
        		}
        );
        
        $scope.$watch(
        		function(){
        			return vm.annulatePenalty.reason;
        		}, 
        		function(newApp,oldApp) {
        			if(!angular.equals(newApp,oldApp))
        				if(newApp == null || newApp == "") 
        					vm.emptyReason = true; 
        				else
        					vm.emptyReason = false;
        		}
        );

        $scope.$watch(
        		function(){
        			return vm.annulatePenalty.amount;
        		}, 
        		function(newApp,oldApp) {
        			if(!angular.equals(newApp,oldApp))
        				if(newApp <= 0 || newApp == null) 
        					vm.wrongAmount = true; 
        				else
        					vm.wrongAmount = false;
        		}
        );        
        
    }
    
})();
