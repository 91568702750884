(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        	.state('modules.report', {
				url : '/reports',
				parent : 'modules',
				abstract: true,
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'content@' : {
						templateUrl : 'app/modules/report/report.template.html',
						controller : 'ReportMenuController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }]
				}
			})
	       	.state('modules.report.dashboard', {
				url : '/dashboard',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'], 
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/dashboard/dashboard.report.template.html',
						controller : 'DashboardReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.draft', {
				url : '/draft',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/draft/draft.report.template.html',
						controller : 'DraftReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.application', {
				url : '/application',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/application/application.report.template.html',
						controller : 'ApplicationReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.marketing', {
				url : '/marketing',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/marketing/marketing.report.template.html',
						controller : 'MarketingReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.wallesterSettlement', {
				url : '/wallesterSettlement',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/wallester-settlement/wallester-settlement.report.template.html',
						controller : 'WallesterSettlementReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.edocs', {
				url : '/edocs',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/edocs/edocs.report.template.html',
						controller : 'EdocsReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.card', {
				url : '/card',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/card/created.card.report.template.html',
						controller : 'CreatedCardReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				}
			})
			.state('modules.report.report1', {
				url : '/report1',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/report1/report1.template.html',
						controller : 'Report1Controller',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getReport1List().$promise;
	                }]
	                
				},
				onEnter: ['WsService', function(WsService) {
	            	WsService.subscribe();
	            }],
	            onExit: ['WsService', function(WsService) {
	            	WsService.unsubscribe();
	            }]
			})
			.state('modules.report.shareholders', {
				url : '/shareholders',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/shareholders/shareholders.report.template.html',
						controller : 'ShareholdersReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getStat().$promise;
	                }]
	                
				},
				onEnter: ['WsService', function(WsService) {
	            	WsService.subscribe();
	            }],
	            onExit: ['WsService', function(WsService) {
	            	WsService.unsubscribe();
	            }]
			})
            .state('modules.report.sales-channel', {
			  url : '/sales-channel',
			  parent : 'modules.report',
              data: {
              	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
              },
			  views : {
			  	'report-body': {
			  		templateUrl : 'app/modules/report/sales-channel/sales-channel.report.template.html',
			  		controller : 'SalesChannelReportController',
			  		controllerAs : 'vm'
			  	}
			  },
			  resolve: {
                  translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                  	$translatePartialLoader.addPart('modules/report');
                  	$translatePartialLoader.addPart('global');                    
                      return $translate.refresh();
                  }],
			  	previousState: ["$state", function ($state) {
                      var currentStateData = {
                          name: $state.current.name || 'modules.report',
                          params: $state.params,
                          url: $state.href($state.current.name, $state.params)
                      };
                      return currentStateData;
                  }],
                  stat: ['Report', function(Report) {
                      return Report.getStat().$promise;
                  }]
                  
			  },
			  onEnter: ['WsService', function(WsService) {
              	WsService.subscribe();
              }],
              onExit: ['WsService', function(WsService) {
              	WsService.unsubscribe();
              }]
		    })
		    .state('modules.report.commissions', {
				url : '/commissions',
				parent : 'modules.report',
	            data: {
	            	authorities: ['ROLE_MANAGER', 'ROLE_ACCOUNTANT'],
	            },
				views : {
					'report-body': {
						templateUrl : 'app/modules/report/commissions/commissions.report.template.html',
						controller : 'CommissionsReportController',
						controllerAs : 'vm'
					}
				},
				resolve: {
	                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
	                	$translatePartialLoader.addPart('modules/report');
	                	$translatePartialLoader.addPart('global');                    
	                    return $translate.refresh();
	                }],
					previousState: ["$state", function ($state) {
	                    var currentStateData = {
	                        name: $state.current.name || 'modules.report',
	                        params: $state.params,
	                        url: $state.href($state.current.name, $state.params)
	                    };
	                    return currentStateData;
	                }],
	                stat: ['Report', function(Report) {
	                    return Report.getCommissionsList().$promise;
	                }]
	                
				},
				onEnter: ['WsService', function(WsService) {
	            	WsService.subscribe();
	            }],
	            onExit: ['WsService', function(WsService) {
	            	WsService.unsubscribe();
	            }]
			});
    }
})();
