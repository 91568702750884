(function() {
	'use strict';
	angular.module('sigmaApp').factory('Report', Report);

	Report.$inject = [ '$resource', 'baseUrl', 'DateUtils'];

	function Report($resource, baseUrl, DateUtils) {
		var resourceUrl = baseUrl + 'api';

		return $resource(resourceUrl,{taskId: '@taskId'},
				{
					'getTasks' : {
						method : 'GET',
						url: resourceUrl + "/tasks",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getStat' : {
						method : 'GET',
						url: resourceUrl + "/bpm/kpis/?group=LOAN_STATUS_AMOUNT&skip=&lastdays=0",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'deleteTask' : {
						method : 'DELETE',
						url: resourceUrl + "/tasks/:taskId"
					},
					'restartTask' : {
						method : 'PUT',
						url: resourceUrl + "/tasks/:taskId/retry"
					},
					'updateTask' : {
						method : 'PUT',
						url: resourceUrl + "/tasks/:taskId"
					},
					'getReport1List' : {
						method : 'GET',
						url: resourceUrl + "/dms/doc/bytag/report1",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getWallesterSettlementList' : {
						method : 'GET',
						url: resourceUrl + "/dms/doc/bytag/wallester-settlement",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getShareholdersList' : {
						method : 'GET',
						url: resourceUrl + "/dms/doc/bytag/shareholders",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'generateShareholders' : {
						method : 'POST',
						url: resourceUrl + "/reports/generate/shareholders?dateFrom=:dateFrom&dateTill=:dateTill"
					},
					'generateReport1' : {
						method : 'POST',
						url: resourceUrl + "/reports/generate/report1"
					},
					'generateWallesterSettlementReport' : {
						method : 'POST',
						url: resourceUrl + "/reports/generate/wallester-settlement"
					},
					'getSalesChannelList' : {
						method : 'GET',
						url: resourceUrl + "/dms/doc/bytag/sales-channel",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'generateSalesChannel' : {
						method : 'POST',
						url: resourceUrl + "/reports/generate/sales-channel?dateFrom=:dateFrom&dateTill=:dateTill"
					},
					'getPartnersList' : {
						method : 'GET',
						url: resourceUrl + "/partners",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getCommissionsList' : {
						method : 'GET',
						url: resourceUrl + "/dms/doc/bytag/commissions",
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'generateCommissions' : {
						method : 'POST',
						url: resourceUrl + "/reports/generate/commissions?partner:=partner&dateFrom=:dateFrom&dateTill=:dateTill"
					},
				});
	}
})();