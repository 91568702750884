(function() {
	'use strict';
	angular.module('sigmaApp').factory('DemoWsService', DemoWsService);

	DemoWsService.$inject = [ '$resource', 'baseUrl', '$rootScope', '$window', '$cookies', '$http', '$q'];

	function DemoWsService($resource, baseUrl, $rootScope, $window, $cookies, $http, $q) {
        var stompClient = null;
        var subscriber = null;
        var listener = $q.defer();
        var connected = $q.defer();
        var alreadyConnectedOnce = false;

        var service = {
            connect: connect,
            disconnect: disconnect,
            receive: receive,
            sendActivity: sendActivity,
            subscribe: subscribe,
            unsubscribe: unsubscribe
        };

        return service;

        function connect () {
        	console.log("ws.connect");
            //building absolute path so that websocket doesnt fail when deploying with a context path
            var loc = $window.location;
            var url = '//' + loc.host /* + loc.pathname*/ + "/" + 'websocket/channel';
        	console.log("ws.connect: " + url);
            var socket = new SockJS(url);
            stompClient = Stomp.over(socket);
            var stateChangeStart;
            var headers = {};
            headers[$http.defaults.xsrfHeaderName] = $cookies.get($http.defaults.xsrfCookieName);
            stompClient.connect(headers, function() {
                connected.resolve('success');
                sendActivity();
                if (!alreadyConnectedOnce) {
                    stateChangeStart = $rootScope.$on('$stateChangeStart', function () {
                        sendActivity();
                    });
                    alreadyConnectedOnce = true;
                }
            });
            $rootScope.$on('$destroy', function () {
                if(angular.isDefined(stateChangeStart) && stateChangeStart !== null){
                    stateChangeStart();
                }
            });
        }

        function disconnect () {
            if (stompClient !== null) {
                stompClient.disconnect();
                stompClient = null;
            }
        }

        function receive () {
            return listener.promise;
        }

        function sendActivity() {
            if (stompClient !== null && stompClient.connected) {
                stompClient
                    .send('/topic/events',
                    {},
                    angular.toJson({'page': $rootScope.toState.name}));
            }
        }

        function subscribe () {
        	console.log("subscribe");
            if (stompClient !== null) {
            	console.log("stompclient exists");
            	if (stompClient.connected) 
            		console.log("stompclient connected");
            	else console.log("failed to connect stomp client");
            } else {
            	console.log("stompclient does not initialized!");
            }
            
            connected.promise.then(function() {
            	console.log("subscribe - connected - then");
                subscriber = stompClient.subscribe('/topic/events', function(data) {
                    listener.notify(angular.fromJson(data.body));
                });
            }, null, null);
        }

        function unsubscribe () {
        	console.log("unsubscribe");
            if (subscriber !== null) {
                subscriber.unsubscribe();
            }
            listener = $q.defer();
        }
	}
})();