(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DashboardReportController', DashboardReportController);

    DashboardReportController.$inject = ['$scope', '$rootScope', '$state', 'stat', '$translate', 'shortRefresh', '$interval', 'baseUrl'];

    function DashboardReportController ($scope, $rootScope, $state, stat, $translate, shortRefresh, $interval, baseUrl) {
        var vm = this;
        vm.reload = reload;
        vm.test = "test";
        
        function reload(){
        	vm.reportLoanStatusCountDataSource.read();
        	vm.reportLoanAmountDataSource.read();
        	vm.reportLoanStatusTotalCountDataSource.read();
        	vm.reportLoanPaymentAmountDataSource1.read();
        	vm.reportLoanPaymentAmountDataSource2.read();
        };

        vm.reportLoanStatusCountDataSource = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=LOAN_STATUS_COUNT&skip=&lastdays=30",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			group : [{ field: "kpiDesc", dir: "asc", aggregates: [{ field: "groupSum", aggregate: "sum" }]}],
			sort :{ field: "amount", dir: "desc" },
			serverPaging : false,
		});
        
        vm.reportLoanAmountDataSource = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=LOAN_AMOUNT&skip=&lastdays=60",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			group : [{ field: "kpiDesc", dir: "asc" }],
			serverPaging : false,
		});
        
        vm.reportLoanStatusTotalCountDataSource = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=LOAN_STATUS_COUNT&skip=&lastdays=0",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			sort :{ field: "kpiDesc", dir: "asc" },
			serverPaging : false,
		});
        
        vm.reportLoanPaymentAmountDataSource1 = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=LOAN_AMOUNT&skip=LOAN_DEBT_AMOUNT&lastdays=60",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			group : [{ field: "kpiDesc", dir: "asc" }],
			serverPaging : false,
		});
        
        vm.reportLoanPaymentAmountDataSource2 = new kendo.data.DataSource({
			transport : {
				read : {
					url : baseUrl + "api/bpm/kpis/?group=LOAN_AMOUNT&skip=LOAN_LIMIT_AMOUNT&lastdays=60",
					dataType : "json"
				}
			},
			schema : {
				model : {
					fields : {
						amount: { type : "number" },
						date: { type : "date" },
						metric : { type : "string" },
						metricGroup: { type : "string" },
						measureUnit: { type : "string" },
					}
				},
			},
			group : [{ field: "kpiDesc", dir: "asc" }],
			serverPaging : false,
		});
    }
})();
