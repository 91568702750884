(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('HistoryLoanController', HistoryLoanController);

    HistoryLoanController.$inject = ['$scope', '$state', 'menuWithHeader'];

    function HistoryLoanController ($scope, $state, menuWithHeader) {
        var vm = this;
        console.log("HistoryLoanController started");
        vm.loanId =  menuWithHeader[0].id;
    }
    
})();
