(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('BpmMenuController', BpmMenuController);

    BpmMenuController.$inject = ['$scope', '$state', '$stateParams', 'eehNavigation'];

    function BpmMenuController ($scope, $state, $stateParams, eehNavigation) {
        var vm = this;
        console.log("BpmMenuController ...");
       
        eehNavigation
	        	.menuItem('sidenavBpm.dashboard', {
		          text: 'modules.bpm.menu.dashboard',
		          iconClass: 'fa-pie-chart',
		          state: 'modules.bpm.dashboard',
		          weight: -10,
		          isVisible: true
		        })
		        .menuItem('sidenavBpm.tasks', {
		          text: 'modules.bpm.menu.tasks',
		          iconClass: 'fa-list-alt',
		          state: 'modules.bpm.tasks',
		          weight: -10,
		          isVisible: true
		        })
		        .iconBaseClass('fa');
        
    }
    
})();
