(function() {
	'use strict';
	angular.module('sigmaApp').factory('ChangeCardStatus', ChangeCardStatus);

	ChangeCardStatus.$inject = [ '$resource', 'baseUrl'];

	function ChangeCardStatus($resource, baseUrl) {
		var resourceUrl = baseUrl + 'api/loans/cards/change-status';
		
		return $resource(resourceUrl, {}, {
			'changeCardStatus' : {
				method : 'POST',
				isArray : false,
			}
        });	
	}
})();