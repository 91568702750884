(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('CalculatorController', CalculatorController);

    CalculatorController.$inject = ['$scope', '$state', 'Calculator', 'formHelper', '$element', '$window', '$timeout', 'baseUrl', 'AlertService', '$translate', '$rootScope', 'Principal', 'Cookies'];

    function CalculatorController ($scope, $state, Calculator, formHelper, $element, $window, $timeout, baseUrl, AlertService, $translate, $rootScope, Principal, Cookies) {
        var vm = this;
        $rootScope.narrow = true;
        vm.kendo = kendo;
        vm.noTariffs = false;
        vm.product = "HERCUS_CARD";
        vm.tariff = {};
        vm.calculator = {};
        vm.personCode = '';
        vm.applAmount = 0;
        vm.financedAmount = 0;
        vm.defaultAmmount = 0;
        vm.loadTariffs = loadTariffs;
        vm.calculate = calculate; 
        vm.isDefaultAmountValue = false;
        vm.office = {};
        var lastErorMsg;
        var applAmountCalcIsParent = false;
        
    	Principal.identity().then(function(account) {
            vm.account = account;
            vm.office.prtId = Cookies.partnerID(vm.account.login);
            vm.office.offId = Cookies.officeID(vm.account.login);
            if (vm.office.prtId <= 0 || vm.office.prtId == null || vm.office.offId <= 0 || vm.office.offId == null){
            	AlertService.error("global.messages.error.wrongPartnerOffice");
            };
            loadTariffs();
        });
        
        vm.mainData = new kendo.data.DataSource({
        	schema: {
			      model: {
			        id: "id",
			        fields: {
			        	"type": { type: "string" },
			        	"productType": { type: "string" },
			        	"personCode": { type: "string" },
			        	"name": { type: "string" },
			        	"priority": { type: "number" },
			        	"minAmount": { type: "number" },
			        	"maxAmount": { type: "number" },
			        	"defaultTrm": { type: "number" },
			        	"defaultAmount": { type: "number" }			    	}
				}
			},
			transport: {
				read: function(e){
				
					Calculator.query(
							{product : vm.product, person_code: vm.tariff.personCode, prt_id: vm.office.prtId },
							function(response){
				        		vm.noTariffs = response.length > 0 ? false : true ;
								e.success(response);
							},
							function(error){
								console.log('calculator tariffs error');
								e.error(error);
							}
					).$promise;
				}
			},
			sort: {
				field: "priority",
				dir: "desc"
			}
        });

        vm.mainOptions = {
        	    selectable: "Single",
        	    dataBound: function(event) {
        	    	var first = event.sender.element.children().first();
        	        event.sender.select(first);
        	    },
        	  	change: function(event) {
        	  		
        	  		vm.onApplErrorHide = false;
        	  		vm.onFinErrorHide = false;
        	  		
        	        var selected = event.sender.dataItem(event.sender.select());
                	vm.calculator.tariffId = selected.id;
                	vm.calculator.prtId = selected.prtId;
                	vm.minSum = selected.minAmount;
                	vm.maxSum = selected.maxAmount;
                	vm.defaultAmmount = selected.defaultAmount < selected.minAmount || selected.defaultAmount > selected.maxAmount ? selected.minAmount : selected.defaultAmount ;

                	if(vm.applAmount == 0) vm.isDefaultAmountValue = true;

             		vm.applAmount = vm.applAmount > 0 ? vm.applAmount : vm.defaultAmmount;
                    vm.financedAmount = vm.applAmount;
                    
                    if(vm.minSum > vm.applAmount) {
                    	vm.applAmount =  vm.minSum;
                	}
                	if(vm.maxSum < vm.applAmount) {
                		vm.applAmount = vm.maxSum;
                	}
                	
	                calculate();
	        	    resizeWrap(getWrapHeight());
        	    }
        };
        
        function alertOnAppAmmountError(){
        	
        	vm.onApplErrorHide = true;
			var message = $translate.instant('modules.calculator.alert.wrongApplAmount', { min: vm.minSum, max: vm.maxSum });  
			if( lastErorMsg == message )
				AlertService.clear();
			else
				lastErorMsg = message;
			
			AlertService.error(message);	
        }
        
        loadTariffs();
        
        function loadTariffs(){
        	vm.mainData.read();
        }
        
        function resizeWrap(ht){
        	vm.tariffsWrap = ht;
        }
        
        $scope.$watch(
    	        function(){
    	        	return vm.calculator.tariffId;
    	        },
    	        function(newVal,oldVal){
    	        	if(newVal != oldVal) {
    	        		if(vm.applAmount < vm.financedAmount) vm.financedAmount = vm.applAmount;  
    	        	}
    	        }
        );

        $scope.$watch(
    	        function(){
    	        	return vm.personCode;
    	        },
    	        function(newVal,oldVal){
    	        	if(newVal != oldVal) {
    	        		vm.tariff.personCode = newVal;
    	        		loadTariffs();
    	        	}
    	        }
        );
        
        $scope.$watch(
	        function(){
	        	return $element.find('#calc').prop('offsetWidth');
	        },
	        function(){
	        	resizeWrap(getWrapHeight());
	        }
        );
        
        $scope.$watch(
    	    function(){
    	    	return vm.applAmount;
    	    },
    	    function(newValue,oldValue){
    	    	if(oldValue != newValue) {
    	    		if(newApplAmountIsValid(newValue)) calculate();
    	    	}
    	    }
        );

        function newApplAmountIsValid(newValue){
        	var response = false;
        	if(newValue > vm.maxSum || newValue < vm.minSum || newValue == null || isNaN(newValue)) alertOnAppAmmountError();
    		else {
    			AlertService.clear();
    			vm.onApplErrorHide = false;
    			applAmountCalcIsParent = true;
    			vm.financedAmount = newValue;
    			response = true;
    		}
        	return response;
        }
	    
        $element.find('input[name="applAmount"]')
	    	.bind("keyup", function (event) {
	    		vm.isDefaultAmountValue = false;
	    	}).bind("blur", function (event) {
	    	});
        
        $scope.$watch(
        	    function(){
        	    	return vm.financedAmount;
        	    },
        	    function(newValue,oldValue){
        	    	if(applAmountCalcIsParent) {
        	    		applAmountCalcIsParent = false;
        	    		return;
        	    	} else {
        	    		if(oldValue != newValue) {
            	    		if(newValue > vm.applAmount) {
            	    			var message = $translate.instant('modules.calculator.alert.wrongMaxFinAmount', { max: vm.applAmount });
            	    			if( lastErorMsg == message )
    	            				AlertService.clear();
    	            			else
    	            				lastErorMsg = message;
            	    			vm.onFinErrorHide = true;
            	    			AlertService.error(message);
            	    		} else {
            	    			AlertService.clear();
            	    			vm.onFinErrorHide = false;
            	    			calculate();
            	    		}
            	    	}        	    		
        	    	}
        	    }
            );
        
// 			for debug only        
//             $scope.$watch(
//             		function(){
//             			return vm.applAmount;
//             		},
//             		function(newVal,oldVal){
//             			console.log("ID N: " + newVal + " O: " + oldVal);
//             		}
//             );
        
		vm.validatorOptions =  {    
			  	rules: {
			  		amountRange: function(input) {
				              if (input.is("[data-role=numerictextbox]") && input.is("[name=applAmount]")) {
				                	  //for recovery after submit and fix error value
				                	  var response = newApplAmountIsValid(input.data("kendoNumericTextBox").value());
				                	  return response; 
				              } else return true;
			  		}
			  	}
		};
        
        function getWrapHeight() {
        	var ht = $element.find('#calc').prop('offsetHeight')-$element.find('#wrap').prop('offsetTop')-23;
        	return $window.innerWidth < 768 ? 'auto' : ht + 'px';
        }
        
        function calculate(){
        	var calculator = vm.calculator;
        	calculator.applAmount = vm.applAmount;
        	calculator.financedAmount = vm.financedAmount;
        	calculator.personCode = vm.personCode;
        	Calculator.send(calculator, function(response){
        		if(response) vm.calculator = response;
        	});
        }

        vm.infoLapa = function(){
        	console.log('Printing InfoLapa');
            var enc = escape(btoa(angular.toJson(vm.calculator)));
       		vm.pdflink = baseUrl + 'api/applications/calculator/docs/infolapa';
           	vm.pdf = vm.pdflink + "?rq=" + enc;
           	//vm.pdf = vm.pdflink + "?rq=" + enc + "&tmst="+ (new Date()).getTime();
       		$window.open(vm.pdf, '_blank');
        };
        
        vm.submit = function (longAppl) { // True - Long application, false - Short application
        	// strange hack for kendo validation - to show error on custom validation on submit
        	vm.validator.validate();
        	if(vm.validator.validate())
        		if(longAppl)
        			$state.go("modules.applications.new", {personCode: vm.personCode, applSum: vm.applAmount, tgrId: vm.calculator.tariffId, prtId: vm.calculator.prtId });
        		else
        			$state.go("modules.short-applications.new", {personCode: vm.personCode, applSum: vm.applAmount, tgrId: vm.calculator.tariffId, prtId: vm.calculator.prtId });
        	else return;
        };
        
        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
        	$rootScope.narrow = false;
        });
        
//        $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams) { 
//        	$rootScope.narrow = true;
//        });
    }
    
})();
