(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('DeleteAddressClientController', DeleteAddressClientController);

    DeleteAddressClientController.$inject = ['$uibModalInstance', '$state', 'Client', 'AlertService'];

    function DeleteAddressClientController ($uibModalInstance, $state, Client, AlertService) {
        var vm = this;
        vm.currentAddress = $state.params.currentAddress;
        vm.clnId = $state.params.clnId;
        if($state.params.adId == "") {
        	$uibModalInstance.dismiss('cancel');
        	AlertService.error("Client's address don't get for deleting");
        	return;
        }
        
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete (adrId) {
        	AlertService.success("Client's address deleted successfully, id = " + adrId);
        	$uibModalInstance.close(true);
            Client.deleteAddress({clnId: vm.clnId, adrId: adrId},
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
