(function() {
    'use strict';

    angular
        .module('sigmaApp')
        .controller('AccruesListLoanController', AccruesListLoanController);

    AccruesListLoanController.$inject = ['$scope', '$state', '$stateParams', 'Loan', 'defaultPageSize', '$filter', '$window', '$translate'];

    function AccruesListLoanController ($scope, $state, $stateParams, Loan, defaultPageSize, $filter, $window, $translate) {
        var vm = this;
        vm.kendo = kendo;
		var components = {
				data: []
			};
		var yearRates = {
				data: []
			};
		var dayRates = {
				data: []
			};
        console.log("AccrueListLoanController started");
        vm.loanAccruesDataSource = new kendo.data.DataSource({
    		schema: {
			      model: {
			        fields: {
			        	"schNum": { type: "string" },
			        	"component": { type: "string" },
			        	"componentTr": { type: "string" },
			        	"dateFrom": { type: "date" },
			        	"dateTill": { type: "date" },
			        	"bal": { type: "number" },
			        	"days": { type: "number" },
			        	"amount": { type: "number" }, 
				        "yearRate": { type: "number" },
			        	"dayRate": { type: "number" }, 
			        	"graceOn": {type: "boolean"}
//			        	"basedy": { type: "number" },
//				        "loanId": { type: "number" },
			        }
			      },
   				  parse: function(d){
 		              	$.each(d, function (idx, elem) {
 		              		elem.componentTr = elem.component ? $translate.instant('modules.accounting.component.' + elem.component) : "";
 		              		
 		              		var component = {
 		              				component: elem.component
 		              		};
 		              		var foundComponent = $filter('filter')(components.data, {component: elem.component}, true);
 		                    if(foundComponent.length < 1) (components.data).push(component);
 		                    
 		              		var yearRate = {
 		              				yearRate: elem.yearRate
 		              		};
 		              		var foundYearRate = $filter('filter')(yearRates.data, {yearRate: elem.yearRate}, true);
 		                    if(foundYearRate.length < 1) (yearRates.data).push(yearRate);
 		                    
 		              		var dayRate = {
 		              				dayRate: elem.dayRate
 		              		};
 		              		var foundDayRate = $filter('filter')(dayRates.data, {dayRates: elem.dayRate}, true);
 		                    if(foundDayRate.length < 1) (dayRates.data).push(dayRate);
 		                });
 		              	return d;
 				  }
    		},
			transport : {
				read : function(e) {
					Loan.getAccrues(
							{loan_no : $state.params.loan_no}, 
							function(response) {
								e.success(response);
							}, 
							function(error) {
								console.log('Accrues list error');
								e.error(error);
							})
							.$promise;;
				}
			},
			aggregate: [
                        { 
                        	field: "amount", 
                        	aggregate: "sum"
                        }                            
            ],
//            pageSize: defaultPageSize,
	        serverPaging: false,
	        serverFiltering: false,
	        serverSorting: false,
	        sort: [
	               { field: "dateFrom", dir: "desc" },
	               { field: "schNum", dir: "desc" },
	               { field: "component", dir: "asc" },
	        ]
        });
        
        vm.loanAccruesOptions = {
        		scrollable: {
    	            virtual: true
    	        },
    	        height: function(){
    	        	return $window.innerHeight/2;
    	        },
    	        sortable: true,                
    	        pageable: {
    	        	refresh: true,
    	        	previousNext: false,
    	        	numeric: false,
    	        	info:false,
    	        	messages: {
    	        	      empty: "No data"
    	        	    }
    	        },
    			filterable : {
    				extra : false,
    				operators : {
    					string : {
    						contains : "contains",
    					},
    					number : {
    						eq : "Equal to",
    					}
    				}
    			},
                toolbar: [
                          { 
  		                    template: "<span style='line-height:22px;' translate='modules.loan.titles.accrues'></span>"
  		                    		 + "<button  has-authority='ROLE_LATEKO' style='margin-right:1em;' class='pull-right btn btn-default btn-xs k-grid-excel'>"
  		                    		 + "<span class='fa fa-file-excel-o'></span>"
  		                             + "&nbsp;<span translate='modules.loan.excel'>"
  		                             + "</span></button>"
                            }
                ],
                excel: {
                	fileName: "loan-accrues.xlsx",
                    allPages: true
                },
                excelExport: function(e) {
                	e.workbook.sheets[0].columns.forEach(function(column){
                		column.autoWidth = true;
                	});
//                	e.workbook.sheets[0].columns = [
//                		{ width:100 },
//                		{ width:300 },
//                		{ width:400 },
//                		// ...
//                	];
                    e.workbook.sheets[0].rows.forEach(function(row){
                      if(row.type != 'data'){
                      	row.cells.forEach(function(cell){
                          cell.value = $translate.instant(cell.value);
                        });
                      }
                	});
                },                
                columns: [ 
                          {
	                         field:"schNum",
	 	                     title: "{{'modules.loan.table.schNum'|translate}}",
	                         filterable: true,
	                         sortable: true,
	                         attributes: { "class": "text-left" },
	                         width: "6ch"
                          },{
	                         field:"component",
	                         title: "{{'modules.loan.table.component'|translate}}",
	                         filterable : {
			                 	multi : true,
			                    dataSource : new kendo.data.DataSource(components),
			                    dataTextField : "component"
							 },
							 template: "<span kendo-tooltip k-position=\"'top'\" k-content=\"'#= componentTr #'\">#= component #</span> ",
	                         sortable: true,
	                         width: "15ch"
                          },{
 	                         field:"dateFrom",
	                         title: "{{'modules.loan.table.from'|translate}}",
	                         filterable: true,
	                         sortable: true,
	                         format: "{0:d}",
	                         width: "11ch"
                          },{
  	                         field:"dateTill",
 	                         title: "{{'modules.loan.table.to'|translate}}",
 	                         filterable: true,
 	                         sortable: true,
 	                         format: "{0:d}",
 	                         width: "11ch"
                           },{
	                         field: "bal",
	                         title: "{{'modules.loan.table.bal'|translate}}",
	                         sortable: true,
	                         filterable: true,
							 template: "#= (bal == 0 || bal == null) ? '' : kendo.toString(bal, 'n2') #",
	                         attributes: {class:"text-right"},
	                         width: "10ch"
                          },{
	                         field: "days",
	                         title: "{{'modules.loan.table.days'|translate}}",
	                         sortable: true,
	                         filterable: true,
							 template: "#= (days == 0 || days == null) ? '' : kendo.toString(days, 'n0') #",
	                         attributes: {class:"text-right"},
	                         footerTemplate: "{{'modules.loan.table.total'|translate}}",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "8ch"
                          },{
	                         field: "amount",
	                         title: "{{'modules.loan.table.amount'|translate}}",
	                         sortable: false,
	                         filterable: false,
							 template: "#= (amount == 0 || amount == null) ? '' : kendo.toString(amount, 'n2') #",
	                         attributes: {class:"text-right"},
	                         aggregates: ["sum"], 
	                         footerTemplate: "#=kendo.toString(sum, 'n2')#",
	                         footerAttributes: { style: "text-align: right; " },
	                         width: "10ch"
                          },{
	                         field: "yearRate",
	                         title: "{{'modules.loan.table.yearRate'|translate}}",
	                         sortable: true,
	                         filterable: true,
							 template: "#= (yearRate == 0 || yearRate == null) ? '' : kendo.toString(yearRate/100, 'p2') #",
	                         attributes: {class:"text-right"},
	                         width: "9ch"
                          },{
	                         field: "dayRate",
	                         title: "{{'modules.loan.table.dayRate'|translate}}",
	                         sortable: true,
	                         filterable: true,
							 template: "#= (dayRate == 0 || dayRate == null) ? '' : kendo.toString(dayRate/100, 'p6') #",
	                         attributes: {class:"text-right"},
	                         width: "11ch"
                          },{
	                         field: "graceOn",
	                         title: "Grace",
	                         sortable: false,
	                         filterable: false,
	                         minScreenWidth: 1130,
							 template: "#= (graceOn) ? 'GRACE' : '' #",
	                         width: "7ch"
                          }] 
                          
        };
        
       
    }

})();
