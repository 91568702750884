(function() {
	'use strict';
	angular.module('sigmaApp').factory('Card', Card);

	Card.$inject = [ '$resource', 'baseUrl', 'DateUtils', '$filter'];

	function Card($resource, baseUrl, DateUtils, $filter) {
		var resourceUrl = baseUrl + 'api/loans/:loan_no';

		return $resource(resourceUrl,{},
				{
					'query' : {
						method : 'GET',
						url: resourceUrl + '/cards',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'get' : {
						method : 'GET',
						url: resourceUrl + '/cards',
						isArray : true,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getCardByToken' : {
						method : 'GET',
						url: resourceUrl + '/cards/:token',
						isArray : false,
						transformResponse : function(data) {
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getHolder' : {
						method : 'GET',
						url: resourceUrl + '/card-holder',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getHolderAddresses' : {
						method : 'GET',
						url: resourceUrl + '/card-holder/addresses',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'getHolderEmails' : {
						method : 'GET',
						url: resourceUrl + '/card-holder/emails',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								try {
									data = angular.fromJson(data);
								} catch(e){
									data = "";
								}
							}
							return data;
						}
					},
					'sendCredentialsEmail': {
						method:'POST',
						params: { username: null, password: null, optionalEmail: null },
						url: resourceUrl + '/card-holder/emails',
					},
					'getDefault' : {
						method : 'GET',
						url: resourceUrl + '/card-holder/default',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'getDefaultNoname' : {
						method : 'GET',
						url:  baseUrl + 'api/loans/cards/noname/default/:prefix',
						isArray : false,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'nonameCardGenerate' : {
						method : 'POST',
						url:  baseUrl + 'api/loans/cards/noname/generate',
						transformRequest : function(data) {
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'getEvents' : {
						method : 'GET',
						url: baseUrl + 'api/line/gps/events/bytoken/:token',
						isArray : true,
						transformResponse : function(data) { 
							if (data) {
								data = angular.fromJson(data);
							}
							return data;
						}
					},
					'updateHolder': {
						method:'PUT',
						url: resourceUrl + '/card-holder',
						transformRequest : function(data) {
		//					data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'createHolder' : {
						method : 'POST',
						url: resourceUrl + '/card-holder',
						transformRequest : function(data) {
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'linkNonameCard' : {
						method : 'POST',
						url: resourceUrl + '/card-holder/link/:token',
						transformRequest : function(data) {
							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							data.dateOfBirth = DateUtils.convertLocalDateToServer(data.dateOfBirth);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data)
							}
							return response;
						}
					},
					'activate': {
						method:'PUT',
						url:  baseUrl + 'api/loans/cards/activate',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'createAnotherCard': {
						method:'POST',
						url: resourceUrl + '/cards/create',
						transformRequest : function(data) {
							return angular.toJson(data);
						}
					},
					'status': {
						method:'PUT',
						url:  baseUrl + 'api/loans/cards/status',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'pin': {
						method:'PUT',
						url:  baseUrl + 'api/loans/cards/pin',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'generatePin': {
						method:'PUT',
						url:  baseUrl + 'api/loans/cards/generatepin',
						transformRequest : function(data) {
//							data.applDate = DateUtils.convertLocalDateToServer(data.applDate);
							return angular.toJson(data);
						},
						transformResponse : function(data) {
							var response = {};
							if(data) {
								response = angular.fromJson(data);
							}
							return response;
						}
					},
					'sendPin': {
						method:'POST',
						url: resourceUrl + '/cards/:token/sendpin'
					}
					
				});
	}
})();